import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader,  IonIcon,  IonItem,  IonList, IonPage, IonRow, IonSpinner, IonTitle, IonToolbar, useIonActionSheet, useIonModal, useIonToast } from "@ionic/react"
import { cameraOutline, close } from "ionicons/icons"

import { useForm,SubmitHandler} from "react-hook-form";

import { ProfilePhotoModal } from '../../components/Avatar'

// form definitions
import { Input, Textarea, FormValues, Select, defaultFormValues } from '../../forms/components/ProfileFormFields'
//import { Input, Textarea, Select, FormValues, myFormValues } from '../../forms/components/ProfileFormFields'
import { useProfileFields,  } from '../../forms/data/profileFields'

import React, { useEffect } from "react";
import { User } from "../../data/user";
import './EditProfileModal.css'
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";

import { pick, mergeWith, isEqual} from 'lodash'

interface EditProfileModalProps {
    onDismiss: (hasUpdatedUser :  boolean) => void
    user : User
    saveUser: (user: User) => Promise<void>;
}


const EditProfileModal: React.FC<EditProfileModalProps> = ({onDismiss,user,saveUser}) => {

  const [avatar, setAvatar] = React.useState("")
  const [isAvatarChanged, setAvatarChanged] = React.useState(false)
  const [presentIonActionSheet] = useIonActionSheet();
  const [showToast] = useIonToast();
  const profileFormFields = useProfileFields();
  
  // avatar editor
  const [presentAvatarEditor, dismissAvatarEditor] = useIonModal(ProfilePhotoModal, {
    onDismiss: (data: string, role: string) => dismissAvatarEditor(data, role), 
    url: avatar, 
    user: user
  });
    
  function mergeFormData(defaults: FormValues, data?: User, removeExtras?: boolean) {
    if (!data) return defaults;

    // This should merge everything on data, and take
    // defaults where applicable.
    // It's only shallow merge, and it uses the "data" if it isn't null or undefined.
    // ?? should do it, so it will allow "" and 0 to be kept on the data object
    // Use removeExtras to take out props from the data object
    // that aren't on the default object

    return mergeWith({},
        removeExtras ? pick(data, Object.keys(defaults)) : data,
        defaults,
        (a: string, b: string) => {return a ?? b;});
  }
  
  const defaultValues = mergeFormData(defaultFormValues, user, true)
  const { register, handleSubmit, setValue, control, getValues, formState: { errors, isSubmitting } } = useForm<FormValues>(
    {mode: 'onChange',defaultValues: mergeFormData(defaultFormValues, user, true)});  // default is formvalues merged with user
  

  useEffect(() =>{

    if (user){
      setAvatar(user.avatar)   
    }
  }, [user,setAvatar])

  const handleCancel = () => {
    // compare default values with current
    const currentValues = getValues();
    if (!isEqual(defaultValues, currentValues)){
      discardEdits()  // ask if discard
    }
    else {
      onDismiss(false)
    }
  }

  // dialog box when cancel is pressed and form edited
  const discardEdits = () => {
  
    presentIonActionSheet({
      header: 'Do you want to discard your changes?',
      buttons: [
        { 
          text: 'Keep Editing',
          role: 'cancel',
          data: {
            action: 'keep'
          }
        },
        { 
          text: 'Discard',
          role: 'destructive',
          data: {
            action: 'discard'
          }
        },

      ],
      onDidDismiss: ({ detail }) => {
        if (detail.data?.action === 'discard'){
          onDismiss(false)
        }
      },
    })
  }

  // dialog box when save is pressed and form edited
  const saveChanges = async (data: FormValues) => {
    presentIonActionSheet({
      header: 'Do you want to save your changes?',
      buttons: [
        { 
          text: 'Save Changes',
          role: 'destructive',
          data: {
            action: 'confirm'
          }
        },
        { 
          text: 'Cancel',
          role: 'cancel',
          data: {
            action: 'cancel'
          }
        },

      ],
      onDidDismiss: async ({ detail }) => {
        if (detail.data?.action === 'confirm'){
          const userUpdate = { ...user, ...data };
          console.log(userUpdate)
          await saveUser(userUpdate);
          onDismiss(true) 
          showToast({
            message: "Your profile has been updated.",
            color: 'success',
            duration: 3000,
            buttons: [{ icon: close }],
          })
        }
      },
    })
  }
  const handlePersonUpdate: SubmitHandler<FormValues> = async (data: FormValues) => {
    // user has pressed save ask if want to update, then save
    if (!isEqual(defaultValues, data)){
      saveChanges(data)   
    }
    else if (isAvatarChanged){
      showToast({
        message: "Your profile picture has been saved.",
        color: 'success',
        duration: 3000,
        buttons: [{ icon: close }],
      })
      onDismiss(false)
    }
    else {
      showToast({
        message: "No changes to profile made.",
        color: 'warning',
        duration: 3000,
        buttons: [{ icon: close }],
      })
      onDismiss(false) 
    }
      
  }

  const editAvatar = async () => {
    
    presentAvatarEditor({
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === 'save') {
          //
          // console.log(`Save image, ${ev.detail.data}`);
          // save avatar back to firebase        
          const userUpdate : User = { ...user, ...{avatar: ev.detail.data}};
          
          saveUser(userUpdate);
          setAvatar(ev.detail.data)
          setAvatarChanged(true)
          showToast({
            message: "Your profile picture has been updated.",
            color: 'success',
            duration: 3000,
            buttons: [{ icon: close }],
          })
        }
      },
    });
  }
/*
  const setFormValues = (user: User) => {
    myFormValues.forEach((elem)=>{
      setValue(elem as FormValueTypes, user[`${elem}`]);
    })
    setValue('fullName', user['fullName']);
  }
  */

  
  /*
  userDefault.practicingFrom = new Date().getTime();
  userDefault.occupation =  'gooooo'
  console.log({...userDefault, ...authInfo.user})
  setUser({...userDefault, ...authInfo.user});
  */

  return (
    <IonPage >
      
    <IonHeader>
      <IonToolbar color="primary">
        <IonButtons slot="start">
          <IonButton fill='clear' onClick={handleCancel}>Cancel</IonButton>
        </IonButtons>
        <IonTitle>Edit Profile</IonTitle>
        <IonButtons slot="end">
        <IonButton  fill='clear' form='hook-form' type="submit" disabled={isSubmitting}>
            { isSubmitting && <IonSpinner></IonSpinner>}Save
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
    <IonContent color={"midblue"}>
     <IonGrid >
        <IonRow class="ion-justify-content-center">
          <IonCol size="3">
            <center>   
            <img alt="avatar" src={avatar} />
            </center>
          </IonCol>
          <IonCol size="8">
          <IonButton size="small" onClick={(e) => {e.preventDefault(); editAvatar()}}><IonIcon slot="start" color="hotcoral" icon={cameraOutline}></IonIcon>Edit Profile Picture</IonButton>
          <br/><br/>
          <p><b>Membership:</b> { user.isSupervisor && <span>Supervisor</span> }   { !user.isSupervisor && <span>Member</span> } </p>
          </IonCol>

        </IonRow>
      </IonGrid>
      
      <IonList className='ionListBackground' inset={true} color={"primary"}>
      
      <form id='hook-form' onSubmit={handleSubmit(handlePersonUpdate)}>
      { profileFormFields.map((field,index) => {
          if (field.type === 'Input'){
            return ( <IonItem color="secondary" key={`${field.formName}${index}`}><Input inputType={field.inputType} label={field.label} formName={field.formName} placeholder={field.placeholder} register={register}  errors={errors} /> </IonItem>)
          }
          if (field.type === 'Textarea'){
            return ( <IonItem color="secondary" key={`${field.formName}x`}><Textarea inputType={field.inputType} label={field.label} formName={field.formName} placeholder={field.placeholder} register={register} errors={errors}  /></IonItem> )
          }
          if (field.type == 'Select'){
            return ( <IonItem color="secondary" key={`${field.formName}x`}><Select label={field.label} formName={field.formName} placeholder={field.placeholder} choose={field.options} control={control} setValue={setValue} errors={errors} /></IonItem>)
          }
      })} 
      </form>
  
      
      </IonList>
      
      
    </IonContent>
    </IonPage>     
  )
}

export default EditProfileModal



/*

{ profileFormFields.map((field,index) => {

  if (field.type === 'Input'){
    return ( <IonItem color="secondary" key={`${field.formName}${index}`}><Input inputType={field.inputType} label={field.label} formName={field.formName} placeholder={field.placeholder} register={register} onChange={onIsEdited} /> </IonItem>)
  }
  if (field.type === 'Textarea'){
    return ( <IonItem color="secondary" key={`${field.formName}x`}><Textarea inputType={field.inputType} label={field.label} formName={field.formName} placeholder={field.placeholder} register={register} onChange={onIsEdited} /></IonItem> )
  }
  if (field.type == 'Select'){
    return ( <IonItem color="secondary" key={`${field.formName}x`}><Select label={field.label} formName={field.formName} placeholder={field.placeholder} choose={field.options} control={control} setValue={setValue} /></IonItem>)
  }
})}

*/
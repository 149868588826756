// Page generated from markdown docs/pages/


import React from 'react';
import { IonHeader, IonBackButton, IonToolbar, IonTitle, IonContent, IonPage, IonButtons } from '@ionic/react';

const Terms: React.FC = () => {
    
    return (
        <IonPage >
          <IonHeader>
            <IonToolbar color="primary">
                <IonButtons slot="start">
                    <IonBackButton></IonBackButton>
            
                </IonButtons>
              <IonTitle>Terms and Conditions</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding"> 
    
            
    
            <p>These terms and conditions govern the use of <strong><a href="https://app.prosupervise.com">app.prosupervise.com</a></strong> (the &quot;App&quot;).  This app is owned and operated by Prosupervise Ltd (&quot;we&quot;, &quot;us&quot;, &quot;our&quot;).</p>
<h2>General</h2>
<p>This web app is owned and operated by: ProSupervise Ltd with a registered address at Moyveela, Oranmore, Co Galway H91V5Y6.</p>
<p>By using our app, which includes access through other digital platforms such as our app, you confirm that you accept these Terms of Service (“terms”) as binding upon you, including additional terms and conditions and policies referenced herein and/or available by hyperlink, and that you agree to comply with them. If you do not agree to these terms, you must not use our app.</p>
<p>You must be at least 18 (eighteen) years of age before you can use this app. By using this app, you agree that vou are at least 18 ears of age and you may legally adhere to this Agreement. Prosupervise Ltd., assumes no responsibility for liabilities related to age misrepresentation.</p>
<p>These terms and conditions were last updated on Thu Feb 22 2024.</p>
<h3>Confidentiality</h3>
<p>Confidentiality is an essential part of all our services and proSupervise Ltd will at all times, operate with the highest standard of security and confidentiality.</p>
<h3>Protection of Intellectual Property</h3>
<p>The intellectual property in the materials in proSupervise Ltd and on our website are owned by or licensed to ProSupervise Ltd. You may download ProSupervise Ltd to view, use, and display the application on your mobile device for your personal use only.</p>
<p>This constitutes the grant of a licence, not a transfer of title. This licence shall automatically terminate if you violate any of these restrictions or these Terms of Service and may be terminated by ProSupervise Ltd at any time.</p>
<p>From time to time, ProSupervise Ltd may use examples of work they have created or businesses that have worked with to illustrate educative points. Any attempt by the Customer to enter the markets that ProSupervise Ltd operates in after gaining possession of such educative materials will be classed as entering competition with ProSupervise Ltd.</p>
<h3>Legal Advice</h3>
<p>ProSupervise Ltd reserves the right to consult with their legal advisors in all cases of Court orders for access to personal records and/or all other information requests.</p>
<p>Where other names have been mentioned through the course of interaction with ProSupervise Ltd then they reserves the right to redact these names on all shared documentation.</p>
<h2>proSupervise Ltd Terms &amp; Conditions of Service</h2>
<h3>Limitations of Use</h3>
<p>By using proSupervise Ltd and our website, you warrant on behalf of yourself, any entity who you represent who has entered into these Terms of Service, and your users that you will not:</p>
<ul>
<li>modify, copy, prepare derivative works of, decompile, or reverse engineer proSupervise Ltd or any materials and software contained within ProSupervise Ltd or on our website.</li>
<li>remove any copyright or other proprietary notations from proSupervise Ltd or any materials and software contained within ProSupervise Ltd or on our website.</li>
<li>transfer proSupervise Ltd or any of its associated materials to another person or &quot;mirror&quot; the materials on any other server.</li>
<li>knowingly or negligently use proSupervise Ltd or any of its associated services in a way that abuses or disrupts our networks or any other service ProSupervise Ltd provides.</li>
<li>use proSupervise Ltd or its associated services to transmit or publish any harassing, indecent, obscene, fraudulent, or unlawful material.</li>
<li>use proSupervise Ltd or its associated services in violation of any applicable laws or regulations.</li>
<li>use proSupervise Ltd to send unauthorised advertising or spam.</li>
<li>harvest, collect, or gather user data without the user’s consent; or</li>
<li>use proSupervise Ltd or its associated services in such a way that may infringe the privacy, intellectual property rights, or other rights of third parties.</li>
</ul>
<h3>Connectivity</h3>
<p>You should be aware that there are certain things that proSupervise Ltd will not take responsibility for. Certain functions of the App such as the ability to have access to your account will require the App to have an active internet connection. The connection can be Wi-Fi, or provided by your mobile network provider, but proSupervise Ltd cannot take responsibility for the App not working at full functionality if you don’t have access to Wi-Fi, or you don’t have any of your data allowance left.</p>
<p>If you’re using the App outside of an area with Wi-Fi, remember that your terms of agreement with your mobile network provider will still apply. As a result, you may be charged by your mobile provider for the cost of data for the duration of the connection while accessing the App, or other third-party charges. In using the App, you’re accepting responsibility for any such charges, including roaming data charges if you use the App outside of your home territory (i.e. region or country) without turning off data roaming. If you are not the bill payer for the device on which you’re using the App, please be aware that we assume you have received permission from the bill payer to use the App. Location services are in use on the App which will monitor your location and usage of the App.</p>
<h3>Indemnification, Liability, And Limitation</h3>
<p>The express terms and conditions of these terms shall apply in place of all warranties, conditions, terms, representations, statements, undertakings and obligations whether expressed or implied by statute, common law, custom, usage or otherwise, all of which are excluded to the fullest extent permitted by law.</p>
<p>Insofar as it is lawful to do so, we do not accept liability of any description including liability for negligence or any damages whatsoever arising out of or in connection with the viewing, use or performance of this App or its contents.</p>
<p>In the event that you reproduce, display, transmit, distribute or otherwise exploit the structure, information, material, or any portion thereof, in any manner not authorised by us, or if you otherwise infringe any intellectual property rights relating to the structure, information, photographs, prints or this app, you unconditionally and irrevocably agree to indemnify us and keep us indemnified from and against any and all losses, expenses, costs or damages, including reasonable lawyers’ fees, incurred by you or others as a result of unauthorised use of the above and/or your breach of these terms.  You unconditionally and irrevocably agree to indemnify us and keep us indemnified from and against all and any losses, costs, claims, liabilities, damages, demands and expenses suffered or incurred by us and arising from any claim brought by any third party against us howsoever arising from or in connection with: these terms; the supply of the services and/or digital goods pursuant to the terms; your use of the services and/or digital goods; or your fraud or negligence.  For the avoidance of doubt, we will under no circumstances whatsoever be liable to you, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, arising under or in connection with these terms for any loss of profits, goodwill, sales, business, or revenue; loss or corruption of data, information or software; loss of business opportunity or anticipated savings; or any indirect or consequential loss.  Without prejudice to other clauses in these terms, our total liability arising under or in connection with these terms, whether arising in contract, tort (including negligence) or restitution, or for breach of statutory duty or misrepresentation, or otherwise, shall in all circumstances be limited to the purchase price paid for the relevant services that is/are the subject of a claim.</p>
<h3>Errors, Inaccuracies And Omissions</h3>
<p>Occasionally there may be information on our App that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, and offers. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information on the App or on any related website is inaccurate at any time without prior notice (including after you have submitted your order).  We undertake no obligation to update, amend or clarify information on the App or on any related website, including without limitation, pricing information, except as required by law. No specified update or refresh date applied on the App or on any related website, should be taken to indicate that all information on the App or on any related website has been modified or updated.</p>
<h3>Social Media Platforms</h3>
<p>Communication, engagement, and actions taken through external social media platforms that we participate on are custom to the terms as well as the data protection and privacy policies and notices held with each social media platform respectively.  You are advised to use social media platforms wisely and communicate / engage upon them with due care and caution regarding your own privacy and personal details.  We will never ask for personal or sensitive information through social media platforms and encourage you when wishing to discuss sensitive details to contact us through primary communication channels such as by telephone or email.</p>
<h3>Links</h3>
<p>proSupervise Ltd has not reviewed all of the sites linked to ProSupervise Ltd or on its corresponding website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement, approval or control by ProSupervise Ltd of the site. Use of any such linked website is at your own risk and we strongly advise you make your own investigations with respect to the suitability of those sites.</p>
<h3>Prohibited Activity</h3>
<p>Our Products must not be used to transmit, distribute or store any material in violation of any applicable law. This includes but isn’t limited to:</p>
<ul>
<li>any material protected by copyright, trademark, trade secret or other intellectual property right used without proper authorization, and</li>
<li>any material that is obscene, defamatory, constitutes an illegal threat or violates export control laws.</li>
<li>The customer is solely responsible for all material they input, upload, disseminate, transmit, create or publish through or on our Products, and for obtaining legal permission to use any works included in such material.</li>
<li>Our Products must not be used for the purpose of intentionally or recklessly introducing viruses or malicious code into our Products and systems.</li>
<li>Our Products must not be used for purposely engaging in activities designed to harass another group or individual. Our definition of harassment includes but is not limited to denial-of-service attacks, hate-speech, advocacy of racial or ethnic intolerance, and any activity intended to threaten, abuse, infringe upon the rights of or discriminate against any group or individual.</li>
</ul>
<p>Other activities considered unethical, exploitative and malicious include:</p>
<ul>
<li>Using our facilities to obtain (or attempt to obtain) services from another provider with the intent to avoid payment.</li>
<li>The unauthorised access, alteration or destruction (or any attempt thereof) of any information about our customers or end-users, by any means or device.</li>
<li>Using our facilities to interfere with the use of our facilities and network by other customers or authorised individuals.</li>
<li>Publishing or transmitting any content of links that incite violence, depict a violent act, depict child pornography or threaten anyone’s health and safety.</li>
<li>Any act or omission in violation of consumer protection laws and regulations.</li>
<li>Any violation of a person’s privacy.</li>
<li>Our Products may not be used by any person or entity, which is involved with or suspected of involvement in activities or causes relating to illegal gambling; terrorism; narcotics trafficking; arms trafficking or the proliferation, development, design, manufacture, production, stockpiling, or use of nuclear, chemical or biological weapons, weapons of mass destruction, or missiles; in each case including any affiliation with others whatsoever who support the above such activities or causes.</li>
</ul>
<h3>Data Protection, Privacy And Security</h3>
<p>Your rights to data protection and privacy, including security over data, are very important to us.  We treat personal data obtained using this App as private and are committed to providing you with secure access to our online service.  This App processes information from you as per our <a href="/privacy">Privacy Policy</a> and <a href="/cookie">Cookie Policy</a>.  When you, amongst other actions, visit our app, enquire about services or send e-mails to us you understand that subsequent data processing will be done as detailed in our Privacy Statement.</p>
<h3>Governing Law And Disputes</h3>
<p>This App is controlled, and operated from the Republic of Ireland and therefore governed by Irish law, subject to the terms of Public International Law.  In the event of any dispute of any nature whatsoever arising between the parties on any matter provided for in, or arising out of this agreement, the Irish law will apply and the appropriate courts of the Republic of Ireland will have jurisdiction.</p>
<h3>Variation Of These Terms &amp; Conditions</h3>
<p>We reserve the right to make changes to this app, these terms, and the other information contained in this App at any time and without notice.  Please refer to these terms when you visit the App as they may change from time to time.</p>
<h3>Severability</h3>
<p>In the event that any provision of these terms is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these terms, such determination shall not affect the validity and enforceability of any other remaining provisions.</p>
<h3>Waiver</h3>
<p>The failure of us to exercise or enforce any right or provision of these terms shall not constitute a waiver of such right or provision.</p>
<h2>Entire Agreement</h2>
<p>These terms and any policies or operating rules posted by us on this App or in respect to our App constitutes the entire agreement and understanding between you and us and govern your use of the app, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of these terms).  Any ambiguities in the interpretation of these terms shall not be construed against the drafting party.</p>
<h2>About This Policy</h2>
<p>This policy outlines a non-exclusive list of activities and intent we deem unacceptable and incompatible with our brand.</p>
<p>We reserve the right to modify this policy at any time by publishing the revised version on our website. The revised version will be effective from the earlier of:</p>
<ul>
<li>the date the customer uses our Products after we publish the revised version on our website; or</li>
<li>30 days after we publish the revised version on our website.</li>
</ul>
<h2>Governing Law</h2>
<p>These Terms of Service are governed by and construed in accordance with the laws of Ireland. You irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</p>
<h2>Contact Information</h2>
<p>You may contact us by e-mail at the following address: <strong><a href="mailto:info&commat;prosupervise&period;.com">info@prosupervise.com</a></strong> or via our <strong><a href="https://www.prosupervise.com/contact123">Contact form</a></strong></p>
<p>Contact information published on this App is published for the purpose of users or prospective users contacting us about services offered to them.  This information should not be considered as made manifestly public for the purposes of general marketing contact.</p>

          </IonContent>
    
        </IonPage>
      );
};   

export default Terms;
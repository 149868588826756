import { IonButton, IonButtons, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonMenuButton, IonPage, IonRouterLink, IonRow, IonText, IonTitle, IonToolbar } from '@ionic/react';
import {

  
} from "react-router-dom";
import './Home.css';
import { Action } from '../forms/components/Action';

const Home: React.FC = () => {
  
  return (
    <IonPage id="homepage" className="homePage">
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton ></IonMenuButton>
          </IonButtons>
          <IonTitle>Prosupervise</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        
        <div className="getStarted">
          <IonGrid>
            <IonRow className={ `ion-text-center ion-justify-content-center ` }>
              <IonCol size="11" className="headingText">
                <img src="/assets/img/proSupervise-Logo-V1.png" alt="" width="25%" className="slide-image" />
              </IonCol>
              <IonCol size="11" className="t" color="primary">
                <IonText color="primary">Looking for a <b>Supervisor</b>?</IonText>
              </IonCol>
            
              <IonRouterLink routerLink="/directory" className="custom-link">
                <IonCol size="11">
                  <IonButton className="" >Access our Supervisor Directory &rarr;</IonButton>
                </IonCol>
              </IonRouterLink>
              <IonCol size="11" className="" color="primary">
                <IonText color="primary">-- or --</IonText>
              </IonCol>
              <IonCol size="11" className="t" color="primary">
                <IonText color="primary">Are you a <b>Supervisor</b> looking for work and advice?</IonText>
              </IonCol>
            
              <IonRouterLink routerLink="/register/true" className="custom-link">
                <IonCol size="11">
                  <IonButton className="" >Register as a Supervisor &rarr;</IonButton>
                </IonCol>
              </IonRouterLink>
              <IonCol size="11" className="" color="primary">
                <IonText color="primary">-- or --</IonText>
              </IonCol>

              <IonCol size="11" className="" color="primary">
                <IonText color="primary">Are you looking for <b>Supervision</b> and want help finding someone?</IonText>
              </IonCol>
            
              <IonRouterLink routerLink="/register/false" className="custom-link">
                <IonCol size="11">
                  <IonButton className="" >Register to Book a consultation &rarr;</IonButton>
                </IonCol>
              </IonRouterLink>

            </IonRow>
          </IonGrid>
        </div>
        
        </IonContent>
        <IonFooter>
          <IonGrid>
            <Action message="Already got an account?" text="Login" link="/login" />
          </IonGrid>
        </IonFooter>
      
      </IonPage>
    );
};

export default Home;

import { IonPage, IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle, IonContent, IonButton, IonCol, IonGrid, IonRow, IonRadio, IonRadioGroup, useIonToast } from "@ionic/react";
import React from "react";
import { usePostHog } from "posthog-js/react";
import { close } from 'ionicons/icons';

const CookiePage: React.FC = () => {
    const [cookieSelection, setCookieSelection] = React.useState('')
    const [status,setStatus] = React.useState('You have not accepted or decline analytics cookies.')
    const [enableSelection, setEnableSelection] = React.useState(false)
    const [showToast] = useIonToast();

    const OnRadioSelect = ( value : string ) => {
        setCookieSelection(value)
        setEnableSelection(true)
    }
    const posthog = usePostHog();

    React.useEffect(() => {
        if (cookieSelection === ''){
            if (posthog.has_opted_out_capturing()){
                setCookieSelection('decline')
                setStatus('You currently have declined analytics cookies.')
            }
            if (posthog.has_opted_in_capturing()){
                setCookieSelection('accept')
                setStatus('You currently have accepted analytics cookies.')
            }
        }

    },[posthog, cookieSelection,status])

    const saveCookieSelection = async () => {
        if (cookieSelection === "accept"){
            posthog.opt_in_capturing()
            setStatus('You currently have accepted analytics cookies.')
        }
        if (cookieSelection === "decline"){
            posthog.opt_out_capturing()
            setStatus('You currently have declined analytics cookies.')
        }

        showToast({
            message: 'Your selection has been saved.',
            color: 'success',
            duration: 3000,
            buttons: [{ icon: close }],
        })
    }

    return (
        <IonPage >
          <IonHeader>
            <IonToolbar color="primary">
                <IonButtons slot="start">
                    <IonBackButton defaultHref="/tabs/home"></IonBackButton>       
                </IonButtons>
              <IonTitle>Cookie Policy</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">  
            <p>The <a href="/">Prosupervise App</a> puts small files (known as &quot;cookies&quot;) on your computer.</p>
            <p>These cookies are used for the entire website.</p>
            <p>They are only set when Javascript has been enabled on your browser and you have accepted them via the cookie banner displayed on the bottom of the page.   Javascript is enabled by default on the browser.   If you choose to disable Javascript, then cookies are not enabled and the information on this page does not apply.</p>
            <h2>Analytics Cookie</h2>
            <p>We use <a href="http://posthog.com">PostHog</a> software to understand how people use the Prosupervise App.   This is to help improve the website and to ensure it meets the needs of its users.</p>
            <p>We do not collect or store personal information via the cookies, so the analytics cannot be used to identify who you are.</p>
            <p>We do not allow PostHog to use or share the analytics data.</p>
            <p>PostHog stores information about:</p>
            <ul>
            <li>the pages you visit</li>
            <li>how long you spend on each page</li>
            <li>how you arrived at the site</li>
            <li>what you click on while you visit the site</li>
            <li>the device and browser you use.</li>
            </ul>
            <h3>Cookie details</h3>
            <IonGrid  >
                <IonRow>
                    <IonCol size="2"><b>Name:</b></IonCol><IonCol size="10">ph_phc_PLUWYyW1rN6fRvG9USU46XN7Btp4tMyX20sGsHnpNFh</IonCol>               
                </IonRow>
                <IonRow>
                    <IonCol size="2"><b>Purpose:</b></IonCol><IonCol size="10">Analytics cookie to track website usage</IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="2"><b>Expires:</b></IonCol><IonCol size="10">7 days</IonCol>
                </IonRow>
                
            </IonGrid>
            
            <h3>Cookie Settings</h3>
                <p>{status}</p>
                <p>Do you want to accept or decline analytics cookies?</p>
                
                <IonRadioGroup value={cookieSelection} onIonChange={(e) => OnRadioSelect(e.detail.value)}>
                    <IonRadio value="accept" labelPlacement="end">Accept analytics cookies</IonRadio>
                    <br /><br/>
                    <IonRadio value="decline" labelPlacement="end">Decline analytics cookies</IonRadio>
                    <br />
                </IonRadioGroup>
           
            <p>
                <IonButton disabled={!enableSelection} size="small" 
                    onClick={(e)=>{e.preventDefault(); saveCookieSelection()}}>Save Cookie Setting</IonButton>
            </p>
           
    
            
          </IonContent>
    
        </IonPage>
    )
}

export default CookiePage;
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact,  IonLoading, IonContent} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import  React from 'react';

// { useAuthState } from "react-firebase-hooks/auth";


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Pages and Components */
import Menu from './components/Menu';
import MainTabs from './pages/MainTabs';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import PasswordReset from './pages/PasswordReset';
import ForgotPassword from './pages/ForgotPassword';
import UserAuthActions from './pages/UserAuthActions';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Home from './pages/Home'
import NotFound from './pages/NotFound'



import { useAuth } from "./contexts/authContext";
import VerifyEmail from './pages/VerifyEmail';
import VerifiedEmail from './pages/VerifiedEmail';
import { auth } from './firebase';
import PaymentTerms from './pages/PaymentTerms';
import StripeRegister from './stripe/StripeRegister';
import OpenDirectory from './pages/OpenDirectory';
import ShowDirectoryUser from './pages/users/ShowDirectoryUser';
import DirectoryContactMe from './pages/DirectoryContactMe';
import ManageUser from './pages/users/ManageUser';
import UserAdmin from './pages/UserAdmin';
import RedirectToLogin from './components/RedirectToLogin';



setupIonicReact();

const App: React.FC = () => {

  const { authInfo } = useAuth()!;

/*
  useEffect(() => {
    !authInfo?.initialized ; //&& (async () => await initialize())();
  },[authInfo, initialize]); */

  if (!authInfo || !authInfo.initialized) {
  //const user = useUserState();

  //if (!user || !user.initialized) {
    
    return (
      <IonApp>
        <IonContent>
          <IonLoading 
            onDidDismiss={() => { console.log('app.tsx auto reload'); window.location.reload()}}
            backdropDismiss={true}
            isOpen={true}
            message="Loading..." 
            duration={3000}
          />
        </IonContent>
        
      </IonApp>
    );
  } else {
    console.log('loaded user', authInfo);
    
    return (
      <IonApp>
        <IonReactRouter>
          
            <Menu />   
            <IonRouterOutlet id="main">
              <Route 
                path="/tabs" 
                render={() => {
                  console.log('at /tabs',authInfo)
                  if (authInfo?.loggedIn && !auth.currentUser?.emailVerified){
                    return <VerifyEmail />
                  }
                  else if (authInfo?.loggedIn){
                    return <MainTabs uid={authInfo.user.uid} />
                  }
                  else {
                    return <Redirect to='/home' />
                  }
                }} 
              />
              <Route 
                exact
                path="/login" 
                render={() => {
                  return authInfo?.loggedIn ? <MainTabs uid={authInfo.user.uid} /> : <LoginPage />
                }} 
              />
              <Route 
                exact
                path="/logout" 
                component={RedirectToLogin}
                
              />
              <Route exact path="/register/:isSupervisor">
                <RegisterPage />
              </Route>
              <Route exact path="/register/">
                <RegisterPage />
              </Route>
              <Route exact path="/notfound">
                <NotFound />
              </Route>
              <Route exact path="/home">
                <Home />
              </Route>
              <Route exact path="/directory">
                <OpenDirectory/>
              </Route>
              <Route exact path="/useradmin">
                <UserAdmin />
              </Route>
              <Route path="/connect/:uid" component={ShowDirectoryUser} />
              <Route path="/manageuser/:uid" component={ManageUser} />
              <Route path="/directorycontactme/:uid" component={DirectoryContactMe} />
              <Route exact path="/privacy">
                <Privacy />
              </Route>
              <Route exact path="/terms">
                <Terms />
              </Route>
              <Route exact path="/paymentterms">
                <PaymentTerms />
              </Route>
              <Route exact path="/passwordreset">
                <PasswordReset />
              </Route>
              <Route exact path="/forgotpassword">
                <ForgotPassword />
              </Route>
              <Route exact path="/verify-email">
                <VerifyEmail/>
              </Route>
              <Route exact path="/striperegister">
                <StripeRegister/>
              </Route>
              <Route exact path="/verified-email">
                <VerifiedEmail />
              </Route>
              <Route path="/auth">
                <UserAuthActions/>
              </Route>
              <Route exact path='/' >
                <Redirect to='/tabs/home' />
              </Route>
              <Route render={() => <Redirect to='/NotFound' />} />
            </IonRouterOutlet>
        
        </IonReactRouter>  
      </IonApp>
    ); 
  }
}


export default App;

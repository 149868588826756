import { IonButtons,IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonBackButton, IonIcon, IonItem, IonLabel, IonList, IonThumbnail, IonAccordion, IonAccordionGroup, IonBadge, IonChip, useIonActionSheet, useIonAlert, useIonLoading, IonLoading, IonButton, useIonToast } from '@ionic/react';
import React , { useState, useEffect} from 'react';
import { collection, query, doc, where, getDoc, setDoc, getDocs, or, writeBatch } from "firebase/firestore";
import { UserRecord } from 'firebase-admin/auth'
import { db, auth, api } from '../../firebase';
import {  useParams } from 'react-router-dom';
import { useAuth } from '../../contexts/authContext';
import { User, userDefault} from '../../data/user';
import { close, call, cardOutline,  logIn, logInOutline, logInSharp, mailOutline, mailSharp, personAddOutline, personRemoveOutline, trashBinOutline} from 'ionicons/icons';
import './ShowUser.css'
import { sendWelcomeMail } from '../../mail/SendMail';


const ManageUser: React.FC = () => {
  
  const [consultant, setConsultant] = useState<User>(userDefault);
  const [reloadUser,setReloadUser]=useState(true)
  const [userDeleted, setUserDeleted] = useState(false)
  const [error, setError] = useState('')
  const [userRecord, setUserRecord] = useState<UserRecord>()
  const [present] = useIonActionSheet();
  const [presentAlert] = useIonAlert();
  const [presentLoading, dismissLoading] = useIonLoading();
  const { authInfo} = useAuth()!;
  const [showToast] = useIonToast();

  const { uid } = useParams() as { uid : string }

  // load user
  useEffect(() => {

    if (typeof uid === 'undefined'){
      setConsultant(userDefault);
      setError('user is not defined')
    }
    else {
      getDoc(doc(collection(db,'users'), uid))
      .then(doc => {
        if (doc.exists() ){
          const user = doc.data() as User;
          setConsultant(user);
        }
        else {
          console.log('user not found ', uid)
          setError('User id: "'+uid+'" not found')
        }
      })
      .catch(err => {
          console.log('Error getting user documents', err);
          setError("Error getting user")
      });
    }
    setReloadUser(false)
  }, [uid, reloadUser])

  React.useEffect(() => {
    const getUser = async () => {
      auth.currentUser?.getIdToken().then((token) => {
        const args = new URLSearchParams();
        args.append('uid',uid)
        //fetch(`https://europe-west1-prosupervise-dev-4241e.cloudfunctions.net/app/get-subscriptions?id=${authInfo.user.customerId}`, {
        fetch(`${api}app/get-user?`+ args.toString(), {
          headers: {
            authorization: `Bearer ${token}`
          }
        })
        .then(response => { 
          return response.json()
        })
        .then(result => {     
          if (result.ok){
            
            console.log('user--------------------', result.payload)
            setUserRecord(result.payload)
          }
          else {
            setError(result.error.message)
            console.log(result.error)
          }
        })
      }) 
    }
    getUser();
  },[uid,reloadUser])

  const postWelcomeEmail = async () =>{
    sendWelcomeMail(consultant?.fullName, consultant.email)
    showToast({
      message: "Welcome Email sent.",
      color: 'success',
      duration: 3000,
      buttons: [{ icon: close }],
    })
  }
  // delete all user data
  const deleteUserData = async () => {
    await presentLoading({message:'Deleting messages...'})
    try {
			// get net
			const batch = writeBatch(db);

      // delete user record
      console.log('delete users:', uid)
      batch.delete(doc(db, "users", uid));

      // deleted stripe account
      console.log('delete stripe')
      batch.delete(doc(db, "stripeAccount", uid));

      // delete all posts and comments
      console.log('delete posts')
      const postsQuery = await query(collection(db, "posts"), where("uid", "==", uid));
      const posts = await getDocs(postsQuery)
      posts.forEach(doc => {
        batch.delete(doc.ref)
      })

      // delete all appointments
      console.log('delete appointments')
      const appointmentsQuery= await query(collection(db, "appointmentList"),or( where("consultantUid", "==", uid),
                          where("patientUid","==", uid)));
      const appointments = await getDocs(appointmentsQuery)
      appointments.forEach(doc => {
        batch.delete(doc.ref)
      })

      // delete cpd records
      console.log('delete cpd')
      const cpdQuery = await query(collection(db, "cpdRecords"), where("uid", "==", uid));
      const cpds = await getDocs(cpdQuery)
      cpds.forEach(doc => {
        batch.delete(doc.ref)
      })

      // delete chat threads
      console.log('delete chat list')
      const chatListQuery = await query(collection(db, "posts"), or(where("userId", "==", uid),where("friendId", "==", uid)));
      const chatList = await getDocs(chatListQuery)
      chatList.forEach(doc => {
        batch.delete(doc.ref)
      })
      console.log('delete chats')
      const chatsQuery = await query(collection(db, "posts"), or(where("creatorId", "==", uid),where("friendId", "==", uid)));
      const chats = await getDocs(chatsQuery)
      chats.forEach(doc => {
        batch.delete(doc.ref)
      })
      console.log('commit')
      await batch.commit()
    }
    catch (e) {
			console.log('error deleting batch thread',e)
      setError('Error deleting user')
		}
    setUserDeleted(true)
    await dismissLoading()
  }

  const deleteUser = async () => {

    console.log(consultant,uid)
    present({
      header: 'Delete user '+consultant.fullName+ ' ('+consultant.email+')?',
      buttons: [
        { 
          text: 'Confirm delete user '+consultant.fullName,
          role: 'destructive',
          data: {
            action: 'confirm'
          }
        },
        { 
          text: 'Cancel',
          role: 'cancel',
          data: {
            action: 'cancel'
          }
        },

      ],
      onDidDismiss: async ({ detail  }) => {
        if (detail.data?.action === 'confirm'){
          // update appointment status
          auth.currentUser?.getIdToken().then((token) => {
            const args = new URLSearchParams();
            args.append('uid',consultant.uid)
            fetch(`${api}app/user-delete-user?`+ args.toString(), {
              headers: {
                authorization: `Bearer ${token}`
              }
            })
            .then(response => { 
              return response.json()
            })
            .then(result => {     
              if (result.ok){
                
                console.log('user deleted ok')
                deleteUserData();
                setError('user deleted')
              }
              else {
                setError(result.error.message)
                console.log(result.error)
              }
            })
          }) 
        }
      },
    })
  }
  // change email
  const changeEmailAddress = async () => {
    presentAlert({
      header: 'Change email address',
      message: 'Enter new email for '+ consultant.fullName+ ' ('+consultant.email+').',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'OK',
          role: 'confirm'
        }
      ],
      inputs: [{placeholder: 'Email',type: 'email', name: 'email'}],
      onDidDismiss: async ({ detail  }) => {
        if (detail.role === 'confirm'){
          auth.currentUser?.getIdToken().then((token) => {
            const args = new URLSearchParams();
            args.append('uid',consultant.uid)
            args.append('email', detail.data?.values.email)
            fetch(`${api}app/user-change-email?`+ args.toString(), {
              headers: {
                authorization: `Bearer ${token}`
              }
            })
            .then(response => { 
              return response.json()
            })
            .then(result => {     
              if (result.ok){
                const updatedUser =  { ...consultant, ...{email: detail.data?.values.email} }
                setDoc(doc(collection(db,'users'), consultant.uid), updatedUser, { merge: true});
                setUserRecord(result.payload)
                setReloadUser(true)
              }
              else {
                setError(result.error.message)
                console.log(result.error)
              }
            })
          }) 
          
        }
      },
    })
  }
  // toggle administration settings
  const toggleAdministrator = async () => {

    const toggle = consultant.isAdmin ? "Remove" : "Make"
    present({
      header: toggle+' user administrator for '+consultant.email,
      buttons: [
        { 
          text: 'Confirm '+toggle+' user administrator',
          role: 'destructive',
          data: {
            action: 'confirm'
          }
        },
        { 
          text: 'Cancel',
          role: 'cancel',
          data: {
            action: 'cancel'
          }
        },

      ],
      onDidDismiss: async ({ detail  }) => {
        if (detail.data?.action === 'confirm'){
          // update appointment status
          const updatedUser =  { ...consultant, ...{isAdmin: !consultant.isAdmin} }
          try {
            await setDoc(doc(collection(db,'users'), consultant.uid), updatedUser, { merge: true});
          }
          catch(e){
              console.log('error update user:', updatedUser, e);
          }
          setReloadUser(true)
        }
      },
    })
  }

  // toggle administration settings
  const verifyEmail = async () => {
    present({
      header: 'Verify email for '+consultant.fullName,
      buttons: [
        { 
          text: 'Verify email for '+consultant.email,
          role: 'destructive',
          data: {
            action: 'confirm'
          }
        },
        { 
          text: 'Cancel',
          role: 'cancel',
          data: {
            action: 'cancel'
          }
        },

      ],
      onDidDismiss: async ({ detail  }) => {
        if (detail.data?.action === 'confirm'){
          // verify email
          auth.currentUser?.getIdToken().then((token) => {
            const args = new URLSearchParams();
            args.append('uid',consultant.uid)
            fetch(`${api}app/user-verify-email?`+ args.toString(), {
              headers: {
                authorization: `Bearer ${token}`
              }
            })
            .then(response => { 
              return response.json()
            })
            .then(result => {     
              if (result.ok){
                setUserRecord(result.payload)
              }
              else {
                setError(result.error.message)
                console.log(result.error)
              }
            })
          }) 
        }
      },
    })
  }

  // toggle disabled user
  const toggleDisableUser = async () => {
    const toggle = userRecord?.disabled ? "Enable" : "Disable"
    present({
      header: toggle+' user account for '+consultant.email,
      buttons: [
        { 
          text: toggle+' user account',
          role: 'destructive',
          data: {
            action: 'confirm'
          }
        },
        { 
          text: 'Cancel',
          role: 'cancel',
          data: {
            action: 'cancel'
          }
        },

      ],
      onDidDismiss: async ({ detail  }) => {
        if (detail.data?.action === 'confirm'){
          // verify email
          auth.currentUser?.getIdToken().then((token) => {
            const args = new URLSearchParams();
            args.append('uid',consultant.uid)
            args.append('toggle', userRecord?.disabled ? "false" : "true")
            fetch(`${api}app/user-disable-toggle?`+ args.toString(), {
              headers: {
                authorization: `Bearer ${token}`
              }
            })
            .then(response => { 
              return response.json()
            })
            .then(result => {     
              if (result.ok){
                setUserRecord(result.payload)
              }
              else {
                setError(result.error.message)
                console.log(result.error)
              }
            })
          }) 
        }
      },
    })
  }

  return (
    <IonPage color='duckegg'>     
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref='/useradmin/'></IonBackButton>
          </IonButtons>    
          <IonTitle>Admin for {consultant?.fullName}{authInfo.user.uid === consultant.uid ? " (You)": ""}</IonTitle>
        </IonToolbar>
        
       </IonHeader>
      <IonContent color='duckegg'>
      { error != '' && <IonChip color="danger">{error}</IonChip>}
      { reloadUser && <IonLoading message="Loading user details ..." />}
      { userDeleted && <><p>User deleted <IonButton routerLink='/useradmin'>User Admin List</IonButton></p></>}
      { !userDeleted && !reloadUser && 
      <>
      <IonAccordionGroup multiple={true} value="first">
        
      <IonAccordion value="first">
        <IonItem key="userInfo" slot="header" color="light">
          <IonLabel>User Info </IonLabel>
        </IonItem>
        <div className="ion-padding" slot="content">
          <IonThumbnail>
            <center>           
              <img alt="avatar" className="avatar" src={consultant?.avatar} />         
            </center>
          </IonThumbnail>
          <b>{ consultant?.fullName  !== '' ? consultant.fullName : "No name" }</b>{authInfo.user.uid === consultant.uid ? " (You)": ""}<IonBadge color={ (userRecord && userRecord.emailVerified) ? "success" : "danger" } >{ (userRecord && userRecord.emailVerified) ? "Verified" : "Not Verified" }</IonBadge><br/>
          <IonIcon slot="icon-only" icon={call} />
          { consultant.cell !== '' ? consultant.cell : "No phone number." } <br/>
          { consultant.email !== '' ? consultant.email : "No email." } <br/>
          { consultant.uid }<br/>
          <hr />
          <IonChip color="highblue">
            <IonIcon  icon={cardOutline} color="hotcoral" />
            <IonLabel>{ consultant?.isSupervisor ? "Supervisor" : "Member"}</IonLabel>
          </IonChip>
          <IonChip color="highblue">
            <IonIcon  icon={personAddOutline} color="hotcoral" />
            <IonLabel>{ consultant?.isAdmin ? "Administrator" : "Not Administrator"}</IonLabel>
          </IonChip>
          <IonChip color={userRecord?.disabled ? "danger": "highblue"}>
            <IonIcon  icon={logIn} color="hotcoral" />
            <IonLabel>{ userRecord?.disabled ? "Login Disabled" : "Login Enabled"}</IonLabel>
          </IonChip>
        </div>
      </IonAccordion>
      
      <IonAccordion value="second">
        <IonItem key="notes" slot="header" color="light">
          <IonLabel>Login Details</IonLabel>
        </IonItem >
        <div className="ion-padding" slot="content">
          <IonList class="ion-no-padding">
          <IonItem key='refresh' >
            <IonLabel class="ion-text-wrap">
              <h2>Last refresh:</h2>
              <p>{userRecord?.metadata.lastRefreshTime}</p>
            </IonLabel>
          </IonItem>
          <IonItem key='signin' color='duckegg'>
            <IonLabel class="ion-text-wrap">
              <h2>Last sign in:</h2>
              <p>{userRecord?.metadata.lastSignInTime}</p>
            </IonLabel>
          </IonItem>
          <IonItem key="created">
            <IonLabel class="ion-text-wrap">
              <h2>User registered on:</h2>
              <p>{userRecord?.metadata.creationTime}</p>
            </IonLabel>
          </IonItem>
          </IonList>
        </div>
      </IonAccordion>
      </IonAccordionGroup>
      <IonList >   
      { !consultant?.isAdmin && authInfo.user.uid !== consultant.uid &&
        <IonItem key="makeAdmin"  detail={true} onClick={(e) => {e.preventDefault();toggleAdministrator()}} button>
          <IonIcon icon={personAddOutline} color='hotcoral'></IonIcon><IonLabel color="primary">&nbsp;Make user an administrator</IonLabel>
        </IonItem>
      }
      { consultant?.isAdmin && authInfo.user.uid !== consultant.uid &&
        <IonItem key="removeAdmin"  detail={true} onClick={(e) => {e.preventDefault();toggleAdministrator()}} button>
          <IonIcon icon={personAddOutline} color='hotcoral'></IonIcon><IonLabel color="primary">&nbsp;Remove administrator privilege</IonLabel>
        </IonItem>
      }
      { !userRecord?.emailVerified && 
        <IonItem key="verify email"  detail={true} onClick={(e) => {e.preventDefault();verifyEmail()}} button>
          <IonIcon icon={mailSharp} color='hotcoral'></IonIcon><IonLabel color="primary">&nbsp;Verify user email</IonLabel>
        </IonItem>
      }
      { authInfo.user.uid !== consultant.uid &&
        <IonItem key="deleteUser"  detail={true} onClick={(e) => {e.preventDefault();deleteUser()}} button>
          <IonIcon icon={personRemoveOutline} color='hotcoral'></IonIcon><IonLabel color="primary">&nbsp;Delete user</IonLabel>
        </IonItem>
      }
        <IonItem key="changeEmail"  detail={true} onClick={(e) => {e.preventDefault();changeEmailAddress()}} button>
          <IonIcon icon={mailOutline} color='hotcoral'></IonIcon><IonLabel color="primary">&nbsp;Change email address</IonLabel>
        </IonItem>
      { !userRecord?.disabled && authInfo.user.uid !== consultant.uid &&
        <IonItem key="disableUser" detail={true} onClick={(e) => {e.preventDefault();toggleDisableUser()}} button>
          <IonIcon icon={logInSharp} color='hotcoral'></IonIcon><IonLabel color="primary">&nbsp;Disable user login</IonLabel>
        </IonItem>
      }
      { userRecord?.disabled && authInfo.user.uid !== consultant.uid &&
        <IonItem key="enableUser"   detail={true} onClick={(e) => {e.preventDefault();toggleDisableUser()}} button>
          <IonIcon icon={logInOutline} color='hotcoral'></IonIcon><IonLabel color="primary">&nbsp;Enable user login</IonLabel>
        </IonItem>
      }
      { typeof consultant.uid === "undefined" &&
        <IonItem key="clearData"   detail={true} onClick={(e) => {e.preventDefault();deleteUserData()}} button>
          <IonIcon icon={trashBinOutline} color='hotcoral'></IonIcon><IonLabel color="primary">&nbsp;Delete User Data</IonLabel>
        </IonItem>
      }
        <IonItem key="sendWelcomeEmail"  detail={true} onClick={(e) => {e.preventDefault();postWelcomeEmail()}} button>
          <IonIcon icon={mailOutline} color='hotcoral'></IonIcon><IonLabel color="primary">&nbsp;Send Welcome Email</IonLabel>
        </IonItem>

      </IonList>
      </>
      }
      
    
    <div className="duckEggBackground" style={{padding: '100px'}}></div>
    
    </IonContent>
    </IonPage>
  )
}


export default ManageUser



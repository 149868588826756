import { useEffect, useRef, useState } from 'react';
import {
  IonButtons,
  IonButton,
  IonContent,
  IonToolbar,
  IonTitle,
  IonPage,
  
  IonIcon,

  
  IonRange,
  useIonActionSheet,
 
  
  
} from '@ionic/react';
import { trashBinOutline, videocam } from 'ionicons/icons';
import { Cropper, ReactCropperElement } from 'react-cropper';
import { storage } from '../firebase';
import {ref, getDownloadURL, uploadString } from "firebase/storage"
import './Avatar.css';
import '../pages/cropper.css'
import { User } from '../data/user';


export const ProfilePhotoModal = ({
  onDismiss,
  url,
  user
}: {
  onDismiss: (data?: string | null | undefined | number, role?: string) => void;
  url : string;
  user : User;
}) => {

    
  //const ChangeProfilePictureSheet = useRef<HTMLIonModalElement>(null);
  const defaultSrc = "/ChannelCrossing.png"
  const cropperRef = useRef<ReactCropperElement>(null);

  //const [imgSrc, setImgSrc] = useState('#');
  const [image, setImage] = useState(defaultSrc);
  //const [cropData, setCropData] = useState('#');
  const [slideValGlobal,setSlideValGlobal] = useState(0.5)
  const [edited, setEdited] = useState(false)
  const [ionActionSheet] = useIonActionSheet();

  // crop - image and save to imgSrc
  /*
  const _crop = (event: any) => {
    // image in dataUrl
    if (typeof cropperRef.current?.cropper !== 'undefined') {
      const cropper = cropperRef.current?.cropper;
      let imgSrc = cropper.getCroppedCanvas({
        width: 170,
        height: 170// input value
      }).toDataURL("image/png");
      setImgSrc( imgSrc);
    }
  } 
  */

  // zoom image
  const zoomImage = (slideVal: number) => {
    if (typeof cropperRef.current?.cropper !== 'undefined') {
      const cropper = cropperRef.current?.cropper;
      let zoomRatio = Math.round((slideVal - slideValGlobal)*10)/10; 
      setSlideValGlobal(slideVal);
      cropper.zoom(zoomRatio);
      setEdited(true);
    }
  }

  // load image from input file form
  const loadImage = (e: any) => {
    console.log('load image')
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
      setSlideValGlobal(0.5);
      setEdited(true); 
    };
    reader.readAsDataURL(files[0]);
  }

  // download supplied image as blob and show on cropper
  const loadImageFromUrl =  async (url : string) => {
    const image = await fetch(url)
    const imageBlob = await image.blob()
    const imageURL = URL.createObjectURL(imageBlob)
    setImage(imageURL)
    setSlideValGlobal(0.5);
  }

  let imageDownload : any
  const loadImageFromUrl2 =  async (url : string) => {
    imageDownload = new Image();
    imageDownload.onerror = function(e) {
      console.log("could not download image:", url, e);

    };
    imageDownload.crossOrigin = "anonymous";
    imageDownload.addEventListener("load", imageReceived, false);
    imageDownload.src = url;
    // make sure the load event fires for cached images too
    if ( imageDownload.complete || imageDownload.complete === undefined ) {
      imageDownload.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
      imageDownload.src = url;
    }
  }

  const imageReceived = async () => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    canvas.width = imageDownload.width;
    canvas.height = imageDownload.height;

    if (context !== null ) context.drawImage(imageDownload, 0, 0);

    try {
      setImage(canvas.toDataURL("image/png"));
      setSlideValGlobal(0.5);
    } catch (err) {
      console.error(`Error: ${err}`);
    }
    
  }

  useEffect(() => {
    console.log('image', url)
    //setImage(url)
   loadImageFromUrl2(url)
  },[url]);

  // upload avatar to firebase storage
  const uploadAvatar = async (userId: string) => {
    
    if (typeof cropperRef.current?.cropper !== 'undefined') {

      // get cropped image
      const cropper = cropperRef.current?.cropper;
      let imgBlob= cropper.getCroppedCanvas({
        width: 300,
        height: 300// input value
      });
      
      let imageObj = imgBlob.toDataURL("image/png");

      // upload to firebase
      const storageRef = ref(storage, `/avatar/${userId}.png`)
      uploadString(storageRef, imageObj, 'data_url')
      .then((snapshot) => {
        console.log('Uploaded a data_url string!',snapshot.ref.fullPath  );
        getDownloadURL(storageRef)
        .then((url) => {
          console.log('url', url)
          onDismiss(url,'save')
        })
        .catch((error) => {
          console.log('error getting save image url image:',error)
        })  
      })
      .catch((error) =>{
        console.log('error saving image:',error)
      })
    }    
  };


  // dialog box when cancel is pressed and form edited
  const cancel = () => {
    if (edited){
      // edited ask if discard
      ionActionSheet({
        header: 'Do you want to discard your changes?',
        buttons: [
          { 
            text: 'Keep Editing',
            role: 'cancel',
            data: {
              action: 'keep'
            }
          },
          { 
            text: 'Discard',
            role: 'destructive',
            data: {
              action: 'discard'
            }
          },

        ],
        onDidDismiss: ({ detail }) => {
          if (detail.data?.action === 'discard'){
            onDismiss(null, 'back')
          }
        },
      })
    }
    else {
      // not edited dismiss
      onDismiss(null, 'back')
    }
  }
  
  // delete photo is pressed - reset url
  const deletePhoto = () => {
    url = "https://ui-avatars.com/api/?size=128&name="+user.fullName;
    loadImageFromUrl2(url)
  }

  const inputRef = useRef<HTMLIonInputElement>(null);
  return (
    <IonPage>
      <IonToolbar>
        <IonButtons slot="start">
          <IonButton strong={true} onClick={() => cancel()}>
            Cancel
          </IonButton>
        </IonButtons>
        <IonTitle>Edit Profile Picture</IonTitle>
        <IonButtons slot="end">
          <IonButton strong={true} onClick={() => uploadAvatar(user.uid)}>
            Save
          </IonButton>
        </IonButtons>
      </IonToolbar>
      
      <IonContent className="ion-padding">
      <div>      
        <div  className="cropperContainer"  >
          <Cropper
            src={image}
            style={{height: 300, width: '100%', border: '0.05em solid black'}}
            // Cropper.js options
            initialAspectRatio={1}
            responsive={true}
            dragMode='move'
            autoCropArea= {0.68}
            center={false}
            zoomOnWheel={false}
            zoomOnTouch={false}
            cropBoxMovable={false}
            cropBoxResizable={false}
            guides={false}
            //crop={_crop}
            ref={cropperRef}
            checkOrientation={false}
            checkCrossOrigin={false}
          />
        </div>
      </div>
      <br style={{clear: 'both'}} />
      <div className="slider-bar">
        <IonRange value={slideValGlobal} id="range" min={0} max={1} step={0.1} aria-label="Volume" onIonChange={(e) => zoomImage(e.detail.value as number)}></IonRange> 
      </div>
      <IonButton >
        <IonIcon icon={videocam} slot="end"></IonIcon>
        <input id="file" hidden type="file" accept=".png, .jpg, .jpeg" onChange={(e) =>{loadImage(e)}}/>
        <label htmlFor="file">Choose Photo</label>
      </IonButton>
      <IonButton onClick={() => deletePhoto()}>
        <IonIcon icon={trashBinOutline} slot="end"></IonIcon>
        Delete Photo
      </IonButton>
      </IonContent>    
    </IonPage>
  );
};


/*

function Example() {
  const [present, dismiss] = useIonModal(ProfilePhotoModal, {
    onDismiss: (data: string, role: string) => dismiss(data, role),
  });
  const [message, setMessage] = useState('This modal example uses the modalController to present and dismiss modals.');

  function openModal() {
    present({
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === 'confirm') {
          setMessage(`Hello, ${ev.detail.data}!`);
        }
      },
    });
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Controller Modal</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonButton expand="block" onClick={() => openModal()}>
          Open
        </IonButton>
        <p>{message}</p>
      </IonContent>
    </IonPage>
  );
}
*/

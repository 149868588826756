import { IonMenuButton, IonButtons,IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';
import './PracticePage.css';
import SuperviseeCalendar from '../appointments/SuperviseeCalendar';
import { useAuth} from '../contexts/authContext';









const MyCalendar: React.FC = () => {
  const { authInfo } = useAuth()!;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
             <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonButtons slot="end">
        </IonButtons>
          <IonTitle>My Calendar</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={true}>   
          <SuperviseeCalendar hide={false} user={authInfo.user} />
      </IonContent>
    </IonPage>
  );
};

export default MyCalendar
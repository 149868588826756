import React, { useRef } from "react"
import { IonItem, IonButton, IonTextarea, IonLabel } from '@ionic/react';
import { Appointment} from "./AppointmentClasses";
import { dbChangeAppointment, dateStamp } from "./AppointmentFunctions";
interface EditNoteProps {
    myAppointment : Appointment
    disabled : boolean
}
  
export const EditNote : React.FC<EditNoteProps> = ({myAppointment, disabled}) => {
    
  const [isEditing, setEditing] = React.useState(false);
  const noteInputRef = useRef<null | HTMLIonTextareaElement>(null);

  const cancelBut = () => {
    setEditing(false)
  }

  const saveBut = () => {
    setEditing(false)
    if (noteInputRef.current !== null && noteInputRef.current.value !== null && typeof noteInputRef.current.value !== "undefined")
      myAppointment.notes = noteInputRef.current.value;
    myAppointment.audit.push(dateStamp()+" Notes updated.")
    dbChangeAppointment(myAppointment);
  }

  const setEditingState = (state: boolean) => {
    setEditing(state)
  }

  return (
    <IonItem className="ion-no-padding">
      <IonLabel>
        <div style={isEditing ? {} : { display: 'none' } }>
        <IonTextarea ref={noteInputRef} label="" placeholder="No notes available" fill="outline" autoGrow={true}
          value={ myAppointment.notes }
        />
        <IonButton size="small" onClick={() => saveBut()}>Save</IonButton><IonButton size="small" onClick={() => cancelBut()}>Cancel</IonButton>
        </div>
      
        <div style={isEditing ? { display: 'none' } : {}} onClick={() => setEditingState(true)} >
          { myAppointment.notes !== '' ? myAppointment.notes : "No notes available."}
        </div>
      </IonLabel>

      <IonButton slot="end" onClick={() => setEditingState(true)} disabled={disabled || isEditing}>Edit</IonButton>
    </IonItem>
  )
}         
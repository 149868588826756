// taken and adapted from https://github.com/zoltangy/react-pwa-install

export const HomeIcon = () => {
  return (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon"
        viewBox="0 0 578 584"
      >
        <path d="M101 35l19-1h333c12 0 23 0 35 3 17 3 34 12 44 27 13 16 16 38 16 58v329c0 19 0 39-8 57a65 65 0 0 1-37 37c-18 7-38 7-57 7H130c-21 1-44 0-63-10-14-7-25-20-30-34-6-15-8-30-8-45V121c1-21 5-44 19-61 13-16 33-23 53-25m7 46c-10 1-19 6-24 14-7 8-9 20-9 31v334c0 12 2 25 10 34 9 10 23 12 35 12h336c14 1 30-3 38-15 6-9 8-20 8-31V125c0-12-2-24-10-33-9-9-22-12-35-12H121l-13 1z" />
        <path d="M271 161c9-11 31-10 38 4 3 5 3 11 3 17v87h88c7 0 16 1 21 7 6 6 7 14 6 22a21 21 0 0 1-10 14c-5 4-11 5-17 5h-88v82c0 7-1 15-6 20-10 10-29 10-37-2-3-6-4-13-4-19v-81h-87c-8-1-17-3-23-9-5-6-6-15-4-22a21 21 0 0 1 11-14c6-3 13-3 19-3h84v-88c0-7 1-14 6-20z" />
      </svg>
  )
}

export function IOSShareIcon() {
  return (
    <svg className="icon" viewBox="0 0 50 50">
      <path d="M30.3 13.7L25 8.4l-5.3 5.3-1.4-1.4L25 5.6l6.7 6.7z" />
      <path d="M24 7h2v21h-2z" />
      <path d="M35 40H15c-1.7 0-3-1.3-3-3V19c0-1.7 1.3-3 3-3h7v2h-7c-.6 0-1 .4-1 1v18c0 .6.4 1 1 1h20c.6 0 1-.4 1-1V19c0-.6-.4-1-1-1h-7v-2h7c1.7 0 3 1.3 3 3v18c0 1.7-1.3 3-3 3z" />
    </svg>
  );
}

export function FireFoxA2HSIcon() {
  return (
    <svg className="icon" viewBox="0 0 700.000000 700.000000">
      <g transform="translate(0.000000,700.000000) scale(0.100000,-0.100000)">
        <path
          d="M3341 6328 c-29 -8 -266 -235 -614 -585 -4 -5 -149 -150 -323 -323
-173 -173 -323 -323 -332 -332 -9 -9 -156 -157 -327 -327 -170 -170 -316 -316
-323 -323 -7 -7 -140 -141 -295 -298 -322 -325 -367 -382 -374 -474 -5 -64 -3
-70 62 -170 36 -56 66 -68 178 -69 153 -2 160 1 274 113 56 54 105 105 110
112 4 8 34 29 67 47 99 55 91 141 93 -932 3 -1047 6 -1108 61 -1194 17 -26 37
-64 45 -83 8 -19 30 -54 50 -78 20 -23 38 -50 42 -60 6 -17 42 -46 133 -109
26 -18 60 -42 76 -54 15 -12 56 -30 90 -40 33 -9 83 -27 110 -39 34 -15 83
-25 160 -32 63 -5 599 -7 1251 -6 1029 3 1145 5 1190 20 28 9 79 26 115 38 66
22 86 31 180 85 71 41 226 198 241 244 7 20 25 51 40 69 29 32 32 42 44 117 4
22 16 69 28 105 22 64 22 73 27 1000 3 514 7 936 8 937 1 2 13 10 26 18 35 23
47 14 184 -126 64 -67 128 -127 142 -134 35 -17 153 -26 228 -17 58 8 67 12
103 52 23 24 45 52 50 61 12 23 12 201 -1 233 -5 14 -577 594 -1271 1288
-1233 1232 -1264 1262 -1308 1269 -64 11 -199 9 -240 -3z m167 -632 c33 -18
1354 -1341 1373 -1376 12 -21 15 -228 17 -1189 2 -660 -1 -1199 -6 -1246 -11
-93 -20 -110 -109 -201 -34 -35 -65 -56 -91 -64 -28 -7 -405 -10 -1246 -8
l-1205 3 -28 23 c-15 12 -42 32 -60 45 -21 14 -47 51 -74 103 l-41 82 1 1202
1 1203 28 45 c15 25 329 348 697 718 493 496 676 673 693 674 13 0 35 -6 50
-14z"
        ></path>
        <path
          d="M3403 4280 c-66 -40 -67 -49 -73 -421 -4 -257 -8 -340 -18 -352 -25
-29 -108 -37 -401 -37 l-289 0 -48 -32 c-31 -22 -57 -49 -72 -80 -21 -41 -23
-51 -12 -82 17 -51 68 -102 115 -115 22 -5 174 -12 337 -15 240 -4 303 -8 327
-21 61 -31 61 -31 61 -355 1 -162 5 -314 9 -338 14 -72 85 -132 157 -132 29 0
46 9 83 42 25 23 51 53 58 67 9 19 12 119 13 348 l0 323 29 32 29 33 314 0
313 1 60 29 c57 28 61 33 72 79 14 57 5 116 -24 148 -10 11 -36 31 -59 44 -41
24 -43 24 -368 27 l-328 2 -19 24 c-18 22 -19 43 -19 336 0 172 -3 326 -6 342
-3 16 -25 50 -49 76 -42 46 -44 47 -101 47 -41 0 -69 -6 -91 -20z"
        ></path>
      </g>
    </svg>
  );
}

export function MenuIcon() {
  return (
    <svg className="icon" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 6a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm0 6a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm-2 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
        fill="context-fill"
        fillOpacity=".8"
      ></path>
    </svg>
  );
}

export function OperaA2HSIcon() {
  return (
    <svg className="icon" viewBox="0 0 24 24">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5 4a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V4zm2 .51A.51.51 0 0 1 7.51 4h8.98a.51.51 0 0 1 .51.51v13.98a.51.51 0 0 1-.51.51H7.51a.51.51 0 0 1-.51-.51V4.51zM10.5 20a.5.5 0 1 0 0 1h3a.5.5 0 1 0 0-1h-3z"
        fill="context-fill"
        fill-opacity=".8"
      ></path>
    </svg>
  );
}

import { useIonViewDidEnter, IonButtons,IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonList, IonItem, IonLabel, IonButton, IonIcon, IonNote, IonBackButton, IonInput , IonAvatar, IonItemSliding, IonItemOption, IonItemOptions, IonFooter } from '@ionic/react';
import React , { useState, useEffect, useRef} from 'react';

import { collection, doc, onSnapshot, setDoc } from "firebase/firestore";
import { db } from '../../firebase';
import { useAuth } from '../../contexts/authContext';
import { send, trash } from 'ionicons/icons';
import { RouteComponentProps } from 'react-router-dom'; 
import { format, isToday, isYesterday, isThisWeek  } from 'date-fns';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'


import './PostComment.css'
import { Link } from 'react-router-dom';


type Comment = {
    comment : string,
    date: number,
    fullName: string,
    avatar : string
    uid : string
}

interface Post {
    title: string 
    content: string 
    authorName: string 
    authorTitle: string
    avatar : string
    uid: string
    docId : string
    date: string
    stars: Array<string>
    comments: Array<Comment>
  }
  
  const duration  = ( timestamp : number ) => {
    
    const date = new Date(timestamp)
    //const currentDate = new Date();
    //const timeDiff = currentDate.getTime() - timestamp;

    if (isToday(date)){
        return  format(date, 'h:mm a');
    } else if (isYesterday(timestamp)){
        return "Yesterday"
    } else if (isThisWeek(timestamp)){
        return format(date,'EEEE')
    } else {
        return format(date,"dd/MM/yyyy")
    }

    return 'NaN';
  
}

interface Props
  extends RouteComponentProps<{
    id: string;
}> {}


const PostComment: React.FC<Props> = ({match}) => {

    const postId = match.params.id;
    const [post, setPost] = useState<Post>();
    const [newComment, setNewComment] = useState("");
    const { authInfo } = useAuth()!;


    

    useEffect(() => {

        // subscribe to post query
        const unsubscribe  = onSnapshot(doc(db, "posts", postId), (doc) => {
            //console.log("Current data: ", doc.data());
            if (doc.exists() ){
                const docData = doc.data() as Post;
                setPost(docData)
            }
            else {
                console.log('error post comment: document does not exist', postId)
            }
        });

        // detach listener
        return unsubscribe

    },[postId])

    // assign date
    let postDate = "NaN";
    if (typeof post?.date === 'string' || typeof post?.date === 'number'){
        postDate = new Date(post.date).toDateString()
    }

    const assignComment= ( comment : string | null | undefined) =>{
        if (typeof comment  === 'string'){
            setNewComment(comment)
        }
    }
    

    // add comment to post and update db
    const updatePost = async (comment : string) => {
        
        const myPost = post;
        // return of comment is empty
        if (comment == ""){
            return 
        }
        // add comments array if it does not exist
        if (typeof myPost !== 'undefined' && typeof myPost?.comments === 'undefined'){
            myPost.comments = [];
        }

        // add comment and update firebase db
        const commentData = {
            comment : comment,
            fullName: authInfo.user.fullName,
            avatar: authInfo.user.avatar,
            uid : authInfo.user.uid,
            date: new Date().getTime()
        }

      //  console.log(commentData)

        if (typeof myPost !== 'undefined' ){
            myPost?.comments.push(commentData);
            try {
                await setDoc(doc(collection(db,'posts'), postId), post, { merge: true});
                setNewComment("");
                setPost(myPost)
                scrollToBottom()
                //setTimeout(() => scrollToBottom(), 100);
                
            }
            catch(e){
                console.log('save post error:',e);
            }
        }
    }


    // delete comment from post
    const deleteComment = async (index: number) => {
        const myPost = post;

        myPost?.comments.splice(index, 1)

        // add comment and update firebase db

        if (typeof myPost !== 'undefined' ){
            try {
                await setDoc(doc(collection(db,'posts'), postId), post, { merge: true});
                setPost(myPost)
            }
            catch(e){
                console.log('save post error:',e);
            }
        }
    }

    

    
    
    const bottomRef = useRef<HTMLDivElement>(null);
    
    //  Scroll to end of content
    const scrollToBottom = async () => {
        if (bottomRef.current){
             bottomRef.current.scrollIntoView()
        }
    }

    
    useIonViewDidEnter(() => {
        scrollToBottom()
        //setTimeout(scrollToBottom, 5000);
    })


/*
    // Options for the observer (which mutations to observe)
    const config = { attributes: true, childList: true, subtree: true };

    // Callback function to execute when mutations are observed
    const callback = (mutationList: Array<MutationRecord>) => {
        let mutation : MutationRecord
        for (mutation of mutationList) {
            if (mutation.type === "childList") {
                console.log("A child node has been added or removed.");
            } else if (mutation.type === "attributes") {
                console.log(`The ${mutation.attributeName} attribute was modified.`);
            }
        }
    };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);

    // Start observing the target node for configured mutations
    const id = document.querySelector('#comments')
    observer.observe(id!, config);
*/
// Later, you can stop observing
//observer.disconnect();

// Watch for DOM changes
    //  Then scroll to bottom
    //  This ensures that the new chat message has *actually* been rendered
    //  Check this:
    //  https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
   





    return (


    <IonPage>     
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/tabs/home"></IonBackButton>
          </IonButtons>    
          <IonTitle>Post</IonTitle>
        </IonToolbar>
       </IonHeader>
      <IonContent color='light' >   
        
        <IonItem key='header' className="post" lines="none">
            <Link to={"/tabs/connect/"+post?.uid}>
                <IonAvatar slot="start" className="postAvatar ">
                    <img alt="Avatar" src={post?.avatar} />
                </IonAvatar>
            </Link>
            <IonLabel class="ion-text-wrap">
                <h1>{post?.title}</h1>
                <IonNote> <Link to={"/tabs/connect/"+post?.uid}>@{post?.authorName}</Link> - {postDate}</IonNote>
                <div >
                    { typeof post?.content !== "undefined" && <ReactMarkdown children={post.content} remarkPlugins={[remarkGfm]} /> }
                </div>
            </IonLabel>
        </IonItem>
        <IonList >
            
            <ShowComments id="comments" key="comments" post={post} uid={authInfo.user.uid} deleteComment={deleteComment} />
        
            <div key='ref' ref={bottomRef} onLoad={() => scrollToBottom()}  > </div>
            
        </IonList> 
        </IonContent> 
        <IonFooter>
            <IonToolbar color="primary">
                <div style={{padding: '10px'}}>
                <IonInput fill="outline"
                    placeholder="Enter comment" 
                    className="comment"
                    autoFocus={true}
                    onInput={(event): void => assignComment(event.currentTarget.value!)}
                    value={newComment}></IonInput></div>
                <IonButtons slot="end"><IonButton slot="end" onClick={(e) =>{e.preventDefault();updatePost(newComment)}}><IonIcon icon={ send } /></IonButton></IonButtons>                                 
            </IonToolbar>       
        </IonFooter>
        
      
    </IonPage>
    )
}

interface Props2 {
    post : Post|undefined
    uid : string
    deleteComment : (index: number ) => void
}
const ShowComments: React.FC<Props2> = ({post, uid, deleteComment}) => {

    if (typeof post !== 'undefined' && typeof post?.comments !== 'undefined') {
        
        return post.comments.map((element, index) =>{
            
            return (
            <IonItemSliding key={index}>
                <IonItem lines="none" key={`comment_${index}`}>
                    <IonAvatar slot="start">
                        <img alt="Avatar" src={element.avatar} />
                    </IonAvatar>
                    <IonLabel className="commentBubble ion-text-wrap">
                    <h3>{ element.fullName } - { duration(element.date) } </h3>
                    <ReactMarkdown children={element.comment} remarkPlugins={[remarkGfm]} />
                    
                    </IonLabel>
                    
                </IonItem>
                <IonItemOptions key={`options_${index}`}>
                    { element.uid === uid &&
                    <IonItemOption color="danger" onClick={() => deleteComment(index)}> 
                        <IonIcon slot="top" icon={trash} onClick={() => deleteComment(index)}></IonIcon>
                        Delete
                    </IonItemOption>
                    }
                </IonItemOptions>
            </IonItemSliding>
            )
        })
    }
    else
        return (<></>)
}

export default PostComment





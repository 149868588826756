import { IonAccordion, IonAccordionGroup, IonBadge, IonButton, IonButtons, IonChip, IonCol, IonContent, IonDatetime, IonDatetimeButton, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonModal, IonPage, IonRow, IonSpinner, IonTitle, IonToolbar, useIonActionSheet, useIonModal, useIonToast } from "@ionic/react"
import { addOutline, chevronBackOutline, chevronForwardOutline, call, calendarOutline, checkboxOutline, closeCircleOutline, calendarClearOutline, refreshCircleOutline,close, chatbubbleEllipsesOutline } from "ionicons/icons"
import React, {  useEffect, useRef, useState } from 'react';

import { parseISO, addDays, subDays, formatISO} from 'date-fns';
//import { Timestamp, query, collection, doc, deleteDoc, where, setDoc, DocumentData } from "firebase/firestore";
//import {  db} from "../firebase";

import { User } from '../data/user'
import { AppointmentAudit, generateDayDiary, capitalizeFirstLetter, displayDate, dbChangeAppointment, dbDeleteAppointment, dateStamp, filterAppointments, StatusBadge } from './AppointmentFunctions'
import './PracticeCalendar.css'
import { Appointment } from "./AppointmentClasses";
import { onSnapshot, doc, collection, query, where, or } from "firebase/firestore";
import { db } from "../firebase";
import {useCollection} from 'react-firebase-hooks/firestore';
import {PatientAppointmentModal} from './DirectoryCalendar'
import { AddToCalendar } from "./AddToCalendar";
import { EditNote } from "./EditNote"
import { sendAppointmentMail } from "../mail/SendMail";

export interface PracticeCalendarProps {
  hide: boolean
  user : User
}


  
const PracticeCalendar : React.FC<PracticeCalendarProps> = ({ hide, user }) => {

  // date bar selection
  const today = new Date();
  const todayIso = formatISO(today);
  const [selectedDate, setSelectedDate] = useState<string>(todayIso);
  
  
  //const [currentDate, setCurrentDate] = useState<Date>(zeroDate(today));

  // used for dialog box for an appointment
  const [currentAppointment, setCurrentAppointment] = useState<Appointment>()

  // todays appointment
  // 
  const [appointmentDiaryToday, setAppointmentDiaryToday] = useState<Appointment[]>([]);
  useEffect(()=>{
    // generate the days appointment slots when selectDateChanges
    setAppointmentDiaryToday(generateDayDiary(selectedDate))
  },[selectedDate])

  // database listener for grabbing appointment changes
  const queryToMake = query(
    collection(db, "appointmentsList"), or(
      where( "consultantUid" , "==", user.uid),
      where( "patientUid" , "==", user.uid))

  )

  const [value, loading, error] = useCollection(
      queryToMake,{ snapshotListenOptions: { includeMetadataChanges: true }});

  // dialog box dismiss function
  const handlePracticeDialogDismiss = () => {
    dismissPracticeModal()
  }

  const handlePatientDialogDismiss = () => {
    dismissPatientModal()
  }

  const [presentPatientModal, dismissPatientModal] = useIonModal(PatientAppointmentModal, {
    myAppointment: { ...currentAppointment },
    consultantUid : currentAppointment?.consultantUid ,
    user: user,
    onDismiss: handlePatientDialogDismiss,
  })

  // create appointment dialog box and present and dismiss functions, passing in appointment item
  const [presentPracticeModal, dismissPracticeModal] = useIonModal(PracticeAppointmentModal, {
    myAppointment: { ...currentAppointment },
    consultant : user,
    onDismiss: handlePracticeDialogDismiss,
  })
  // handler for editing dialoge box
  const onEdithandler = (myAppointment: Appointment) => {
    console.log('click', myAppointment)
    if (user.uid === myAppointment.consultantUid){
      myAppointment.consultantName = user.fullName
      myAppointment.consultantEmail = user.email
      myAppointment.location = user.myLocation
      setCurrentAppointment(myAppointment)
      presentPracticeModal()
    }
    else {
      setCurrentAppointment(myAppointment)
      myAppointment.name = user.fullName
      myAppointment.location = user.myLocation
      presentPatientModal()
    }
  }
  

  // fn to add one day to selected date
  const addDay = async () => {
    const date = typeof selectedDate === 'string' ? parseISO(selectedDate) : today;

    const nextDay = addDays(date,1)
    setSelectedDate(formatISO(nextDay));
    //setCurrentDate(zeroDate(nextDay));
  }

  /*
  // fn to set date to today
  const setToday = async () => {
    setSelectedDate(todayIso);
    //setCurrentDate(zeroDate(today));
  }
  */
  
  // fn to subtract one day to selected date
  const subDay = async () => {
    const date = typeof selectedDate === 'string' ? parseISO(selectedDate) : today;
    const prevDay = subDays(date,1)
    setSelectedDate(formatISO(prevDay));
    //setCurrentDate(zeroDate(prevDay));
  }

  // calendar dialog has selected date
  const selectDate = (date: string | string[] ) => {
    console.log('select date', date)
    if (typeof date !== 'string')
      setSelectedDate(todayIso)
    else
      setSelectedDate(date)
    //setCurrentDate(parseISO(date))
  };

  // date time buttons
  const datetimePractice = useRef<null | HTMLIonDatetimeElement>(null);
  const reset = () => {
    datetimePractice.current?.reset(todayIso);
  };
  const cancel = () => {
    datetimePractice.current?.cancel(true);
  };
  const confirm = () => {
    datetimePractice.current?.confirm(true);
  };

  // highlight booked dates on the calendar
  const myBookedDates = (bookedDays: string[]) =>{
    const bookedDates = (isoString: string) => {
        if (bookedDays.includes(isoString)){
          return {
            textColor: '#800080',
            backgroundColor: '#ffc0cb',
          };
        }
    }
    return bookedDates
  }
  

  // data db loading error
  if (error){
    // db error report it
    console.log(error);
    return (<IonContent>DB Error { error.message }</IonContent>)
  }
  // loading from db show wait
  if (loading) return (<IonSpinner></IonSpinner>)
  // data loaded return the list
  if (value) {
    // filter the appointment for today - returns list of hour-> appointment 
    const [appointmentScheduleToday, appointmentDaysInMonth] = filterAppointments(value.docs, selectedDate)
    

    console.log('appt sched:',appointmentScheduleToday, appointmentDaysInMonth)
    return (
    <IonContent color="duckegg" style={hide ? { display: 'none' } : {}}>
      <IonGrid  className="cal-container">
        <IonRow className="ion-align-items-center cal-container ">
          <IonCol className="cal-container" size="auto">
            <IonButton fill='clear'>
                <IonIcon slot="icon-only" icon={addOutline} />
              </IonButton>
          </IonCol>
          <IonCol className="cal-container">
           
          </IonCol>
          <IonCol className="cal-container" size="auto">
            <IonButton fill='clear' onClick={()=> subDay()}><IonIcon  slot="icon-only" icon={chevronBackOutline} /></IonButton>  
          </IonCol>
          <IonCol className="cal-container" size="auto">
            <IonDatetimeButton color="primary" datetime="dateTimePractice"></IonDatetimeButton>
            <IonModal keepContentsMounted={true}  > 
              <IonDatetime id="dateTimePractice" 
                ref={datetimePractice} 
                color="primary"  
                highlightedDates={myBookedDates(appointmentDaysInMonth)}
                showDefaultButtons={true}
                value={selectedDate}      
                presentation="date"
                onIonChange={(e) => { e.preventDefault();selectDate(e.detail.value!);}}>
                  <IonButtons slot="buttons">
                    <IonButton color="danger" onClick={cancel}>
                      Cancel
                    </IonButton>
                    <IonButton color="primary" onClick={reset}>
                      Today
                    </IonButton>
                    <IonButton color="primary" onClick={confirm}>
                      Select
                    </IonButton>
                  </IonButtons>
              </IonDatetime>
            </IonModal>
          </IonCol >
          <IonCol size="auto" className="cal-container">
            <IonButton fill='clear' onClick={() => addDay()}><IonIcon  slot="icon-only" icon={chevronForwardOutline} /></IonButton> 
          </IonCol>
        </IonRow>
      </IonGrid>
       
      <IonList key="schedule" style={{paddingTop: '0px', paddingBottom: '0px' }}>
      {  
          appointmentDiaryToday.map((d, index)  => {
  
          // appointment is from agendaList
          //let appointment = d as Appointment;
          // replace agenda appointment if an appointment slot exists
          let appt = d
          //let color = "none"
          const appointmentHour = new Date(d.start).getHours().toString();
          if (appointmentScheduleToday[appointmentHour]){
            // existing appointment
            appt = appointmentScheduleToday[appointmentHour]
            //color = "secondary"
          } else if (appt.consultantUid === ''){
            // new appointment slot
            appt.consultantUid = user.uid
          }

          // display the list item
          return ( 
            <PracticeCalendarItem key={appt.start} myAppointment={appt} index={index} uid={user.uid} onEdithandler={onEdithandler} />   
          )   
          })
      }
      </IonList>
    </IonContent>
  )}
}


interface PracticeCalendarItemProps { 
  myAppointment : Appointment
  uid : string
  index : number;
  onEdithandler: (myAppointment: Appointment) => void 
}

type Status = {
  status : string
  message : string
  class : string 
  color : string
  disabled : boolean
}

type StatusList = {
  [ key : string] : Status
}
const statusMsg : StatusList  = {
  'reserved': { status: "Reserved for appointment", message: "Slot is reserved by you to take bookings.", class: "", disabled: false, color:'secondary'},
  'nobooking': { status: "Appointment not reserved", message: "Edit slot to make available to take booking.",class: "secondaryHatched", disabled: false, color:''},
  'booked': {status: "Booked Appointment", message: "", class: "secondaryBooked", disabled:false, color:''}
}
type NextStep ={
  [ key :string] : string
}
const nextStepConsultant : NextStep = {
  'provisional' : '(Waiting for you to confirm the booking).',
  'confirmed'  : '(Waiting for appointment to happen).',
  'cancel'  : '(Waiting to confirm the appointment cancellation).',
  'cancelled' : '(Appointment cancelled)' ,
  'delivered' : '(Appointment completed).'

}

const nextStepUser  : NextStep = {
  'provisional' : '(Waiting for booking confirmation).',
  'confirmed':  '(Waiting for appointment).',
  'cancel'  :  '(Waiting appointment confirmation cancellation).',
  'cancelled' : '(Appointment cancelled)' ,
  'delivered' : '(Appointment completed).'
}


export const PracticeCalendarItem: React.FC<PracticeCalendarItemProps> = ({myAppointment, index, onEdithandler, uid}) => {

  // workout the booking state
  let messageState : string = ''
  switch(myAppointment.status){
      case "free" : 
          messageState = "reserved";
          break;
      case "blocked":
          messageState = 'nobooking'
          break;
      default:
          messageState = 'booked'
  }

  const bookingStatusMsg = statusMsg[messageState]

  // if booked then workout who the meeting is with
  let msg = ''
  if (myAppointment.consultantUid === uid){
    // appointment delivered by me
    msg = 'Delivered by you to '+myAppointment.name+'. '+
            nextStepConsultant[myAppointment.status];
  }
  else {
    // appoint I have with someone else
    msg = 'Delivered by '+myAppointment.consultantName+' to you. '+
            nextStepUser[myAppointment.status];
  }
  const bookedStatusMsg: Status = { status: myAppointment.status, message: msg, class:'', disabled: false, color:'secondary'}
  return (
    <IonItem key={myAppointment.start} button={true} disabled={bookingStatusMsg.disabled}  color={bookingStatusMsg.color} className={bookingStatusMsg.class}    detail={true} lines="full" onClick={()=>onEdithandler(myAppointment)}>
      <IonLabel key={`s${myAppointment.start}`} className={'schedule-'+myAppointment.status.toLowerCase()+ " ion-text-wrap"}>
        { myAppointment.status !== 'delivered' && 
        <div>
          <StatusBadge status={bookingStatusMsg.status} />
          { messageState === 'reserved' && <br/> }
          { messageState === 'reserved' && <StatusBadge status={myAppointment.status} /> }
          <br/>{bookingStatusMsg.message}
        </div>  
        }            
        { messageState === 'booked' && <div><StatusBadge status={bookedStatusMsg.status} /><br/>{bookedStatusMsg.message} </div>}              
      </IonLabel>
      <IonLabel  key={`sdaytime${index}`} slot="end">
        <p >{displayDate(myAppointment.start, 'hh:mm aa')}</p>
        <p className="ion-text-right">{displayDate(myAppointment.end, 'hh:mm aa')}</p>
      </IonLabel>
    </IonItem>
  )
};


//-------------------------------------------------------------------------------------------------------

type Props = {
  myAppointment: Appointment
  consultant : User
  onDismiss: () => void
}
export const PracticeAppointmentModal: React.FC<Props> = ({ consultant, myAppointment, onDismiss }) => {
  const [appointment,setAppointment] = useState<Appointment>(myAppointment)
  const [loading,setLoading] = useState(true)
  const [present] = useIonActionSheet();
  const [showToast] = useIonToast();
  const startHour = displayDate(appointment.start, 'hh:mm bbb')
  const endHour = displayDate(appointment.end, 'hh:mm bbb')
  const date = displayDate(appointment.start, 'MMM dd, yyyy')
  
  //console.log('appointment', appointment)

  // set up listener for appointments, when consultantUid changes
  useEffect(() => {
    const appointmentId = consultant.uid+myAppointment.start.toString();
    //console.log('useEffect key', appointmentId)
    const unsubscribe  = onSnapshot(doc(db, "appointmentsList", appointmentId), (doc) => {
      if (doc.exists() ){
        // update state
        const thisAppointment = doc.data() as Appointment;
        console.log("DB update:",thisAppointment)
        setAppointment(thisAppointment)
      }
      else {
        // new appointment set state
        //console.log('use effect new appointment', myAppointment)
        myAppointment.audit = []
        myAppointment.status = 'blocked'
        setAppointment(myAppointment)
      }
      setLoading(false)
    });

    // detach listener
    return unsubscribe
    
}, [consultant.uid, myAppointment.start,myAppointment,setAppointment,loading])


// dialog box to confirm appointment is delivered
const deliveredAppointment = async (start: string, end: string, date: string, myAppointment: Appointment, patientName : string) => {

  present({
    header: 'Confirm appointment with '+patientName+' from '+start+ ' to '+end+' on '+date+' has been delivered?',
    buttons: [
      { 
        text: 'Confirm Appointment Delivered',
        role: 'destructive',
        data: {
          action: 'confirm'
        }
      },
      { 
        text: 'Cancel',
        role: 'cancel',
        data: {
          action: 'cancel'
        }
      },

    ],
    onDidDismiss: async ({ detail  }) => {
      if (detail.data?.action === 'confirm'){

        // update appointment status
        myAppointment.status = 'delivered';
        myAppointment.audit.push(" Appointment confirmed as delivered.")
        // update db
        dbChangeAppointment(myAppointment);

        // send confirmation email
        const appointmentDate =  displayDate(myAppointment.start, 'EEEE, MMM dd, yyyy')+ ' from ' +
          displayDate(appointment.start, 'hh:mm bbb') + ' to '+
          displayDate(appointment.end, 'hh:mm bbb') 
        sendAppointmentMail(
          myAppointment.name,
          myAppointment.patientEmail,
          "Prosupervise - Completed Appointment",
          "Completed Appointment", 
          appointmentDate, 
          "This booking will has now been completed by supervisor - "+myAppointment.consultantName, 3)
        sendAppointmentMail(
          myAppointment.consultantName,
          consultant.email,
          "Prosupervise - Completed Appointment",
          "Completed Appointment", 
          appointmentDate, 
          "This booking will has now been completed by you for  - "+myAppointment.name, 3)
      }
    },
  })
}

  // dialog box to confirm booked appointment
  const confirmAppointment = async (start: string, end: string, date: string, myAppointment: Appointment, patientName : string) => {

    present({
      header: 'Confirm appointment with '+patientName+' from '+start+ ' to '+end+' on '+date+' ?',
      buttons: [
        { 
          text: 'Confirm Appointment',
          role: 'destructive',
          data: {
            action: 'confirm'
          }
        },
        { 
          text: 'Cancel',
          role: 'cancel',
          data: {
            action: 'cancel'
          }
        },

      ],
      onDidDismiss: async ({ detail  }) => {
        if (detail.data?.action === 'confirm'){

          // update appointment status
          myAppointment.status = 'confirmed';
          myAppointment.audit.push(dateStamp()+" Appointment confirmed.")
          // update db
          dbChangeAppointment(myAppointment);

          // send confirmation email
          const appointmentDate =  displayDate(myAppointment.start, 'EEEE, MMM dd, yyyy')+ ' from ' +
            displayDate(appointment.start, 'hh:mm bbb') + ' to '+
            displayDate(appointment.end, 'hh:mm bbb') 
          sendAppointmentMail(
            myAppointment.name,
            myAppointment.patientEmail,
            "Prosupervise - Confirmed Appointment",
            "Confirmed Appointment", 
            appointmentDate, 
            "This booking will has now been confirmed by supervisor - "+myAppointment.consultantName, 3)
          sendAppointmentMail(
            myAppointment.consultantName,
            consultant.email,
            "Prosupervise - Confirmed Appointment",
            "Cancelled Appointment", 
            appointmentDate, 
            "This booking will has now been confirmed by you for  - "+myAppointment.name, 3)
        }
      },
    })
  }
  // dialog box to make appointment available 
  const makeFreeAppointmentSlot = async (start: string, end: string, date: string, user: User, myAppointment: Appointment) => {
    console.log('appointment:',myAppointment)

    present({
      header: 'Make appointment slot from '+start+ ' to '+end+' on '+date+' available to book?',
      buttons: [
        { 
          text: 'Make slot available',
          role: 'destructive',
          data: {
            action: 'confirm'
          }
        },
        { 
          text: 'Cancel',
          role: 'cancel',
          data: {
            action: 'cancel'
          }
        },

      ],
      onDidDismiss: async ({ detail  }) => {
        if (detail.data?.action === 'confirm'){

          // update appointment status
          myAppointment.consultantName = user.fullName;
          myAppointment.location = user.myLocation
          myAppointment.consultantUid = user.uid
          myAppointment.status = 'free';
          myAppointment.audit.push(`${dateStamp()} Appointment made available.`)
          // update db
          dbChangeAppointment(myAppointment);
          console.log('Send update:', myAppointment)
        }
      },
    })
  }

  // appointment slot is free, delete appointment slot
  const deleteAppointmentSlot = async (start: string, end: string, date: string, user: User, myAppointment: Appointment) => {
    console.log('appointment:',myAppointment)

    present({
      header: 'Clear appointment slot from '+start+ ' to '+end+' on '+date+' available to book?',
      buttons: [
        { 
          text: 'Clear appointment slot',
          role: 'destructive',
          data: {
            action: 'confirm'
          }
        },
        { 
          text: 'Cancel',
          role: 'cancel',
          data: {
            action: 'cancel'
          }
        },

      ],
      onDidDismiss: async ({ detail  }) => {
        if (detail.data?.action === 'confirm'){

          await dbDeleteAppointment(myAppointment);
          setLoading(true) // for refresh
        }
      },
    })
  }

  const updateContactDetails = async (myAppointment: Appointment, user: User) => {
    // update appointment with latest user info
    // update appointment status
    myAppointment.patientEmail = user.email;
    myAppointment.patientPhone = user.phone
    myAppointment.name = user.fullName
    myAppointment.audit.push(dateStamp()+" Updated appointment contact details.")
    // update db
    dbChangeAppointment(myAppointment);
    
    showToast({
      message: "Your contact details have been refreshed.",
      color: 'success',
      duration: 3000,
      buttons: [{ icon: close }],
    })
  }


  const reasons = [
    'Cannot make this time and will reschedule',
    'No longer requires this appointment',
    'Was not satisfied with last appointment',
    'Supervisor is no longer available',
    'Other reason'
  ]
  
  // dialog box to confirm booked appointment
  const cancelAppointment = async (start: string, end: string, date: string, myAppointment: Appointment, patientName : string) => {
    present({
      header: `Choose reason for cancelling appointment with ${patientName} from ${start} to ${end} on ${date} ?`,
      buttons: [
        { 
          text: `${reasons[0]}`,
          role: 'destructive',
          data: {
            action: 'confirm',
            text: `${reasons[0]}`,
          }
        },
        { 
          text: `${reasons[1]}`,
          role: 'destructive',
          data: {
            action: 'confirm',
            text: `${reasons[1]}`,
          }
        },
        { 
          text: `${reasons[2]}`,
          role: 'destructive',
          data: {
            action: 'confirm',
            text: `${reasons[2]}`,
          }
        },
        { 
          text: `${reasons[3]}`,
          role: 'destructive',
          data: {
            action: 'confirm',
            text: `${reasons[3]}`,
          }
        },
        { 
          text: `${reasons[4]}`,
          role: 'destructive',
          data: {
            action: 'confirm',
            text: `${reasons[4]}`,
          }
        },
        { 
          text: 'Cancel',
          role: 'cancel',
          data: {
            action: 'cancel'
          }
        },

      ],
      onDidDismiss: async ({ detail  }) => {
        console.log(detail)

        if (detail.data?.action === 'confirm'){

          // update appointment status
          myAppointment.status = 'cancelled';
          myAppointment.audit.push(`${dateStamp()} Appointment cancelled.`)
          myAppointment.audit.push(`${dateStamp()} ${detail.data?.text}.`)
          // update db
          dbChangeAppointment(myAppointment);

          // send email update
          const appointmentDate =  displayDate(myAppointment.start, 'EEEE, MMM dd, yyyy')+ ' from ' +
          displayDate(appointment.start, 'hh:mm bbb') + ' to '+
          displayDate(appointment.end, 'hh:mm bbb') 

          sendAppointmentMail(
            myAppointment.name,
            myAppointment.patientEmail,
            "Prosupervise - Cancelled Appointment",
            "Cancelled Appointment", 
            appointmentDate, 
            "This booking will has now been cancelled by supervisor - "+myAppointment.consultantName+
            " for reason - "+`${detail.data?.text}.`, 3)
          sendAppointmentMail(
            myAppointment.consultantName,
            consultant.email,
            "Prosupervise - Cancelled Appointment",
            "Cancelled Appointment", 
            appointmentDate, 
            "This booking will has now been cancelled by you for  - "+myAppointment.name+
            " for reason - "+`${detail.data?.text}.`, 3)

          showToast({
            message: "Your appointment has been cancelled.",
            color: 'warning',
            duration: 3000,
            buttons: [{ icon: close }],
          })
        }
        
      },
    })
  }




  const sendMsgLink = '/tabs/chats/'+appointment.consultantUid+'/friend/'+appointment.patientUid
   
  return (
    <IonPage>
    <IonHeader>
      <IonToolbar color="primary">
      <IonButtons slot="end">
          <IonButton onClick={onDismiss}>Close</IonButton>
        </IonButtons>
        <IonTitle>Practice Appointment</IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent>
      { appointment.status === 'blocked' && <IonChip color="danger">This booking slot has not been reserved.  Please make it available via the action below.</IonChip>}
      <IonAccordionGroup multiple={true} value="first">
      <IonAccordion value="first">
        <IonItem key="bookinginfo" slot="header" color="light">
          <IonLabel>Booking Info <IonBadge class={'status-'+appointment.status.toLowerCase()} >{ appointment.status === "blocked" ? "Not Reserved" : capitalizeFirstLetter(appointment.status) }</IonBadge></IonLabel>
        </IonItem>
        <div className="ion-padding" slot="content">
          <b>{ appointment.name !== '' ? appointment.name : "No one booked" }</b><br/>
          <IonIcon slot="icon-only" icon={call} />
          { appointment.patientPhone !== '' ? appointment.patientPhone : "No phone number." } <br/>
          { appointment.patientEmail !== '' ? appointment.patientEmail : "No email." } <br/>
          <hr />
          { displayDate(appointment.start, 'EEEE, MMM dd, yyyy')} <br/>
          { displayDate(appointment.start, 'hh:mm bbb')}&nbsp;&mdash;&nbsp;
          { displayDate(appointment.end, 'hh:mm bbb')} <br/>
          { appointment.consultantName !== '' ? appointment.consultantName : "No consultant yet" } <br/>
          { appointment.location !== '' ? appointment.location : "No location." } <br/>
        </div>
      </IonAccordion>
      <IonAccordion value="second">
        <IonItem key="notes" slot="header" color="light">
          <IonLabel>Notes</IonLabel>
        </IonItem >
        <div className="ion-padding" slot="content">
          <EditNote myAppointment={appointment} disabled={appointment.status === 'blocked'}  />   
          <IonItem key="audit" className="ion-no-padding" >
            <IonLabel>
              <h3>Audit</h3>
              <div>
              { appointment.audit.length > 0 ? <AppointmentAudit audit={appointment.audit} /> : "No audit log available."}
              </div>
            </IonLabel>
          </IonItem>
        </div>
      </IonAccordion>
      <IonAccordion value="third">
        <IonItem key="billing:" slot="header" color="light">
          <IonLabel>Billing Info</IonLabel>
        </IonItem>
        <div className="ion-padding" slot="content">
          Not yet available.
        </div>
      </IonAccordion>
      
    </IonAccordionGroup>


    <IonList>   
      { appointment.status === 'blocked' &&
        <IonItem key="delete" detail={true} onClick={(e) => {e.preventDefault();makeFreeAppointmentSlot( startHour, endHour, date, consultant, appointment )}} button>
          <IonIcon icon={calendarClearOutline} color='hotcoral'></IonIcon><IonLabel color="primary">&nbsp;Make appointment slot available</IonLabel>
        </IonItem>
      }
      { appointment.status === 'free' &&
        <IonItem key="clear" detail={true} onClick={(e) => {e.preventDefault();deleteAppointmentSlot( startHour, endHour, date, consultant, appointment )}} button>
          <IonIcon icon={closeCircleOutline} color='hotcoral'></IonIcon><IonLabel color="primary">&nbsp;Clear appointment slot</IonLabel>
        </IonItem>
      }
      { appointment.status === 'provisional' &&
        <IonItem key="confirm" detail={true} onClick={(e) => {e.preventDefault();confirmAppointment( startHour, endHour, date, appointment, appointment.name )}} button>
          <IonIcon icon={checkboxOutline} color='hotcoral'></IonIcon><IonLabel color="primary">&nbsp;Confirm appointment</IonLabel>
        </IonItem>
      }
      { appointment.status === 'confirmed' &&
        <IonItem key="confirm" detail={true} onClick={(e) => {e.preventDefault();deliveredAppointment( startHour, endHour, date, appointment, appointment.name )}} button>
          <IonIcon icon={checkboxOutline} color='hotcoral'></IonIcon><IonLabel color="primary">&nbsp;Complete appointment</IonLabel>
        </IonItem>
      }
      { (appointment.status !== 'blocked' &&
         appointment.status !== 'free' &&
         appointment.status !== 'delivered' &&
         appointment.status !== 'cancelled') &&

        <IonItem key="cancel" detail={true} onClick={(e) => {e.preventDefault();cancelAppointment( startHour, endHour, date, appointment, appointment.name )}} button>
          <IonIcon icon={closeCircleOutline} color='hotcoral'></IonIcon><IonLabel color="primary">&nbsp;Cancel appointment</IonLabel>
        </IonItem>
      }
      { (appointment.status !== 'blocked' &&
         appointment.status !== 'free' ) &&  
        <IonItem key="updateDetails" detail={true} onClick={(e) => {e.preventDefault();updateContactDetails(appointment, consultant )}} button>
          <IonIcon icon={refreshCircleOutline} color='hotcoral'></IonIcon><IonLabel color="primary">&nbsp;Refresh your contact details</IonLabel>
        </IonItem>
      }
      { (appointment.status !== 'blocked' &&
         appointment.status !== 'free' ) && 
        <IonItem key="message" detail={true} routerLink={sendMsgLink} button>
          <IonIcon icon={chatbubbleEllipsesOutline} color='hotcoral'></IonIcon><IonLabel color="primary">&nbsp;Send message</IonLabel>
        </IonItem>
      }
      <IonItem key="calendar" onClick={(e) => {e.preventDefault();AddToCalendar(appointment)}} button>
      <IonIcon icon={calendarOutline} color='hotcoral'></IonIcon><IonLabel color="primary">&nbsp;Download appointment</IonLabel>
      </IonItem>
      
    </IonList>
    </IonContent>
  </IonPage>
  )
}

export default PracticeCalendar
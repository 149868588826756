

// ProgressBar component to show amount of CPD

export interface CPDProgressBar  {
    bgcolor : string
    completed : string
    percent : number
}


const CPDProgressBar : React.FC<CPDProgressBar> = ({bgcolor, completed, percent}) => {

    const bgColor = bgcolor ? bgcolor : 'red'
    const percentageCompleted = completed ? completed : 100

    const containerStyles = {
      height: 15,
      width: '100%',
      backgroundColor: "#e0e0de",
      borderRadius: 5,
    }
  
    const fillerStyles = {
      height: '100%',
      width: `${percentageCompleted}%`,
      backgroundColor: bgColor,
      borderRadius: 'inherit',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  
    const labelStyles = {
      padding: 0,
      color: 'white',
      fontWeight: 'bold',
      fontSize: '0.75rem'
    }
  
    return (
      <div style={containerStyles}>
        <div style={fillerStyles}>
          <span style={labelStyles}>{`${percent}%`}</span>
        </div>
      </div>
    );
  }
  
  export default CPDProgressBar;
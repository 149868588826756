import { IonBadge, IonButton, IonButtons, IonIcon, IonRouterLink, IonText, IonToolbar } from "@ionic/react";
import './CookieBanner.css'
import React, { useEffect, useState } from "react";
import { cardOutline, closeCircleOutline, cloudDownloadOutline } from "ionicons/icons";
import { useIonicPWAInstall } from "../pwaprompt/PWAPrompt";
import { FirebaseError } from "firebase/app";
import { StripeAccount, stripeAccountDefault } from "../data/StripeAccount";
import { doc, collection, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import { format } from "date-fns";

export interface InfoBannerProps {
  customerId : string
  uid: string
}

const InfoBanner: React.FC<InfoBannerProps> = ({customerId, uid}) => {
  type Count = {
    count : number
    dismiss: boolean
  }

  type PaymentBanner = {
    dismiss: boolean
    status : string
  }
 
  type ServiceStatus = {
    show : PaymentBanner
    stripeAccount: StripeAccount
  }

  //const [items, setItems] = useState([]);
  const [install, setInstall] = useState<Count>()
  const [stripeAccount, setStripeAccount] = React.useState<ServiceStatus>({show: {dismiss:true, status: ''}, stripeAccount: stripeAccountDefault})
  
  const [error, setError] = React.useState('')
/*
  useEffect(() => {
    localStorage.setItem('install', JSON.stringify(install));
    console.log('install', install)
  }, [install]);
  */
  const { isSupported, isInstalled, pwaInstall } = useIonicPWAInstall()!

  useEffect(() => {
    // count down installer count to zero
    const installString = localStorage.getItem('install'+uid);
    //console.log('installstring', installString)
    if (installString && installString !== "undefined"){
      const installCount = JSON.parse(installString)
      if (installCount.count > 0 ){
        setInstall({count: installCount.count -1, dismiss: installCount.dismiss})
        localStorage.setItem('install'+uid, JSON.stringify({count: installCount.count -1,dismiss: installCount.dismiss}));
      
      }
      else {
        setInstall(installCount)
      }
      //console.log('installCount',installCount)
    }
    else {
      //console.log('set local storage')
      localStorage.setItem('install'+uid, JSON.stringify({count: 10}));
      setInstall({count:10,dismiss: false})
    }
    
  },[uid])

  // fetch account from db if customerId exists
  React.useEffect(()=>{
    const fetchStripeAccount = async () => {
      try {
        if (customerId && typeof customerId !== 'undefined' && customerId !== ''){
          const paymentBannerString = localStorage.getItem('paymentBanner'+uid)
          let paymentBanner = {dismiss:false, status: ''}
          if (paymentBannerString && paymentBannerString !== "undefined"){
            paymentBanner = JSON.parse(paymentBannerString)
          }
          
          const docRef = doc(collection(db,'stripeAccount'), customerId)
          const docSnap = await getDoc(docRef)
          if (docSnap.exists()){
            const stripeAccount = docSnap.data() as StripeAccount
            if (stripeAccount.status !== paymentBanner.status){
              paymentBanner.dismiss = false;
              paymentBanner.status = stripeAccount.status
              localStorage.setItem('paymentBanner'+uid, JSON.stringify(paymentBanner));
            }
            setStripeAccount({show: paymentBanner,stripeAccount : docSnap.data() as StripeAccount})
          }
        }
        
      } catch(error ) {
        if (error instanceof FirebaseError){
          setError(error.message)
        }
      }
    }
    if (customerId && customerId !== '')
      fetchStripeAccount()

    
  },[customerId,uid])

  const DisplayMembership = ( stripeAccount: StripeAccount) => {
    console.log(stripeAccount)
    let subscriptionHeadline = '';
    let subscriptionDescription = '';
    let subscriptionLink = '/tabs/home/stripeaccount'
    let subscriptionBadge = ''
    const trial_end = format(new Date(stripeAccount.trial_end * 1000), "dd MMM")
  
    if (stripeAccount.customerId === ''){
      subscriptionHeadline = 'You have Prosupervise Standard (free) membership'
      subscriptionDescription = "Subscribe to access free trial of premium features"
      subscriptionLink = '/tabs/home/striperegister'
    }
    else {
      if (stripeAccount.hasTrial  ){     
        subscriptionHeadline = `You are now on the Prosupervise Premium Free Trial`
        subscriptionBadge = `ends ${trial_end}`
        subscriptionDescription = "Access your membership plan"
        subscriptionLink = '/tabs/home/stripeaccount'
      }
      else {
        switch (stripeAccount.status){
          case 'trialing':
            break;
          case 'canceled':
            subscriptionHeadline = `You have cancelled your Premium Membership and are now a Standard member`
            subscriptionDescription = "Upgrade to Prosupervise Premium"
            break
          case 'paused':
            subscriptionHeadline = `Your subscription has ended its trial period`
            subscriptionDescription = 'Enter you payment detail to continue with Premium membership'
            break;
          case 'incomplete_expired':
            subscriptionHeadline = `You have Prosupervise Standard (free) membership`
            subscriptionDescription = "Upgrade to Prosupervise Premium"
            break;
          case 'unpaid':
            subscriptionHeadline = `Your subscription payment details has failed`
            subscriptionDescription = 'Enter you payment detail to continue with Premium membership'
            break;
          case 'active':
          case 'past_due':
          case 'incomplete':
            subscriptionHeadline = `You have the Prosupervise Premium membership (paid by ${stripeAccount.period})`
            subscriptionDescription = "Access your membership plan"
        } 
      }
    }
  
    return (
      <IonToolbar color="hotcoral">
        <IonButtons slot="start">
          <IonButton fill="clear" routerLink={subscriptionLink}>
            <IonIcon slot="icon-only" color="almostwhite" icon={cardOutline}></IonIcon>
          </IonButton>
        </IonButtons>
        <IonRouterLink  routerLink={subscriptionLink}>
        <IonText color="almostwhite">
        { subscriptionHeadline }{ subscriptionBadge !== '' && <span>({subscriptionBadge})</span> }
        { !stripeAccount.livemode && <IonBadge color="warning">Test mode</IonBadge> }
        <br />
             <IonButton size="small" color="almostwhite">   {subscriptionDescription}
          &nbsp;<b>&gt;</b></IonButton>  
        </IonText></IonRouterLink>
        <IonButtons slot="end">
          <IonButton fill="clear" onClick={() => closePaymentBanner()}>
            <IonIcon slot="icon-only" color="almostwhite" icon={closeCircleOutline}></IonIcon>
          </IonButton>
        </IonButtons>
      </IonToolbar>
    )
  }

  const closeInstallBanner = () => {
    // close the installer banner 
    localStorage.setItem('install'+uid, JSON.stringify({count: 0,dismiss: true}));
    setInstall({count:0, dismiss: true})
  }

  const closePaymentBanner = () => {
    localStorage.setItem('paymentBanner'+uid,JSON.stringify({dismiss:true, status: stripeAccount.stripeAccount.status}))
    setStripeAccount({...stripeAccount, ...{show: {dismiss:true, status: stripeAccount.stripeAccount.status}}})
  }

  const installApp = () => {
    closeInstallBanner()
    pwaInstall()
  }

  // data db loading error
  if (error !== ''){
    // db error report it
    console.log('***error', error);
    return (<IonText>DB Error { error }</IonText>)
  }
  return (
    <div>
    { install && install.count <= 0&& !install.dismiss && isSupported && !isInstalled && <div>
      <IonToolbar color="hotcoral">
        <IonButtons slot="start">
            <IonButton fill="clear" onClick={()=> installApp()}>
              <IonIcon slot="icon-only" color="almostwhite" icon={cloudDownloadOutline}></IonIcon>
            </IonButton>
        </IonButtons>
        <IonText color="almostwhite" onClick={() => installApp()}>Add the app to your home screen?
          <IonButton color="almostwhite" size="small">Install the app on your home screen &gt;</IonButton> </IonText>
        <IonButtons slot="end">
          <IonButton fill="clear" onClick={() => closeInstallBanner()}>
            <IonIcon slot="icon-only" color="almostwhite" icon={closeCircleOutline}></IonIcon>
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </div>
    }
    { !stripeAccount.show.dismiss && DisplayMembership(stripeAccount.stripeAccount)}
  </div>
  );
  };
  
  export default InfoBanner;
import { IonButtons,IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonBackButton, IonButton, IonIcon, useIonModal, IonSegment, IonSegmentButton, IonItem, IonAccordion, IonAccordionGroup, IonLabel, IonList, IonCol, IonGrid, IonRow, IonText, IonThumbnail } from '@ionic/react';
import React , { useState, useEffect} from 'react';
import { collection, doc, getDoc } from "firebase/firestore";
import { db } from '../../firebase';
import { useAuth } from '../../contexts/authContext';
import {  useParams } from 'react-router-dom';

import { User, userDefault} from '../../data/user';
import { calendarOutline, chatbubbleEllipsesOutline, exitOutline, locationOutline, settingsOutline } from 'ionicons/icons';
import  EditProfileModal  from './EditProfileModal';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { BannerBadge } from '../../appointments/AppointmentFunctions';
import DirectoryCalendar from '../../appointments/DirectoryCalendar';
import { useProfileFields,  } from '../../forms/data/profileFields'
import './ShowUser.css'


const ShowUser: React.FC = () => {
  
  const [consultant, setConsultant] = useState<User>(userDefault);
  const [reloadUser,setReloadUser]=useState(true)
  const [error, setError] = useState('')
  const [segment, setSegment] = React.useState('about');
  const { authInfo, saveUser } = useAuth()!; 

  const { uid } = useParams() as { uid : string }

  // load user
  useEffect(() => {

    if (typeof uid === 'undefined'){
      setConsultant(userDefault);
      setError('user is not defined')
    }
    else {
      getDoc(doc(collection(db,'users'), uid))
      .then(doc => {
        if (doc.exists() ){
          const user = doc.data() as User;
          setConsultant(user)
          console.log('got user:', user)
        }
        else {
          console.log('user not found ', doc)
          setError('User id: "'+uid+'" not found')
        }
      })
      .catch(err => {
          console.log('Error getting user documents', err);
          setError("Error getting user")
      });
    }
    setReloadUser(false)
  }, [uid, reloadUser])

  const openPersonEditor = () => {
    presentEditProfileModal()
  }
  const handleEditModalDialogDismiss = (hasUpdatedUser :  boolean) => {
    dismissEditProfileModal()
    if (hasUpdatedUser){
      setReloadUser(true)
    }
  }

  const [presentEditProfileModal, dismissEditProfileModal] = useIonModal(EditProfileModal, {
    saveUser: saveUser,
    user: consultant,
    onDismiss: handleEditModalDialogDismiss,
  })

  const sendMsgLink = '/tabs/chats/'+authInfo.user.uid+'/friend/'+uid
  const isConsultant = (authInfo.user.uid === uid)

  const clickShowAppointment = () =>{
    if (!isConsultant){
      setSegment('availability')
    }
  }

  return (
    <IonPage>     
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref='/tabs/connect/'></IonBackButton>
          </IonButtons>    
          <IonTitle>{consultant?.fullName}{isConsultant && ' (You)'}</IonTitle>
          { uid === authInfo.user.uid &&
          <IonButtons slot="end">
             <IonButton fill='clear' onClick={() => openPersonEditor()}><IonIcon  slot="icon-only" icon={settingsOutline} /></IonButton>          
          </IonButtons>
          }
        </IonToolbar>
        { !isConsultant && 
        <IonToolbar color="primary">
          <IonSegment value={segment} onIonChange={(e) => setSegment(e.detail.value as string)}>
              <IonSegmentButton value="about">
                About
              </IonSegmentButton>
              <IonSegmentButton value="availability">
                Availability
              </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      }
       </IonHeader>
      <IonContent color='duckegg'>
      {  error !== '' && <p>{error}</p> }
      <div  style={segment === 'about' ? {} : { display: 'none' } }>
      <IonList class="ion-no-padding" key="profile">
      <IonItem key="top" color="highblue">
        <IonGrid>
          <IonRow class="ion-align-items-center">
            <IonCol size="3">
              <IonThumbnail>
              <center>           
                <img alt="avatar" className="avatar" src={consultant?.avatar} />         
              </center>
              </IonThumbnail>
            </IonCol>
            <IonCol size="8">
              <IonText color="primary"> <p>
              <b>{ consultant?.fullName }</b>
              <br />{consultant?.occupation}<br />
              { consultant.myLocation !== '' && <span><IonIcon color="hotcoral" icon={locationOutline}></IonIcon>{consultant.myLocation}</span>   }
              </p> </IonText>       
            </IonCol>
            
          </IonRow> 
            <IonRow class="ion-align-items-center">
            <IonCol>
                  <IonButton disabled={isConsultant} routerLink={sendMsgLink} ><IonIcon icon={chatbubbleEllipsesOutline} color="hotcoral" slot="start"></IonIcon>Send Message</IonButton>
            
                  <IonButton disabled={isConsultant} onClick={()=>clickShowAppointment()} ><IonIcon icon={calendarOutline} color="hotcoral" slot="start"></IonIcon>Book</IonButton>
            
                  <IonButton disabled={ typeof consultant.url === "undefined" || consultant.url === ''} href={consultant.url} ><IonIcon icon={exitOutline} color="hotcoral" slot="start"></IonIcon>Visit Website</IonButton>
            </IonCol>
            </IonRow>
        </IonGrid>
      </IonItem>
        
      <IonItem key='about' color='duckegg'>
        <IonLabel class="ion-text-wrap">
          <h2>About me</h2>
          { consultant.bio !== '' ? <ReactMarkdown children={consultant?.bio} remarkPlugins={[remarkGfm]} />: "Not available." }
        </IonLabel>
      </IonItem>
      <IonItem key='servicesOffered' color='duckegg'>
        <IonLabel class="ion-text-wrap">
          <h2>Services Offered</h2>
          { typeof consultant.servicesOffered !== "undefined" && consultant.servicesOffered.length >  0 ? <ul>
                {consultant.servicesOffered.map((service) => {
                  return (<li>{service}</li>)
                }
                )}</ul>
                : <p>{"Not available."}</p>
          }
        </IonLabel>
      </IonItem>
      <IonItem key='theory' color='duckegg'>
        <IonLabel class="ion-text-wrap">
          <h2>Theoretical Orientation </h2>
          <p>{ typeof consultant.orientation !== "undefined" && consultant.orientation !== '' ? consultant.orientation : "Not available." }</p>
        </IonLabel>
      </IonItem>
      </IonList>
    { isConsultant && 
    <IonAccordionGroup>
      <IonAccordion key="details" color='duckegg'>
        
        <IonItem  slot="header" color="duckegg">
          <IonLabel>Consultant Details</IonLabel>
        </IonItem>
  
        <div  className="ion-padding midBlueBackground" slot="content">
        <BannerBadge key='ff' color="danger" message="This information is not visible to other users" />
        <ConsultantDetails consultant={consultant} />
        </div>
      </IonAccordion>
    </IonAccordionGroup>
    }
    <div className="duckEggBackground" style={{padding: '100px'}}></div>
    </div> 
    { !isConsultant && <DirectoryCalendar user={authInfo.user} consultant={consultant} hide={segment === 'about'} />}
    
    </IonContent>
    </IonPage>
  )
}

type Labels = {
  [key: string] : string;
}

interface ConsultantDetailsProp {
  consultant : User

}

const ConsultantDetails : React.FC<ConsultantDetailsProp>= ({consultant}) => {

  const profileNames = ["phone","email","qualYears", "cpd","compliance","accreditingBody"];
  const profileFormFields = useProfileFields();
  const  profileFields : Labels = {}


  profileFormFields.forEach(field =>{
    if (profileNames.includes(field.formName)){
      profileFields[field.formName] = field.label
    }
  })
  
  return (
    <IonList key="profileExtra">
      {
        Object.entries(consultant).map(
          ([name,value], index) => {
            if (profileNames.includes(name)){
              return (
              <IonItem key={`${name}_${index}`} color='duckegg'>
                <IonLabel class="ion-text-wrap">
                  <h2>{profileFields[name]}</h2>
                  <p>{ value !== '' ? value as string : "Not available."}</p>
                </IonLabel>
              </IonItem>
            
              )
            }
          }
        )
      }

      
    </IonList>
    
  )
}
export default ShowUser

import { useIonViewDidEnter,IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonBackButton,  IonFooter, IonIcon, IonTextarea, IonButton, IonButtons, IonItem, IonList, IonSpinner, IonLabel, IonChip, useIonViewWillLeave, useIonActionSheet, useIonLoading} from '@ionic/react';
import React, { useState, useEffect} from 'react';
import { doc, getDoc, collection, setDoc, query, where, onSnapshot, runTransaction, orderBy, deleteDoc} from "firebase/firestore";
import { db } from '../firebase';
//import { useAuth } from '../../contexts/authContext';
import { RouteComponentProps } from 'react-router-dom';
import { User } from '../data/user';
import './Chat.css'
import {   checkmarkDone,  send, trash } from 'ionicons/icons';
import { useAuth } from '../contexts/authContext';


import { ChatMessage, ChatThread } from './components/FirebaseChatDB'
import { FirebaseError } from 'firebase/app';
import { useCollection } from 'react-firebase-hooks/firestore';

import { format, isToday, isYesterday, isThisWeek } from 'date-fns';


const duration  = ( timestamp : number ) => {
    
    const date = new Date(timestamp)
    //const currentDate = new Date();
    //const timeDiff = currentDate.getTime() - timestamp;
    if (isThisWeek(timestamp)){
        return  format(date, 'EEEE h:mm a');
    } else {
        return format(date,"dd/MM/yyyy h:mm a")
    }
/*
    if (isToday(date)){
        return  format(date, 'h:mm a');
    } else if (isYesterday(timestamp)){
        return "Yesterday"
    } else if (isThisWeek(timestamp)){
        return format(date,'EEEE')
    } else {
        return format(date,"dd/MM/yyyy")
    }

    return 'NaN';*/
}

const dateBanner  = ( timestamp : number ) => {
    
    const date = new Date(timestamp)
    //const currentDate = new Date();
    //const timeDiff = currentDate.getTime() - timestamp;

    if (isToday(date)){
        return  "Today";
    } else if (isYesterday(timestamp)){
        return "Yesterday"
    } else if (isThisWeek(timestamp)){
        return format(date,'EEEE')
    } else {
        return format(date,"dd/MM/yyyy")
    }

    return 'NaN';
  
}
// show chat message for user or group
interface Props
  extends RouteComponentProps<{
    userId: string;
    type: string
    friendId : string
  }> {}
// /tabs/chat/:userId/friend/:friendId
const Chat: React.FC<Props> = ({ match }) => { 
    const userId = match.params.userId;
    //const type = match.params.type
    const friendId = match.params.friendId
    
    const [ error, setError ] = useState("");
    const [ recipientUser, setRecipientUser] = useState<User>()
    const [ message, setMessage] = useState("")
    const [chat, setChat] = useState<ChatThread>()

	const { authInfo } = useAuth()!;

    const contentRef = React.useRef<HTMLInputElement>(null);
    //  Scroll to end of content
    const scrollToBottom = async () => {
       if (contentRef.current){
            contentRef.current.scrollIntoView()
       }
    }

    const scrollToBottom2 = () => {
        if (contentRef.current){
             contentRef.current.scrollIntoView()
        }
     }
     
    //  Scroll to end of content
    //  Mark all chats as read if we come into a chat
    //  Set up our swipe events for animations and gestures
    useIonViewDidEnter(() => {
        setTimeout(() => scrollToBottom2(),300);
       
    });
    
    // Leaving page
    // Mark chat thread messages as all read
    useIonViewWillLeave(  () => {
       
        markMessagesRead()
    })

    const markMessagesRead = async () => {

        const uid = authInfo.user.uid;
        const chatThreadId = friendId+userId;
        
        // update chatThread thread 
        try {

            // update db
            const chatThreadRef = doc(db,"chatList", chatThreadId);
            await runTransaction(db, async (transaction) => {
                
                const chatThreadDoc = await transaction.get(chatThreadRef);
                if (!chatThreadDoc.exists()) {
                    throw "Document does not exist!";
                }
                const chatThread = chatThreadDoc.data() as ChatThread
                //console.log('got', chatThread)
                // blat the unread
                if (uid === chatThread.userId){
                    chatThread.userMsgIds = new Array<string>
                    transaction.set(chatThreadRef, { userMsgIds : []}, { merge: true });
                }
                else {
                    chatThread.friendMsgIds = new Array<string>
                    transaction.set(chatThreadRef, { friendMsgIds : []}, { merge: true });
                }

                
               // console.log('done')
            });
        }
        catch(error){
            if (error instanceof FirebaseError){
                setError(error.code)
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage)
            } else {
                console.log('chat.tsx:',error)
            }
        }
    }
    

    useEffect(() => {
        if (message && contentRef.current){
            contentRef.current.scrollIntoView()
        }

    },[message])
    // load chat thread object
    useEffect(() => {
        const chatId = friendId+userId;
        const today = new Date().getTime()
        const unsubscribe  = onSnapshot(doc(db, "chatList", chatId), (doc) => {
            if (doc.exists() ){
                const chatThread = doc.data() as ChatThread;
                setChat(chatThread)
            }
            else {
                const chatThread = {
                    id : chatId,
                    lastUpdate: today,
                    friendName : recipientUser?.fullName,
                    friendAvatar : recipientUser?.avatar,
                    userName: authInfo.user.fullName,
                    userAvatar: authInfo.user.avatar,
                    lastMessage: '',
                    friendId : friendId,
                    userId : userId,
                    groupId : '',
                    friendMsgIds : new Array<string>,
                    userMsgIds: new Array<string>
                } as ChatThread
                
                setChat(chatThread)
            }
            
        });

        // detach listener
        return unsubscribe
        
    }, [friendId, userId, authInfo.user, recipientUser])

    // load user
    useEffect(() => {
        let uid = friendId
        if (friendId === authInfo.user.uid){
            uid = userId  // reply is other
        }
        getDoc(doc(collection(db,'users'), uid))
            .then(doc => {
              if (doc.exists() ){
                const user = doc.data() as User;
                setRecipientUser(user)
                //console.log('got user:', user)
              }
              else {
                console.log('user not found ', friendId, doc)
                setError('Recipient User id: "'+friendId+'" not found')
              }
            })
            .catch(err => {
                console.log('Error getting user documents', err);
                setError("Error getting document")
            });
    }, [userId, friendId,authInfo.user])
    
    // sendMessage - update ChatThread and create Message object
    const sendMessage = async () => {
        
        if ( typeof chat !== 'undefined' && typeof recipientUser !== 'undefined' ){
            // update the thread with latest person data
            const today = new Date().getTime()
            const updateThread = chat;
            if (userId === authInfo.user.uid){
                // sender
                updateThread.friendAvatar = recipientUser.avatar;
                updateThread.friendName = recipientUser.fullName;
                updateThread.userAvatar =authInfo.user.avatar;
                updateThread.userName = authInfo.user.fullName;
            }
            else {
                // recipient
                updateThread.userAvatar = recipientUser.avatar;
                updateThread.friendName = recipientUser.fullName;
                updateThread.userAvatar =authInfo.user.avatar;
                updateThread.userName = authInfo.user.fullName;
            }
            
            updateThread.lastUpdate = today;
            updateThread.lastMessage = message;

            // create message
            const newMsgRef = doc(collection(db,'chats'))
            let recipientId = friendId;  // message is going to friend
            if (authInfo.user.uid === friendId) { 
                recipientId = userId    // message is going to originator
                updateThread.userMsgIds.push(newMsgRef.id)  // add id to originator unread list
                updateThread.friendMsgIds = new Array<string>  // mark messages sent to me (friend) read
            } else {
                updateThread.friendMsgIds.push(newMsgRef.id)  // add id to friend unread list
                updateThread.userMsgIds = new Array<string> // mark send to me (originator) read
            }

            const newMsg = {
                id : newMsgRef.id,
                chatId : updateThread.id,
                creatorId : authInfo.user.uid,
                userName: authInfo.user.fullName,
                message : message,
                createDate : today,
                isDeleted : false,
                recipients : {
                    id : recipientId,
                    isRead : false
                }
            }
            

            // update chat thread and then create message on Firebase
            try {
                const chatThreadRef = doc(db,"chatList", updateThread.id);
                await runTransaction(db, async (transaction) => {
                    /*
                    const chatThreadDoc = await transaction.get(chatThreadRef);
                    if (!chatThreadDoc.exists()) {
                      throw "Document does not exist!";
                    }
                    */
                    
                    transaction.set(chatThreadRef, updateThread,{ merge: true });
                });
                //await setDoc(doc(collection(db,'chatList'), updateThread.id), updateThread,{ merge: true });

                await setDoc(doc(collection(db,'chats'), newMsg.id), newMsg,{ merge: true });

                setMessage("")
                scrollToBottom();
            }
            catch(error){
                if (error instanceof FirebaseError){
                    /*
                    if (error.code === 'auth/wrong-password' ||
                        error.code === 'auth/user-not-found' )
                        setMyError(noAccountWithPassword);
                    else if (error.code === 'auth/internal-error' ||
                             error.code === 'auth/network-request-failed')
                        setMyError(pleaseTryAgain)
                    else
                        setMyError(error.code);
                    */
                    setError(error.code)
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.log(errorCode, errorMessage)
                } else {
                    console.log('chat.tsx:',error)
                }
            }
        }

    }
    /*
    <div className="chat-contact">
                            <img src={ recipientUser?.avatar } alt="avatar" />
                            <div className="chat-contact-details">
                                <p>{ recipientUser?.fullName }</p>
                                <IonText color="medium">last seen today at 22:10</IonText>
                            </div>
                        </div>
                        */
    
    return (
        <IonPage className="chat-page">
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start"><IonBackButton mode="md" defaultHref='/tabs/chats/'></IonBackButton>
                    </IonButtons>
                    
                    <IonTitle className="ion-text-center">
                        <div className="chat-contact">
                            <img  src={ recipientUser?.avatar } alt="avatar" /> 
                            <span>{ recipientUser?.fullName }</span>
                        </div> 
                    </IonTitle>

                </IonToolbar>
                
            </IonHeader>
            <IonContent id="main-chat-content" >
                { error && 
                    <IonItem key='error' color="danger">
                        <IonLabel class="ion-text-wrap" >{error}</IonLabel>
                    </IonItem>

                }
                { typeof chat?.id !== "undefined" && <ChatMessageList chatId={chat?.id} uid={authInfo.user.uid} friendId={friendId} chatThread={chat} /> }
                { typeof chat?.id === "undefined"  && <IonSpinner /> } 
                <div key='ref' ref={contentRef} onLoad={() => {scrollToBottom();}} > </div>
           
            </IonContent>
            <IonFooter>
                <IonToolbar color="primary">
                   <div style={{padding: '10px'}}>
                    <IonTextarea fill="outline"
                        placeholder="Enter message" className="message"
                        autoGrow={true}
                        autoFocus={true}
                        onInput={(event): void => setMessage(event.currentTarget.value!)}
                        value={message}></IonTextarea></div>
                    <IonButtons slot="end"><IonButton color={message !== "" ? "hotcoral" : "almostwhite"} slot="end" onClick={(e) =>{e.preventDefault();sendMessage()}}><IonIcon icon={ send } /></IonButton></IonButtons>                                 
                </IonToolbar>       
            </IonFooter>
        </IonPage>
    )
}
export default Chat;

interface ChatMessageListProps {
    chatId : string |undefined
    uid : string
    friendId : string
    chatThread : ChatThread
}
const ChatMessageList : React.FC<ChatMessageListProps> = ({chatId, uid,friendId, chatThread}) => {
    // grab the messages that match this thread chatId = id

    const getAllChatMessagesQuery = query(
		collection(db, "chats"), where( "chatId" , "==", chatId), orderBy("createDate","asc")
	)
	
	const [value, loading, error] = useCollection(
		getAllChatMessagesQuery,{ snapshotListenOptions: { includeMetadataChanges: true }})
    
    const [present] = useIonActionSheet();
    const [presentLoading, dismissLoading] = useIonLoading();

    const deleteChatMessage = async (id : string ) => {
		present({
			buttons: [
			{ 
				text: 'Cancel',
				role: 'cancel',
				data: {
					action: 'cancel'
				}
			},
			{ 
				text: 'Delete Message',
				role: 'destructive',
				data: {
					action: 'discard'
				}
			},
	
		],
		onDidDismiss: ({ detail }) => {
			if (detail.data?.action === 'discard'){
				deleteChatMessageDB(id)
			}
		},
		})
	}	

    const deleteChatMessageDB = async ( id : string ) => {

		await presentLoading({message:'Deleting messages...'})
		try {
			// get delete message
            await deleteDoc(doc(db,"chats",id))

            // delete any references
            if (chatThread.friendMsgIds.includes(id) || chatThread.userMsgIds.includes(id)){
                // need to remove the chat item
                const chatThreadRef = doc(db,"chatList", chatThread.id);
                await runTransaction(db, async (transaction) => {
                    
                    const chatThreadDoc = await transaction.get(chatThreadRef);
                    if (!chatThreadDoc.exists()) {
                      throw "Document does not exist!";
                    }
                    const updateThread = chatThreadDoc.data()
                    if (chatThread.friendMsgIds.includes(id)){
                        const index = chatThread.friendMsgIds.indexOf(id)
                        updateThread.friendMsgIds.splice(index, 1)
                    }
                    if (chatThread.userMsgIds.includes(id)){
                        const index = chatThread.userMsgIds.indexOf(id)
                        updateThread.friendMsgIds.splice(index, 1)
                    }
                    
                    transaction.set(chatThreadRef, updateThread,{ merge: true });
                });
            }
		} catch (e) {
			console.log('error deleting message',e)
		}
		await dismissLoading()
	}

    //
    // display the chat messages 
    //
    let currentBannerDate  = "";
    let displayBanner = ""
    let unreadBanner = ""
    let unreadMsgs = chatThread.userMsgIds;
    let readReceipts = chatThread.friendMsgIds
    let bDisplayedUnreadBanner = false;
    if (uid === chatThread.friendId){
        unreadMsgs = chatThread.friendMsgIds
        readReceipts = chatThread.userMsgIds
    }
    

    if (value){
        // display data

        return (
            <div>
            <IonList>
                { value.docs.map((doc,index)  => { 
                    const d = doc.data() as ChatMessage

                    // set date banners
                    const bannerDate = dateBanner(d.createDate)
                    if ( displayBanner !== ""){
                        displayBanner = ""
                    }
                    if (bannerDate !== currentBannerDate){
                        displayBanner = bannerDate;
                        currentBannerDate =  bannerDate;
                    }

                    // set unread message banner
                    if ( unreadBanner !== ""){  // only display once
                        bDisplayedUnreadBanner = true;
                        unreadBanner = "";
                        
                    }
                    if (unreadMsgs.includes(d.id) && !bDisplayedUnreadBanner){
                        unreadBanner = `${unreadMsgs.length} UNREAD MESSAGE`   
                    }


                    // set message read links
                    const messageRead = (!readReceipts.includes(d.id) && d.creatorId === uid)
                    return (         
                        <div key={`bubbleA_${index}`}>
                        { displayBanner  && <div key={`banner_${index}`}className="chat-bubble banner"> <IonChip >{ displayBanner }</IonChip> </div>}
                        { unreadBanner  && <div key={`bannerunread_${index}`}className="chat-bubble banner"> <IonChip >{ unreadBanner }</IonChip> </div>}
                        <div key={`bubble_${index}`} className={ d.recipients.id === friendId ?  "chat-bubble bubble-received" : "chat-bubble bubble-sent"  } >
                            <div key={`bubble1_${index}`}>
                                { !unreadMsgs.includes(d.id) ? d.message : <b> { d.message } </b>}
                                <span className="chat-bottom-details" >
                                    <span>{duration(d.createDate)}</span>
                                    { messageRead && <IonIcon icon={ checkmarkDone } style={{ fontSize: "0.8rem" }} /> }
                                    { d.creatorId === uid && <IonIcon  onClick={(e) => {e.preventDefault(); deleteChatMessage(d.id) }} icon={ trash  } />}
                                </span> 
                            </div>
                            <div key={`bubble2_${index}`}className={  d.recipients.id === friendId ?  "bubble-arrow" : "bubble-arrow alt" }></div>
                        </div>
                        </div>
                    )})
                }
                { !value.docs.length && <IonItem>No Messages</IonItem>}
            </IonList>
            
            </div>
        )
    }
    
    if (loading){
        // display spinner
        <IonSpinner />
    }

    if (error){
        // display error
        if (error instanceof FirebaseError){
            console.log('chat list',error)
            return (
            <p>{error.code}{error.message}</p>
            )		
        }
        return (<p>Some error</p>)
    }

    return (
        <></>
    )
}


//
// Display CPD records for a given user
//

import { query, collection, where, doc, deleteDoc, setDoc } from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import { User } from '../data/user'
import { db } from '../firebase';
import { IonAccordion, IonAccordionGroup, IonButton, IonContent, IonIcon, IonItem, IonLabel,  IonList,  IonNote,  IonSpinner, useIonActionSheet, useIonToast, useIonLoading, useIonModal } from '@ionic/react';
import { CPDRecord } from './CPDRecord';
import React from 'react';
import { close, albumsOutline, checkmarkCircleOutline, headsetOutline, bagOutline, chatboxEllipsesOutline, medalOutline, chatbubblesOutline, easelOutline,bodyOutline,megaphoneOutline, heartHalfOutline, cubeOutline, timerOutline, calendarOutline, fileTrayFullOutline, closeCircleOutline, downloadOutline, } from 'ionicons/icons';
import './CPDStyle.css'
import cpdSettings from './cpdSettings.json'
import { dateStamp, formatDate, getCPDHours} from './CPDFunctions';

import CPDNewRecordModal from './CPDNewRecordModal';
import CPDEditRecordModal from './CPDEditRecordModal';
import { FirebaseError } from 'firebase/app';
import CPDProgress from './CPDProgress';
import CPDDownloadPDFModal from './CPDDownloadPDFModal';


// icon mapping for CPD types
type MapType = { 
  [id: string]: string; 
}

// CPD type to icon string name
const iconTypeMap : MapType = {}
cpdSettings.cpdType.forEach( item => {
  iconTypeMap[item.type] = item.icon
})

// icon string names to icon
const icons : MapType = {
  'checkmarkCircleOutline': checkmarkCircleOutline, 
  'albumsOutline' : albumsOutline,
  'headsetOutline' : headsetOutline,
  'bagOutline' : bagOutline,
  'chatboxEllipsesOutline' : chatboxEllipsesOutline,
  'medalOutline' : medalOutline,
  'chatbubblesOutline' : chatbubblesOutline,
  'easelOutline': easelOutline,
  'bodyOutline' : bodyOutline,
  'megaphoneOutline': megaphoneOutline,
  'heartHalfOutline': heartHalfOutline,
  'cubeOutline': cubeOutline
};

// return the named icon
const getIcon = (item :string ) => {
	if (item in icons) return icons[item];
  else return cubeOutline;
}

// form title mapping
/*
const questions : MapType = {
  'reflection': "Reflect on and describe the impact the CPD activity has had",
  'learning': "Describe what you have learned from doing this CPD activity"
}
*/

const cpdLabels : MapType = {
  'reflection': "The CPD activity has had this impact",
  'learning': "This is what I have learned from doing this CPD activity"
}

/*
const questionsGuidance : MapType = {
  'reflection': "You could think about how the CPD activity might benefit those who come into contact with.",
  'learning': "You may want to refer to the CPD standards when answering this question"
}
*/


export interface CPDDisplayRecordsProps {
  user : User
}
  
const CPDDisplayRecords : React.FC<CPDDisplayRecordsProps> = ({ user }) => {

  const [presentCPDNewRecordModal, dismissCPDNewRecordModal] = useIonModal(CPDNewRecordModal, {className: "modal-fullscreen", onDismiss: () => onDismissCPDNewRecordModal() });
  
  // database listener for grabbing cpd record changes
  const queryToMake = query(
    collection(db, "cpdRecords"), 
        where( "uid","==", user.uid)
  )

  const [value, loading, error] = useCollection(
    queryToMake,{ snapshotListenOptions: { includeMetadataChanges: true }});

  const onDismissCPDNewRecordModal = () => {
    dismissCPDNewRecordModal()
    //console.log('got it', cpdRecordId)
  }
  
  // data db loading error
  if (error){
    // db error report it
    console.log(error);
    return (<IonContent>DB Error { error.message }</IonContent>)
  }
  // loading from db show wait
  if (loading) return (<IonSpinner></IonSpinner>)
  if (value ){
    
    // generate CPDRecords Array 
    const cPDRecordsByYear = new Array<CPDRecord>;
    const years  : string[] = []
    const today = new Date();
    const thisYear = `${today.getFullYear()}`
    const cpdHours = getCPDHours(cpdSettings.cpdHours,thisYear)
    let hours = 0;
    let currentCPD = false;

    value.docs.map((doc) => {
      const cpdRecord = doc.data() as CPDRecord;
      if (!years.includes(cpdRecord.year)){
        // add to years we have CPD records for
        years.push(cpdRecord.year)
      }
      if (cpdRecord.year ===thisYear){
        hours = hours + cpdRecord.hours
        currentCPD = true;
        //console.log('logging', hours, cpdRecord.hours)
      }
      cPDRecordsByYear.push(cpdRecord)
    })
  
    return (
      <>
        
        <CPDProgress cpd={hours} maxCpd={cpdHours} />
      
        { years.length > 0 &&
          years.map((year :string) => {
            //return (<span key="year"><b>{ year } CPD Activity</b><br/></span>)
            
            return (
              <CPDDisplayYear key={year} year={year} cpdRecordsList={cPDRecordsByYear} /> 
            )
           
          })
        }

        { !currentCPD && 
          <div><p>No {thisYear} CPD Activity.</p>  
          <IonButton size="small" onClick={() => presentCPDNewRecordModal()} ><IonIcon icon={fileTrayFullOutline} color="hotcoral"></IonIcon>&nbsp; Add {thisYear} CPD Activity</IonButton>
          </div>
        }
        
        
      </>
    )
  }
}

export default CPDDisplayRecords

export interface CPDDisplayYearProps {
  year : string
  cpdRecordsList: Array<CPDRecord>
}

const CPDDisplayYear : React.FC<CPDDisplayYearProps> = ({year, cpdRecordsList}) => {
  const [cpdRecordId, setCpdRecordId] = React.useState('')
  const [record, setRecord] = React.useState({id: ''})
  const [present] = useIonActionSheet();
  const [presentLoading, dismissLoading] = useIonLoading();
  const [showToast] = useIonToast();
  const [presentNewRecordModal, dismissNewRecordModal] = useIonModal(CPDNewRecordModal, {onDismiss: (id : string) => onDismissNewRecordModal(id) });
  const [presentEditRecordModal, dismissEditRecordModal] = useIonModal(CPDEditRecordModal, { id : { ...record}, onDismiss: (id: string) => onDismissEditRecordModal(id)} );
  const [presentDownloadPDFModal, dismissDownloadPDFModal] = useIonModal(CPDDownloadPDFModal, { year : year, onDismiss: () => onDismissDownloadPDFModal()} );
  
  const today = new Date();
  const thisYear = `${today.getFullYear()}`
  const cpdHours = getCPDHours(cpdSettings.cpdHours,year)
  let hours = 0
  let isComplete = true;

  // calculate hours and submit
  for (let i=0; i < cpdRecordsList.length; i++){
    if (cpdRecordsList[i].year === year){
      hours = cpdRecordsList[i].hours + hours;
      isComplete = isComplete && (cpdRecordsList[i].status === 'submit')
    }
  }
  
  let icon = closeCircleOutline;
  let iconColor = 'danger'
  if (hours >= cpdHours && isComplete){
    icon = checkmarkCircleOutline;
    iconColor = 'green'
  }
  //console.log('logged', year,hours, isComplete)

  // calculate submit


  const onDismissNewRecordModal = (id : string) => {
    dismissNewRecordModal()
    console.log('got it', id)
    if (id !== cpdRecordId){
      // set display the new record id
      setCpdRecordId(id)
    }
  }

  const onDismissEditRecordModal = (id: string) => {
    dismissEditRecordModal()
    if (id !== cpdRecordId){
      // set display current record id
      setCpdRecordId(id)
    }
  }

  const onDismissDownloadPDFModal = () => {
    dismissDownloadPDFModal()
  }


  // edit CPD record
  const onEdit = (id: string ) => {
    setRecord({id: id});
    presentEditRecordModal();
  }

  // delete CPD record
  const onDelete = (id: string, title: string) => {
    console.log('delete:',id)
    
    present({
      header: `Delete CPD activity "${title}" ?`,
      buttons: [
        { 
          text: 'Delete',
          role: 'destructive',
          data: {
            action: 'confirm'
          }
        },
        { 
          text: 'Cancel',
          role: 'cancel',
          data: {
            action: 'cancel'
          }
        },

      ],
      onDidDismiss: async ({ detail  }) => {
        if (detail.data?.action === 'confirm'){
          /*
          await dbDeleteAppointment(myAppointment);
          setLoading(true) // for refresh
          */
          let isError = false;
          await presentLoading({message:'Deleting CPD activity ...'})
          console.log('confirm')
          try {
            await deleteDoc(doc(collection(db,'cpdRecords'), id));
          }
          catch(e){
            console.log('error deleting cpd activity:',  e);
            isError = true
          }
          await dismissLoading()
          if (!isError) 
            showToast({
              message: `CPD activity "${title}" has been deleted`,
              color: 'success',
              duration: 3000,
              buttons: [{ icon: close }],
            })
          else
            showToast({
              message: `CPD activity deletion of "${title}" has failed`,
              color: 'danger',
              duration: 3000,
              buttons: [{ icon: close }],
            })
        }
      },
    })
  }

  // return true if CPD record is complete
  const checkSubmit = (cpdRecord: CPDRecord) => {
    if (cpdRecord.status === 'submit') return false
    else
    return cpdRecord.title !== '' && cpdRecord.type  !== '' &&
      cpdRecord.date !== '' &&
      cpdRecord.year !== '' &&
      cpdRecord.reflection  !== '' &&
      cpdRecord.learning  !== '' &&
      cpdRecord.hours > 0
  }

  // submit cpd record status as submitted 
  const onSubmit = async (cpdRecord : CPDRecord) => {

    present({
      header: `I confirm that for the CPD activity "${cpdRecord.type}"`,
      subHeader: 'I have provided a description on what I have learned.  I have reflected on and described the positive impact of the CPD activity.  I have not included any personal information about anyone, including the people I supervise, colleagues, managers and employers.',
      buttons: [
        { 
          text: 'Confirm',
          role: 'destructive',
          data: {
            action: 'confirm'
          }
        },
        { 
          text: 'Cancel',
          role: 'cancel',
          data: {
            action: 'cancel'
          }
        },

      ],
      onDidDismiss: async ({ detail  }) => {
        if (detail.data?.action === 'confirm'){
          // confirm
          saveSubmit(cpdRecord)
        }
      },
    })
  }

  const saveSubmit = async (cpdRecord : CPDRecord) => {
    if (!cpdRecord)
        return;

    // update audit
    cpdRecord.status = 'submit';
    cpdRecord.audit.push(dateStamp()+" CPD activity submitted.")

    // update record on firebase
    await presentLoading({message:'Saving CPD activity ...'})
    try { 
      await setDoc(doc(collection(db,'cpdRecords'), cpdRecord.id), cpdRecord,{ merge: true })
      showToast({
        message: `CPD activity "${cpdRecord.type}" has been submitted`,
        color: 'success',
        duration: 3000,
        buttons: [{ icon: close }],
      })
    }
    catch(error){
      if (error instanceof FirebaseError){
          /*
          if (error.code === 'auth/wrong-password' ||
              error.code === 'auth/user-not-found' )
              setMyError(noAccountWithPassword);
          else if (error.code === 'auth/internal-error' ||
                   error.code === 'auth/network-request-failed')
              setMyError(pleaseTryAgain)
          else
              setMyError(error.code);
          */
        showToast({
          message: `Submit of CPD activity "${cpdRecord.type}" has has failed: ${error.message}`,
          color: 'danger',
          duration: 3000,
          buttons: [{ icon: close }],
        })
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage)
      } else {
          console.log('edit cpd record:',error)
      }
    }
    await dismissLoading()

  }



  return (
    
      <React.Fragment key={year+"top"}>

      <IonAccordionGroup key={year+"group"} value={year}>
      <IonAccordion key={year} value={year} className="bottomBorder1" >
        <IonItem key={"header"+year} slot="header" color="duckegg" >
        <IonIcon icon={icon} size="large" color={iconColor}></IonIcon>&nbsp;<IonLabel><b>My { year } Reflective Plan ({hours}/{cpdHours})</b></IonLabel>
        </IonItem>
        <div key={year+"main"} className="mainAccordion ion-padding" slot="content">
          <IonAccordionGroup key={year+"group2"} value={cpdRecordId}>
          {
            cpdRecordsList
             .filter(cpdRecord => cpdRecord.year === year )
             .map((cpdRecord) => {
              const icon : string = iconTypeMap[cpdRecord.type] ? iconTypeMap[cpdRecord.type] : "cubeOutline"
              const color : string = cpdRecord.status === 'submit' ? "green" : "danger"
              const canSubmit = checkSubmit(cpdRecord)
              const statusIcon = cpdRecord.status === 'submit' ? checkmarkCircleOutline : closeCircleOutline 
              const statusIconColor = cpdRecord.status === 'submit' ? 'green' : 'danger' 
              const statusMsg = cpdRecord.status === 'submit' ? 'Completed' : 'Not Completed' 
              return(
              <IonAccordion key={cpdRecord.id} value={cpdRecord.id} className="bottomBorder">
                <IonItem key={"cpdRecord"+cpdRecord.id}  slot="header" color='secondary'>
                  <IonIcon icon={getIcon(icon)} size="medium" slot="start" color={color}></IonIcon>&nbsp;
                  <IonLabel className="ion-text-wrap"><b>{cpdRecord.type}</b><br/>{cpdRecord.title} </IonLabel>
                </IonItem>
                <div  key={cpdRecord.id+"content"} slot="content">
                  <IonList key={cpdRecord.id+"list"} lines="full">
                    <IonItem key={cpdRecord.id+"status"} >
                      <IonLabel>
                        <IonIcon icon={timerOutline} color="primary" size="medium"></IonIcon>
                        &nbsp;{ cpdRecord.hours ? cpdRecord.hours : "0" } hours&nbsp; 
                        <IonIcon icon={calendarOutline} color="primary" size="medium"></IonIcon>
                        &nbsp;{cpdRecord.date && cpdRecord.date !== ''? formatDate(cpdRecord.date) : "No completion date."}&nbsp;
                        <IonIcon icon={statusIcon} color={statusIconColor}></IonIcon>
                        &nbsp;{statusMsg}
                      </IonLabel>
                    </IonItem>
                    {DisplayText( cpdRecord.learning, 'learning',cpdRecord.id)}
                    {DisplayText( cpdRecord.reflection, 'reflection', cpdRecord.id)}
                    {DisplayAudit(cpdRecord.audit, cpdRecord.id)}

                    
                    <IonItem key={"button"+cpdRecord.id} lines="full">   
                      <IonLabel>
                        <IonButton onClick={() => onEdit(cpdRecord.id)} >Edit</IonButton>
                        <IonButton onClick={() => onDelete(cpdRecord.id, cpdRecord.type)}>Delete</IonButton>
                        <IonButton disabled={!canSubmit} onClick={()=> onSubmit(cpdRecord)} slot="end">Submit</IonButton> 
                      </IonLabel> 
                    </IonItem>    
                  </IonList>

                </div>
              </IonAccordion>
              
              )
            })
          }
          </IonAccordionGroup>
          <div style={{ paddingTop: '5px'}}>
            { year === thisYear && <IonButton size="small" onClick={() => presentNewRecordModal()} ><IonIcon icon={fileTrayFullOutline} color="hotcoral"></IonIcon>&nbsp; Add {year} CPD Activity</IonButton>}
            <IonButton size="small" onClick={()=> presentDownloadPDFModal()}><IonIcon icon={downloadOutline} color="hotcoral"></IonIcon>&nbsp; Download {year} CPD Record</IonButton>
          </div>
          
        </div>
      </IonAccordion>
      </IonAccordionGroup>
      
      </React.Fragment>
      

  )
  }


const DisplayText = (textItem: string, elementName: string, id: string) => {
  const key = elementName+id
  return (
    <IonItem key={key}>
      <IonLabel className="ion-text-wrap">
        <strong>{ cpdLabels[elementName] }</strong>
        <br />
        { textItem !== '' && textItem }
        { textItem === '' &&
          <IonNote  className="ion-text-wrap">
          No text entered.
          </IonNote>
        }
      </IonLabel>
    </IonItem>
  )
}

const DisplayAudit = (audit : string[], id: string) => {
  const textMap = () => {
    return audit.map((text: string, index: number) => {
      return (<span key={id+index}>{text}<br/></span>)
  })}
  return (
    <IonItem key={"audit"+id}>
      <IonLabel><strong>Audit</strong><br/>
        <IonNote  className="ion-text-wrap">
          {textMap()} 
        </IonNote>
      </IonLabel>
    </IonItem>
  )
}


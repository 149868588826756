import React from 'react';
import { useLocation, withRouter } from 'react-router-dom';

import { useIonModal, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonTitle, IonToolbar } from '@ionic/react';
import { help, logIn, logOut, personAdd, helpBuoy, document, shieldCheckmarkOutline, closeOutline, settingsOutline, cloudDownloadOutline, checkboxOutline, cardOutline, calendarOutline } from 'ionicons/icons';
import { home, peopleOutline, fileTrayStackedOutline, bookOutline, chatbubblesOutline} from 'ionicons/icons';

import  EditProfileModal  from '../pages/users/EditProfileModal';
import { useAuth } from '../contexts/authContext';

import { useIonicPWAInstall}  from '../pwaprompt/PWAPrompt'





import './Menu.css'

const routes = {
  appSupervisorPages: [
    { title: 'Notices', path: '/tabs/home', icon: home },
    { title: 'Directory', path: '/tabs/connect', icon: peopleOutline },
    { title: 'Messages', path: '/tabs/chats', icon: chatbubblesOutline },
    { title: 'My Practice', path: '/tabs/practice', icon: fileTrayStackedOutline },
    { title: 'Lexicon', path: '/tabs/cpd', icon: bookOutline },
  ],
  appNoSupervisorPages: [
    { title: 'Notices', path: '/tabs/home', icon: home },
    { title: 'Directory', path: '/tabs/connect', icon: peopleOutline },
    { title: 'Messages', path: '/tabs/chats', icon: chatbubblesOutline },
    { title: 'My Calendar', path: '/tabs/mycalendar', icon: calendarOutline },
  ],
  appLoggedOutPages: [
    { title: 'Home', path: '/home', icon: home},
    { title: 'Directory', path: '/directory', icon: peopleOutline },
    
  ],
  loggedInPages: [
    { title: 'Manage Account', path:'/tabs/home/account', icon: cardOutline},
    { title: 'Privacy', path: '/tabs/home/privacy', icon: shieldCheckmarkOutline },
    { title: 'Service Terms', path: '/tabs/home/terms', icon: checkboxOutline },
    { title: 'Logout', path: '/tabs/logout', icon: logOut }
  ],
  adminPages: [
    {title: 'User Admin', path:'/useradmin', icon: personAdd}
  ],
  loggedOutPages: [
    { title: 'Login', path: '/login', icon: logIn },
    { title: 'Privacy', path: '/privacy', icon: shieldCheckmarkOutline },
    { title: 'Service Terms', path: '/tabs/home/terms', icon: checkboxOutline },
    { title: 'Register', path: '/register/false', icon: personAdd }
  ],
  aboutSection: [
    { title: 'Help', path: '/docs/help/index.html', icon: helpBuoy },
    { title: 'Open Mind', path: '/docs/articles/index.html', icon: document },
    { title: 'FAQ', path: '/docs/faq/index.html', icon: help },
  ],
  aboutNotPremiumInSection: [
    { title: 'Help', path: '/docs/help/index.html', icon: helpBuoy },
    { title: 'FAQ', path: '/docs/faq/index.html', icon: help },
  ],
};


interface Pages {
  title: string,
  path: string,
  icon: string,
  routerDirection?: string
}

/*
interface StateProps {
  darkMode: boolean;
  isAuthenticated: boolean;
  menuEnabled: boolean;
}

interface DispatchProps {
//  setDarkMode: typeof setDarkMode
}
*/

//const isAuthenticated = false;
const menuEnabled = true;


//const Menu: React.FC<MenuProps> = ({ history, isAuthenticated, menuEnabled }) => {
const Menu: React.FC = () => {
  
  const location = useLocation();

  const { authInfo, saveUser } = useAuth()!;
  const { isSupported, isInstalled, pwaInstall } = useIonicPWAInstall()!


  // profile editor
  const openPersonEditor = () => {
    presentEditProfileModal()
  }
  const handleEditModalDialogDismiss = () => {
    dismissEditProfileModal()
  }

  const [presentEditProfileModal, dismissEditProfileModal] = useIonModal(EditProfileModal, {
    saveUser: saveUser,
    user: {...authInfo.user},
    onDismiss: handleEditModalDialogDismiss,
  })

        
  function renderlistItems(list: Pages[]) {
    return list
      .filter(route => !!route.path)
      .map(p => (
        <IonMenuToggle key={p.title} auto-hide="false">
          <IonItem detail={false} routerLink={p.path} className={location.pathname.startsWith(p.path) ? 'selected' : undefined}>
            <IonIcon slot="start" icon={p.icon} />
            <IonLabel>{p.title}</IonLabel>
          </IonItem>
        </IonMenuToggle>
      ))
  }

  function renderURLlistItems(list: Pages[]) {
    return list
      .filter(route => !!route.path)
      .map(p => (
        <IonMenuToggle key={p.title} auto-hide="false">
          <IonItem detail={false} href={p.path} className={location.pathname.startsWith(p.path) ? 'selected' : undefined}>
            <IonIcon slot="start" icon={p.icon} />
            <IonLabel>{p.title}</IonLabel>
          </IonItem>
        </IonMenuToggle>
      ))
  }

  return (
    <IonMenu  type="overlay" disabled={!menuEnabled} contentId="main">
      
      
      
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>{authInfo.loggedIn && authInfo.user.fullName}</IonTitle>
          <IonButtons slot="end" >
            <IonMenuToggle>
              <IonButton ><IonIcon icon={closeOutline}></IonIcon></IonButton>
            </IonMenuToggle>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      
      
      <IonContent forceOverscroll={false}>
        
        <IonList lines="none">
          <IonListHeader>Account</IonListHeader>
          {authInfo.loggedIn ? renderlistItems(routes.loggedInPages) : renderlistItems(routes.loggedOutPages)}
          {authInfo.loggedIn &&
            <IonMenuToggle key="editProfile" auto-hide="false">
              <IonItem detail={false} button onClick={openPersonEditor} className='undefined'>
                <IonIcon slot="start" icon={settingsOutline} />
                <IonLabel>Edit Profile</IonLabel>
              </IonItem>
            </IonMenuToggle>
          }
        </IonList>
        <IonList lines="none">
          <IonListHeader>About</IonListHeader>
          {authInfo.loggedIn && authInfo.user.isSupervisor ? renderURLlistItems(routes.aboutSection) : renderURLlistItems(routes.aboutNotPremiumInSection)}
        </IonList>
        
        <IonList lines="none">
          <IonListHeader>Admin</IonListHeader>
          { authInfo.user.isAdmin && renderURLlistItems(routes.adminPages) }
        </IonList>

        <IonList lines="none">
          <IonMenuToggle key="about" auto-hide="false">
            <IonItem button disabled={(!isSupported || isInstalled)} detail={false} onClick={pwaInstall} className='undefined'>
              <IonIcon slot="start" icon={cloudDownloadOutline}></IonIcon>
              <IonLabel>Install App</IonLabel>
            </IonItem>
          </IonMenuToggle>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default withRouter(Menu);

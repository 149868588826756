
import { FormValueTypes } from '../components/ProfileFormFields'


export type ControlTypes = "Input" | "Textarea" | "Select"
type TextFieldTypes =
  | 'date'
  | 'email'
  | 'number'
  | 'password'
  | 'search'
  | 'tel'
  | 'text'
  | 'url'
  | 'time'
  | 'week'
  | 'month'
  | 'datetime-local'

interface ProfileFields {
    formName : FormValueTypes
    type: ControlTypes
    inputType: TextFieldTypes
    label : string
    placeholder: string
    options : Array<string>
}

/*
export interface FormValues {
    fullName : string
    occupation: string
    bio : string
    myLocation : string
    servicesOffered: string
    email: string
}
*/


/*

export type User = {
    uid : string
    name: PersonName
    avatar : string
    myLocation : string
    occupation: string
    fullName : string
    location: Address
    picture: Picture
    title: string
    email : string
    phone: string,
    cell: string,
    bio : string
    practicingFrom: number
    practices : Array<string>[] 
    qualifications : Array<string>[] 
    authProvider: string
}
*/


// defines form data for the You -> Profile
export const useProfileFields  = () : Array<ProfileFields> => {

    return [
        {
            type: "Input",
            formName: "fullName",
            inputType: "text",
            label: "Your name",
            placeholder: "First and last name",
            options : []
        },
        {
            type: "Input",
            formName: "email",
            inputType: "text",
            label: "Email",
            placeholder: "Your email address",
            options : []
        },
        {
            type: "Input",
            formName: "phone",
            inputType: "tel",
            label: "Phone",
            placeholder: "Your phone number",
            options : []
        },
        {
            type: "Input",
            formName: "url",
            inputType: "text",
            label: "Website address",
            placeholder: "Your web site",
            options : []
        },
        {
            type: "Input",
            formName: "myLocation",
            inputType: "text",
            label: "Location",
            placeholder: "Your Location",
            options : []
        },
        {
            type: "Input",
            formName: "occupation",
            inputType: "text",
            label: "Occupation",
            placeholder: "Your Occupation",
            options : []
        },
        {
            type: "Textarea",
            formName: "bio",
            inputType: "text",
            label: "About me and my practice",
            placeholder: "Enter your career summary",
            options: []
        },
        {
            type: "Select",
            inputType: "text",
            formName: "qualYears",
            label: "How long qualified",
            placeholder: "Make a selection",
            options: ["under 5 yrs+", "10 yrs+", "20 yrs+"]
        },
        {
            type: "Select",
            inputType: "text",
            formName: "servicesOffered",
            label: "Services Offered",
            placeholder: "Make a selection",
            options: ["Individual", "Groups", "Organisational","Other"]
        },
        {
            type: "Textarea",
            formName: "orientation",
            inputType: "text",
            label: "Theoretical orientation",
            placeholder: "....",
            options: []
        },
        {
            type: "Select",
            inputType: "text",
            formName: "cpd",
            label: "CPD",
            placeholder: "Make a selection",
            options: ["Children first", "Assist", "Storm", "Other"]
        },
        {
            type: "Input",
            formName: "accreditingBody",
            inputType: "text",
            label: "Accreditation body and membership number",
            placeholder: "...",
            options : []
        },
        {
            type: "Input",
            formName: "compliance",
            inputType: "text",
            label: "Compliance",
            placeholder: "...",
            options : []
        },
  
    ];
}
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from "./contexts/authContext";
import { IonicPWAInstallProvider}  from './pwaprompt/PWAPrompt'
import { PostHogProvider } from 'posthog-js/react'



// Call the element loader after the platform has been bootstrapped


const container = document.getElementById('root');
const root = createRoot(container!);

const options = {
  api_host: import.meta.env.VITE_REACT_APP_PUBLIC_POSTHOG_HOST,
}

root.render(
  <React.StrictMode>
    <AuthProvider>
      <IonicPWAInstallProvider>
        <PostHogProvider 
          apiKey={import.meta.env.VITE_REACT_APP_PUBLIC_POSTHOG_KEY}
          options={options}
        >
          <App />
        </PostHogProvider>
      </IonicPWAInstallProvider>
    </AuthProvider>
    
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export const userDefault =  {
    uid : '',
    customerId : '',
    title: '',
    fullName : '',
    myLocation: '',
    avatar : '',
    gender: '',
    occupation: '',
    name : {
        title:"",
        first:"",
        last:""
    },
    location:{
        street:{
            number: "",
            name: "",
        },
        city: "",
        state: "",
        country:"",
        postcode:"",
        coordinates:{
            latitude:"",
            longitude:""
        },
        timezone:{
            offset:"",
            description:""
        }
    },
    picture: {
        large:"",
        medium: "",
        thumbnail:""
    },
    phone:"",
    cell:"",
    email : '',
    bio : '',
    practicingFrom: 0,
    practices: [],
    qualifications : [],
    authProvider: '',
    servicesOffered: [],
    url: '',
    orientation: '',
    qualYears: '',
    cpd: '',
    accreditingBody : '',
    compliance : '',
    isDeleted: false,
    isSupervisor: false,
    registerAsSupervisor: false,
    isAdmin : false
}

export type User = {
    uid : string
    customerId : string
    name: PersonName
    avatar : string
    myLocation : string
    occupation: string
    fullName : string
    location: Address
    picture: Picture
    title: string
    email : string
    phone: string,
    cell: string,
    bio : string
    practicingFrom: number
    practices : Array<string>[] 
    qualifications : Array<string>[] 
    authProvider: string
    servicesOffered: Array<string>
    url: string
    orientation: string
    qualYears: string
    cpd: string
    accreditingBody : string
    compliance : string
    isDeleted: boolean
    isSupervisor: boolean
    registerAsSupervisor: boolean
    isAdmin : boolean
}

export type Picture = {
        large:string,
        medium: string,
        thumbnail:string
}
export type  Street = {
    number: string
    name: string
}
export type  Coordinates = { 
    latitude:string
    longitude:string
}
export type TimeZone = {
    offset:string,
    description:string
}
export type Address = {
    street: Street
    city: string,
    state: string,
    country:string,
    postcode:string,
    coordinates:Coordinates
    timezone: TimeZone
}

export type PersonName = {
    title: string
    first: string
    last: string
}

export type Qualification = {
    qid : string
    uid : string
    qualificationName : string
    instituteName : string
    awardDate : Date
}

export type Practice = {
    pid : string
    consultantUid : string
    office_name : string
    timeSlot : number
    firstFee : number
    followUpFee : number
    address: string
}

export type ConsultantAvailability = {
    cid : string
    pid : string
    dayOfWeek : number
    startTime : number
    endtime : number
    isAvailable : boolean
    availableReason : boolean
}

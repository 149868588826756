
export type StripeAccount = {
    uid : string
    customerId : string
    subscriptionId : string
    hasTrial : boolean
    trial_end: number
    livemode: boolean
    status: string
    period : string
}

export const stripeAccountDefault : StripeAccount = {
    uid: '',
    customerId: '',
    subscriptionId : '',
    trial_end : 0,
    hasTrial: false,
    livemode: false,
    status : '',
    period : ''
}

import { IonToolbar, IonButtons, IonButton, IonIcon, IonText } from "@ionic/react";
import { closeCircleOutline, personOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
export interface VerifyBannerProps {
  uid: string
  email: string
  verified: boolean|undefined
}

type VerifyBanner = {
  dismiss: boolean
}
const VerifyBanner: React.FC<VerifyBannerProps> = ({uid, email, verified}) => {

  const [verify, setVerify] = useState<VerifyBanner>({dismiss: false})
  useEffect(() => {
    // count down installer count to zero
    const verifyString = localStorage.getItem('verify'+uid);
    
    if (verifyString && verifyString !== "undefined"){
      const verifyStatus= JSON.parse(verifyString)
      setVerify(verifyStatus)
      //console.log('installCount',installCount)
    }
    else {
      //console.log('set local storage')
      localStorage.setItem('verify'+uid, JSON.stringify({dismiss: false}));
      setVerify({dismiss: false})
    }
    
  },[uid])

  const closeVerifyBanner = () => {
    // close the installer banner 
    localStorage.setItem('verify'+uid, JSON.stringify({dismiss: true}));
    setVerify({dismiss: true})
  }
  return (<>
    { !verify.dismiss && verified &&
      <IonToolbar color="hotcoral">
      <IonButtons slot="start">
        <IonButton>
          <IonIcon slot="icon-only" color="almostwhite" icon={personOutline}></IonIcon>
        </IonButton>
      </IonButtons>
      <IonText color="almostwhite">Your email {email} has been verified.</IonText>
      <IonButtons slot="end">
        <IonButton fill="clear" onClick={() => closeVerifyBanner()}>
          <IonIcon slot="icon-only" color="almostwhite" icon={closeCircleOutline}></IonIcon>
        </IonButton>
      </IonButtons>
    </IonToolbar>
    }
  
  </>)
}
export default VerifyBanner
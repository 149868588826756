import { IonAccordion, IonAccordionGroup, IonBadge, IonButton, IonButtons, IonChip, IonCol, IonContent, IonDatetime, IonDatetimeButton, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonModal, IonPage, IonRow, IonSpinner, IonTitle, IonToolbar, useIonActionSheet, useIonModal, useIonToast } from "@ionic/react"
import { chevronBackOutline, chevronForwardOutline, call, close, calendarOutline, refreshCircleOutline, calendarClearOutline, chatbubbleEllipsesOutline } from "ionicons/icons"
import React, {  useEffect, useRef, useState } from 'react';

import { parseISO, addDays, subDays} from 'date-fns';
//import { Timestamp, query, collection, doc, deleteDoc, where, setDoc, DocumentData } from "firebase/firestore";
//import {  db} from "../firebase";

import { User } from '../data/user'
import { AppointmentAudit, generateDayDiary, capitalizeFirstLetter, displayDate, dbChangeAppointment, dateStamp, StatusBadge, dbGetAppointment, filterScheduleAppointments, BannerBadge } from './AppointmentFunctions'
import './PracticeCalendar.css'
import { Appointment } from "./AppointmentClasses";
import { onSnapshot, doc, collection, query, where, or } from "firebase/firestore";
import { db } from "../firebase";
import {useCollection} from 'react-firebase-hooks/firestore';
import { AddToCalendar } from "./AddToCalendar";
import { PracticeAppointmentModal} from './PracticeCalendar'
import { sendAppointmentMail } from "../mail/SendMail";

export interface PracticeCalendarProps {
  hide: boolean
  user : User
  consultant : User
}


  
const DirectoryCalendar : React.FC<PracticeCalendarProps> = ({ hide, user, consultant }) => {

  // date bar selection
  const today = new Date();
  const todayIso = today.toISOString();
  const [selectedDate, setSelectedDate] = useState<string>(todayIso);
  
  //const [currentDate, setCurrentDate] = useState<Date>(zeroDate(today));

  // used for dialog box for an appointment
  const [currentAppointment, setCurrentAppointment] = useState<Appointment>()

  // todays appointment
  // 
  const [appointmentDiaryToday, setAppointmentDiaryToday] = useState<Appointment[]>([]);
  useEffect(()=>{
    // generate the days appointment slots when selectDateChanges
    setAppointmentDiaryToday(generateDayDiary(selectedDate))
  },[selectedDate])

  // database listener for grabbing appointment changes
  const queryToMake = query(
    collection(db, "appointmentsList"), or(
            where( "consultantUid" , "==", consultant.uid ),
            where("patientUid", "==", consultant.uid ),
            where( "consultantUid", "==", user.uid),
            where( "patientUid","==", user.uid)
        )
  )

  const [value, loading, error] = useCollection(
      queryToMake,{ snapshotListenOptions: { includeMetadataChanges: true }});

  

  // dialog box dismiss function
  const handlePracticeDialogDismiss = () => {
    dismissPracticeModal()
  }

  const handlePatientDialogDismiss = () => {
    dismissPatientModal()
  }

  const [presentPatientModal, dismissPatientModal] = useIonModal(PatientAppointmentModal, {
    myAppointment: { ...currentAppointment },
    consultantUid : currentAppointment?.consultantUid ,
    user: user,
    onDismiss: handlePatientDialogDismiss,
  })

  // create appointment dialog box and present and dismiss functions, passing in appointment item
  const [presentPracticeModal, dismissPracticeModal] = useIonModal(PracticeAppointmentModal, {
    myAppointment: { ...currentAppointment },
    consultant : user,
    onDismiss: handlePracticeDialogDismiss,
  })

  // handler for editing dialoge box
  const onEdithandler = (myAppointment: Appointment) => {
    console.log('click', myAppointment, user.uid)
    if (user.uid === myAppointment.consultantUid){
      // practice modal
      myAppointment.consultantName = user.fullName
      myAppointment.location = user.myLocation
      setCurrentAppointment(myAppointment)
      presentPracticeModal()
    }
    else if (user.uid === myAppointment.patientUid || '' === myAppointment.patientUid){
      // patient modal - either owned by me or free slot for booking
      setCurrentAppointment(myAppointment)
      myAppointment.name = user.fullName
      myAppointment.location = user.myLocation
      presentPatientModal()
    }
    // last case where consultantUid = consultant.uid should not happen
  }
    
  

  // fn to add one day to selected date
  const addDay = async () => {
    const date = typeof selectedDate === 'string' ? parseISO(selectedDate) : today;

    const nextDay = addDays(date,1)
    setSelectedDate(nextDay.toISOString());
    //setCurrentDate(zeroDate(nextDay));
  }

  /*
  // fn to set date to today
  const setToday = async () => {
    setSelectedDate(todayIso);
    //setCurrentDate(zeroDate(today));
  }
  */
  
  // fn to subtract one day to selected date
  const subDay = async () => {
    const date = typeof selectedDate === 'string' ? parseISO(selectedDate) : today;
    const prevDay = subDays(date,1)
    setSelectedDate(prevDay.toISOString());
    //setCurrentDate(zeroDate(prevDay));
  }

  // calendar dialog has selected date
  const selectDate = (date: string ) => {
    console.log('select date', date)
    setSelectedDate(date)
    //setCurrentDate(parseISO(date))
  };

  const datetimeDirectory = useRef<null | HTMLIonDatetimeElement>(null);
  const reset = () => {
    datetimeDirectory.current?.reset();
  };
  const cancel = () => {
    datetimeDirectory.current?.cancel(true);
  };
  const confirm = () => {
    datetimeDirectory.current?.confirm(true);
  };
  // highlight booked dates on the calendar
  const myBookedDates = (bookedDays: string[]) =>{
    const bookedDates = (isoString: string) => {
        if (bookedDays.includes(isoString)){
          return {
            textColor: '#800080',
            backgroundColor: '#ffc0cb',
          };
        }
    }
    return bookedDates
  }
  // data db loading error
  if (error){
    // db error report it
    console.log(error);
    return (<IonContent>DB Error { error.message }</IonContent>)
  }
  // loading from db show wait
  if (loading) return (<IonSpinner></IonSpinner>)
  // data loaded return the list
  if (value) {
    // filter the appointment for today - returns list of hour-> appointment 
    const [appointmentScheduleToday, consultantScheduleToday, appointmentDaysInMonth] = filterScheduleAppointments(value.docs, selectedDate, consultant.uid, user.uid)

    // console.log('cons :', consultantScheduleToday,'me:', appointmentScheduleToday, appointmentDaysInMonth)
    return (
    <IonContent color="duckegg" style={hide ? { display: 'none' } : {}}>
      <IonGrid  className="cal-container">
        <IonRow className="ion-align-items-center cal-container ">
         
          <IonCol className="cal-container">
           
          </IonCol>
          <IonCol className="cal-container" size="auto">
            <IonButton fill='clear' onClick={()=> subDay()}><IonIcon  slot="icon-only" icon={chevronBackOutline} /></IonButton>  
          </IonCol>
          <IonCol className="cal-container" size="auto">
            <IonDatetimeButton color="primary" datetime="dateTimeDirectory"></IonDatetimeButton>
            <IonModal keepContentsMounted={true}  > 
              <IonDatetime id="dateTimeDirectory" 
                ref={datetimeDirectory} 
                color="primary"  
                showDefaultButtons={false}
                highlightedDates={myBookedDates(appointmentDaysInMonth)}
                value={selectedDate}      
                presentation="date"
                onIonChange={(e) => { e.preventDefault();selectDate(e.detail.value!);}}>
                  <IonButtons slot="buttons">
                    <IonButton color="danger" onClick={cancel}>
                      Cancel
                    </IonButton>
                    <IonButton color="primary" onClick={reset}>
                      Today
                    </IonButton>
                    <IonButton color="primary" onClick={confirm}>
                      Select
                    </IonButton>
                  </IonButtons>
              </IonDatetime>
            </IonModal>
          </IonCol >
          <IonCol size="auto" className="cal-container">
            <IonButton fill='clear' onClick={() => addDay()}><IonIcon  slot="icon-only" icon={chevronForwardOutline} /></IonButton> 
          </IonCol>
        </IonRow>
      </IonGrid>
       
      <IonList style={{paddingTop: '0px', paddingBottom: '0px' }}>
      {  appointmentDiaryToday.map((d)  => {
  
          // appointment is from agendaList
          //let appointment = d as Appointment;
          // replace agenda appointment if an appointment slot exists
          let appt = d
          let myApp = null
          
          const appointmentHour = new Date(d.start).getHours().toString();
          if (consultantScheduleToday[appointmentHour]){
            appt = consultantScheduleToday[appointmentHour]
            
          }
          if (appointmentScheduleToday[appointmentHour]){
            myApp = appointmentScheduleToday[appointmentHour]
          }
          // display the list item
          return (                
                <ScheduleTableItem key={appt.start} consultantAppointment={appt} myAppointment={myApp} uid={user.uid} onEdithandler={onEdithandler} />   
          )
          })
      }
      </IonList>
    </IonContent>
  )}
}

  interface ScheduleTableItemProps { 
    consultantAppointment : Appointment
    myAppointment: Appointment | null;
    uid : string
    onEdithandler: (myAppointment: Appointment) => void 
  }

  type Status = {
    status : string
    message : string
    class : string 
    color : string
    disabled : boolean
  }

  type StatusList = {
    [ key : string] : Status
  }
  const statusMsg : StatusList  = {
    'available': { status: "Available", message: "Appointment slot available for booking.", class: "", disabled: false, color:"secondary"},
    'reserved': { status: "Reserved for appointment", message: "Slot is reserved by you to take bookings. Clear reservation to make this appointment.", class: "secondaryHatched", disabled: true, color:''},
    'nobooking': { status: "No appointments available", message: "No appointment slots are available for this hour.",class: "secondaryHatched", disabled: true, color:''},
    'booked': {status: "Booked Appointment", message: "", class: "secondaryBooked", disabled:false, color:''}
  }


  export const ScheduleTableItem: React.FC<ScheduleTableItemProps> = ({consultantAppointment, myAppointment,uid, onEdithandler}) => {
    
    // set appointment status to block if appoint is null, ie. space not reserved
    
    let myAppointmentStatus = 'available'
    if (myAppointment !== null){
      myAppointmentStatus = myAppointment.status
    }


    // workout the booking state
    let messageState : string = ''
    switch(consultantAppointment.status){
        case "free" : 
            messageState = (myAppointmentStatus === 'available' ? "available" : "reserved");
            break;
        case "blocked":
            messageState = 'nobooking'
            break;
        default:
            messageState = 'booked'
    }

   let bookingStatusMsg = statusMsg[messageState]
   //console.log('===========', myAppointment, messageState, myAppointmentStatus)

    // if booked then workout who the meeting is with, assume it not current uid first
    //let bookedStatusMsg: Status =  { status: "No appointments availableXXX", message: "No appointment slots are available for this hour.",class: "secondaryHatched", disabled: true, color:''}
   
    let bookedStatusMsg: Status = { status: 'Private', message: 'Not for me'+consultantAppointment.consultantName+'.', class:'secondaryHatched', disabled: true, color:''}
    if (messageState === 'booked' && consultantAppointment.patientUid === myAppointment?.patientUid){
        // booked and appointment match - say so and allow access
        let msg = 'Delivered by '+consultantAppointment.consultantName
        if (consultantAppointment.consultantUid === uid){
          msg = msg + ' (to you).'
        }
        else { 
          msg = msg + ' (you).'
        }
        bookedStatusMsg = { status: consultantAppointment.status, message: msg, class:'', disabled: false, color:'secondary'}
    }
    else if (consultantAppointment?.patientUid !== ''){
      // some one elses appointment
      console.log('-----------------arggg', myAppointment)
      messageState = 'nobooking'
      bookingStatusMsg = statusMsg[messageState]
    }
    
    
    return (
      <IonItem key={consultantAppointment.start} button={true} disabled={bookingStatusMsg.disabled}  color={bookingStatusMsg.color} className={bookingStatusMsg.class}    detail={true} lines="full" onClick={()=>onEdithandler(consultantAppointment)}>
        <IonLabel className={'schedule-'+consultantAppointment.status.toLowerCase()+ " ion-text-wrap"}>
            <StatusBadge status={bookingStatusMsg.status} /><br/>{bookingStatusMsg.message}
            { messageState === 'booked' && <div><StatusBadge status={bookedStatusMsg.status} /><br/>{bookedStatusMsg.message} </div>}       
        </IonLabel>
        <IonLabel slot="end">
            <p >{displayDate(consultantAppointment.start, 'hh:mm aa')}</p>
            <p className="ion-text-right">{displayDate(consultantAppointment.end, 'hh:mm aa')}</p>
        </IonLabel>
      </IonItem>
    )
  };

//-------------------------------------------------------------------------------------------------------

type Props = {
  myAppointment: Appointment
  consultantUid : string
  user : User
  onDismiss: () => void
}
export const PatientAppointmentModal: React.FC<Props> = ({ consultantUid, myAppointment, user, onDismiss }) => {
  //console.log('my app', myAppointment)
  const [appointment,setAppointment] = useState<Appointment>(myAppointment)
  const [loading,setLoading] = useState(true)
  const [present] = useIonActionSheet();
  const [showToast] = useIonToast();
  const startHour = displayDate(appointment.start, 'hh:mm bbb')
  const endHour = displayDate(appointment.end, 'hh:mm bbb')
  const date = displayDate(appointment.start, 'MMM dd, yyyy')
  
  //console.log('appointment', appointment,myAppointment)

  // set up listener for appointments, when consultantUid changes
  useEffect(() => {
    const appointmentId = consultantUid+myAppointment.start.toString();
    //console.log('useEffect key', appointmentId)
    const unsubscribe  = onSnapshot(doc(db, "appointmentsList", appointmentId), (doc) => {
      if (doc.exists() ){
        // update state
        const thisAppointment = doc.data() as Appointment;
        //console.log("DB update:",thisAppointment)
        setAppointment(thisAppointment)
      }
      else {
        // new appointment set state
        console.log('use effect new appointment', myAppointment)
        myAppointment.audit = []
        myAppointment.status = 'blocked'
        setAppointment(myAppointment)
      }
      setLoading(false)
    });

    // detach listener
    return unsubscribe
    
}, [consultantUid, myAppointment.start,myAppointment,setAppointment,loading])


 
  // dialog box to confirm booked appointment
  const makeAppointmentSlot = async (start: string, end: string, date: string, user: User, myAppointment: Appointment, consultantName : string) => {
    
    // need to check that there is no clash with this appointment in users diary
    const userAppointmentSlotKey =  user.uid+appointment.start.toString();
    
    const doc = await dbGetAppointment(userAppointmentSlotKey)
    if (typeof doc !== 'undefined' && doc.exists()){
        // existing appointment with overwrite existing slot if free or abandon
        console.log('>>>>>>',doc.data(), userAppointmentSlotKey)
        const appointment = doc.data()
        let message = '';
        if (appointment.status === 'free'){
            message = 'Oops, this appointment clashes with a free slot in your calendar, please delete and try again'
        } else {
            message = 'Oops, with appointment clashes with an appointment with '+appointment.name+ ' please try again'
        }

        showToast({
            message: message,
            color: 'warning',
            buttons: [{ icon: close }],
        });
        return
    }

    present({
      header: 'Make appointment with '+consultantName+' from '+start+ ' to '+end+' on '+date+' ?',
      buttons: [
        { 
          text: 'Book Appointment',
          role: 'destructive',
          data: {
            action: 'confirm'
          }
        },
        { 
          text: 'Cancel',
          role: 'cancel',
          data: {
            action: 'cancel'
          }
        },

      ],
      onDidDismiss: async ({ detail  }) => {
        if (detail.data?.action === 'confirm'){

          // update appointment status
          myAppointment.name = user.fullName;
          myAppointment.patientUid = user.uid;
          myAppointment.patientEmail = user.email;
          myAppointment.patientPhone = user.cell;
          myAppointment.treatment = 'Supervision';
          myAppointment.status = 'provisional';
          myAppointment.audit.push(dateStamp()+" Provisional booking made.")
          // update db
          dbChangeAppointment(myAppointment);
          //console.log('Send update:', myAppointment)

          // send email confirmation
          const appointmentDate =  displayDate(myAppointment.start, 'EEEE, MMM dd, yyyy')+ ' from ' +
          displayDate(appointment.start, 'hh:mm bbb') + ' to '+
          displayDate(appointment.end, 'hh:mm bbb') 

          sendAppointmentMail(
            user.fullName,
            user.email,
            "Prosupervise - Provisional Appointment",
            "Provisional Appointment", 
            appointmentDate, 
            "This booking will now be confirmed by supervisor - "+myAppointment.consultantName, 3)
          sendAppointmentMail(
            myAppointment.consultantName,
            myAppointment.consultantEmail,
            "Prosupervise - Provisional Appointment",
            "Provisional Appointment", 
            appointmentDate, 
            "Please confirm provisional appointment booking by "+myAppointment.name, 3)
          
        }
      },
    })
  }

  const updateContactDetails = async (myAppointment: Appointment, user: User) => {
    // update appointment with latest user info
    // update appointment status
    myAppointment.patientEmail = user.email;
    myAppointment.patientPhone = user.phone
    myAppointment.name = user.fullName
    myAppointment.audit.push(dateStamp()+" Updated appointment contact details.")
    // update db
    dbChangeAppointment(myAppointment);
    showToast({
      message: "Your contact details have been refreshed.",
      color: 'success',
      duration: 3000,
      buttons: [{ icon: close }],
    })
  }
/*
  // appointment slot is free, delete appointment slot
  const deleteAppointmentSlot = async (start: string, end: string, date: string, user: User, myAppointment: Appointment) => {
    console.log('appointment:',myAppointment)

    present({
      header: 'Clear appointment slot from '+start+ ' to '+end+' on '+date+' available to book?',
      buttons: [
        { 
          text: 'Clear appointment slot',
          role: 'destructive',
          data: {
            action: 'confirm'
          }
        },
        { 
          text: 'Cancel',
          role: 'cancel',
          data: {
            action: 'cancel'
          }
        },

      ],
      onDidDismiss: async ({ detail  }) => {
        if (detail.data?.action === 'confirm'){

          await dbDeleteAppointment(myAppointment);
          setLoading(true) // for refresh
        }
      },
    })
  }
  */    
  const sendMsgLink = '/tabs/chats/'+appointment.patientUid+'/friend/'+appointment.consultantUid
  return (
    <IonPage>
    <IonHeader>
      <IonToolbar color="primary">
      <IonButtons slot="end">
          <IonButton onClick={onDismiss}>Close</IonButton>
        </IonButtons>
        <IonTitle>Your Appointment</IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent>
      { appointment.status === 'blocked' && <BannerBadge color="danger" message="This a blocked booking slot.  Please make it available via the action below." /> }
      { appointment.status === 'free' && <IonChip color="success">{ "This slot can be booked for "+user.fullName+ ". Please book via action below"}</IonChip>}
      <IonAccordionGroup multiple={true} value="first">
      <IonAccordion value="first">
        <IonItem slot="header" color="light">
          <IonLabel>Booking Info <IonBadge class={'status-'+appointment.status.toLowerCase()} >{ appointment.status === "free" ? "Available" : capitalizeFirstLetter(appointment.status) }</IonBadge></IonLabel>
        </IonItem>
        <div className="ion-padding" slot="content">
          <b>{ appointment.name !== '' ? appointment.name : "No one booked" }</b><br/>
          <IonIcon slot="icon-only" icon={call} />
          { appointment.patientPhone !== '' ? appointment.patientPhone : "No phone number." } <br/>
          { appointment.patientEmail !== '' ? appointment.patientEmail : "No email." } <br/>
          <hr />
          { displayDate(appointment.start, 'EEEE, MMM dd, yyyy')} <br/>
          { displayDate(appointment.start, 'hh:mm bbb')}&nbsp;&mdash;&nbsp;
          { displayDate(appointment.end, 'hh:mm bbb')} <br/>
          { appointment.consultantName !== '' ? "Offered by "+appointment.consultantName : "No consultant yet" } <br/>
          { appointment.location !== '' ? appointment.location : "No location." } <br/>
        </div>
      </IonAccordion>
      <IonAccordion value="second">
        <IonItem slot="header" color="light">
          <IonLabel>History</IonLabel>
        </IonItem >
        <div className="ion-padding" slot="content">
          
          <IonItem className="ion-no-padding" >
            <IonLabel>
              <h3>Audit</h3>
              <div>
              { appointment.audit.length > 0 ? <AppointmentAudit audit={appointment.audit} /> : "No audit log available."}
              </div>
            </IonLabel>
          </IonItem>
        </div>
      </IonAccordion>
      <IonAccordion value="third">
        <IonItem slot="header" color="light">
          <IonLabel>Billing Info</IonLabel>
        </IonItem>
        <div className="ion-padding" slot="content">
          Not yet available.
        </div>
      </IonAccordion>
    </IonAccordionGroup>


    <IonList>   
      { appointment.status === 'free' &&
        <IonItem button key="book" detail={true} onClick={(e) => {e.preventDefault();makeAppointmentSlot( startHour, endHour, date, user, appointment, appointment.consultantName )}} >
          <IonIcon icon={calendarClearOutline} color='hotcoral'></IonIcon><IonLabel color="primary">&nbsp;Book an Appointment</IonLabel>
        </IonItem>
      }
      { (appointment.status !== 'free' &&
        appointment.status !== 'blocked') && 
        <IonItem key="updateDetails" detail={true} onClick={(e) => {e.preventDefault();updateContactDetails(appointment, user )}} button>
          <IonIcon icon={refreshCircleOutline} color='hotcoral'></IonIcon>&nbsp;<IonLabel color="primary">Refresh contact details</IonLabel>
        </IonItem>
      }
      { (appointment.status !== 'blocked' &&
         appointment.status !== 'free' ) && 
        <IonItem key="message" detail={true} routerLink={sendMsgLink} button>
        <IonIcon icon={chatbubbleEllipsesOutline} color='hotcoral'></IonIcon><IonLabel color="primary">&nbsp;Send message</IonLabel>
        </IonItem>
      }
      <IonItem detail={true} button key="calendar" onClick={(e) => {e.preventDefault();AddToCalendar(appointment)}} >
        <IonIcon icon={calendarOutline} color='hotcoral'></IonIcon><IonLabel color="primary">&nbsp;Download appointment</IonLabel>
      </IonItem>
    </IonList>
    </IonContent>
  </IonPage>
  )
}


export default DirectoryCalendar
import { useIonActionSheet, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSearchbar, IonButtons, IonButton, IonIcon, IonModal, IonItem, IonAvatar, IonLabel, IonList, IonSpinner, IonBadge, IonItemOption, IonItemOptions, IonItemSliding, useIonLoading, IonMenuButton } from '@ionic/react';
import { addOutline, trash } from 'ionicons/icons';
import './Chats.css';


import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
//import ChatItem from './components/ChatItem';
import { useRef } from 'react';

// user data model for contacts
import { User } from '../data/user';

import { DocumentData, collection, doc, getDocs, or, query, where, writeBatch} from "firebase/firestore";
import { db } from "../firebase";
import {useCollection} from 'react-firebase-hooks/firestore';

import ContactModal from './components/ContactModal';
import { FirebaseError } from 'firebase/app';
import { useAuth } from '../contexts/authContext';

import { format, isToday, isYesterday, isThisWeek } from 'date-fns';

interface UserItem {
	userName : string
	avatar: string
	occupation: string
	location: string
}

interface UserList{
	[key: string]:UserItem
}

const duration  = ( timestamp : number ) => {
    
    const date = new Date(timestamp)
    //const currentDate = new Date();
    //const timeDiff = currentDate.getTime() - timestamp;

    if (isToday(date)){
        return  format(date, 'h:mm a');
    } else if (isYesterday(timestamp)){
        return "Yesterday"
    } else if (isThisWeek(timestamp)){
        return format(date,'EEEE')
    } else {
        return format(date,"dd/MM/yyyy")
    }

    return 'NaN';
}



const Chats : React.FC = () => {
	const [ showContactModal, setShowContactModal ] = useState(false);
	//const [userList,setUserList] = useState<UserList>({})
	const [users, loading, error] = useCollection(collection(db, "users"),{ snapshotListenOptions: { includeMetadataChanges: true }})
	const [presentingElement, setPresentingElement] = useState<HTMLElement | null>(null);
	const pageRef = useRef(null);
	const history = useHistory()
	const { authInfo } = useAuth()!;
	

	//const [ results, setResults ] = useState(latestChats);
	
	

	useEffect(() => {
		setPresentingElement(pageRef.current);
		
	}, []);

	const newChat = (user: User|null) => {
		// close modal
		setShowContactModal(false)

		if (user !== null){
			// user selected, start chat
			console.log(user)
			history.push('/tabs/chats/'+authInfo.user.uid+'/friend/'+user.uid);	
		}
	}

/*
	useEffect(() => {

		setResults(latestChats);
	}, [ latestChats ]);
*/

	
/*
	const search = (searchTerm : string) => {
		if (searchTerm !== "") {

			const searchTermLower = searchTerm.toLowerCase();

			const newResults = latestChats.filter(chat => contacts.filter(c => c.id === chat.contact_id)[0].name.toLowerCase().includes(searchTermLower));
			setResults(newResults);
		} else {
			setResults(latestChats);
		}	
	}
*/




	
	if (loading){
		// display spinner
		<IonSpinner />
	}

	if (error){
		// display error
		if (error instanceof FirebaseError){
			console.log('chat list',error)
			return (
			<p>{error.code}{error.message}</p>
			)		
		}
		return (<p>Some error</p>)
	}
	if (users){
		const newUserList : UserList = {};

		users.docs.map((doc : DocumentData) => {
			const user = doc.data() as User
			const userItem = { userName: user.fullName, avatar: user.avatar, occupation: user.occupation, location:user.myLocation}
			newUserList[user.uid] =userItem
		})
		
	
	return (
		<IonPage ref={ pageRef }>
			<IonHeader>
				<IonToolbar  color="primary">	
					<IonButtons slot="start">
						<IonMenuButton></IonMenuButton>
					</IonButtons>	
					<IonButtons slot="end">
						<IonButton fill="clear" onClick={ (e) => {e.preventDefault(); setShowContactModal(true)} }>
							<IonIcon slot="icon-only" icon={addOutline} />
						</IonButton>
					</IonButtons>
					<IonTitle>Messages</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent color="blue" fullscreen>
				<IonSearchbar  />
				
				<ChatThreadList uid={authInfo.user.uid} userList={newUserList} />
				<IonModal isOpen={ showContactModal } presentingElement={ presentingElement! } onDidDismiss={ () => setShowContactModal(false) }>
					<ContactModal newChat={newChat} uid={authInfo.user.uid}/>
				</IonModal>
			</IonContent>
		</IonPage>
	);
	}
};


interface ChatThreadListProps {
	uid : string
	userList : UserList
}

const ChatThreadList: React.FC<ChatThreadListProps> = ({ uid, userList }) => {  
   
	const [present] = useIonActionSheet();
	const [presentLoading, dismissLoading] = useIonLoading();
	const slidingItemRef = useRef<HTMLIonListElement>(null)
	
	const getAllMessageThreadsQuery = query(
		collection(db, "chatList"), or (
			where("userId", "==", uid),
			where("friendId", "==", uid)
		)
	)

	const [value, loading, error] = useCollection(
		getAllMessageThreadsQuery,{ snapshotListenOptions: { includeMetadataChanges: true }});
	
		/*
	const checkChatUser = async (userList : UserList) => {

		const q = query(collection(db, "chatList"))
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach((chatObj) => {
			const chatThread = chatObj.data()
			if (typeof userList[chatThread.friendId] === "undefined"){
				console.log(chatThread.friendId, ' - does not exist')
				console.log('chat id %s should be deleted', chatThread.id)
				deleteChatThreadDB(chatThread.id)

			}
			if (typeof userList[chatThread.userId] === "undefined"){
				console.log(chatThread.userId, ' - does not exist user')
			}
			//console.log(userList[chatThread.friendId], userList[chatThread.userId])
			
		})
		//console.log(userList)
		
	}*/

	const deleteChatThread = async (id : string ) => {
		present({
			buttons: [
			{ 
				text: 'Cancel',
				role: 'cancel',
				data: {
					action: 'cancel'
				}
			},
			{ 
				text: 'Delete all Messages',
				role: 'destructive',
				data: {
					action: 'discard'
				}
			},
	
		],
		onDidDismiss: ({ detail }) => {
			if (detail.data?.action === 'discard'){
				deleteChatThreadDB(id)
			}
			if (detail.data?.action === 'cancel'){
				console.log('cancel')
				slidingItemRef.current?.closeSlidingItems()
			}
		},
		})
	}	
	
	const deleteChatThreadDB = async ( id : string ) => {

		await presentLoading({message:'Deleting messages...'})
		try {
			// get net
			const batch = writeBatch(db);

			// delete chatMessages
			const q = query(collection(db, "chats"), where("chatId", "==", id))
			const querySnapshot = await getDocs(q);
			querySnapshot.forEach((chatObj) => {
				const message = chatObj.data()
				console.log('delete:', message)
				const mesgRef = doc(db,'chats',message.id)
				batch.delete(mesgRef)
			});
			

			// delete chatThread object
			batch.delete(doc(collection(db,'chatList'), id));

			// commits the changes
			await batch.commit();

		} catch (e) {
			console.log('error deleting batch thread',e)
		}
		await dismissLoading()
		slidingItemRef.current?.closeSlidingItems()

	}

	//const { authInfo } = useAuth()!;
	

	if (value){
		// display data
		
		return (
			<IonList ref={slidingItemRef} style={{paddingTop: '0px', paddingBottom: '0px' }}>
				{ value.docs.map((doc,index)  => { 
					const d = doc.data()
					//console.log(d?.friendId, d?.userId, uid, userList)
					let user = userList[d?.friendId]
					console.log("user:",user)
					if (typeof user === "undefined" ){
						user = { userName: 'deleted', avatar: 'deleted', occupation: 'deleted', location:'deleted'}
					}
					let notificationCount = d?.userMsgIds.length 
					if (d?.friendId === uid ){
						notificationCount = d?.friendMsgIds.length
						user = userList[d?.userId]
						if (typeof user === "undefined" ){
							user = { userName: 'deleted', avatar: 'deleted', occupation: 'deleted', location:'deleted'}
						}
					}
					//console.log(user)
					//checkChatUser(userList)

					
					
					return (  
					<IonItemSliding key={`sliding_${index}`} >      
							<IonItem color="duckegg" key={`chat-${index}`} routerLink={"/tabs/chats/"+d?.userId+'/friend/'+d?.friendId} button detail={true} lines="full"  >
								<IonAvatar slot="start" >
									<img alt= "Avatar" src={user.avatar} />
								</IonAvatar> 
								<IonLabel class="ion-text-wrap">
									<h3>{ user.userName }
									{ notificationCount > 0 && <IonBadge color="danger">{notificationCount}</IonBadge> }</h3>
									<p>{d.lastMessage}</p>
								</IonLabel>
								<IonLabel slot="end">
									<p>{ duration( d.lastUpdate)}</p>
								</IonLabel>
							</IonItem> 
						<IonItemOptions key={`options_${index}`}>
							<IonItemOption disabled={uid !== d.userId} 
									color={ uid !== d.userId ? "light" : "danger"}
									onClick={() => deleteChatThread(d.id)}> 
								<IonIcon slot="top" icon={trash} onClick={() => deleteChatThread(d.id)}></IonIcon>
								Delete
							</IonItemOption>
						</IonItemOptions> 
					</IonItemSliding> 
					)})
				}
				{ !value.docs.length && <IonItem>No Messages</IonItem>}
			</IonList>
			
		)
	}

	if (loading){
		// display spinner
		<IonSpinner />
	}

	if (error){
		// display error
		if (error instanceof FirebaseError){
			console.log('chat list',error)
			return (
			<p>{error.code}{error.message}</p>
			)		
		}
		return (<p>Some error</p>)
	}

    return (
        <></>
    )
}




export default Chats;



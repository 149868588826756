import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonSpinner, IonTitle, IonToolbar } from '@ionic/react';
import {  View, Page, Text, Image, Document, StyleSheet, PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import { arrowBack, downloadOutline } from 'ionicons/icons';
import React from 'react';
import { dateStampNice, formatDate, getCPDHours } from './CPDFunctions';
import { useAuth } from "../contexts/authContext";
import { query, collection, where } from 'firebase/firestore';
import { db } from '../firebase';
import { useCollection } from 'react-firebase-hooks/firestore';
import { CPDRecord } from './CPDRecord';
import cpdSettings from './cpdSettings.json'
import { isIOS, isSafari} from "mobile-device-detect";

interface DisplayCPDRecordSummaryProps {
  cpdRecords : Array<CPDRecord>
}
const DisplayCPDRecordSummary = ({ cpdRecords} : DisplayCPDRecordSummaryProps) => {
  return (
    <View style={styles.tableContainerGrey}>
      <View style={styles.containerItem}> 
        <Text style={styles.text10left}>Item</Text> 
        <Text style={styles.text80}>
          CPD Activity
        </Text>
        <Text style={styles.text10right}>Hours</Text>
      </View>
      { cpdRecords.map((activity, index) => {
         const status = activity.status === 'submit' ? "Submitted on " : "Draft, last updated on "
         const isComplete = (activity.status === 'submit') && (activity.hours > 0)
         let highlight = styles.text80;
         if (!isComplete){
          highlight = {...highlight, ...{borderColor : 'red' }}
         }
         return(
          <View style={styles.container}>
            <Text style={styles.text10left}>{index+1}</Text> 
            <Text style={highlight }>{activity.title} ({ activity.date === '' ? 'No completion date' : status+formatDate(activity.date)}). </Text> 
            <Text style={styles.text10right}>{activity.hours}</Text>
          </View>
         )
      })}
    </View>
  )
}
interface DisplayCPDRecordProps {
  cpdRecord: CPDRecord
  activity : number
  year : string
}
const DisplayCPDRecord = ({ cpdRecord, activity, year} : DisplayCPDRecordProps) => {
  const status = cpdRecord.status === 'submit' ? "Submitted on " : "Draft, last updated on "
  const isComplete = (cpdRecord.status === 'submit') && (cpdRecord.hours > 0)
  let highlight = styles.containerItem;
  if (!isComplete){
    highlight = {...highlight, ...{borderColor : 'red' }}
  }
  return (
    <Page style={styles.body}>
      <Text style={styles.header} fixed>
        {year} CPD Record downloaded on {dateStampNice()}
      </Text>
      <Text style={styles.subtitle}>
        CPD Activity {activity+1}
      </Text>
      <View style={styles.tableContainerGrey}>

        <View style={highlight}>
            <Text style={styles.text}>
            { cpdRecord.type }. { cpdRecord.date === '' ? 'No completion date' : status+formatDate(cpdRecord.date)}, {cpdRecord.hours} hours.
            </Text>
        </View>
          
        <View style={styles.containerItemGrey}>
          <Text style={styles.text}>What was the activity?</Text>
        </View>
        <View style={styles.container}>
          <Text style={styles.text}>{cpdRecord.title}</Text>
        </View>

        <View style={styles.containerItemGrey}>
          <Text style={styles.text}>What I have learned?</Text>
        </View>
        <View style={styles.container}>
          <Text style={styles.text}>{cpdRecord.learning === '' ? "No text provided" : cpdRecord.learning}</Text>
        </View>

        <View style={styles.containerItemGrey}>
          <Text style={styles.text}>Impact of the activity?</Text>
        </View>
        <View style={styles.container}>
          <Text style={styles.text}>{cpdRecord.reflection === '' ? "No text provided" : cpdRecord.reflection}</Text>
        </View>
      </View>
      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />
    </Page>
   
)}

interface CPDTableRowProps {
  description : string
  item : string
}
const CPDTableRow = ({item, description} : CPDTableRowProps) => (
  <View style={styles.container}>
      <Text style={styles.description}>{ description }</Text>
      <Text style={styles.item}>{item !== '' ? item : 'Not available.'}</Text>
  </View>
  
);


interface CPDRecordPDFProps {
    year : string
    cpdHours : number
    fullName: string
    cpdRecords : Array<CPDRecord>
    hours : number
    position: string
    status : string
}
const CPDRecordPDF = ({ year, cpdHours, fullName, cpdRecords, hours, position, status} : CPDRecordPDFProps) => (
    <Document
      author={fullName}
      title={`${year} CPD Record for ${fullName}`}
      creator='app.prosupervise.com'
      subject='CPD Record'
    >
      
      <Page style={styles.body}>
        <Text style={styles.header} fixed>
          {year} CPD Record downloaded on {dateStampNice()}
        </Text>
        <Image
          style={styles.image}
          src="/docs/images/proSupervise-Logo-V1.png"
        />
        
        <Text style={styles.title}>{year} CPD Record</Text>
        <View style={styles.tableContainer}>
          <CPDTableRow description='Name:' item={fullName} />
          <CPDTableRow description='Position:' item={position} />
          <CPDTableRow description='CPD Year:' item={year} />
          <CPDTableRow description='Status:' item={status} />
          <CPDTableRow description='Required Hours:' item={`${cpdHours}`}/>
          <CPDTableRow description='Completed Hours:' item={`${hours} (${(hours/(cpdHours)*100)}%)`}/>

        </View>
        <DisplayCPDRecordSummary cpdRecords={cpdRecords} />
      </Page>

      {
        cpdRecords.map((cpdRecord, index) => {
          return (
            <DisplayCPDRecord cpdRecord={cpdRecord} activity={index} year={year} />
          )
        })
      }
      
      
    </Document>
  );
  
  const borderColor = 'black'
  const styles = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    title: {
      fontSize: 20,
      textAlign: 'center',
      fontFamily: 'Helvetica',
      paddingBottom: 12,
      paddingTop: 12
    },
    author: {
      fontSize: 12,
      textAlign: 'center',
      marginBottom: 40,
    },
    subtitle: {
      fontSize: 18,
      margin: 0
    },
    text: {
      margin: 12,
      fontSize: 12,
      textAlign: 'justify'
      
    },
    textBold: {
      margin: 12,
      fontSize: 14,
      textAlign: 'justify',
      fontStyle: 'bold'
      
    },
    image: {
      marginVertical: 0,
      width: '40%',
      display: 'flex',
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: 'center',
      color: 'grey',
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
    tableContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginTop: 12,
      marginBottom: 12,
      
      borderWidth: 1,
      borderColor: 'black',
    },
    tableContainerGrey: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginTop: 12,
      backgroundColor: 'lightgrey',
      borderWidth: 1,
      borderColor: 'black',
    },
    tableContainerBody: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      borderWidth: 1,
      borderColor: 'black',
    },
    container: {
      flexDirection: 'row',
      backgroundColor: 'white',
      borderBottomWidth: 1,
      
      alignItems: 'center',
    
      textAlign: 'left',
      flexGrow: 1,
      
    },
    containerItem: {
      flexDirection: 'row',
      width: '100%',
      borderBottomWidth: 1,
      backgroundColor: '#03a3b4',
      alignItems: 'center',
    
      textAlign: 'left',
      flexGrow: 1,
      
    },
    containerItemGrey: {
      flexDirection: 'row',
      width: '100%',
      borderBottomWidth: 1,
      backgroundColor: 'lightgrey',
      alignItems: 'center',
      
      textAlign: 'left',
      flexGrow: 1,
      
    },

    text10left: {
      width: '10%',
      textAlign: 'left',
      fontSize : 12,
      paddingRight: 8,
      paddingLeft: 8,
      paddingTop: 8,
      paddingBottom: 8,
    },

    text80: {
      width: '80%',
      extAlign: 'justify',
      fontSize : 12,
      
      borderRightWidth: 1,
      borderLeftWidth: 1,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
      paddingBottom: 8,
    },
    text10right: {
      width: '10%',
      textAlign: 'right',
      fontSize : 12,
      paddingRight: 8,
      paddingTop: 8,
      paddingBottom: 8,
    },
    description: {
      width: '30%',
      textAlign: 'left',
      borderRightColor: borderColor,
      borderRightWidth: 1,
      backgroundColor: '#03a3b4',
      paddingLeft: 8,
      fontWeight: 'bold',
      fontStyle: 'bold',
      fontSize: 12,
      paddingTop: 8,
      paddingBottom: 8,
    },
    item: {
      width: '70%',
      textAlign: 'left',
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 8,
      fontSize: 12,
      fontWeight:'normal',
      fontStyle:'normal'
    },
    tableCol: {
      width: '33%',
      borderLeftWidth: 1,
      textAlign: 'justify',
      paddingTop: 0,
      marginTop: 0,
      paddingLeft: 8,
      fontSize: 12,
      fontWeight:'normal',
      fontStyle:'normal'
    },
  });
  


interface CPDDownloadPDFModalProps {
    year : string;
    onDismiss : () => void 
}
  
  
// Generates CPD PDF for current user
const CPDDownloadPDFModal : React.FC<CPDDownloadPDFModalProps> = ({ year, onDismiss }) => {
  const { authInfo } = useAuth()!;
  
  // database listener for grabbing cpd record changes
  const queryToMake = query(
    collection(db, "cpdRecords"), 
        where("uid","==", authInfo.user.uid),
        where("year", "==", year)
  )
  
  const [value, loading, error] = useCollection(
    queryToMake,{ snapshotListenOptions: { includeMetadataChanges: true }});


  const cpdHours = getCPDHours(cpdSettings.cpdHours,year)

  if (loading) return <IonSpinner></IonSpinner>
  
  // data db loading error
  if (error){
    // db error report it
    console.log(error);
    return (<IonContent>DB Error { error.message }</IonContent>)
  }
  
  if (value){
    const cpdRecords = new Array<CPDRecord>;
    const pdfFileName = `CPD_Record_${year}.pdf`
    let hours = 0;
    let isComplete = true
    value.docs.map((doc) => {
      const cpdRecord = doc.data() as CPDRecord;
      hours = hours + cpdRecord.hours
      isComplete = isComplete && (cpdRecord.status === 'submit')
      cpdRecords.push(cpdRecord)
    })

    // all records submitted and target hours achieved
    isComplete = isComplete && (hours >= cpdHours)

    //const [instance] = usePDF({ document: <Quixote title="my title" /> });
    const styles  =  {
      height: '100vh',
      width: '100vw'
    }

    const isAppleMobile = isIOS && isSafari
    //if (instance.loading) return <IonSpinner></IonSpinner>;
   // if (instance.error || instance.url)
    return (
    <IonPage>   
    <IonHeader>
      <IonToolbar color="primary">
        <IonButtons slot="start">
          <IonButton onClick={()=>onDismiss()} ><IonIcon icon={arrowBack} ></IonIcon></IonButton>
        </IonButtons>
        <IonTitle>Download {year} CPD Record</IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent color="light">
        { isAppleMobile  && 
          <>
            <PDFDownloadLink 
              document={
                <CPDRecordPDF 
                  year={year} 
                  cpdHours={cpdHours}
                  fullName={authInfo.user.fullName}
                  cpdRecords={cpdRecords}
                  hours={hours}
                  position={authInfo.user.occupation}
                  status={ isComplete ? "Complete" : "In Progress"}/>
                }
              fileName={pdfFileName}>
            {({  url, loading }) => {
              if (loading ) return (<IonSpinner></IonSpinner>)
              if (url) return (
                <>
                <p></p>
                <IonButton size="small" href={url}><IonIcon icon={downloadOutline} color="hotcoral"></IonIcon>&nbsp; Open {year} CPD Record</IonButton>
                </>
                )
              
            }
            }
            </PDFDownloadLink>
          </>
        }
        { !isAppleMobile && 
        <PDFViewer style={styles} >
          <CPDRecordPDF 
            year={year} 
            cpdHours={cpdHours}
            fullName={authInfo.user.fullName}
            cpdRecords={cpdRecords}
            hours={hours}
            position={authInfo.user.occupation}
            status={ isComplete ? "Complete" : "In Progress"}/>
        </PDFViewer>
        }
    </IonContent>
    </IonPage>
    )
  }
}


export default CPDDownloadPDFModal;

/*

{ instance.error && 
            <div>Some kind of error {instance.error}</div>
        }
        { instance.url &&
            <a href={instance.url} download="test.pdf">
            Download
          </a>
        }
        */
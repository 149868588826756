
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonNote, IonPage, IonSelect, IonSelectOption, IonText, IonTitle, IonToolbar, useIonActionSheet, useIonToast, useIonLoading, IonTextarea, IonDatetimeButton, IonModal, IonDatetime } from '@ionic/react';
import React from 'react';
import { dateStamp, formatDate } from './CPDFunctions';
import { useAuth } from '../contexts/authContext';

import { close, arrowBack, checkboxOutline, closeCircleOutline } from 'ionicons/icons';
import cpdSettings from './cpdSettings.json'
import './CPDStyle.css'

import { setDoc, collection, doc } from 'firebase/firestore'
import { db } from '../firebase';
import { FirebaseError } from 'firebase/app';

import { formatISO } from 'date-fns';
import { CPDRecord } from './CPDRecord';

type MapType = { 
  [id: string]: string; 
}
// form title mapping
const questions : MapType = {
  'reflection': "Reflect on and describe the impact the CPD activity has had?",
  'learning': "Describe what you have learned from doing this CPD activity?"
}

const questionsGuidance : MapType = {
    'reflection': "You could think about how the CPD activity might benefit those who come into contact with.",
    'learning': "You may want to refer to the CPD standards when answering this question."
}

// creates a new CPD Record
export interface NewRecordModalProps {
  onDismiss : (cpdRecordId : string) => void
}

const CPDNewRecordModal : React.FC<NewRecordModalProps> = (
  { onDismiss }
) => { 
  
  const today = new Date();
  const thisYear = `${today.getFullYear()}`
  const todayIso = formatISO(today);


  const { authInfo } = useAuth()!;

  const defaultCpdRecord = {
    title : '',
    type: '',
    date: todayIso,
    year: thisYear,
    activity : '',
    learning : '',
    reflection: '',
    id: '',
    hours : 0,
    peerReviewed: false,
    evidence: [],
    audit: [dateStamp()+" CPD activity created."],
    status: 'draft',
    uid : authInfo.user.uid
  }
  const [edited, setEdited] = React.useState(false)
  const [error, setError] = React.useState("")
  const [cpdRecord, setCpdRecord] = React.useState<CPDRecord>(defaultCpdRecord)
  const [isValidHours, setIsValidHours] = React.useState(true);
  
  const [present] = useIonActionSheet();

  const [showToast] = useIonToast();

  const [presentLoading, dismissLoading] = useIonLoading();

  
  
  
  const captureInput = (ev: Event) => {    
    const value = (ev.target as HTMLInputElement).value;
    const name = (ev.target as HTMLInputElement).name
    if (!edited ){
      setEdited(true)
    }    
    if (cpdRecord){
        setCpdRecord({...cpdRecord, [name]: value})
    }
  }

  function isFloat(val : string) {
    const floatRegex = /^-?\d+(?:[.,]\d*?)?$/;
    if (!floatRegex.test(val))
        return false;

    const parsedNumber = parseFloat(val);
    if (isNaN(parsedNumber))
        return false;
    return true;
}

  const captureNumber = (ev: Event) => {    
    const value = (ev.target as HTMLInputElement).value;
    const name = (ev.target as HTMLInputElement).name
    if (!edited ){
      setEdited(true)
    }    
    if (cpdRecord){
        if (isFloat(value)){
            const parsedNumber = parseFloat(value)
            if (!isValidHours) setIsValidHours(true)
            setCpdRecord({...cpdRecord, [name]: parsedNumber})
        } else if (isValidHours){
            setIsValidHours(false)
            setCpdRecord({...cpdRecord, [name]: value})
        }
        
    }
  }
  // Removes non alphanumeric characters
  

  const captureSelect= (ev: Event) => {
    const value = (ev.target as HTMLSelectElement).value;
    const name = (ev.target as HTMLInputElement).name
    if (!edited && value !== ''){
      setEdited(true)
    }
    if (cpdRecord)
      setCpdRecord({...cpdRecord, [name]: value})
  }

  // calendar dialog has selected date
  const selectDate = (date: string|string[] ) => {
  
    if (date && typeof date === 'string'){
      if (cpdRecord){
        setCpdRecord({...cpdRecord, date: date})
      }
      if (!edited){
        setEdited(true)
      }
    }
  };

  const onSubmit = async () => {
   
      // create new record on firebase
      await presentLoading({message:'Saving CPD activity ...'})
      try { 
        const newRef = doc(collection(db,'cpdRecords'))
        cpdRecord.id = newRef.id;
        await setDoc(doc(collection(db,'cpdRecords'), newRef.id), { ...cpdRecord, ...{id: newRef.id}},{ merge: true })
        
        showToast({
          message: `CPD activity "${cpdRecord.type}" has been added`,
          color: 'success',
          duration: 3000,
          buttons: [{ icon: close }],
        })
        onDismiss(newRef.id)
      }
      catch(error){
        if (error instanceof FirebaseError){
            /*
            if (error.code === 'auth/wrong-password' ||
                error.code === 'auth/user-not-found' )
                setMyError(noAccountWithPassword);
            else if (error.code === 'auth/internal-error' ||
                    error.code === 'auth/network-request-failed')
                setMyError(pleaseTryAgain)
            else
                setMyError(error.code);
            */
            setError(error.message)
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
        } else {
            console.log('chat.tsx:',error)
        }
      }
      await dismissLoading()

  }
  
  const cancel = () => {
    if (edited){
      present({
        header: 'You have made changes.  Exit without adding new CPD activity?',
        buttons: [
          { 
            text: 'Exit without Saving',
            role: 'destructive',
            data: {
              action: 'confirm'
            }
          },
          { 
            text: 'Cancel',
            role: 'cancel',
            data: {
              action: 'cancel'
            }
          },

        ],
        onDidDismiss: async ({ detail  }) => {
          if (detail.data?.action === 'confirm'){
            onDismiss('')
          }
        },
      })
    }
    else {
      // close
      onDismiss('')
    }
  }

  const sanitizeString =(str: string) =>{
    str = str.replace(/([^a-z0-9áéíóúñü_-\s.,]|[\t\n\f\r\v\0])/gim,"");
    return str.trim();
  }
  function renderCPDtypeItems() {
    return cpdSettings.cpdType
      .map((type,key) => (
        <IonSelectOption key={key} value={sanitizeString(type.type)}>{type.type}</IonSelectOption>
      ))
  }
  
  return (
    <IonPage>   
    <IonHeader>
      <IonToolbar color="primary">
        <IonButtons slot="start">
          <IonButton onClick={()=>cancel()} ><IonIcon icon={arrowBack} ></IonIcon></IonButton>
        </IonButtons>
        <IonTitle>Add a new CPD Activity</IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent color="light">
    <div className="ion-padding">
      <h3>Tell us about your CPD Activity</h3>
    </div>
      <IonList inset={true} lines="full">
        <IonItem key="question"><IonLabel>What type of CPD best describes this activity?</IonLabel></IonItem>
        <IonItem key="type">  
        
          <IonSelect  name="type" justify="start" placeholder="Make a Selection" value={cpdRecord.type} onIonChange={(event) => captureSelect(event)}>
            { renderCPDtypeItems() }
          </IonSelect>
        </IonItem>
      </IonList>
      <IonList inset={true} style={{marginBottom: '4px'}}>
        <IonItem key="titleQuestion">In one sentence describe the CPD activity</IonItem>
        <IonItem key="title">
          <IonInput onIonInput={(event) => captureInput(event)} 
          value={cpdRecord.title} name="title" 
          aria-label="Briefly describe the CPD activity" 
          placeholder="Enter text"
          >
         </IonInput>
        </IonItem>
      </IonList>
      <div className="ion-padding" style={{paddingTop: '0px'}}>
      <IonNote >
          For example: I watched a video on new techniques for supervision that can help me with difficult clients.
      </IonNote>
      </div>
      <IonList inset={true} style={{marginBottom: '4px'}} lines="full">
        <IonItem key="completionDate" id="open-modal">
          <IonLabel><b>When did the CPD activity complete?</b> ({cpdRecord.date && cpdRecord.date !== ''? formatDate(cpdRecord.date) : "No completion date"})</IonLabel>
          <IonNote slot="end" id="datetimeValue"></IonNote>
          <IonDatetimeButton datetime="datetime"></IonDatetimeButton>
          <IonModal keepContentsMounted={true}>
            <IonDatetime id="datetime" showDefaultButtons={true} 
              value={cpdRecord.date !== '' ? cpdRecord.date : todayIso}
              presentation="date"
              onIonChange={(e) => { e.preventDefault();selectDate(e.detail.value!);}}
              color="secondary" ></IonDatetime>
          </IonModal>
        </IonItem>
      </IonList>
      <div className="ion-padding" style={{paddingTop: '0px'}}>
      <IonNote >
          Choose a completion date by clicking on the date button.
      </IonNote>
      </div>
      

      <IonList inset={true} style={{marginBottom: '4px'}} lines="full">
        <IonItem key="hoursQuestion"><IonLabel className="ion-text-wrap"><b>How many hours did this CPD activity take?</b>
        </IonLabel></IonItem>
        <IonItem key="hours">
          <IonInput onIonInput={(event) => captureNumber(event)}  
            className = {isValidHours ? "" : "ion-invalid ion-touched"}
            name = "hours"
            min={0}
            type = "number"
            errorText='Enter a number'
            aria-label="How many hours did this CPD activity take?" 
            value={ cpdRecord ? cpdRecord.hours : 0}
            placeholder="Enter hours">
          </IonInput>
        </IonItem>
      </IonList>
      <div className="ion-padding" style={{paddingTop: '0px'}}>
      <IonNote >
          Use decimal number for part hours e.g. 1.5 for an 1 hour 30 minutes.
      </IonNote>
      </div>

      <IonList inset={true} style={{marginBottom: '4px'}} lines="full">
        <IonItem key="learningQuestion"><IonLabel className="ion-text-wrap"><b>{ questions['learning']}</b></IonLabel></IonItem>
        <IonItem key="learning" lines="none">
          <IonTextarea onIonInput={(event) => captureInput(event)}  
            rows = {4}
            name = "learning"
            autoGrow={true}
            aria-label={ questions['learning']} 
            value={ cpdRecord ? cpdRecord.learning : ''}
            placeholder="Enter text">
          </IonTextarea>
        </IonItem>
      </IonList>
      <div className="ion-padding" style={{paddingTop: '0px'}}>
      <IonNote >
          {questionsGuidance['learning']}
      </IonNote>
      </div>

      <IonList inset={true} style={{marginBottom: '4px'}} lines="full">
        <IonItem key="reflectionQuestion"><IonLabel className="ion-text-wrap"><b>{ questions['reflection']}</b></IonLabel></IonItem>
        <IonItem key="reflection" lines="none">
          <IonTextarea onIonInput={(event) => captureInput(event)}  
            rows = {4}
            autoGrow = {true}
            name = "reflection"
            aria-label={ questions['reflection']} 
            value={ cpdRecord ? cpdRecord.reflection : ''}
            placeholder="Enter text">
          </IonTextarea>
        </IonItem>
      </IonList>
      <div className="ion-padding" style={{paddingTop: '0px'}}>
      <IonNote >
          {questionsGuidance['reflection']}
      </IonNote>
      </div>
      { error !== "" &&  
        <div className="ion-padding"  style={{paddingTop: '0px'}}>
        <IonText color="danger">
            { error }
        </IonText>
        </div>
      }

      <IonButtons className="ion-padding">
        <IonButton key="submit" disabled={!edited} onClick={()=>onSubmit()} fill="solid" color="primary">
          <IonIcon aria-hidden="true" icon={checkboxOutline} color="hotcoral" slot="start"></IonIcon>
          Add new CPD Activity
        </IonButton>
        <IonButton key="cancel" onClick={() => cancel()} fill="solid" color="primary" >
          <IonIcon aria-hidden="true" icon={closeCircleOutline} color="hotcoral"  slot="start"></IonIcon>
          Cancel
        </IonButton>
      </IonButtons>

    </IonContent>
    </IonPage>
  )
}

export default CPDNewRecordModal



// Page generated from markdown docs/pages/


import React from 'react';
import { IonHeader, IonBackButton, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonLoading, IonSpinner, IonItem } from '@ionic/react';
import { useAuth } from '../contexts/authContext';
import './StripeStyle.css'
import { auth } from "../firebase";
import { api } from '../firebase';


// select prices for customer account 
const StripePortal: React.FC = () => {
  
  const { authInfo } = useAuth()!;
  const [url, setUrl] = React.useState('')
  const [error,setError] = React.useState('')

  React.useEffect(()=>{
    const createStripePortal = async () =>  {
      const customerId = authInfo.user.customerId
      const return_url = window.location.origin+'/tabs/home/stripeaccount'
      const args = new URLSearchParams();
      args.append('return_url', return_url)
      args.append('customerId', customerId)
      args.append('uid', authInfo.user.uid)
      auth.currentUser?.getIdToken().then((token: string) => {
        fetch(`${api}app/portal?`+ args.toString(), {
          headers: {
            authorization: `Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
        })
        .then(response => { 
          return response.json()
        })
        .then( portal => {
          if (portal.ok){
            setUrl(portal.url)
          } else {
            setError(portal.error.message)
          } 
        })
      })   
    }
    createStripePortal()  
  }, [authInfo])

  const goUrl = ( url : string) => {
    window.location.assign(url)
    return <>Please wait redirecting to stripe Portal...</>
  }

  return (
    <IonPage >
      <IonHeader>
        <IonToolbar color="primary">
            <IonButtons slot="start">
                <IonBackButton></IonBackButton> 
            </IonButtons>
          <IonTitle>Back to Account</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding" color="secondary"> 
      { url === '' && 
        <IonLoading 
          onDidDismiss={() => { console.log('app.tsx auto reload'); window.location.reload()}}
          backdropDismiss={true}
          isOpen={true}
          message="Loading..." 
          duration={3000}
        />} 
      { url !== '' && <>
        <IonLoading 
        onDidDismiss={() => { console.log('app.tsx auto reload'); window.location.reload()}}
        backdropDismiss={true}
        isOpen={true}
        message="Loading Portal ..." 
        duration={3000}
      />
        <IonItem color="success"><IonSpinner></IonSpinner> Please wait redirecting to stripe Portal {goUrl(url)}</IonItem>
        </>
      }
      { error !== '' && 
        <IonItem color="danger">{error}</IonItem>
      }
        
      </IonContent>

    </IonPage>
  );
};   

export default StripePortal;
import { ErrorMessage } from "@hookform/error-message";
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonInput, IonItem, IonLabel, IonPage, IonRow, IonSpinner } from "@ionic/react";
import { Link, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";



import { useState } from "react"
import { confirmPasswordReset } from "firebase/auth";
import { auth } from "../firebase";


const defaultFormFields = {
  password: '',
  confirmPassword: '',
}

function PasswordReset() {

  console.log('password reset')
  /**
   * Extract oobCode from the URL.
   * Delete console.log in production.
   */
  //const [searchParams] = useParams()
  const search = useLocation().search
  const searchParams = new URLSearchParams(search)
  //const [formFields, setFormFields] = useState(defaultFormFields)
  const { password, confirmPassword } = defaultFormFields;

  const oobCode:string | null = searchParams.get('oobCode')
  
  interface FormValues {
    password : string
    confirmPassword : string
  }

  const [passwordReset, setPasswordReset] = useState(false)
  const { handleSubmit, control, formState } = useForm<FormValues>();
  const { isSubmitting } = formState;
  const { errors } = formState;
  const [isError, setIsError] = useState(false)
  const [myError, setMyError] = useState('')


  const handleResetPassword = async (data: FormValues) => {
    if (data.password !== data.confirmPassword) {
      setMyError("Passwords did not match.")
      setIsError(true)
      return;
    }
    try {
      if (oobCode) {
        await confirmPasswordReset(auth, oobCode, data.password)
        //await confirmThePasswordReset(oobCode, confirmPassword)
        setPasswordReset(true)
      } else {
        setMyError('Something is wrong; try again later!')
        setIsError(true)
        console.log('missing oobCode')
      }
      //await sendPasswordResetEmail(auth, data.email);
      //setPasswordResetSent(true);
    } catch (error:any) {   // eslint-disable-line
      switch(error.code) {
        case "auth/invalid-action-code": 
        case "auth/expired-action-code": 
          setMyError("This code has expired. Please reset password again");
          break;
        case "auth/user-disabled": 
          setMyError("The user account has been disabled by an administrator.");
          break;
        case "auth/user-not-found":
          setMyError("That email address doesn't match an existing account");
          break;
        case "auth/weak-password":
          setMyError(" Weak password, strong passwords have at least 6 characters and a mix of letters and numbers");
          break;
        default:
          setMyError("Unspecified error, please try again ")
      }
      setIsError(true);
      console.log(error)
    }
  };

  return(


    <IonPage>
      <IonContent>
          <IonGrid className="gridColorPurple">
              <IonRow class="ion-align-items-center center-height">
                  <IonCol class="no-padding">
                      <IonCard className="login margin-5px">
                          <IonCardHeader>
                              <IonCardTitle>Reset Password</IonCardTitle>
                          </IonCardHeader>
                          { passwordReset &&
                            <IonCardContent>
                              <p>You password has been reset!</p> 
                              <center> <Link to="/login">Log in?</Link> </center>
                            </IonCardContent>
                          }
                          { !passwordReset && 
                          <form onSubmit={handleSubmit(handleResetPassword)}>
                          <IonGrid class="background-white">
                              <IonRow>
                                  <IonCol>
                                      <Controller
                                          render={({ field: { onChange} }) => (
                                          <IonInput labelPlacement="floating" fill="outline" label="New password" name="password" type="password" value={password} placeholder="Enter new password" onIonChange={onChange} />
                                          )}
                                          control={control}
                                          name="password"
                                          rules={{
                                          required: "This is a required field",
                                          }}
                                      />
                                  
                                      <ErrorMessage
                                          errors={errors}
                                          name="password"
                                          as={<div style={{ color: "red" }} />}
                                      />
                                      
                                  </IonCol>
                              </IonRow>
                              <IonRow>
                                  <IonCol>
                                      <Controller
                                          render={({ field: { onChange } }) => (
                                          <IonInput labelPlacement="floating" fill="outline" label="Confirm password" name="confirmPassword" type="password" value={confirmPassword} placeholder="Confirm new password" onIonChange={onChange} />
                                          )}
                                          control={control}
                                          name="confirmPassword"
                                          rules={{
                                          required: "This is a required field",
                                          }}
                                      />
                                  
                                      <ErrorMessage
                                          errors={errors}
                                          name="confirmPassword"
                                          as={<div style={{ color: "red" }} />}
                                      />
                                      
                                  </IonCol>
                              </IonRow>
                              <IonRow>
                                  <IonCol>
                                      <IonButton expand="block" disabled={isSubmitting} size="default" type="submit">{ isSubmitting && <IonSpinner></IonSpinner>}Reset Password</IonButton>  
                                  </IonCol>
                              </IonRow> 
                              { isError && 
                              
                              <IonRow>
                                  <IonCol>
                                      <IonItem  color="danger">
                                          <IonLabel class="ion-text-wrap" >{myError}</IonLabel>
                                      </IonItem>
                                  </IonCol>
                              </IonRow> 
                              }
                              
                              <IonRow class="ion-justify-content-right">
                                  <IonCol >                        
                                     <center> <Link to="/login">Log in?</Link> </center>
                                  </IonCol>
                              </IonRow>    
                          </IonGrid>
                          </form>
                          }
                      </IonCard>
                  </IonCol>
              </IonRow>
          </IonGrid>
      </IonContent>
  </IonPage>
    
  )
}

export default PasswordReset




import {  IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react"
import CPDProgressBar from "./CPDProgressBar"

import './CPDProgress.css';
import { checkmarkCircleOutline, closeCircleOutline } from "ionicons/icons";


export interface CPDProgressProps  {
  cpd : number
  maxCpd : number
}


// Display CPD progress as charts

const CPDProgress : React.FC<CPDProgressProps> = ({ cpd, maxCpd}) => {

  const cpdCompleted : number = Math.round((cpd/maxCpd))*100;
  const barCpdCompleted = cpdCompleted <= 100 ? cpdCompleted : 100
  const today = new Date();
  const thisYear = `${today.getFullYear()}`

  return (
    <>
    <IonGrid className="noPadding">
      <IonRow className="ion-justify-content-center">
        <IonCol className="roundBox" >

          <IonGrid className="no-padding">
            <IonRow className="ion-align-items-center ion-justify-content-center">
              <IonCol size="2" class="no-padding">
                { cpdCompleted >= 100 && <IonIcon icon={checkmarkCircleOutline} size="large" color="green"></IonIcon>}
                { cpdCompleted < 100 && <IonIcon icon={closeCircleOutline} size="large" color="hotcoral"></IonIcon> }
              </IonCol>
              <IonCol size="8" className="no-padding">
                <IonGrid>
                  <IonRow>
                    <IonCol className="ion-text-start smallFont"><b>Required {thisYear} CPD Hours</b></IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol class="no-padding"><CPDProgressBar bgcolor="green" completed={`${barCpdCompleted}`} percent={cpdCompleted} /></IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="10" class="ion-text-start smallFont">
                      {cpd} hours completed
                    </IonCol>
                    <IonCol size="2" class="ion-text-end smallFont">
                      <b>{cpd}/{maxCpd}</b> 
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCol>
        
      </IonRow>
    </IonGrid>
    </>
  );
  }
  
  export default CPDProgress;
import React from "react";
import { auth, storage, googleProvider} from '../firebase'

import { collection, getDoc, setDoc, doc } from "firebase/firestore";
import { ref, uploadString, getDownloadURL } from 'firebase/storage';
import { db } from '../firebase';
import { userDefault, User } from "../data/user";
import { signInWithPopup } from "firebase/auth";
 
import { usePostHog } from 'posthog-js/react'

type UserDataInterface = { initialized: boolean; loggedIn: boolean; user: User ; docId: string,error: string};
type MyContextInterface = {
  authInfo: UserDataInterface;
  initialize: () => Promise<boolean>;
  logOut: () => Promise<void>;
  logIn: () => Promise<boolean>;
  saveUser: (user: User) => Promise<void>;
  registerUser: (user: User) => Promise<void>;
  signInWithGoogle: () => Promise<void>;
};

function onAuthStateChange(callback : ( initialized: boolean, loggedIn: boolean, user: User, docId: string, error: string) => void) {
    
    return auth.onAuthStateChanged(user => {
      
      console.log('auth state change',user)
        if (user) {
          console.log('auth:', user)
          
          // grab the user details from db
          getDoc(doc(collection(db,'users'), user.uid))
          .then(doc => {
            if (doc.exists() ){
              const docData = doc.data() as User;
              
              
              callback(true, true, docData, docData.uid,'');

              
              console.log('auth doc:', docData)
            }
            else {
              console.log('auth docId not found', user.uid, doc)
 
              callback(true, false, userDefault, '','User record not found, Internal error please email admin@prosupervise.com')
            }
          })
          .catch(err => {
              console.log('Error getting user documents', err);
              callback(true, false, userDefault, '', 'Internal error please email admin@prosupervise.com')
          });
            
        } else {
          callback(true, false, userDefault, '','');
        }
    });
}


// create the context
export const AuthContext = React.createContext<MyContextInterface | undefined>(
  undefined
);




// create the context provider, we are using use state to ensure that
// we get reactive values from the context...
type Props = {
  children: React.ReactNode;
};

export const AuthProvider: React.FC<Props> = ({ children }) => {

  const posthog = usePostHog()
  // the reactive values
  const [authInfo, setAuthInfo] = React.useState<UserDataInterface>({
    initialized: false,
    loggedIn: false,
    user: userDefault,
    docId : '',
    error: ''
  });

  const saveInfo = ( initialized: boolean, loggedIn: boolean, user: User, docId: string, error: string) => {
    setAuthInfo({ initialized: initialized, loggedIn: loggedIn, user: user, docId: docId, error: error})
  }
  
  React.useEffect(() => {
    const unsubscribe = onAuthStateChange(saveInfo);
    return () => {
      unsubscribe();
    }
  }, [])

  

  // save user record in firebase database under collection users
  const saveUser = async (userUpdate: User) => {
    const docId = authInfo?.docId;
    setAuthInfo({ initialized: true, loggedIn: true, user: userUpdate, docId: docId, error: ''});
    //console.log('save user',userUpdate, authInfo)
    // update firebase
    try {
      await setDoc(doc(collection(db,'users'), docId), userUpdate, { merge: true});
    }
    catch(e) {
      console.log('error save user', e);
    }
  }
  

  //
  // register user functions
  //

  // register user record in firebase database under collection users
  const registerUser = async (newUser: User) => {
    const newUid = newUser.uid;
    try {

      // post hog identify user
       posthog?.identify(newUid, {
        email: newUser.email,
      })

      // add new user record to firebase
      const docRef = doc(db,"users", newUid);  // uid is key
      await setDoc(docRef, newUser);

      setAuthInfo({ initialized: true, loggedIn: true, user: newUser, docId: newUid, error: ''});
      //console.log('register user:', authInfo)
    }
    catch(e) {
      console.log('error register user', e);
    }
  }

  // download url and pass image64 string via call back
  function toDataURL(url:string, callback: (url: string|null|ArrayBuffer)=>void) {
    const xhr = new XMLHttpRequest();
    xhr.onload = function() {
      const reader = new FileReader();
      reader.onloadend = function() {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  // create avatar based on name and register user details
  const createAvatarAndRegister = async (fullName: string, avatar: string, email: string, uid: string, authProvider: string ) => {
    toDataURL(avatar, function(dataUrl) {
      const storageRef = ref(storage, `/avatar/${uid}.png`)
      if (typeof dataUrl === "string"){
        uploadString(storageRef, dataUrl, 'data_url')  // upload avatar to firestore storage
        .then( snapshot => {
          console.log('Uploaded a data_url string!',snapshot.ref.fullPath  );
          return getDownloadURL(storageRef) // get avatar url
        })
        .then ( url => {
          //console.log('url', url)
          const registerUserDetails = {
              uid : uid,
              fullName : fullName,
              avatar: url,
              email : email,
              authProvider: authProvider
          };
          registerUser( {...userDefault, ...registerUserDetails} ); // register user
        })
        .catch(error => {
          console.log('register avatar and user error',error)
        });
      }
      else {
        console.log('Avatar image create failed')
      }
    })
  }

  //---------------------------------------

  // login functions via google provider
  const signInWithGoogle = async () => {
    try {
      const res = await signInWithPopup(auth, googleProvider);
      console.log('sign in got res:', res)
      const user = res.user;
      const registeredUserDoc = await getDoc(doc(collection(db,'users'), user.uid))
      if (registeredUserDoc.exists() ){
        const registeredUser = registeredUserDoc.data() as User;
        // user exists sign in
        setAuthInfo({ initialized: true, loggedIn: true, user: registeredUser, docId: registeredUser.uid, error: '' });
      }
      else {
        // user does not exist register new user object
        const displayName = (user.displayName !== null ? user.displayName : 'No Name');
        const email = ( user.email !== null ? user.email : "No email")
        const avatar = (user.photoURL !== null ? user.photoURL : 'https://ui-avatars.com/api/?size=128&name='+displayName)
        
        await createAvatarAndRegister (displayName, avatar, email, user.uid, 'google' )
      }
    } catch (err  ) {
      console.log('error:signin with google:',err);
    }
  };


  // sign out of prosupervise
  const logOut = async () => {
    auth.signOut()
    .then(() => console.log('user signed out'))
    .catch((err) =>
      console.log('error:signout:',err)
    )
  };

  const initialize = () => {

console.log("auth initialise")
    
      setAuthInfo({
        initialized: true,
        loggedIn: false,
        user: userDefault,
        docId : '',
        error: ''
      });
  
  };

  const v = {
    authInfo,
    logOut,
    initialize,
    saveUser,
    registerUser,
    signInWithGoogle
  } as MyContextInterface;

  return <AuthContext.Provider value={v}>{children}</AuthContext.Provider>;
};

export const useAuth = () => React.useContext(AuthContext);
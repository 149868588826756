//-----------------------------------------------------------------------------------------------------------------
//
// Date functions
//


import { format,parseISO } from 'date-fns';
export const dateStamp = () : string => {
  return format(new Date(), "dd/MM/yy HH:mm")
}

export const dateStampNice = () : string => {
  return format(new Date(), "MMM, dd, yyyy")
}

export const formatDate = (isoDate: string) : string => {
  const date = parseISO(isoDate)
  return format(date, "MMM, dd, yyyy")
}

//-----------------------------------------------------------------------------------------------------------------
//
// Misc functions
//
type CPDhours = {
  year : string
  hours : number
}


export const getCPDHours = ( cpdYears : CPDhours[], year: string) =>{
  const cpdYear = cpdYears.filter( cpdYear => cpdYear.year === year)
  if (cpdYear.length > 0 ){
    return (cpdYear[0].hours)
  }
  else {
    const cpdYear = cpdYears.filter( cpdYear => cpdYear.year === 'default')
    if (cpdYear.length > 0 ){
      return (cpdYear[0].hours)
    }
    else  
      return 22;
  }
}
//-----------------------------------------------------------------------------------------------------------------
//
// DB functions
//

import { setDoc, doc, collection, deleteDoc, getDoc } from "firebase/firestore";
import { CPDRecord} from "./CPDRecord";
import { db } from "../firebase";

//  db function to update CPDRecord on db
export const dbChangeCPDRecord=  async ( cpdRecord: CPDRecord) => {
  try {
    const key = ''//cpdRecord.consultantUid+cpdRecord.start.toString();
    console.log('key:', key, cpdRecord)
    await setDoc(doc(collection(db,'cpdRecordsList'), key), cpdRecord, { merge: true});
  }
  catch(e){
    console.log('error update cpdRecord:', cpdRecord, e);
  }
}
  
  export const dbDeleteCPDRecord=  async ( cpdRecord: CPDRecord) => {
  try {
    const key = ''//cpdRecord.consultantUid+cpdRecord.start.toString();
    console.log('key:', key)
    await deleteDoc(doc(collection(db,'cpdRecordsList'), key));
    return true
  }
  catch(e){
    console.log('error deleting cpdRecord:', cpdRecord, e);
    return false
  }
}
  
  //  db function to update CPDRecord on db
  export const dbGetCPDRecord=  async ( key : string) => {
    try {      
      return await getDoc(doc(collection(db,'cpdRecordsList'), key));
    }
    catch(e){
      console.log('error getting cpdRecord:', key, e);
    }
  }
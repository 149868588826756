
import { IonTextarea, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonNote, IonPage, IonSelect, IonSelectOption, IonText, IonTitle, IonToolbar, useIonActionSheet, useIonToast, useIonLoading, IonDatetime, IonModal, IonDatetimeButton} from '@ionic/react';
import React, { useEffect } from 'react';
import { dateStamp, formatDate } from './CPDFunctions';
import { close, arrowBack, checkboxOutline, closeCircleOutline } from 'ionicons/icons';
import cpdSettings from './cpdSettings.json'
import './CPDStyle.css'

import { setDoc, collection, doc, getDoc } from 'firebase/firestore'
import { db } from '../firebase';
import { FirebaseError } from 'firebase/app';

import { CPDRecord } from './CPDRecord';
import { formatISO } from 'date-fns';

type MapType = { 
[id: string]: string; 
}
// form title mapping
const questions : MapType = {
  'reflection': "Reflect on and describe the impact the CPD activity has had?",
  'learning': "Describe what you have learned from doing this CPD activity?"
}

const questionsGuidance : MapType = {
   'reflection': "You could think about how the CPD activity might benefit those who come into contact with.",
   'learning': "You may want to refer to the CPD standards when answering this question."
}

type ID = {
  id : string
}
interface CPDEditRecordModalProps {
  id : ID;
  onDismiss : (id: string) => void 
}


// creates a new CPD Record - via url /editrecordmodal/
const CPDEditRecordModal : React.FC<CPDEditRecordModalProps> = ({ id, onDismiss }) => { 
  
  const [cpdRecordId,setCpdRecordId] = React.useState(id.id)
  const [edited, setEdited] = React.useState(false)
  const [cpdRecord, setCpdRecord] = React.useState<CPDRecord>()
  const [error, setError] = React.useState("")
  const [isValidHours, setIsValidHours] = React.useState(true);
  const today = new Date();
  const todayIso = formatISO(today);

  const [present] = useIonActionSheet();

  const [showToast] = useIonToast();

  const [presentLoading, dismissLoading] = useIonLoading();

  useEffect(()=>{
    if (cpdRecordId && cpdRecordId !== ""){
        getDoc(doc(collection(db,'cpdRecords'), cpdRecordId))
        .then(cpdDoc => {
            if (cpdDoc.exists()){
                setError("")
                setCpdRecord(cpdDoc.data() as CPDRecord)
            } else {
                setError(`CPD activity ${cpdRecordId} does not exist`)
                setCpdRecordId("");
            }
        })
        .catch(() => setError(`CPD get record fail`))
    }
    else {
        setError('no CPD record id'+cpdRecordId)
    }
  },[cpdRecordId,setCpdRecord])

  const captureInput = (ev: Event) => {    
    const value = (ev.target as HTMLInputElement).value;
    const name = (ev.target as HTMLInputElement).name
    if (!edited ){
      setEdited(true)
    }    
    if (cpdRecord){
        setCpdRecord({...cpdRecord, [name]: value})
    }
  }

  function isFloat(val : string) {
    const floatRegex = /^-?\d+(?:[.,]\d*?)?$/;
    if (!floatRegex.test(val))
        return false;

    const parsedNumber = parseFloat(val);
    if (isNaN(parsedNumber))
        return false;
    return true;
}

  const captureNumber = (ev: Event) => {    
    const value = (ev.target as HTMLInputElement).value;
    const name = (ev.target as HTMLInputElement).name
    if (!edited ){
      setEdited(true)
    }    
    if (cpdRecord){
        if (isFloat(value)){
            const parsedNumber = parseFloat(value)
            if (!isValidHours) setIsValidHours(true)
            setCpdRecord({...cpdRecord, [name]: parsedNumber})
        } else if (isValidHours){
            setIsValidHours(false)
            setCpdRecord({...cpdRecord, [name]: value})
        }
        
    }
  }
  // Removes non alphanumeric characters
  

  const captureSelect= (ev: Event) => {
    const value = (ev.target as HTMLSelectElement).value;
    const name = (ev.target as HTMLInputElement).name
    if (!edited && value !== ''){
      setEdited(true)
    }
    if (cpdRecord)
      setCpdRecord({...cpdRecord, [name]: value})
  }
  

  const onSubmit = async () => {
    
/*
    const cpdRecord = {
      title : title,
      type: type,
      date: today.getTime(),
      year: today.getFullYear(),
      activity : '',
      learning : '',
      reflection: '',
      id: '',
      hours : 0,
      peerReviewed: false,
      evidence: [],
      audit: [dateStamp()+" CPD activity created."],
      status: 'draft',
      uid : authInfo.user.uid
    }
    */
    if (!cpdRecord)
        return;

    const checkSubmit = cpdRecord.title !== '' && cpdRecord.type  !== '' &&
        cpdRecord.date !== '' &&
        cpdRecord.year !== '' &&
        cpdRecord.reflection  !== '' &&
        cpdRecord.learning  !== '' &&
        cpdRecord.hours > 0

    // update audit
    const updatedCpdRecord = { ...cpdRecord}
    updatedCpdRecord.audit.push(dateStamp()+" CPD activity updated.")
    if (checkSubmit === false || edited )
      updatedCpdRecord.status = 'draft'

    // update record on firebase
    await presentLoading({message:'Saving CPD activity ...'})
    try { 
      await setDoc(doc(collection(db,'cpdRecords'), updatedCpdRecord.id), updatedCpdRecord,{ merge: true })
      showToast({
        message: `CPD activity "${updatedCpdRecord.type}" has been updated`,
        color: 'success',
        duration: 3000,
        buttons: [{ icon: close }],
      })
      onDismiss( updatedCpdRecord.id)
    }
    catch(error){
      if (error instanceof FirebaseError){
          /*
          if (error.code === 'auth/wrong-password' ||
              error.code === 'auth/user-not-found' )
              setMyError(noAccountWithPassword);
          else if (error.code === 'auth/internal-error' ||
                   error.code === 'auth/network-request-failed')
              setMyError(pleaseTryAgain)
          else
              setMyError(error.code);
          */
          setError(error.message)
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode, errorMessage)
      } else {
          console.log('error edit cpd record:',error)
      }
    }
    await dismissLoading()

  }
  
  const cancel = () => {
    if (edited){
      present({
        header: 'You have made changes.  Exit without adding new CPD activity?',
        buttons: [
          { 
            text: 'Exit without Saving',
            role: 'destructive',
            data: {
              action: 'confirm'
            }
          },
          { 
            text: 'Cancel',
            role: 'cancel',
            data: {
              action: 'cancel'
            }
          },

        ],
        onDidDismiss: async ({ detail  }) => {
          if (detail.data?.action === 'confirm'){
            onDismiss(cpdRecordId)
          }
        },
      })
    }
    else {
      // close
      onDismiss(cpdRecordId)
    }
  }

  const sanitizeString =(str: string) =>{
    str = str.replace(/([^a-z0-9áéíóúñü_-\s.,]|[\t\n\f\r\v\0])/gim,"");
    return str.trim();
  }
  function renderCPDtypeItems() {
    return cpdSettings.cpdType
      .map((type,index) => (
        <IonSelectOption key={index} value={sanitizeString(type.type)}>{type.type}</IonSelectOption>
      ))
  }

  // date time button
  //const datetimeEditRecordModal = useRef<null | HTMLIonDatetimeElement>(null);

  // calendar dialog has selected date
  const selectDate = (date: string|string[] ) => {
  
    if (date && typeof date === 'string'){
      if (cpdRecord){
        setCpdRecord({...cpdRecord, date: date})
      }
      if (!edited){
        setEdited(true)
      }
    }
  };
/*
  const resetDate = () => {
    console.log("reset date", datetimeEditRecordModal.current)
    datetimeEditRecordModal.current?.reset(todayIso);
  };
  const cancelDate = () => {
    console.log("cancel date", datetimeEditRecordModal.current)
    datetimeEditRecordModal.current?.cancel(true);
  };
  const confirmDate = () => {
    console.log("confirm date", datetimeEditRecordModal.current)
    datetimeEditRecordModal?.current?.confirm();
  };
  */
  if (cpdRecord){
    //console.log(docValue.data() as CPDRecord)
    //const cpdRecord = docValue.data() as CPDRecord

    //setCpdRecord(docValue.data() as CPDRecord)
    //console.log(cpdRecord)

  return (
    <IonPage>   
    <IonHeader>
      <IonToolbar color="primary">
        <IonButtons slot="start">
          <IonButton onClick={()=>cancel()} ><IonIcon icon={arrowBack} ></IonIcon></IonButton>
        </IonButtons>
        <IonTitle>Edit CPD Activity</IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent color="light">
    <div className="ion-padding">
      <h3>Tell us about your CPD Activity?</h3>
    </div>
      <IonList inset={true} lines="full" style={{marginBottom: '4px'}}>
        <IonItem key="question"><IonLabel className="ion-text-wrap" ><b>What type of CPD best describes this activity?</b></IonLabel></IonItem>
        <IonItem key="type">   
          <IonSelect  name="type" justify="start" placeholder="Make a Selection" 
            value={cpdRecord ? cpdRecord.type : ''} 
            onIonChange={(event) => captureSelect(event)}>
            { renderCPDtypeItems() }
          </IonSelect>
        </IonItem>
      </IonList>
      <div className="ion-padding" style={{paddingTop: '0px'}}>
      <IonNote >
          Select the CPD activity from the drop down list.
      </IonNote>
      </div>

      <IonList inset={true} style={{marginBottom: '4px'}} lines="full">
        <IonItem key="titleQuestion"><IonLabel className="ion-text-wrap"><b>In one sentence describe the CPD activity?</b>
            </IonLabel></IonItem>
        <IonItem key="title">
          <IonInput onIonInput={(event) => captureInput(event)}  
            name = "title"
            aria-label="Briefly describe the CPD activity" 
            value={ cpdRecord ? cpdRecord.title : ''}
            placeholder="Enter text">
          </IonInput>
        </IonItem>
      </IonList>
      <div className="ion-padding" style={{paddingTop: '0px'}}>
      <IonNote >
          For example: I watched a video on new techniques for supervision that can help me with difficult clients.
      </IonNote>
      </div>

      <IonList inset={true} style={{marginBottom: '4px'}} lines="full">
        <IonItem key="completionDate" id="open-modal">
          <IonLabel><b>When did the CPD activity complete?</b> ({cpdRecord.date && cpdRecord.date !== ''? formatDate(cpdRecord.date) : "No completion date"})</IonLabel>
          <IonNote slot="end" id="datetimeValue"></IonNote>
          <IonDatetimeButton datetime="datetime"></IonDatetimeButton>
          <IonModal keepContentsMounted={true}>
            <IonDatetime id="datetime" showDefaultButtons={true} 
              value={cpdRecord.date !== '' ? cpdRecord.date : todayIso}
              presentation="date"
              onIonChange={(e) => { e.preventDefault();selectDate(e.detail.value!);}}
              color="secondary" ></IonDatetime>
          </IonModal>
        </IonItem>
      </IonList>
      <div className="ion-padding" style={{paddingTop: '0px'}}>
      <IonNote >
          Choose a completion date by clicking on the date button.
      </IonNote>
      </div>
      

      <IonList inset={true} style={{marginBottom: '4px'}} lines="full">
        <IonItem key="hoursQuestion"><IonLabel className="ion-text-wrap"><b>How many hours did this CPD activity take?</b>
        </IonLabel></IonItem>
        <IonItem key="hours">
          <IonInput onIonInput={(event) => captureNumber(event)}  
            className = {isValidHours ? "" : "ion-invalid ion-touched"}
            name = "hours"
            min={0}
            type = "number"
            errorText='Enter a number'
            aria-label="How many hours did this CPD activity take?" 
            value={ cpdRecord ? cpdRecord.hours : 0}
            placeholder="Enter hours">
          </IonInput>
        </IonItem>
      </IonList>
      <div className="ion-padding" style={{paddingTop: '0px'}}>
      <IonNote >
          Use decimal number for part hours e.g. 1.5 for an 1 hour 30 minutes.
      </IonNote>
      </div>

      <IonList inset={true} style={{marginBottom: '4px'}} lines="full">
        <IonItem key="learningQuestion"><IonLabel className="ion-text-wrap"><b>{ questions['learning']}</b></IonLabel></IonItem>
        <IonItem key="learning" lines="none">
          <IonTextarea onIonInput={(event) => captureInput(event)}  
            rows = {4}
            name = "learning"
            autoGrow={true}
            aria-label={ questions['learning']} 
            value={ cpdRecord ? cpdRecord.learning : ''}
            placeholder="Enter text">
          </IonTextarea>
        </IonItem>
      </IonList>
      <div className="ion-padding" style={{paddingTop: '0px'}}>
      <IonNote >
          {questionsGuidance['learning']}
      </IonNote>
      </div>

      <IonList inset={true} style={{marginBottom: '4px'}} lines="full">
        <IonItem key="reflectionQuestion"><IonLabel className="ion-text-wrap"><b>{ questions['reflection']}</b></IonLabel></IonItem>
        <IonItem key="reflection" lines="none">
          <IonTextarea onIonInput={(event) => captureInput(event)}  
            rows = {4}
            autoGrow = {true}
            name = "reflection"
            aria-label={ questions['reflection']} 
            value={ cpdRecord ? cpdRecord.reflection : ''}
            placeholder="Enter text">
          </IonTextarea>
        </IonItem>
      </IonList>
      <div className="ion-padding" style={{paddingTop: '0px'}}>
      <IonNote >
          {questionsGuidance['reflection']}
      </IonNote>
      </div>
      { error !== "" &&  
        <div className="ion-padding"  style={{paddingTop: '0px'}}>
        <IonText color="danger">
            { error }
        </IonText>
        </div>
      }

      <IonButtons className="ion-padding">
        <IonButton key="submit" disabled={!edited || !isValidHours} onClick={()=>onSubmit()} fill="solid" color="primary">
          <IonIcon aria-hidden="true" icon={checkboxOutline} color="hotcoral" slot="start"></IonIcon>
          Save
        </IonButton>
        <IonButton key="cancel" onClick={() => cancel()} fill="solid" color="primary" >
          <IonIcon aria-hidden="true" icon={closeCircleOutline} color="hotcoral"  slot="start"></IonIcon>
          Cancel
        </IonButton>
      </IonButtons>
    </IonContent>
    </IonPage>
  )}
  else {
    return ( <>
      <strong>Error: {JSON.stringify(error)}</strong><br/>
      <IonButton routerLink='/tabs/cpd'>Go Back</IonButton>
      </>
    )
  }
}

export default CPDEditRecordModal

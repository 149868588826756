import { IonMenuButton, IonButtons,IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSegment, IonSegmentButton, IonList, IonItem, IonLabel } from '@ionic/react';
import React from 'react';
import './PracticePage.css';
import PracticeCalendar from '../appointments/PracticeCalendar';
import { useAuth} from '../contexts/authContext';

const PracticePage: React.FC = () => {
  const [segment, setSegment] = React.useState('schedule');
  const { authInfo } = useAuth()!;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
             <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonButtons slot="end">
        </IonButtons>
          <IonTitle>My Practice</IonTitle>
        </IonToolbar>
        <IonToolbar color="primary">
        <IonSegment value={segment} onIonChange={(e) => setSegment(e.detail.value as string)}>
          <IonSegmentButton value="schedule">
            My Calendar
          </IonSegmentButton>
          <IonSegmentButton value="money">
            Payments/Invoices
          </IonSegmentButton>
        </IonSegment>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={true}>   
          <PracticeCalendar hide={segment !== 'schedule'} user={authInfo.user} />
          <MoneyList hide={segment !== 'money'} />
      </IonContent>
    </IonPage>
  );
};




interface MoneyListInterface {
  hide: boolean
}
const MoneyList: React.FC<MoneyListInterface> = ({ hide }) => {
  return(
    <>
    <IonList key="money" style={hide ? { display: 'none' } : {}}>
      <IonItem>
        <IonLabel>Content to appear here</IonLabel>
      </IonItem>
    </IonList>
    </> 
  )  
}



export default PracticePage;
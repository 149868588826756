import { Appointment } from "./AppointmentClasses";

//---------------------------------------------------------------------------------------------------------
// download .ics file - from https://github.com/matthiasanderer/icsFormatter/blob/master/icsFormatter.js
//
const icsFormatter = () => {
    
    const SEPARATOR =  '\n';
    const calendarEvents : string[] = [];
    const calendarStart = [
        'BEGIN:VCALENDAR',
        'VERSION:2.0'
    ].join(SEPARATOR);
    const calendarEnd = SEPARATOR + 'END:VCALENDAR';

    return {
        /**
         * Returns events array
         * @return {array} Events
         */
        'events': function() {
            return calendarEvents;
        },

        /**
         * Returns calendar
         * @return {string} Calendar in iCalendar format
         */
        'calendar': function() {
            return calendarStart + SEPARATOR + calendarEvents.join(SEPARATOR) + calendarEnd;
        },

        /**
         * Add event to the calendar
         * @param  {string} subject     Subject/Title of event
         * @param  {string} description Description of event
         * @param  {string} location    Location of event
         * @param  {string} begin       Beginning date of event
         * @param  {string} stop        Ending date of event
         */
        'addEvent': function(subject: string, description: string, location: string, begin: number, stop: number) {
            // I'm not in the mood to make these optional... So they are all required
            if (typeof subject === 'undefined' ||
                typeof description === 'undefined' ||
                typeof location === 'undefined' ||
                typeof begin === 'undefined' ||
                typeof stop === 'undefined'
            ) {
                return false;
            }

            //TODO add time and time zone? use moment to format?
            const start_date = new Date(begin);
            const end_date = new Date(stop);

            const start_year = ("0000" + (start_date.getFullYear().toString())).slice(-4);
            const start_month = ("00" + ((start_date.getMonth() + 1).toString())).slice(-2);
            const start_day = ("00" + ((start_date.getDate()).toString())).slice(-2);
            const start_hours = ("00" + (start_date.getHours().toString())).slice(-2);
            const start_minutes = ("00" + (start_date.getMinutes().toString())).slice(-2);
            const start_seconds = ("00" + (start_date.getMinutes().toString())).slice(-2);

            const end_year = ("0000" + (end_date.getFullYear().toString())).slice(-4);
            const end_month = ("00" + ((end_date.getMonth() + 1).toString())).slice(-2);
            const end_day = ("00" + ((end_date.getDate()).toString())).slice(-2);
            const end_hours = ("00" + (end_date.getHours().toString())).slice(-2);
            const end_minutes = ("00" + (end_date.getMinutes().toString())).slice(-2);
            const end_seconds = ("00" + (end_date.getMinutes().toString())).slice(-2);

            // Since some calendars don't add 0 second events, we need to remove time if there is none...
            let start_time = '';
            let end_time = '';
            if (start_minutes !=='0' || start_seconds !=='0' || end_minutes !=='0' || end_seconds !== '0') {
                start_time = 'T' + start_hours + start_minutes + start_seconds;
                end_time = 'T' + end_hours + end_minutes + end_seconds;
            }

            const start = start_year + start_month + start_day + start_time;
            const end = end_year + end_month + end_day + end_time;

            const calendarEvent = [
                'BEGIN:VEVENT',
                'CLASS:PUBLIC',
                'DESCRIPTION:' + description,
                'DTSTART;VALUE=DATE:' + start,
                'DTEND;VALUE=DATE:' + end,
                'LOCATION:' + location,
                'SUMMARY;LANGUAGE=en-us:' + subject,
                'TRANSP:TRANSPARENT',
                'END:VEVENT'
            ].join(SEPARATOR);

            calendarEvents.push(calendarEvent);
            return calendarEvent;
        },

        /**
         * Download calendar using the saveAs function from filesave.js
         */
        'download': () => {
            if (calendarEvents.length < 1) {
                return false;
            }
            const calendar = calendarStart + SEPARATOR + calendarEvents.join(SEPARATOR) + calendarEnd;
            window.open( "data:text/calendar;charset=utf8," + encodeURI(calendar));
        }
    };
};

/**
   * Download .ics files for supplied appointment
   * @param appointment 
   */
export const AddToCalendar = ( appointment : Appointment) => {

    const calEntry = icsFormatter();

	const title = 'Prosupervise appointment with '+ appointment.consultantName;
	const place = appointment.location;
	const begin = appointment.start
	const end = appointment.end

	const description = 'Please visit app.prosupervise.com for details.';

	calEntry.addEvent(title,description, place, begin, end);
	calEntry.download();

}
import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCheckbox, IonCol, IonContent, IonGrid, IonHeader, IonInput, IonItem, IonLabel, IonPage, IonRow, IonSpinner, IonTextarea, IonTitle, IonToolbar } from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";

import "./Home.css";

import { getDoc, doc, collection } from "firebase/firestore";
import { useHistory, useParams } from "react-router";
import { userDefault,User } from "../data/user";
import { db } from "../firebase";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { api } from '../firebase';

const DirectoryContactMe: React.FC = () => {
  type FormData = {
    email: string
    name: string
    message: string
  }
  
  const ReCaptchaKey = import.meta.env.VITE_RECAPTCHA_V2_SITE_KEY
  const { uid } = useParams() as { uid : string }
  const [consultant, setConsultant] = useState<User>(userDefault);
  const [enableSend,setEnableSend]= useState(false)
  //const [reloadUser,setReloadUser]=useState(true)
  const [messageSent, setMessageSent] = useState(false)
  const [reCaptchaKey, setReCaptchaKey] = useState(false)
  const [error, setError] = useState('')
  const [isLoading, setLoading] = useState(false);
  const {  control, formState, handleSubmit} = useForm<FormData>();
  const { errors } = formState;
  const captchaRef = useRef<ReCAPTCHA>(null)
  const history = useHistory()
  // load user
  useEffect(() => {
    if (typeof uid === 'undefined'){
      setConsultant(userDefault);
      setError('user is not defined')
    }
    else {
      getDoc(doc(collection(db,'users'), uid))
      .then(doc => {
        if (doc.exists() ){
          const user = doc.data() as User;
          setConsultant(user)
          console.log('got user:', user)
        }
        else {
          console.log('user not found ', doc)
          setError('User id: "'+uid+'" not found')
        }
      })
      .catch(err => {
          console.log('Error getting user documents', err);
          setError("Error getting user")
      });
    }
   // setReloadUser(false)
  }, [uid])

  const handleOK = async () => {
    setLoading(false);
    setError("")
    setMessageSent(false)
    setEnableSend(false)
    history.goBack();
  }

  const handleSend = async (data: FormData) => {
    console.log(data)
    console.log(consultant.fullName)
    console.log(consultant.email)
    setLoading(true)
    // get ReCaptiva details
    const ref = captchaRef.current;
    let token = null;
    if (ref){
      token = ref.getValue();
      ref.reset();
    }

    // set up mail data
    const mailData = {
      sender: {
        name: 'Prosupervise',
        email: 'info@prosupervise.com'
      },
      to: [
        {
          name: consultant.fullName,
          email: consultant.email
        }
      ],
      subject: "Contact from Prosupervise.com",
      templateId: 5,
      params: {
        name: data.name,
        title: "Contact from Prosupervise.com",
        email: data.email,
        message : data.message,
      }
    };
    
    // send email and captiva token to google cloud function
    const args = new URLSearchParams();
    args.append('mailData', JSON.stringify(mailData))
    args.append('token', token ? token : "")
    
    console.log("sending", token)
    fetch(`${api}directorycontactme?`+ args.toString(), {
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    })
    .then(response => { 
      return response.json()
    })
    .then( data => {
      //console.log(data) 
      if (data.ok){
        console.log('Email sent successfully!');    
        setMessageSent(true)
      } else {
        console.error('Failed to send email:', data.error);
        setError('Failed to send email.')
      }
      if (ref){
        ref.reset();
      }
    })
    .catch( error => {
      console.log("***error",error)
      if (ref){
        ref.reset();
      }
    })
    setLoading(false)
  }

  const clickTerms = () => {
    setEnableSend(!enableSend)
  }

  function onChangeReCaptchaKey(value: string|null) {
    if (value === null){
      setReCaptchaKey(false)
    }
    else {
      setReCaptchaKey(true)
    }
    console.log("Captcha value:", value,reCaptchaKey);
  }


  console.log("site key", ReCaptchaKey)
  
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref='/directory/'></IonBackButton>
          </IonButtons>    
          <IonTitle>Contact Me</IonTitle>
        </IonToolbar>
        
       </IonHeader>
      <IonContent color='duckegg' >
        
        {  error !== '' && <p>{error}</p> }
        
        <IonGrid className="gridColorPurple">
          <IonRow class="ion-align-items-center">
            <IonCol class="no-padding">
              <IonCard className="login margin-5px">
                <IonCardHeader>
                    <IonCardTitle>Contact { consultant?.fullName }</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                { error === '' && !messageSent &&
                  <form onSubmit={handleSubmit(handleSend)}>
                  <IonGrid >
                    <IonRow>
                      <IonCol >
                        <p>Please send { consultant?.fullName } a message by filling out the form below:<br/></p>
                      </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol>
                        <Controller
                          render={({ field: { onChange } }) => (
                          <IonInput labelPlacement="stacked" fill="outline" label="What is your name?" name="name" type="text" placeholder="Your name" onIonChange={onChange} />
                          )}
                          control={control}
                          name="name"
                          rules={{
                          required: "This is a required field",
                          
                          }}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="name"
                            as={<div style={{ color: "red" }} />}
                        />
                        
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol >
                        <Controller
                          render={({ field: { onChange } }) => (
                          <IonInput labelPlacement="stacked" fill="outline" label="What is your email address?" name="email" type="email" placeholder="Email address" onIonChange={onChange} />
                          )}
                          control={control}
                          name="email"
                          rules={{
                          required: "This is a required field",
                          pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                              message: "Enter a valid email address"
                          }
                          }}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="email"
                            as={<div style={{ color: "red" }} />}
                        />
                        
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol >
                        <Controller
                          render={({ field: { onChange } }) => (
                          <IonTextarea labelPlacement="stacked" fill="outline" rows={4} autoGrow={true} label="Entry your message?" name="text"  placeholder="Your message" onIonChange={onChange} />
                          )}
                          control={control}
                          name="message"
                          rules={{
                          required: "This is a required field",
                          }}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="message"
                            as={<div style={{ color: "red" }} />}
                        />
                        
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol>
                          
                          <IonCheckbox onIonChange={()=>clickTerms()} checked={enableSend} labelPlacement="end" >I agree to the Prosupervise&nbsp;
                                <Link to='/terms'>Terms of Service</Link> and <Link to="/privacy">Privacy Policy</Link>.
                          </IonCheckbox>                       
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                      <ReCAPTCHA sitekey={ReCaptchaKey} onChange={onChangeReCaptchaKey} ref={captchaRef}/>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonButton expand="block" disabled={isLoading || !enableSend || !reCaptchaKey } size="default" type="submit">{ isLoading ? <> <IonSpinner></IonSpinner> Sending ...</> : <>Send &rarr;</>}</IonButton>  
                        </IonCol>
                    </IonRow> 
                  </IonGrid>
                  </form>
                }
                { error !== '' &&                      
                  <IonRow>
                    <IonCol>
                      <IonItem  color="danger">
                        <IonLabel class="ion-text-wrap" >{error}</IonLabel>
                      </IonItem>
                      <IonButton onClick={handleOK}>OK</IonButton>
                    </IonCol>
                  </IonRow> 
                }
                { messageSent &&
                  <IonRow>
                    <IonCol>
                      <IonItem  color="success">
                        <IonLabel class="ion-text-wrap" >Your message has been sent.</IonLabel>
                      </IonItem>
                      
                        <IonButton onClick={handleOK} >OK</IonButton>
                     
                    </IonCol>
                </IonRow>
                }
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
export default DirectoryContactMe;
import { IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonMenuButton, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { trailSignOutline } from "ionicons/icons";
import React from "react";
import './HomePage.css';
import CPDDisplayRecords from "../CPD/CPDDisplayRecords";
import { useAuth } from "../contexts/authContext";

const CPDPage: React.FC = () => {
  const { authInfo } = useAuth()!;
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>Lexicon</IonTitle>   
        </IonToolbar>
      </IonHeader>


      <IonContent fullscreen={true} class="ion-padding" color="secondary">
        
        <b>CPD Record</b><br/>

        <CPDDisplayRecords user={authInfo.user} />

      

        <p><b>CPD Support Resources</b></p>
        <IonList lines="full" className="backGroundPrimary">    
          <IonItem key="training" button={true} detail={true} href="/docs/training/index.html" color="duckegg">
            <IonIcon icon={trailSignOutline} color="hotcoral"></IonIcon>
            <IonLabel>Training</IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
    );
};
    
export default CPDPage;




// Page generated from markdown docs/pages/


import React from 'react';
import { IonHeader, IonBackButton, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonGrid, IonRow, IonBadge, IonSpinner, IonIcon, IonLabel, IonItem } from '@ionic/react';
import { auth, db, api } from "../firebase";
import { useAuth } from '../contexts/authContext';
import { collection, doc, onSnapshot, setDoc } from 'firebase/firestore';
import { StripeAccount, stripeAccountDefault } from '../data/StripeAccount';
import { format } from 'date-fns';
import { cardOutline, chevronForwardOutline, calendarOutline, informationCircleOutline } from 'ionicons/icons';
import Stripe from 'stripe'

// select prices for customer account 
const StripePrices: React.FC = () => {
  const [prices, setPrices] = React.useState({ prices: [], error: {} });
  const [stripeAccount, setStripeAccount] = React.useState(stripeAccountDefault)
  const [subscriptions, setSubscriptions] = React.useState([]);
  const [isSubLoaded, setIsSubLoaded]  = React.useState(false)
  const [isSubscribing, setIsSubscribing] = React.useState(false)
  const [error,setError] = React.useState('')
  const [message, setMessage] = React.useState({color: '', text: ''});
  const { authInfo} = useAuth()!;

  React.useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setIsSubscribing(false)
      setIsSubLoaded(false)
      setMessage({
        color: 'success',
        text: "Your subscription purchase was successful."}
      )
    }

    if (query.get('canceled')) {
      setIsSubscribing(false)
      setMessage({
        color: 'danger',
        text: "Your subscription purchase was canceled - choose a subscription when you're ready."}
      );
    }
  },[setIsSubscribing]);  
  // get current prices

  React.useEffect(() => {
    const fetchPrices = async () => {
      const prices = await fetch(`${api}prices?id=prod_PgGYQ7mRBsBzNu`).then(r => r.json());
      //const prices = await fetch(`https://prices-dv5jc4unoa-ew.a.run.app?id=prod_PgGYQ7mRBsBzNu`).then(r => r.json());
      //const prices = await fetch(`https://europe-west1-prosupervise-dev-4241e.cloudfunctions.net/prices?id=prod_PgGYQ7mRBsBzNu`).then(r => r.json());
      console.log(prices)
      if (prices.error !== ''){
        setError(`Error getting prices ${prices.error}`)
      }
      setPrices(prices);
    };
    fetchPrices();
  }, [])
  // get stripe account
  React.useEffect(()=>{
    const unSubscribe = onSnapshot(doc(db, "stripeAccount", authInfo.user.customerId), (doc) => {
      if (doc.exists()){
        const account = doc.data() as StripeAccount
        setStripeAccount(account)
        console.log('stripe account', account)
        if (account.status === 'paused'){
          const endDate = format(new Date(account.trial_end * 1000), "MMMM dd, yyyy")
          setMessage({color: 'danger',
          text: `Your free trial ended on ${endDate}. Add a payment method to resume this plan.`})
        }
      }
    });
    return unSubscribe
  },[authInfo.user.customerId])

  // 

  // get subscriptions for current customerId
  React.useEffect(() => {

    const updateAccount = async (subs : []) => {
      try {
        subs.map(async (sub: Stripe.Subscription)=> {
          if (sub.status === 'active' && stripeAccount.status !== 'active' && stripeAccount.customerId !== ''){
            console.log('mismatch ', sub, stripeAccount)
            // get the account object and update
            const accountUpdate = {
              status: sub.status,
              hasTrial: false,
              trial_end: sub.trial_end,
              period: stripeAccount.period,
              livemode: sub.livemode,
              uid: stripeAccount.uid,
              customerId: stripeAccount.customerId,
            };
            //await db.doc(`stripeAccount/${stripeAccount.customerId}`).set(accountUpdate);
            await setDoc(doc(collection(db,'stripeAccount'),stripeAccount.customerId),accountUpdate,{ merge: true })
          }       
        })
      } catch (e){
        console.log("*** update account ", e);
      }

  
    }
    const fetchSubscriptions = async () => {
      auth.currentUser?.getIdToken().then((token) => {
        const args = new URLSearchParams();
        args.append('customerId', authInfo.user.customerId)
        args.append('uid', authInfo.user.uid)
        //fetch(`https://europe-west1-prosupervise-dev-4241e.cloudfunctions.net/app/get-subscriptions?id=${authInfo.user.customerId}`, {
        fetch(`${api}app/get-subscriptions?`+ args.toString(), {
          headers: {
            authorization: `Bearer ${token}`
          }
        })
        .then(response => { 
          return response.json()
        })
        .then(result => {     
          if (result.ok){
            setSubscriptions(result.payload)
            setIsSubLoaded(true)
            console.log('subscriptions', result.payload)
            updateAccount(result.payload)
          }
          else {
            setError(result.error.message)
            console.log(result.error)
          }
        })
      }) 
    }
    if ( typeof authInfo.user.customerId !== 'undefined' && authInfo.user.customerId !== ''){
      fetchSubscriptions();
    }
    else {
      console.log("**** authInfo.user.customerId is undefined or '':",authInfo.user.customerId)
    }
  }, [authInfo.user,stripeAccount]);

  // subscribe to named price
  const subscribe = async (priceId : string) => {
    setIsSubscribing(true)
    const customerId = authInfo.user.customerId
    const args = new URLSearchParams();
    const isTrial = stripeAccount.status !== '' ? 'false' : 'true'
    //const bIsTrial = stripeAccount.status !== '' ? false : true
    args.append('priceId', priceId)
    args.append('customerId', customerId)
    args.append('isTrial', isTrial)
    args.append('uid', authInfo.user.uid)
    args.append('returnUrl',window.location.origin+'/tabs/home/stripeaccount/')
    
    auth.currentUser?.getIdToken().then((token: string) => {
      fetch(`${api}app/create-checkout-session?`+ args.toString(), {
        headers: {
          authorization: `Bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
      })
      .then(response => { 
        return response.json()
      })
      .then(json => {               
        console.log(json) 
        if (json.ok){
          window.location.assign(json.url)
        } else {
          console.log(json.error)
          setError(json.error.message)
        }
      })
    }) 
  }

  // active subscription are active, part of trial or paused
  const bActiveSubscription = (status: string) => {
    return ( 
      status === 'active' || 
      status === 'trialing' ||
      status === 'paused'
    )
  }

  // inactive not not active and not trail and not paused
  const bInactiveSubscription = (status: string) => {
    return ( 
      status !== 'active' && 
      status !== 'trialing' &&
      status !== 'paused'
    )
  }
  
  return (
    <IonPage >
      <IonHeader>
        <IonToolbar color="primary">
            <IonButtons slot="start">
                <IonBackButton defaultHref='/tabs/home/account'></IonBackButton> 
            </IonButtons>
          <IonTitle>Membership Plan</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding" color="secondary"> 
      <p>{ authInfo.user.customerId }</p>
      <p>{ stripeAccount.status }</p>
      
      { message.text !== '' && 
        <IonItem color={message.color}>
          <IonIcon aria-hidden="true" slot="start" icon={cardOutline} ></IonIcon>
          <IonLabel>{message.text}</IonLabel>
        </IonItem>
    }
        
      { bActiveSubscription(stripeAccount.status) && isSubLoaded &&
        <div>
        <h1>Current Plan</h1>
        
        { subscriptions
            .filter((subscription : Stripe.Subscription )=> (bActiveSubscription(subscription.status)))
            .map((subscription2 : Stripe.Subscription) => {
              return( <DisplaySubscription key="currentPlan" subscription={subscription2} plan={subscription2?.plan} /> )
            })
        }
        <IonButton  routerLink="/tabs/home/stripeportal" expand="block" strong={true}><IonIcon aria-hidden="true" slot="start" icon={cardOutline}></IonIcon>Access your payment portal<IonIcon aria-hidden="true" slot="end" icon={chevronForwardOutline}></IonIcon></IonButton>
        
        </div>
      }
      { prices.prices.length > 0 && (stripeAccount.status === '' || stripeAccount.status === 'canceled'  || stripeAccount.status === 'incomplete_expired') && isSubLoaded &&
      
      <div className="price-list">
        <h1>Select a Plan</h1>
        <IonGrid>
        <IonRow>
          
        { prices.prices.filter((price : Stripe.Price) => price.active)
          .map((price : Stripe.Price,index ) => {
          //console.log(price)
          const product : Stripe.Product = price.product as Stripe.Product
          const recurring : Stripe.Price.Recurring| null = price.recurring
          const interval = (recurring?.interval) ? recurring.interval : ""
          return (
            <IonCol key={`${index}price`} size="6" size-lg="5" size-sm="5" size-xs="12">
            <IonCard key={`${price.id}price`} color="almostwhite">
              <IonCardHeader>
              <IonCardTitle>{product.name}{ !price.livemode && <IonBadge color="warning">Test mode</IonBadge> }</IonCardTitle>
                <DisplayImage images={product.images} /> 
                <IonCardSubtitle>{product.description} </IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>
                <ul style={{margin: '0px'}}>{ product.features.map((feature, index) => {return(<li  key={index}>{feature.name}</li>)})}</ul>
                <FormatPrice currency={price.currency} amount={price.unit_amount} period={interval} />
                { stripeAccount.status !== 'canceled' && <span><IonBadge color="success">Free 30 day Trial</IonBadge></span>}
                <IonButton expand="block" disabled={isSubscribing} onClick={() => subscribe(price.id)}>
                { isSubscribing && <span>Processing ....<IonSpinner></IonSpinner></span>}
                { !isSubscribing && <span>Subscribe</span>}
                </IonButton>
              </IonCardContent>
            </IonCard>
            </IonCol>
          )
        })}
        </IonRow>
        </IonGrid>
      </div>
      }
      { !prices.prices.length && <div><IonSpinner></IonSpinner>Loading prices ...</div>}
      { error !== "" && <IonItem key='error' color="danger">Error : {error}</IonItem>  }
      
      { isSubLoaded && subscriptions.length > 0 &&
        <>
        <h1>Past Subscriptions</h1>
        { !subscriptions.length && <p><IonSpinner></IonSpinner>Loading past subscriptions ...</p>}
        <div className="price-list">
        <IonGrid>
        <IonRow>
          
        { subscriptions.filter((subscription : Stripe.Subscription )=> (bInactiveSubscription(subscription.status)))
          .map((subscription2 : Stripe.Subscription,index : number ) => {
          //console.log(subscription2)
          return (
            <IonCol key={index} size="6" size-lg="4" size-sm="6" size-xs="12">         
              <DisplaySubscription subscription={subscription2} plan={subscription2?.plan} />
            </IonCol>
          )
        })}
        </IonRow>
        </IonGrid>
        </div>
      </>
      }
      {!isSubLoaded && 
        <div><IonSpinner></IonSpinner>Loading subscriptions ...</div>
      }
      </IonContent>

    </IonPage>
  );
};   

interface FormatPriceProps {
  currency : string
  amount: number|null
  period : string| null
}

const FormatPrice: React.FC<FormatPriceProps> = ({currency, amount, period}) => {
    let price = "NaN"
    if (amount === null || period === null)
      return(<></>)
    switch (currency){
      case 'gbp' : price = `£${amount/100}`; break;
      case 'eur' : price = `€${amount/100}`; break;
      default: price = `${currency}${amount/100}`; break;
    }
    return (
      <div style={{boxSizing: 'border-box', display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
      <span style = {{fontSize: '36px', fontWeight: 700, color: '#045167', marginRight: '4px'}}>{price}</span>
      <span style = {{fontSize: '13px', lineHeight: '13px'}}>per<br/>{period}</span>
      </div>
    )
}



interface DisplayImageProps { images: string[]}

const DisplayImage: React.FC<DisplayImageProps> = ({images}) => {
  if (typeof images !== "undefined" && images.length > 0 ){
    return (   
      <img style={{borderRadius: '5px', backgroundColor: 'var(--ion-color-tertiary)', padding: '10px'}}alt="Product image" src={images[0]} />
    )
  }
}

interface DisplayPlanProps {
  subscription : Stripe.Subscription
  plan : Stripe.Plan
}
  
const displayStatusBadge = (color: string, status: string) => {
  return(
    <span key={status}>
      <IonBadge color={color} >
        <IonIcon aria-hidden="true" icon={cardOutline}></IonIcon>&nbsp;{status}
      </IonBadge>
    </span>
  )
}

const displayNextPayment = (description: string, date:number) => {
  return (
    <span key="date">&nbsp;
      <IonBadge>
        <IonIcon aria-hidden="true" icon={calendarOutline}></IonIcon>
        &nbsp;{description} { format(new Date(date * 1000), "d MMM, yy")}
      </IonBadge>
    </span>
  )
}

const displayCancelMessage = (date: number) => {
  return (
    <IonItem key="cancelMessage" color='hotcoral' >
      <IonIcon aria-hidden="true" color="almostwhite" icon={informationCircleOutline} slot="start"></IonIcon>
      <IonLabel><strong>Your Prosupervise Subscription has been cancelled.</strong><br/>
      Your access to premium features will end on { format(new Date(date * 1000), "MMMM dd, yyyy")}.  Not ready to lose access to premium features?<br/>
      Access your payment portal to restart your subscription and resume billing.   
      </IonLabel>
    </IonItem>

  )
}

const displayPastDueMessage = () => {
  return (
    <IonItem key="cancelMessage" color='danger' >
      <IonIcon aria-hidden="true" color="almostwhite" icon={informationCircleOutline} slot="start"></IonIcon>
      <IonLabel><strong>Your Prosupervise Subscription payment has failed.</strong><br/>
      Your access to premium features will end and your subscription will be cancelled.  Not ready to lose access to premium features?<br/>
      Access your payment portal to restart your subscription and resume billing.   
      </IonLabel>
    </IonItem>

  )
}

const DisplaySubscription: React.FC<DisplayPlanProps> = ({subscription, plan}) => {
  const product = (plan?.product ) ? plan.product as Stripe.Product : null
  const trial_end = subscription.trial_end ? subscription.trial_end : 0
  const cancel_at = subscription.cancel_at ? subscription.cancel_at : 0
  return (
    <IonCard key={subscription.id} color="almostwhite">
      <IonCardHeader>
        <IonCardTitle>{product?.name}{ !subscription.livemode && <IonBadge color="warning">Test mode</IonBadge> }</IonCardTitle>
        <IonCardSubtitle>{product?.description} </IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent>
        <FormatPrice currency={plan.currency} amount={plan.amount} period={plan.interval} />
        { subscription.status === 'canceled' && displayStatusBadge("warning","Cancelled")}
        { subscription.status === 'incomplete_expired' && displayStatusBadge("warning","Expired")}
        { subscription.status === 'past_due' && displayStatusBadge("danger","Payment Failed")}
        { subscription.status === 'active' && displayStatusBadge('success', 'Active')}
        { subscription.status === 'trialing' && displayStatusBadge('success', 'Free Trial')}
        { subscription.status === 'paused' && displayStatusBadge('danger', 'Paused')}
        { (subscription.status === 'trialing') &&  displayNextPayment("Free trial will end on", trial_end)}
        { (subscription.status === 'canceled' || subscription.status === 'incomplete_expired') && displayNextPayment('Subscription ended on',subscription.current_period_end )}
        { (subscription.status === 'active') &&  displayNextPayment('Next payment due on', subscription.current_period_end)}
        { subscription.status === 'active' && subscription.cancel_at_period_end && displayCancelMessage(cancel_at)}
        { subscription.status === 'past_due' && subscription.cancel_at_period_end && displayPastDueMessage()}
      </IonCardContent>
    </IonCard>
  )
}

export default StripePrices;
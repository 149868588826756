import React, { useState } from 'react';

import { Link, Redirect, useParams, useHistory} from "react-router-dom";
import { 
    IonContent, 
    IonPage, 
    IonItem,
    IonButton,
    IonInput,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonGrid,
    IonRow,
    IonCol,
    IonSpinner,
    IonLabel,
    IonCheckbox,
    IonNote,
    IonButtons,
    IonHeader,
    IonMenuButton,
    IonTitle,
    IonToolbar
} from '@ionic/react';




import "./LoginPage.css";
import { userDefault  } from '../data/user';

// react hook form 
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
//import { useAuthState, useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";

// firebase auth
import { auth, storage } from "../firebase";
import {  UserCredential, createUserWithEmailAndPassword, sendEmailVerification  } from 'firebase/auth';
import { useAuth } from '../contexts/authContext'; 
import { FirebaseError } from 'firebase/app';
import { ref, uploadString, getDownloadURL } from 'firebase/storage';
//import {ref, getDownloadURL, uploadBytes } from "firebase/storage"
//import { ref, uploadString, getDownloadURL } from 'firebase/storage';


const noAccountWithPassword = "Sorry, please try again with a different email address.";

const RegisterPage: React.FC = () => {
    
    interface FormData {
        email : string
        password: string
        name : string
        agreement: boolean
        supervisor: boolean
    }

    const { registerUser } = useAuth()!;
    const { isSupervisor } = useParams() as { isSupervisor : string }
    const supervisor = isSupervisor === "true"
    const { handleSubmit, control, formState } = useForm<FormData>();
    const { isSubmitting } = formState;
    const { errors } = formState;
    const [isError, setIsError] = useState(false)
    const [myError, setMyError] = useState('')
    const { authInfo } = useAuth()!;
    const history = useHistory();
 
    //console.log('isSupervisor:', isSupervisor, supervisor)

    // download url and pass image64 string via call back
    function toDataURL(url:string, callback: (url: string|null|ArrayBuffer)=>void) {
        const xhr = new XMLHttpRequest();
        xhr.onload = function() {
          const reader = new FileReader();
          reader.onloadend = function() {
            callback(reader.result);
          }
          reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    // create avatar based on name and register user details
    const createAvatarAndRegister = async (data: FormData, uid: string ) => {
        const args = new URLSearchParams();
        args.append("size",'128')
        args.append("name", data.name)

        toDataURL('https://ui-avatars.com/api/?'+args.toString(), function(dataUrl) {
            const storageRef = ref(storage, `/avatar/${uid}.png`)
            if (typeof dataUrl === "string"){
                uploadString(storageRef, dataUrl, 'data_url')  // upload avatar to firestore storage
                .then( () => {
                    return getDownloadURL(storageRef) // get avatar url
                })
                .then ( url => {
                    const registerUserDetails = {
                        uid : uid,
                        fullName : data.name,
                        avatar: url,
                        email : data.email,
                        registerAsSupervisor: supervisor,
                        authProvider: 'local'
                    };
                    registerUser( {...userDefault, ...registerUserDetails} ); // register user
                })
                .catch(error => {
                    console.log('error',error)
                    if (error instanceof FirebaseError){
                        setMyError(error.code)
                    }

                });
            }
            else {
                setMyError('Avatar image create failed')
            }
        })
    }

const handleRegister = async (data: FormData) => {

  //const avatar = "https://robohash.org/"+data.name+".png?set=set5";
      //const avatar = "https://ui-avatars.com/api/?size=128&name="+data.name;

  
  createUserWithEmailAndPassword(auth, data.email, data.password)
  .then((userCredential : UserCredential) => {
    // send verification mail.
    sendEmailVerification(userCredential.user);
    //auth.signOut();
    return userCredential.user.uid
  })
  .then(uid =>{
    // create avatar file and user in firebase database
    createAvatarAndRegister(data, uid)
  })
  .then(()=>{
    history.push('/verify-email')
  })
  .catch(error => {
      if (error instanceof FirebaseError){
          setIsError(true);
          if (error.code === 'auth/email-already-in-use' ||
              error.code === 'auth/user-not-found' )
              setMyError(noAccountWithPassword);
          else
              setMyError(error.code);
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode, errorMessage)
      }
  })
}
/*
const [presentAlert] = useIonAlert();

const alertNotImplemented = () => {
  presentAlert({
    header: 'Alert',
    subHeader: 'Information',
    message: 'Not yet implemented!',
    buttons: ['OK'],
  });
}
*/
if (authInfo.loggedIn){
  
  return (<Redirect to={ {pathname:'/tabs/home/'}} />)  

}
else
  return (
<IonPage>
    <IonHeader> 
        <IonToolbar color="primary">
            <IonButtons slot="start">
                <IonMenuButton ></IonMenuButton>
            </IonButtons>
            <IonTitle>Register</IonTitle>
        </IonToolbar>
    </IonHeader>
    <IonContent>
        <IonGrid className="gridColorPurple" >
            <IonRow class="ion-align-items-center center-height">
                <IonCol class="no-padding">
                    <IonCard className="login margin-5px">
                        <IonCardHeader>
                            <IonCardTitle>Join ProSupervise</IonCardTitle>
                        </IonCardHeader>
                        <form onSubmit={handleSubmit(handleRegister)}>
                        <IonGrid class="background-white">
                            <IonRow>
                                <IonCol>
                                    
                                    <Controller
                                        render={({ field: { onChange } }) => (
                                        <IonInput labelPlacement="stacked" className="customInput" fill="outline" label="First and Last Name" name="name" type="text" placeholder="Enter First and Last Name" onIonChange={onChange} />
                                        )}
                                        control={control}
                                        name="name"
                                        rules={{
                                        required: "This is a required field",                                     
                                        }}
                                    />
                                
                                    <ErrorMessage
                                        errors={errors}
                                        name="name"
                                        as={<div style={{ color: "red" }} />}
                                    />
                                    
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    
                                    <Controller
                                        render={({ field: { onChange, value }}) => (
                                        <IonInput labelPlacement="stacked" fill="outline" value={value} label="Email address" name="email" type="email" placeholder="Enter Email address" onIonChange={onChange} />
                                        )}
                                        control={control}
                                        name="email"
                                        rules={{
                                        required: "This is a required field",
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            message: "invalid email address"
                                        }
                                        }}
                                    />
                                
                                    <ErrorMessage
                                        errors={errors}
                                        name="email"
                                        as={<div style={{ color: "red" }} />}
                                    />
                                
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                
                                    <Controller
                                        render={({ field: { onChange, value } }) => (
                                        <IonInput labelPlacement="stacked" fill="outline" value={value} label="Password" name="password" type="password" placeholder="Enter password" onIonChange={onChange} />
                                        )}
                                        control={control}
                                        name="password"
                                        rules={{
                                        required: "This is a required field",
                                        }}
                                    />
                                
                                    <ErrorMessage
                                        errors={errors}
                                        name="password"
                                        as={<div style={{ color: "red" }} />}
                                    />
                                    
                                </IonCol>
                            </IonRow>
                            
                            <IonRow>
                                <IonCol>
                                    <Controller
                                        render={({ field: { onChange, value } }) => (
                                            <><IonCheckbox checked={value} labelPlacement="end" justify='start' name="agreement" onIonChange={onChange} ><IonLabel class="ion-text-wrap">I agree to the Prosupervise&nbsp;</IonLabel>
                                            </IonCheckbox>
                                            <IonNote class="ion-text-wrap ionLabelClick"><Link to='/terms'>Terms of Service</Link> and <Link to="/privacy">Privacy Policy</Link>.</IonNote></>
                                            )}
                                        control={control}
                                        name="agreement"
                                        rules={{
                                        required: "This is a required field",
                                        }}
                                    />                                   
                                    <ErrorMessage
                                        errors={errors}
                                        name="agreement"
                                        as={<div style={{ color: "red" }} />}
                                    />                                 
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonButton expand="block" disabled={isSubmitting} size="default" type="submit">{ isSubmitting && <IonSpinner></IonSpinner>}Join with Email</IonButton>  
                                </IonCol>
                            </IonRow> 
                            { isError && 
                            
                            <IonRow>
                                <IonCol>
                                    <IonItem  color="danger">
                                        <IonLabel class="ion-text-wrap" >{myError}</IonLabel>
                                    </IonItem>
                                </IonCol>
                            </IonRow> 
                            }
                            <IonRow>
                                <IonCol>
                                    <hr />
                                </IonCol>
                            </IonRow>         
                            <IonRow class="ion-justify-content-center">
                                <IonCol >                        
                                    <center>
                                   <p>Already have an account? <Link to="/login">Login</Link> </p>
                                    </center>
                                </IonCol>
                            </IonRow>   
                        </IonGrid>
                        </form>
                    </IonCard>
                </IonCol>
            </IonRow>
        </IonGrid>
    </IonContent>
</IonPage>
  );
};

export default RegisterPage;
import { IonAccordion, IonAccordionGroup, IonBadge, IonButton, IonButtons, IonChip, IonContent, IonHeader, IonIcon, IonItem, IonItemDivider, IonItemGroup, IonLabel, IonList, IonPage, IonSpinner, IonTitle, IonToolbar, useIonActionSheet, useIonModal, useIonToast } from "@ionic/react"
import { call, close, calendarOutline, refreshCircleOutline, calendarClearOutline, chatbubbleEllipsesOutline } from "ionicons/icons"
import React, {  useEffect, useState } from 'react';


//import { Timestamp, query, collection, doc, deleteDoc, where, setDoc, DocumentData } from "firebase/firestore";
//import {  db} from "../firebase";

import { User } from '../data/user'
import { AppointmentAudit, capitalizeFirstLetter, displayDate, dbChangeAppointment, dateStamp, StatusBadge, dbGetAppointment, BannerBadge } from './AppointmentFunctions'
import './PracticeCalendar.css'
import { Appointment } from "./AppointmentClasses";
import { onSnapshot, doc, collection, query, where  } from "firebase/firestore";
import { db } from "../firebase";
import {useCollection} from 'react-firebase-hooks/firestore';
import { AddToCalendar } from "./AddToCalendar";
import { PracticeAppointmentModal} from './PracticeCalendar'
import sendMail, { sendAppointmentMail } from "../mail/SendMail";
//const sendMail = require("../mail/SendMail.js");

export interface SuperviseeCalendarProps {
  hide: boolean
  user : User
}
  
const SuperviseeCalendar : React.FC<SuperviseeCalendarProps> = ({ hide, user }) => {

  // date selection
  const dateToday = new Date()
  dateToday.setHours(0,0,0,0)
  const today = dateToday.getTime();
 

  // used for dialog box for an appointment
  const [currentAppointment, setCurrentAppointment] = useState<Appointment>()
  const [allDates, setAllDates] = useState(false)

  // todays appointment
  // 
  //const [appointmentDiaryToday, setAppointmentDiaryToday] = useState<Appointment[]>([]);

  // database listener for grabbing appointment changes
  const queryToMake = query(
    collection(db, "appointmentsList"), 
        where( "patientUid","==", user.uid)
    
  )

  const [value, loading, error] = useCollection(
      queryToMake,{ snapshotListenOptions: { includeMetadataChanges: true }});


  // flip all dates
  const toggleAllDates = () => {
    setAllDates(!allDates)
  }
  // dialog box dismiss function
  const handlePracticeDialogDismiss = () => {
    dismissPracticeModal()
  }

  const handlePatientDialogDismiss = () => {
    dismissPatientModal()
  }

  const [presentPatientModal, dismissPatientModal] = useIonModal(PatientAppointmentModal, {
    myAppointment: { ...currentAppointment },
    consultantUid : currentAppointment?.consultantUid ,
    user: user,
    onDismiss: handlePatientDialogDismiss,
  })

  // create appointment dialog box and present and dismiss functions, passing in appointment item
  const [presentPracticeModal, dismissPracticeModal] = useIonModal(PracticeAppointmentModal, {
    myAppointment: { ...currentAppointment },
    consultant : user,
    onDismiss: handlePracticeDialogDismiss,
  })

  // handler for editing dialoge box
  const onEdithandler = (myAppointment: Appointment) => {
    
    if (user.uid === myAppointment.consultantUid){
      // practice modal
      myAppointment.consultantName = user.fullName
      myAppointment.location = user.myLocation
      setCurrentAppointment(myAppointment)
      presentPracticeModal()
    }
    else if (user.uid === myAppointment.patientUid || '' === myAppointment.patientUid){
      // patient modal - either owned by me or free slot for booking
      setCurrentAppointment(myAppointment)
      myAppointment.name = user.fullName
      myAppointment.location = user.myLocation
      presentPatientModal()
    }
    // last case where consultantUid = consultant.uid should not happen
  }

  // data db loading error
  if (error){
    // db error report it
    console.log(error);
    return (<IonContent>DB Error { error.message }</IonContent>)
  }
  // loading from db show wait
  if (loading) return (<IonSpinner></IonSpinner>)
  // data loaded return the list
  if (value) {
    // filter the appointment for today - returns list of hour-> appointment 
    const dates: number[] = [];
    const appointments = new  Array<Appointment> ;
    value.docs.map((doc,) => {
        const appointment = doc.data() as Appointment
        appointments.push(appointment)
        
        if (dates.indexOf(appointment.start) < 0 && (!allDates || appointment.start >= today)){
            dates.push(appointment.start)
        }
    })

    // sort appointment dates and collapse to days
    const sortedDates = dates.sort();
    const uniqueDates: string[] = []
    sortedDates.forEach((date)=>{
        const dateString = displayDate(date, 'MMM dd, yyyy')
        if (uniqueDates.indexOf(dateString) < 0){
            uniqueDates.push(dateString)
        }
    })

    return (
    <IonContent color="duckegg" style={hide ? { display: 'none' } : {}}>
      <IonHeader>
      <IonToolbar color="duckegg">
        <IonButtons slot="end">
          <IonButton onClick={toggleAllDates} color="primary" fill={allDates ? "outline" : "solid"}><IonIcon slot="start" color='hotcoral' icon={calendarOutline}></IonIcon>All Dates</IonButton>
          <IonButton onClick={toggleAllDates} color="primary" fill={allDates ? "solid" : "outline"}><IonIcon slot="start"  icon={calendarOutline} color='hotcoral'></IonIcon> Future Dates</IonButton>
        </IonButtons>
      </IonToolbar> 
      </IonHeader>
        {  
           uniqueDates.map((date) => {
                //const currentDate = displayDate(date, 'MMM dd, yyyy')
                return (
                <IonItemGroup key={date}>
                    <IonItemDivider color="duckegg" >
                        <IonLabel><b>{date}</b></IonLabel>
                    </IonItemDivider>  
                    {
                        appointments.map((appointment) => {
                            const currentDate = displayDate(appointment.start, 'MMM dd, yyyy')
                            if (date === currentDate){
                                return (<SuperviseeTableItem key={appointment.start} appointment={appointment} uid={user.uid} onEdithandler={onEdithandler} />)
                            }
                        })
                    }                   
                </IonItemGroup>
                )
            })
        }
    </IonContent>
  )}
}

  interface SuperviseeTableItemProps { 
    appointment: Appointment ;
    uid : string
    onEdithandler: (myAppointment: Appointment) => void 
  }

  type Status = {
    status : string
    message : string
    class : string 
    color : string
    disabled : boolean
  }

  type StatusList = {
    [ key : string] : Status
  }
  const statusMsg : StatusList  = {
    'available': { status: "Available", message: "Appointment slot available for booking.", class: "", disabled: false, color:"secondary"},
    'reserved': { status: "Reserved for appointment", message: "Slot is reserved by you to take bookings. Clear reservation to make this appointment.", class: "secondaryHatched", disabled: true, color:''},
    'nobooking': { status: "No appointments available", message: "No appointment slots are available for this hour.",class: "secondaryHatched", disabled: true, color:''},
    'booked': {status: "Booked Appointment", message: "", class: "secondaryBooked", disabled:false, color:''}
  }


  export const SuperviseeTableItem: React.FC<SuperviseeTableItemProps> = ({appointment, uid, onEdithandler}) => {
    
    // set appointment status to block if appoint is null, ie. space not reserved
    
    let myAppointmentStatus = 'available'
    if (appointment !== null){
      myAppointmentStatus = appointment.status
    }


    // workout the booking state
    let messageState : string = ''
    switch(appointment.status){
        case "free" : 
            messageState = (myAppointmentStatus === 'available' ? "available" : "reserved");
            break;
        case "blocked":
            messageState = 'nobooking'
            break;
        default:
            messageState = 'booked'
    }

   let bookingStatusMsg = statusMsg[messageState]
  // console.log('===========', appointment, messageState, myAppointmentStatus,uid)
   let bookedStatusMsg: Status = { status: 'Private', message: 'Not for me'+appointment.consultantName+'.', class:'secondaryHatched', disabled: true, color:''}

    if (messageState === 'booked' && uid === appointment?.patientUid){
        // booked and appointment match - say so and allow access
        const msg = 'Delivered by '+appointment.consultantName+ ' (to you)'
        bookedStatusMsg = { status: appointment.status, message: msg, class:'', disabled: false, color:'secondary'}
    }
    else if (appointment?.patientUid !== ''){
      // some one elses appointment
      console.log('-----------------arggg', appointment)
      messageState = 'nobooking'
      bookingStatusMsg = statusMsg[messageState]
    }

    
    return (
      <IonItem key={appointment.start} button={true} disabled={bookingStatusMsg.disabled}  color={bookingStatusMsg.color} className={bookingStatusMsg.class}    detail={true} lines="full" onClick={()=>onEdithandler(appointment)}>
        <IonLabel className={'schedule-'+appointment.status.toLowerCase()+ " ion-text-wrap"}>
            <StatusBadge status={bookingStatusMsg.status} /><br/>{bookingStatusMsg.message}
            { messageState === 'booked' && <div><StatusBadge status={bookedStatusMsg.status} /><br/>{bookedStatusMsg.message} </div>}       
        </IonLabel>
        <IonLabel slot="end">
            <p >{displayDate(appointment.start, 'hh:mm aa')}</p>
            <p className="ion-text-right">{displayDate(appointment.end, 'hh:mm aa')}</p>
        </IonLabel>
      </IonItem>
    )
  };

//-------------------------------------------------------------------------------------------------------

type Props = {
  myAppointment: Appointment
  consultantUid : string
  user : User
  onDismiss: () => void
}
export const PatientAppointmentModal: React.FC<Props> = ({ consultantUid, myAppointment, user, onDismiss }) => {
  //console.log('my app', myAppointment)
  const [appointment,setAppointment] = useState<Appointment>(myAppointment)
  const [loading,setLoading] = useState(true)
  const [present] = useIonActionSheet();
  const [showToast] = useIonToast();
  const startHour = displayDate(appointment.start, 'hh:mm bbb')
  const endHour = displayDate(appointment.end, 'hh:mm bbb')
  const date = displayDate(appointment.start, 'MMM dd, yyyy')
  
  //console.log('appointment', appointment,myAppointment)

  // set up listener for appointments, when consultantUid changes
  useEffect(() => {
    const appointmentId = consultantUid+myAppointment.start.toString();
    //console.log('useEffect key', appointmentId)
    const unsubscribe  = onSnapshot(doc(db, "appointmentsList", appointmentId), (doc) => {
      if (doc.exists() ){
        // update state
        const thisAppointment = doc.data() as Appointment;
        //console.log("DB update:",thisAppointment)
        setAppointment(thisAppointment)
      }
      else {
        // new appointment set state
        //console.log('use effect new appointment', myAppointment)
        myAppointment.audit = []
        myAppointment.status = 'blocked'
        setAppointment(myAppointment)
      }
      setLoading(false)
    });

    // detach listener
    return unsubscribe
    
}, [consultantUid, myAppointment.start,myAppointment,setAppointment,loading])


 
  // dialog box to confirm booked appointment
  const makeAppointmentSlot = async (start: string, end: string, date: string, user: User, myAppointment: Appointment, consultantName : string) => {
    
    // need to check that there is no clash with this appointment in users diary
    const userAppointmentSlotKey =  user.uid+appointment.start.toString();
    
    const doc = await dbGetAppointment(userAppointmentSlotKey)
    if (typeof doc !== 'undefined' && doc.exists()){
        // existing appointment with overwrite existing slot if free or abandon
        //console.log('>>>>>>',doc.data(), userAppointmentSlotKey)
        const appointment = doc.data()
        let message = '';
        if (appointment.status === 'free'){
            message = 'Oops, this appointment clashes with a free slot in your calendar, please delete and try again'
        } else {
            message = 'Oops, with appointment clashes with an appointment with '+appointment.name+ ' please try again'
        }

        showToast({
            message: message,
            color: 'warning',
            buttons: [{ icon: close }],
        });
        return
    }

    present({
      header: 'Make appointment with '+consultantName+' from '+start+ ' to '+end+' on '+date+' ?',
      buttons: [
        { 
          text: 'Book Appointment',
          role: 'destructive',
          data: {
            action: 'confirm'
          }
        },
        { 
          text: 'Cancel',
          role: 'cancel',
          data: {
            action: 'cancel'
          }
        },

      ],
      onDidDismiss: async ({ detail  }) => {
        if (detail.data?.action === 'confirm'){

          // update appointment status
          myAppointment.name = user.fullName;
          myAppointment.patientUid = user.uid;
          myAppointment.patientEmail = user.email;
          myAppointment.patientPhone = user.cell;
          myAppointment.treatment = 'Supervision';
          myAppointment.status = 'provisional';
          myAppointment.audit.push(dateStamp()+" Provisional booking made.")
          // update db
          dbChangeAppointment(myAppointment);
          //console.log('Send update:', myAppointment)
          console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.sending email ")
          sendMail("test message","jeff@riberlodge.biz","testing","testing",3)
        }
      },
    })
  }

  const updateContactDetails = async (myAppointment: Appointment, user: User) => {
    // update appointment with latest user info
    // update appointment status
    myAppointment.patientEmail = user.email;
    myAppointment.patientPhone = user.phone
    myAppointment.name = user.fullName
    myAppointment.audit.push(dateStamp()+" Updated appointment contact details.")
    // update db
    dbChangeAppointment(myAppointment);

    // send email
    const appointmentDate =  displayDate(appointment.start, 'EEEE, MMM dd, yyyy')+ ' from ' +
        displayDate(appointment.start, 'hh:mm bbb') + ' to '+
        displayDate(appointment.end, 'hh:mm bbb') 
    
    sendAppointmentMail("Jeff Parker","jeff@riberlodge.biz","Prosupervise Provisional Appointment","Provisonal Appointment", appointmentDate, "This booking will now be confirmed by supervisor"+appointment.consultantName, 3)
    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.sending email ")
          
    showToast({
      message: "Your contact details have been refreshed.",
      color: 'success',
      duration: 3000,
      buttons: [{ icon: close }],
    })
  }
/*
  // appointment slot is free, delete appointment slot
  const deleteAppointmentSlot = async (start: string, end: string, date: string, user: User, myAppointment: Appointment) => {
    console.log('appointment:',myAppointment)

    present({
      header: 'Clear appointment slot from '+start+ ' to '+end+' on '+date+' available to book?',
      buttons: [
        { 
          text: 'Clear appointment slot',
          role: 'destructive',
          data: {
            action: 'confirm'
          }
        },
        { 
          text: 'Cancel',
          role: 'cancel',
          data: {
            action: 'cancel'
          }
        },

      ],
      onDidDismiss: async ({ detail  }) => {
        if (detail.data?.action === 'confirm'){

          await dbDeleteAppointment(myAppointment);
          setLoading(true) // for refresh
        }
      },
    })
  }
  */    
  const sendMsgLink = '/tabs/chats/'+appointment.patientUid+'/friend/'+appointment.consultantUid
  return (
    <IonPage>
    <IonHeader>
      <IonToolbar color="primary">
      <IonButtons slot="end">
          <IonButton onClick={onDismiss}>Close</IonButton>
        </IonButtons>
        <IonTitle>Your Appointment</IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent>
      { appointment.status === 'blocked' && <BannerBadge color="danger" message="This a blocked booking slot.  Please make it available via the action below." /> }
      { appointment.status === 'free' && <IonChip color="success">{ "This slot can be booked for "+user.fullName+ ". Please book via action below"}</IonChip>}
      <IonAccordionGroup multiple={true} value="first">
      <IonAccordion value="first">
        <IonItem slot="header" color="light">
          <IonLabel>Booking Info <IonBadge class={'status-'+appointment.status.toLowerCase()} >{ appointment.status === "free" ? "Available" : capitalizeFirstLetter(appointment.status) }</IonBadge></IonLabel>
        </IonItem>
        <div className="ion-padding" slot="content">
          <b>{ appointment.name !== '' ? appointment.name : "No one booked" }</b><br/>
          <IonIcon slot="icon-only" icon={call} />
          { appointment.patientPhone !== '' ? appointment.patientPhone : "No phone number." } <br/>
          { appointment.patientEmail !== '' ? appointment.patientEmail : "No email." } <br/>
          <hr />
          { displayDate(appointment.start, 'EEEE, MMM dd, yyyy')} <br/>
          { displayDate(appointment.start, 'hh:mm bbb')}&nbsp;&mdash;&nbsp;
          { displayDate(appointment.end, 'hh:mm bbb')} <br/>
          { appointment.consultantName !== '' ? "Offered by "+appointment.consultantName : "No consultant yet" } <br/>
          { appointment.location !== '' ? appointment.location : "No location." } <br/>
        </div>
      </IonAccordion>
      <IonAccordion value="second">
        <IonItem slot="header" color="light">
          <IonLabel>History</IonLabel>
        </IonItem >
        <div className="ion-padding" slot="content">
          
          <IonItem className="ion-no-padding" >
            <IonLabel>
              <h3>Audit</h3>
              <div>
              { appointment.audit.length > 0 ? <AppointmentAudit audit={appointment.audit} /> : "No audit log available."}
              </div>
            </IonLabel>
          </IonItem>
        </div>
      </IonAccordion>
      <IonAccordion value="third">
        <IonItem slot="header" color="light">
          <IonLabel>Billing Info</IonLabel>
        </IonItem>
        <div className="ion-padding" slot="content">
          Not yet available.
        </div>
      </IonAccordion>
    </IonAccordionGroup>


    <IonList>   
      { appointment.status === 'free' &&
        <IonItem button key="book" detail={true} onClick={(e) => {e.preventDefault();makeAppointmentSlot( startHour, endHour, date, user, appointment, appointment.consultantName )}} >
          <IonIcon icon={calendarClearOutline} color='hotcoral'></IonIcon><IonLabel color="primary">&nbsp;Book an Appointment</IonLabel>
        </IonItem>
      }
      { (appointment.status !== 'free' &&
        appointment.status !== 'blocked') && 
        <IonItem key="updateDetails" detail={true} onClick={(e) => {e.preventDefault();updateContactDetails(appointment, user )}} button>
          <IonIcon icon={refreshCircleOutline} color='hotcoral'></IonIcon>&nbsp;<IonLabel color="primary">Refresh contact details</IonLabel>
        </IonItem>
      }
      { (appointment.status !== 'blocked' &&
         appointment.status !== 'free' ) && 
        <IonItem key="message" detail={true} routerLink={sendMsgLink} button>
        <IonIcon icon={chatbubbleEllipsesOutline} color='hotcoral'></IonIcon><IonLabel color="primary">&nbsp;Send message</IonLabel>
        </IonItem>
      }
      <IonItem detail={true} button key="calendar" onClick={(e) => {e.preventDefault();AddToCalendar(appointment)}} >
        <IonIcon icon={calendarOutline} color='hotcoral'></IonIcon><IonLabel color="primary">&nbsp;Download appointment</IonLabel>
      </IonItem>
    </IonList>
    </IonContent>
  </IonPage>
  )
}


export default SuperviseeCalendar
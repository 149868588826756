import React, { useEffect }  from 'react';
import { IonTabs, IonRouterOutlet, IonTabBar, IonTabButton, IonIcon, IonLabel, IonBadge } from '@ionic/react';
import { Route, Redirect } from 'react-router-dom';
import { homeOutline, peopleOutline, fileTrayStackedOutline, chatbubblesOutline, bookOutline, calendarOutline } from 'ionicons/icons';

import './MainTabs.css';
import HomePage from './HomePage';
import ConnectPage from './ConnectPage';
import PracticePage from './PracticePage';
import MyCalendar from './MyCalendar';
import CPDPage from './CPDPage';
import RedirectToLogin from '../components/RedirectToLogin';
import PostComment from './posts/PostComment';
import ShowUser from './users/ShowUser';
import Chats from '../chat/Chats'
import Chat from '../chat/Chat'
//import Home from './Home'
import { collection, onSnapshot, or, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from "../contexts/authContext";
import Privacy from './Privacy';
import Terms from './Terms';
import CookiePage from './CookiePage';
import AccountPage from './AccountPage';
import StripeRegister from '../stripe/StripeRegister';
import StripePrices2 from '../stripe/StripePrices2';
import StripePortal from '../stripe/StripePortal';
import PaymentTerms from './PaymentTerms';
import ForgotPassword from './ForgotPassword';
import UserAdmin from './UserAdmin';


interface MainTabsProps {
  uid : string
 }

/*
<Route path="/tabs/home" render={() => <HomePage /> } exact={true} />
<Route path="/tabs/connect" render={() =>  <ConnectPage /> } exact={true} />
<Route path="/tabs/practice" render={() => <PracticePage />} exact={true} />
<Route path="/tabs/you" render={() =>  <YouPage />} exact={true} />
*/

const MainTabs: React.FC<MainTabsProps> = ({ uid }) => {

  const [chatNotifications, setChatNotifications] = React.useState(0)

  const getAllMessageThreadsQuery = query(
		collection(db, "chatList"), or (
			where("userId", "==", uid),
			where("friendId", "==", uid)
		)
	)

  const { authInfo } = useAuth()!;

  useEffect(() => {
    const unsubscribe = onSnapshot(getAllMessageThreadsQuery, (querySnapshot) => {
      let notificationCount = 0;
      querySnapshot.forEach((doc) => {
          const chatThread = doc.data()
          let thisCount = chatThread.userMsgIds.length
          if (chatThread.friendId === uid){
            thisCount =chatThread.friendMsgIds.length
          }
          notificationCount = notificationCount + thisCount;
      });
      setChatNotifications(notificationCount)
      updateMessagesBadge(notificationCount) 
    });
    // detach listener
    return unsubscribe
  },[uid, getAllMessageThreadsQuery])

  const updateMessagesBadge = async (unreadCount: number) => {
    // Check if the API is supported.
    if (!navigator.setAppBadge) return;
  
    // Try to set the app badge.
    try {
      await navigator.setAppBadge(unreadCount);
    } catch (e) {
      // The badge is not supported, or the user has prevented the app
      // from setting a badge.
      console.log('count not set badge')
    }
  }

  return  (
    <IonTabs>
      <IonRouterOutlet >
        <Redirect exact path="/tabs" to="/tabs/home" />
        {/*
          Using the render method prop cuts down the number of renders your components will have due to route changes.
          Use the component prop when your component depends on the RouterComponentProps passed in automatically.
        */}
        
        <Route exact path="/tabs/home">
          <HomePage />
        </Route>
        <Route path="/tabs/home/:id" component={PostComment} />
        <Route exact path="/tabs/connect">
          <ConnectPage />
        </Route>
        <Route exact path="/tabs/useradmin">
          <UserAdmin />
        </Route>
        <Route path="/tabs/connect/:uid" component={ShowUser} />
        <Route exact path="/tabs/home/privacy" component={Privacy} />
        <Route exact path="/tabs/home/terms" component={Terms} />
        <Route exact path="/tabs/home/cookie" component={CookiePage} />
        <Route exact path="/tabs/home/forgotpassword" component={ForgotPassword} />
        <Route exact path="/tabs/home/paymentterms" component={PaymentTerms} />
        <Route exact path="/tabs/home/account" component={AccountPage} />
        <Route exact path="/tabs/home/striperegister" component={StripeRegister} />
        <Route exact path="/tabs/home/stripeaccount" component={StripePrices2} />
        <Route exact path="/tabs/home/stripeportal" component={StripePortal} />
        <Route exact path="/tabs/practice" component={PracticePage} />
        <Route exact path="/tabs/cpd/">
          <CPDPage />
        </Route>
        <Route exact path="/tabs/mycalendar" component={MyCalendar} />
        <Route exact path="/tabs/chats">
          <Chats />
        </Route>
        <Route path="/tabs/chats/:userId/:type/:friendId" component={Chat} />
        <Route 
                exact
                path="/tabs/logout" 
                component={RedirectToLogin}
                
              />
        <Route 
                exact
                path="/tabs/you/logout" 
                component={RedirectToLogin}
                
              />
        <Route render={() => <Redirect to='/tabs/home' />} />
      </IonRouterOutlet>
      <IonTabBar slot="bottom">
        <IonTabButton  tab="home" href="/tabs/home">
          <IonIcon icon={homeOutline} />
          <IonLabel >Notices</IonLabel>
        </IonTabButton>
        <IonTabButton tab="connect" href="/tabs/connect">
          <IonIcon icon={peopleOutline} />
          <IonLabel>Directory</IonLabel>
        </IonTabButton>
        <IonTabButton tab="messages" href="/tabs/chats">
          { chatNotifications > 0 && <IonBadge color="danger"> { chatNotifications } </IonBadge> }
          <IonIcon icon={chatbubblesOutline} />
          <IonLabel>Messages</IonLabel>
        </IonTabButton>
        { !authInfo.user.isSupervisor && 
        <IonTabButton tab="practice" href="/tabs/mycalendar">      
          <IonIcon icon={calendarOutline} />
          <IonLabel>My Calendar</IonLabel>
        </IonTabButton>
        }
        { authInfo.user.isSupervisor && 
        <IonTabButton tab="practice" href="/tabs/practice">      
          <IonIcon icon={fileTrayStackedOutline} />
          <IonLabel>My Practice</IonLabel>
        </IonTabButton>
        }
        { authInfo.user.isSupervisor && 
        <IonTabButton tab="cpd" href="/tabs/cpd">
          <IonIcon icon={bookOutline} />
          <IonLabel>Lexicon</IonLabel>
        </IonTabButton>
        }
      </IonTabBar>
    </IonTabs>
  );
};




export default MainTabs;
// Page generated from markdown docs/pages/


import React from 'react';
import { IonHeader, IonBackButton, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonButton, IonBadge, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonSpinner, IonIcon, IonRouterLink, IonCheckbox, IonItem } from '@ionic/react';
import { auth } from "../firebase";
import { useAuth } from '../contexts/authContext';
import { Redirect } from 'react-router-dom';
import Stripe from 'stripe'
import { cardOutline, chevronForwardOutline, homeOutline } from 'ionicons/icons';
import { api } from '../firebase';
import VerifyBanner from '../components/VerifyBanner';
// register a new Stripe customer and then select a subscription as next page 
const StripeRegister: React.FC = () => {

  const [customer, setCustomer] = React.useState('')
  const [product, setProduct] = React.useState<Stripe.Product>();
  const [isSubLoaded, setIsSubLoaded]  = React.useState(false)
  const [isRegistering, setIsRegistering] = React.useState(false)
  const [enableRegister,setEnableRegister] =  React.useState(false)
  const [error,setError ] = React.useState('');
  const { authInfo, saveUser} = useAuth()!;


  React.useEffect(() => {
    const fetchProduct = async () => {
      fetch(`${api}product?id=prod_PgGYQ7mRBsBzNu`)
      .then(response => { 
        return response.json()
      })
      .then(result => {
        if (result.ok){
          setProduct(result.payload as Stripe.Product)
          setIsSubLoaded(true)
          //console.log('product:', result.payload)
        }
        else {
          console.log(result.error)
          setError(result.error.message)
        }
      })
    };
    fetchProduct();
  }, [])

  
  const createCustomer = async () => {
    
    const customerId = authInfo.user.customerId ;
    if (
      typeof customerId === 'undefined' ||
      customerId === ''){
        setIsRegistering(true)
        // no customer id, create a new one
        const args = new URLSearchParams();
        args.append('name', authInfo.user.fullName)
        args.append('email', authInfo.user.email)
        args.append('uid', authInfo.user.uid)
        console.log('register', authInfo.user.email)

        auth.currentUser?.getIdToken().then((token: string) => {
          fetch(`${api}app/create-customer?`+ args.toString(), {
            headers: {
              authorization: `Bearer ${token}`,
              'Content-Type': 'application/x-www-form-urlencoded'
            },
          })
          .then(response => { 
            console.log(response)
            return response.json()
          })
          .then(json => {     
            if (json.ok){
              setCustomer(json.payload.id)
              console.log('created stripe customer', authInfo.user.email, json.payload.id)
              const userUpdate = { ...authInfo.user, ...{customerId: json.payload.id} };
              saveUser(userUpdate)
            } else {
              console.log('create stripe customer error', authInfo.user.email, json.error)
            }
            console.log(json)  
            console.log(json.payload) 
          })
        })
    } else {
      console.log('user already registered', authInfo.user.customerId, authInfo.user.email)
      setCustomer(customerId)
    }
  }

  const clickTerms = () => {
    setEnableRegister(!enableRegister)
  }
  if(customer !== '') {
    return <Redirect to={{pathname: '/tabs/home/stripeaccount'}} />
  }

  return (
    <IonPage >
      <IonHeader>
        <VerifyBanner uid={authInfo.user.uid} email={authInfo.user.email} verified={auth.currentUser?.emailVerified} />
        <IonToolbar color="primary">
            <IonButtons slot="start">
                <IonBackButton defaultHref='/tabs/home/account'></IonBackButton> 
            </IonButtons>
          <IonTitle>Register</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding"> 
      
      
      { authInfo.user.customerId !== '' &&
        <>
        <h2>Account Registered</h2>
        <p>You already have an account.</p>
        <IonButton routerLink='/'>
        <IonIcon aria-hidden="true" color="hotcoral" icon={homeOutline} slot="start"></IonIcon>
          Home
          <IonIcon aria-hidden="true" slot="end" icon={chevronForwardOutline}></IonIcon>
        </IonButton>    
        <IonButton routerLink='/tabs/home/stripeaccount'>
          <IonIcon aria-hidden="true" icon={cardOutline} slot="start"></IonIcon>
          Access Your Membership Plan 
          <IonIcon aria-hidden="true" slot="end" icon={chevronForwardOutline}></IonIcon>
        </IonButton>
        </> 

      }
      { !isSubLoaded && <span><IonSpinner></IonSpinner>Loading Prosupervise Product ...</span>}
      { isSubLoaded && authInfo.user.customerId === '' &&
        <>
        <h2>Register for Prosupervise Premium Service</h2>
        <IonCard key={`${product?.id}product`} color="almostwhite">
          <IonCardHeader>
          <IonCardTitle>{product?.name}{ product?.livemode && <IonBadge color="warning">Test mode</IonBadge> }</IonCardTitle>
            <DisplayImage images={product?.images} /> 
            
          </IonCardHeader>
          <IonCardContent>
            <p>{product?.description}</p>
            <ul style={{margin: '0px'}}>{ product?.features.map((feature, index) => {return(<li  key={index}>{feature.name}</li>)})}</ul>
          
            <p>Register for Prosupervise Premium Service, Free for 30 days and Cancel Anytime.</p>
            <IonCheckbox onIonChange={()=>clickTerms()} labelPlacement="end">By registering, I agree to the <IonRouterLink routerLink="/tabs/home/paymentterms">Payment Terms and Conditions</IonRouterLink>.</IonCheckbox>
            <IonButton expand="block" disabled={isRegistering || !enableRegister } onClick={() => createCustomer()}>
            { isRegistering && <span>Processing ....<IonSpinner></IonSpinner></span>}
            { !isRegistering && <span>Register</span>}
            <IonIcon aria-hidden="true" slot="end" icon={chevronForwardOutline}></IonIcon>
            </IonButton>

          </IonCardContent>
        </IonCard>
        </>
      }
      { error !== '' &&
        <IonItem color="danger">{ error }</IonItem>
      }
      </IonContent>

    </IonPage>
  );
};   


interface DisplayImageProps { images: string[]|undefined}

const DisplayImage: React.FC<DisplayImageProps> = ({images}) => {
  if (typeof images !== "undefined" && images.length > 0 ){
    return (   
      <img style={{width: "50%", borderRadius: '5px', backgroundColor: 'var(--ion-color-tertiary)', padding: '10px'}}alt="Product image" src={images[0]} />
    )
  }
}
export default StripeRegister;
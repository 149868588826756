import { IonContent, IonMenuButton, IonButtons, IonHeader, IonPage, IonTitle, IonToolbar, IonItem, IonSpinner, IonAvatar, IonLabel, IonList, IonSearchbar, IonFooter, IonGrid } from '@ionic/react';
import './ConnectPage.css';
import { db } from '../firebase';
import { collection, } from "firebase/firestore";
import {useCollection} from 'react-firebase-hooks/firestore';
import React from 'react';
import { User } from '../data/user';
import { useAuth } from '../contexts/authContext';
import { Action } from '../forms/components/Action';


const OpenDirectory: React.FC = () => {

  const { authInfo} = useAuth()!;

  

  
  return (
    
    <IonPage className="homePage">
      <IonHeader>
        <IonToolbar color="primary">
        <IonButtons slot="start">
            <IonMenuButton ></IonMenuButton>
          </IonButtons>
          <IonTitle>Directory</IonTitle>
        </IonToolbar>
      </IonHeader>
     
      <IonContent fullscreen={true} color={'secondary'} >
        
        <UserList loggedInUserId={authInfo.user.uid} />
      </IonContent>
      <IonFooter>
          <IonGrid >
            <Action message="Already got an account?" text="Login" link="/login" />
          </IonGrid>
        </IonFooter>
    </IonPage>
  );
};

export default OpenDirectory;


interface Props {
  loggedInUserId : string
}


const UserList: React.FC<Props>= ( {loggedInUserId}) => {

  
  const [value, loading, error] = useCollection(collection(db, "users"),{ snapshotListenOptions: { includeMetadataChanges: true }});
  const [searchQuery, setSearchQuery] = React.useState("")
  
  const handleSearchInput = (ev:Event) => {
    let query = '';
    const target = ev.target as HTMLIonSearchbarElement;
    if (target) query = target.value!.toLowerCase();
    setSearchQuery(query) 
  };
 
  // return true if searchQuery matches user property
  const filterUser = (user: User) => {
    if (user.fullName.toLowerCase().includes(searchQuery))
      return true;
    if (user.occupation.toLowerCase().includes(searchQuery))
      return true;
    if (user.myLocation.toLowerCase().includes(searchQuery))
      return true;

    return false
  }
  
  if (error) { console.log('userlist',error);
    return <p>Error: { error.message } </p> }
  if (value){ 
    
    return(
      <>
      <IonSearchbar showClearButton="focus" color="almostwhite" onIonInput={(ev) => handleSearchInput(ev)} />
      <IonList className="backGroundPrimary">
      { value.docs.map((doc,index)  => { 
        const d = doc.data() as User
        if (d.isDeleted === false && d.isSupervisor){
          let avatar = d?.avatar ;
          if (d?.avatar === ''){
            avatar = "https://ui-avatars.com/api/?size=128&name="+d?.fullName
          }
          let itsMe = '';
          if (loggedInUserId === d.uid)  
            itsMe = ' (You)'
          if (filterUser(d)){
            // user matches search term
            return (         
              <IonItem color="highblue" key={index} routerLink={"/connect/"+d?.uid} button detail={true} lines="full"  >
                <IonAvatar slot="start" >
                    <img alt= "Avatar" src={avatar} />
                </IonAvatar>
                <IonLabel class="ion-text-wrap">
                    <p><b>{ d?.fullName }</b>{itsMe}<br/>{d?.occupation}<br />{d?.myLocation}</p>
                </IonLabel>
              </IonItem>  
            )
          }
          else {
              return <></>
          }
        }
      })}
      </IonList>
      </>
      
    )
  }
  if (loading) {
    
    return (<IonSpinner />)
  }

  return (<></>)

};


// Page generated from markdown docs/pages/


import React from 'react';
import { IonHeader, IonBackButton, IonToolbar, IonTitle, IonContent, IonPage, IonButtons } from '@ionic/react';

const Privacy: React.FC = () => {
    
    return (
        <IonPage >
          <IonHeader>
            <IonToolbar color="primary">
                <IonButtons slot="start">
                    <IonBackButton></IonBackButton>
            
                </IonButtons>
              <IonTitle>Privacy Policy</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding"> 
    
            
    
            <p><strong>Introduction</strong></p>
<p>This privacy notice provides you with details of how we collect and process your personal data through your use of our web application: <a href="https://app.prosupervise.com">https://app.prosupervise.com</a>/</p>
<p>By providing us with your data, you warrant to us that you are over 18 years of age.</p>
<p><strong>Contact Details</strong></p>
<p>Our full details are:</p>
<p>proSupervise Ltd., Moyveela, Oranmore, Co Galway. H91V5Y6</p>
<div class="w3-responsive">
<table class="w3-table w3-border">
<tr><td> <b>Full name of legal entity: </b></td><td>ProSupervise Ltd</td></tr>
<tr><td> <b>Person in charge of Data Protection: &nbsp;</b></td><td>Mary Mernagh</td></tr>
<tr><td> <b>Email address:</b></td><td><a href="mailto:info@prosupervise.com">info@prosupervise.com</a></td></tr>
<tr><td> <b>Postal address:</b></td><td>Moyveela, Oranmore, Co Galway H91V5Y6</td></tr>
</table>
</div>
<p>It is very important that the information we hold about you is accurate and up to date. Please let us know if at any time your personal information changes by emailing us <strong><a href="mailto:info@prosupervise.com">info@prosupervise.com</a></strong> or via the <strong><a href="https://www.prosupervise.com/contact123">contact form</a></strong>.</p>
<p>This privacy statement was last updated on Thu Feb 22 2024.</p>
<p><strong>proSupervise Ltd Privacy Policy</strong></p>
<p>Your privacy is important to us. It is proSupervise Ltd’s policy to respect your privacy and comply with any applicable law and regulation regarding any personal information we may collect about you, including via our app, proSupervise Ltd, and its associated services.
Personal information is any information about you which can be used to identify you. This includes information about you as a person (such as name, address, and date of birth), your devices, payment details, and even information about how you use an app or online service.
In the event our app contains links to third-party sites and services, please be aware that those sites and services have their own privacy policies. After following a link to any third-party content, you should read their posted privacy policy information about how they collect and use personal information. This Privacy Policy does not apply to any of your activities after you leave our app.</p>
<p><strong>Information We Collect</strong></p>
<p>Information we collect falls into one of two categories: 'voluntarily provided' information and 'automatically collected' information.
'Voluntarily provided' information refers to any information you knowingly and actively provide us when using our app and its associated services.
'Automatically collected' information refers to any information automatically sent by your device in the course of accessing our app and its associated services.</p>
<p><strong>Log Data</strong></p>
<p>When you access our servers via our app, we may automatically log the standard data provided by your device. It may include your device's Internet Protocol (IP) address, your device type and version, your activity within the app, time and date, and other details about your usage.</p>
<p>Additionally, when you encounter certain errors while using the app, we automatically collect data about the error and the circumstances surrounding its occurrence. This data may include technical details about your device, what you were trying to do when the error happened, and other technical information relating to the problem. You may or may not receive notice of such errors, even in the moment they occur, that they have occurred, or what the nature of the error is.
Please be aware that while this information may not be personally identifying by itself, it may be possible to combine it with other data to personally identify individual persons.</p>
<p><strong>Personal Information</strong></p>
<p>We may ask for personal information — when you contact us — which may include one or more of the following:</p>
<ul>
<li>Name</li>
<li>Email</li>
<li>Phone/mobile number</li>
<li>Account reference number</li>
<li>Username</li>
</ul>
<p><strong>Sensitive Information</strong></p>
<p>'Sensitive information' or 'special categories of data' is a subset of personal information that is given a higher level of protection. Examples of sensitive information include information relating to your racial or ethnic origin, political opinions, religion, trade union or other professional associations or memberships, philosophical beliefs, sexual orientation, sexual practices or sex life, criminal records, health information, or biometric information.
The types of sensitive information that we may collect about you include:</p>
<ul>
<li>Client notes that you may store</li>
<li>Information you share in a private and confidential platform</li>
</ul>
<p>We will not request or collect sensitive information about you and it is the your responsibility to manage what information you share on the platform.  We will only use or disclose your sensitive information as permitted, required, or authorised by law.</p>
<p><strong>Legitimate Reasons for Processing Your Personal Information</strong></p>
<p>We only collect and use your personal information when we have a legitimate reason for doing so. In which instance we only collect personal information that is reasonably necessary to provide our services to you.</p>
<p><strong>Collection and Use of Information</strong></p>
<p>We may collect personal information from you when you do any of the following on our app:</p>
<ul>
<li>Use a mobile device or web browser to access our content.</li>
<li>Contact us via email, social media, or on any similar technologies.</li>
<li>When you mention us on social media</li>
</ul>
<p>We may combine voluntarily provided and automatically collected personal information with general information or research data we receive from other trusted sources. For example, If you consent to us accessing your social media profiles, we may combine information sourced from those profiles with information received from you directly to provide you with an enhanced experience of our app and services.</p>
<p><strong>Security of Your Personal Information</strong></p>
<p>When we collect and process personal information, and while we retain this information, we will protect it within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification.</p>
<p>Although we will do our best to protect the personal information you provide to us, we advise that no method of electronic transmission or storage is 100% secure and no one can guarantee absolute data security.</p>
<p>You are responsible for selecting any password and its overall security strength, ensuring the security of your own information within the bounds of our services. For example, ensuring you do not make your personal information publicly available via our platform.</p>
<p><strong>How Long We Keep Your Personal Information</strong></p>
<p>During the performance of our contract some sensitive data may be transferred through our operating systems. This information can be both in telephone and text formats. We treat this information with the upmost importance and given the sensitivity and possible legal consequences which could be attached to the retention of this material we operate a specific retention period for this area. We will store this information for seven years after it has been captured upon which time it will then be deleted, unless we are obliged to store this information for legal or insurance purposes.</p>
<p>However, if necessary, we may retain your personal information for our compliance with a legal, accounting, or reporting obligation or for archiving purposes in the public interest, scientific, or historical research purposes or statistical purposes.</p>
<p>proSupervise Ltd will delete all inactive or dormant accounts after 2 years since their last activity. If you decide that you no longer want to have access to the proSupervise Ltd App then it is the purchaser’s/client’s responsibility to remain cognisant of the information that is stored on the proSupervise Ltd App.
All financial information will be kept for seven years to satisfy our legal and accounting obligations.</p>
<p><strong>Lodging A Subject Access Request</strong></p>
<p>If you would like to lodge a Subject Access Request, you will not have to pay a fee to access your personal data as part of the Applicant process (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive, or excessive or refuse to comply with your request in these circumstances. Please contact us at marycmernagh@gmail.comto exercise this right.</p>
<p>We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.</p>
<p>We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made several requests. In this case, we will notify you.  Please contact us at shannen@trustdish.com to exercise this right.</p>
<p>If you are not happy with any aspect of how we collect and use your data, you have the right to complain to the Data Commissioner’s Office <a href="https://www.dataprotection.ie/">https://www.dataprotection.ie/</a>.</p>
<p>We should be grateful if you would contact us first if you do have a complaint so that we can try to resolve it for you.</p>
<p><strong>Children’s Privacy</strong></p>
<p>We do not aim any of our products or services directly at children under the age of 18 and we do not knowingly collect personal information about children under 18.</p>
<p><strong>Disclosure of Personal Information to Third Parties</strong></p>
<p>We may disclose personal information to:</p>
<ul>
<li>a parent, subsidiary or affiliate of our company</li>
<li>third-party service providers for the purpose of enabling them to provide their services including (without limitation) IT service providers, data storage, hosting and server providers, ad networks, analytics, error loggers, debt collectors, maintenance or problem-solving providers, providers, professional advisors, and payment systems operators.</li>
<li>our employees, contractors, and/or related entities</li>
<li>our existing or potential agents or business partners</li>
<li>credit reporting agencies, courts, tribunals, and regulatory authorities, in the event you fail to pay for goods or services we have provided to you.</li>
<li>courts, tribunals, regulatory authorities, and law enforcement officers, as required by law, in connection with any actual or prospective legal proceedings, or in order to establish, exercise, or defend our legal rights.</li>
<li>third parties, including agents or sub-contractors who assist us in providing information, products, services, or direct marketing to you.</li>
<li>third parties to collect and process data.</li>
<li>an entity that buys, or to which we transfer all or substantially all of our assets and business.</li>
<li>Google Analytics - We may receive data from third parties such as analytics providers such as Google based outside the EU, advertising networks such as Facebook based outside the EU, such as search information providers such as Google based outside the EU, providers of technical, payment and delivery services, such as data brokers or aggregators. GA4 will no longer monitor sessions and pageviews but instead monitor events and parameters. While for Universal Analytics user-level and event-level data properties could have an indefinite duration, user-level data can be retained for a maximum of 14 months for Google Analytics 4 properties.</li>
</ul>
<p>Third parties we currently use include:</p>
<ul>
<li>Google Firebase</li>
<li>Brevo Email Marketing</li>
<li>Stripe - Terms of use can be accessed https://stripe.com/in/legal/stripe-shop</li>
</ul>
<p><strong>International Transfers of Personal Information</strong></p>
<p>The personal information we collect is stored and/or processed in, or where we or our partners, affiliates, and third-party providers maintain facilities.
The countries to which we store, process, or transfer your personal information may not have the same data protection laws as the country in which you initially provided the information. If we transfer your personal information to third parties in other countries: (i) we will perform those transfers in accordance with the requirements of applicable law; and (ii) we will protect the transferred personal information in accordance with this privacy policy.</p>
<p><strong>Your Rights and Controlling Your Personal Information</strong></p>
<p>Your choice: By providing personal information to us, you understand we will collect, hold, use, and disclose your personal information in accordance with this privacy policy. You do not have to provide personal information to us, however, if you do not, it may affect your use of our app or the products and/or services offered on or through it.</p>
<p>Information from third parties: If we receive personal information about you from a third party, we will protect it as set out in this privacy policy. If you are a third party providing personal information about somebody else, you represent and warrant that you have such person’s consent to provide the personal information to us.
Marketing permission: If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by contacting us using the details below.</p>
<p>Access: You may request details of the personal information that we hold about you.</p>
<p>Correction: If you believe that any information, we hold about you is inaccurate, out of date, incomplete, irrelevant, or misleading, please contact us using the details provided in this privacy policy. We will take reasonable steps to correct any information found to be inaccurate, incomplete, misleading, or out of date.
Non-discrimination: We will not discriminate against you for exercising any of your rights over your personal information. Unless your personal information is required to provide you with a particular service or offer (for example serving particular content to your device), we will not deny you goods or services and/or charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties, or provide you with a different level or quality of goods or services.</p>
<p>Notification of data breaches: We will comply with laws applicable to us in respect of any data breach.</p>
<p>Complaints: If you believe that we have breached a relevant data protection law and wish to make a complaint, please contact us using the details below and provide us with full details of the alleged breach. We will promptly investigate your complaint and respond to you, in writing, setting out the outcome of our investigation and the steps we will take to deal with your complaint. You also have the right to contact a regulatory body or data protection authority in relation to your complaint.
Unsubscribe: To unsubscribe from our email database or opt-out of communications (including marketing communications), please contact us using the details provided in this privacy policy or opt-out using the opt-out facilities provided in the communication. We may need to request specific information from you to help us confirm your identity.</p>
<p><strong>Use of Cookies</strong></p>
<p>Our privacy policy covers the use of cookies between your device and our servers. A cookie is a small piece of data that an app may store on your device, typically containing a unique identifier that allows the app servers to recognise your device when you use the app; information about your account, session and/or device; additional data that serves the purpose of the cookie; and any self-maintenance information about the cookie itself.</p>
<p>We use cookies to give your device access to core features of our app, to track app usage and performance on your device, to tailor your experience of our app based on your preferences, and to serve advertising to your device. Any communication of cookie data between your device and our servers occurs within a secure environment.</p>
<p><strong>Business Transfers</strong></p>
<p>If we or our assets are acquired, or in the unlikely event that we go out of business or enter bankruptcy, we would include data, including your personal information, among the assets transferred to any parties who acquire us. You acknowledge that such transfers may occur, and that any parties who acquire us may, to the extent permitted by applicable law, continue to use your personal information according to this policy, which they will be required to assume as it is the basis for any ownership or use rights we have over such information.</p>
<p><strong>Limits of Our Policy</strong></p>
<p>Our app may link to external sites that are not operated by us. Please be aware that we have no control over the content and policies of those sites and cannot accept responsibility or liability for their respective privacy practices.</p>
<p><strong>Changes to This Policy</strong></p>
<p>At our discretion, we may change our privacy policy to reflect updates to our business processes, current acceptable practices, or legislative or regulatory changes. If we decide to change this privacy policy, we will post the changes here.
If required by law, we will get your permission or give you the opportunity to opt in to or opt out of, as applicable, any new uses of your personal information.</p>
<p><strong>Data Controller / Data Processor</strong></p>
<p>The GDPR distinguishes between organisations that process personal information for their own purposes (known as &quot;data controllers&quot;) and organisations that process personal information on behalf of other organisations (known as &quot;data processors&quot;). We, proSupervise Ltd, located at the address provided at the start of this section, are a Data Controller and/or Processor with respect to the personal information you provide to us.</p>
<p><strong>Legal Bases for Processing Your Personal Information</strong></p>
<p>We will only collect and use your personal information when we have a legal right to do so. In which case, we will collect and use your personal information lawfully, fairly, and in a transparent manner. If we seek your consent to process your personal information, and you are under 16 years of age, we will seek your parent or legal guardian’s consent to process your personal information for that specific purpose.
Our lawful bases depend on the services you use and how you use them. This means we only collect and use your information on the following grounds:</p>
<p><strong>Consent From You</strong></p>
<p>Where you give us consent to collect and use your personal information for a specific purpose. You may withdraw your consent at any time using the facilities we provide; however, this will not affect any use of your information that has already taken place. When you contact us, you may consent to your name and email address being used so we can respond to your enquiry. While you may request that we delete your contact details at any time, we cannot recall any email we have already sent. If you have any further enquiries about how to withdraw your consent, please feel free to enquire using the details provided in the Contact Us section of this privacy policy.</p>
<p><strong>Performance of a Contract or Transaction</strong></p>
<p>Where you have entered into a contract or transaction with us, or in order to take preparatory steps prior to our entering into a contract or transaction with you. For example, we need technical information about your device in order to provide the essential features of our app.</p>
<p><strong>Our Legitimate Interests</strong></p>
<p>Where we assess it is necessary for our legitimate interests, such as for us to provide, operate, improve and communicate our services. For example, we collect technical information about your device in order to improve and personalise your experience of our app. We consider our legitimate interests to include research and development, understanding our audience, marketing and promoting our services, measures taken to operate our services efficiently, marketing analysis, and measures taken to protect our legal rights and interests.</p>
<p><strong>Compliance with Law</strong></p>
<p>In some cases, we may have a legal obligation to use or keep your personal information. Such cases may include (but are not limited to) court orders, criminal investigations, government requests, and regulatory obligations. If you have any further enquiries about how we retain personal information in order to comply with the law, please feel free to enquire using the details provided in the Contact Us section of this privacy policy.</p>
<p><strong>International Transfers Outside of the European Economic Area (EEA)</strong></p>
<p>We will ensure that any transfer of personal information from countries in the European Economic Area (EEA) to countries outside the EEA will be protected by appropriate safeguards, for example by using standard data protection clauses approved by the European Commission, or the use of binding corporate rules or other legally accepted means.</p>
<p><strong>Your Rights and Controlling Your Personal Information</strong></p>
<p>Restrict: You have the right to request that we restrict the processing of your personal information if (i) you are concerned about the accuracy of your personal information; (ii) you believe your personal information has been unlawfully processed; (iii) you need us to maintain the personal information solely for the purpose of a legal claim; or (iv) we are in the process of considering your objection in relation to processing on the basis of legitimate interests.
Objecting to processing: You have the right to object to processing of your personal information that is based on our legitimate interests or public interest. If this is done, we must provide compelling legitimate grounds for the processing which overrides your interests, rights, and freedoms, in order to proceed with the processing of your personal information.
Data portability: You may have the right to request a copy of the personal information we hold about you. Where possible, we will provide this information in CSV format or other easily readable machine format. You may also have the right to request that we transfer this personal information to a third party.
Deletion: You may have a right to request that we delete the personal information we hold about you at any time, and we will take reasonable steps to delete your personal information from our current records. If you ask us to delete your personal information, we will let you know how the deletion affects your use of our app, website or products and services. There may be exceptions to this right for specific legal reasons which, if applicable, we will set out for you in response to your request. If you terminate or delete your account, we will delete your personal information within 30 days days of the deletion of your account. Please be aware that search engines and similar third parties may still retain copies of your personal information that has been made public at least once, like certain profile information and public comments, even after you have deleted the information from our services or deactivated your account.</p>

          </IonContent>
    
        </IonPage>
      );
};   

export default Privacy;
import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import React from "react";
import { Redirect } from "react-router-dom";

const  UserAuthActions : React.FC = () => {
    const [authAction, setAuthAction] = React.useState({mode: '', oobCode: ''})

    React.useEffect(() => {
        // Lod params
        const loadParams = () => {
            const query = new URLSearchParams(window.location.search);
            const mode = query.get('mode')
            const mode2 = mode !== null ? mode : ''
            const oobCode = query.get('oobCode') 
            const oobCode2 = oobCode !== null  ? oobCode : 'undefined'
            setAuthAction({mode: mode2, oobCode: oobCode2})
            //console.log("useEffect loaded:", mode, oobCode, window.location.search)
        }
        loadParams()
    },[]);  

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref='/tabs/home/'></IonBackButton> 
                    </IonButtons>
                <IonTitle>Action</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                { authAction.mode  === 'resetPassword' &&
                    <Redirect to={`/passwordreset?oobCode=${authAction.oobCode}`} />
                }
                { authAction.mode === 'verifyEmail' && 
                    <Redirect to={`/verified-email?oobCode=${authAction.oobCode}`} />    
                }
                { authAction.mode === '' &&
                    <>
                        <p>No action code</p>
                        <IonButton routerLink='/tabs/home'>Home</IonButton>
                    </>
                }
            </IonContent>
        </IonPage>
    )
    
}


export default UserAuthActions
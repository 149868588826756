import { IonAvatar, IonButton, IonButtons, IonContent, IonHeader, IonItem, IonLabel, IonList, IonSearchbar, IonSpinner, IonTitle, IonToolbar } from "@ionic/react";
import React from 'react';
// firestore db
import { db } from '../../firebase';
import { collection, DocumentData } from "firebase/firestore";
import {useCollection} from 'react-firebase-hooks/firestore';

// user data model for contacts
import { User } from '../../data/user';

import "./ContactModal.css";

interface ContactModalProps {
    newChat : (user: User|null) => void
    uid : string
}

const ContactModal : React.FC<ContactModalProps> = ({ newChat, uid }) => {

    
    const [users, loading, error] = useCollection(collection(db, "users"),{ snapshotListenOptions: { includeMetadataChanges: true }})
    const [searchQuery, setSearchQuery] = React.useState("")
  
    const handleSearchInput = (ev:Event) => {
      let query = '';
      const target = ev.target as HTMLIonSearchbarElement;
      if (target) query = target.value!.toLowerCase();
      setSearchQuery(query) 
    };
     // return true if searchQuery matches user property
    const filterUser = (user: User) => {
    
    if (typeof user.uid === "undefined"){
      return true;
    }
    if (searchQuery === ""){
      return true;
    }

    if (user.fullName.toLowerCase().includes(searchQuery))
      return true;
    if (user.email.toLowerCase().includes(searchQuery))
      return true;
    if (user.myLocation.toLowerCase().includes(searchQuery))
      return true;
    if ('supervisor'.includes(searchQuery) && user.isSupervisor)
      return true;
    if ('member'.includes(searchQuery) && !user.isSupervisor)
      return true;
    return false
  }

    if (error ){
        console.log('chat contact modal error - ',error)
    }
    if (loading){
        return( <IonSpinner/>)
    }
    if (users){
        return (
            <div style={{ height: "100%" }}>
                <IonHeader>
                    <IonToolbar color="primary">
                        <IonTitle>New Chat</IonTitle>
                        <IonButtons slot="end">
                            <IonButton fill="clear" onClick={ () => newChat(null) }>Cancel</IonButton>
                        </IonButtons>
                    </IonToolbar>
                    <IonToolbar color="blue">
                        <IonSearchbar showClearButton="focus"  onIonInput={(ev) => handleSearchInput(ev)} />
                    </IonToolbar>
                </IonHeader>

                <IonContent color="blue">
                    <IonList>
                        { users.docs.map((doc : DocumentData) => {
                            const user = doc.data() as User
                            if (filterUser(user)){
                            console.log(user)
                            return (
                                <IonItem key={ `contact_${ user.uid }` } lines="full" button detail={true} onClick={() => newChat(user)} 
                                    disabled={ uid === user?.uid ? true : false} 
                                    className="contact-item">
                                    <IonAvatar slot="start" >
                                        <img src={ user.avatar } alt="contact avatar" />
                                    </IonAvatar>
                                    <IonLabel class="ion-text-wrap">
                                        <p><b>{ user?.fullName }</b>{ uid === user?.uid && " (You)" }<br/>
                                        { user?.occupation !== "" ? user.occupation : "No occupation"}<br/>
                                        { user?.myLocation !== "" ? user.myLocation : "No location"}<br/>
                                        </p>
                                    </IonLabel>
                                </IonItem>
                            );
                            } else {
                                return <></>
                            }
                        })}
                    </IonList>
                </IonContent>
            </div>
        );
    }
}

export default ContactModal;



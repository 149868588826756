import React, {  } from 'react';
import { IonContent, IonLoading, IonPage } from '@ionic/react';
import { useAuth } from "../contexts/authContext";



const RedirectToLogin: React.FC = () => {
  const { authInfo , logOut} = useAuth()!;
  const [loggedOut, setLoggedOut] = React.useState(false)
  
   const delay = (ms : number) => new Promise(
    resolve => setTimeout(resolve, ms)
  );
  React.useEffect(() => { 

    const tryLogOut = async () => {
      if (!loggedOut  && authInfo.loggedIn){
        try {
          await delay(1000);
          logOut()
        }
        catch (e) {
          console.log('logout error:',e);
        }
      }
      else if (!loggedOut && !authInfo.loggedIn ){
        setLoggedOut(true)
      }
    }
    tryLogOut()
    
  },[authInfo,logOut,loggedOut])

  return (
    <IonPage>
    <IonContent>
      <IonLoading isOpen={true} message="Logging out ..." duration={1000} />
    </IonContent>
    </IonPage>
  )
};

export default RedirectToLogin;

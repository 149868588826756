// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { 
  Auth, 
  getAuth,
  GoogleAuthProvider,
} from "firebase/auth";

import {
  getFirestore
} from "firebase/firestore";

import { initializeAppCheck, ReCaptchaEnterpriseProvider, getToken } from "firebase/app-check";
declare global {
  // eslint-disable-next-line no-var
  var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined;
}


const environment = import.meta.env.VITE_ENVIRONMENT ? import.meta.env.VITE_ENVIRONMENT: "development"

console.log('environment:', environment)
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
let firebaseConfig ;
let recaptchaSiteKey = '';
let api = 'xxxxxx';

if (environment !== 'development'){
  firebaseConfig  = {
    // live
    apiKey: import.meta.env.VITE_REACT_APP_API_KEY,
    authDomain: import.meta.env.VITE_REACT_APP_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_REACT_APP_PROJECT_ID,
    storageBucket: import.meta.env.VITE_REACT_APP_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_REACT_APP_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_REACT_APP_APP_ID,
    measurementId: import.meta.env.VITE_REACT_APP_MEASUREMENT_ID,
    
  };
  recaptchaSiteKey =  import.meta.env.VITE_REACT_APP_RECAPTCHA_SITE_KEY
  api = import.meta.env.VITE_DEV_FIREBASE_FUNCTIONS_URL
  
} else {
  // development
  firebaseConfig  = {
    apiKey: import.meta.env.VITE_DEV_REACT_APP_API_KEY,
    authDomain: import.meta.env.VITE_DEV_REACT_APP_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_DEV_REACT_APP_PROJECT_ID,
    storageBucket: import.meta.env.VITE_DEV_REACT_APP_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_DEV_REACT_APP_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_DEV_REACT_APP_APP_ID,
    measurementId: import.meta.env.VITE_DEV_REACT_APP_MEASUREMENT_ID,
    
  };
  recaptchaSiteKey =  import.meta.env.VITE_RECAPTCHA_V2_SITE_KEY
  //api = import.meta.env.VITE_FIREBASE_FUNCTIONS_URL
  api = import.meta.env.VITE_DEV_FIREBASE_FUNCTIONS_URL
}

console.log('firebase config:',firebaseConfig)
console.log('recaptiva site key', recaptchaSiteKey)
console.log('functions api', api)


// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and Filestore and get a reference to the service
const auth: Auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);



if (document.location.hostname === 'localhost') {
  window.FIREBASE_APPCHECK_DEBUG_TOKEN = '5852D17B-5F11-40F4-B76C-EADB6CDD7C9B'
  console.log('debug on localhost')
}


// initialise app check
const  appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(recaptchaSiteKey),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});


getToken(appCheck)
      .then(() => {
        console.log('app check token success');
      })
      .catch(error => {
        console.log('app check token error', error.message, error.code);
      });

// init auth providers
const googleProvider = new GoogleAuthProvider();

export {
  auth,
  db,
  storage,
  googleProvider,
  api
};
import { IonBackButton, IonBadge, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import './Home.css';
import { calendarOutline, cardOutline, lockClosedOutline, shieldCheckmarkOutline } from 'ionicons/icons';

import {  collection, doc, getDoc } from 'firebase/firestore';
import { useAuth} from '../contexts/authContext';
import { db } from '../firebase';
import { StripeAccount, stripeAccountDefault} from '../data/StripeAccount';
import { format } from 'date-fns';
import React from 'react';
import { FirebaseError } from '@firebase/app';

const DisplayMembership = ( stripeAccount: StripeAccount) => {
  console.log(stripeAccount)
  let subscriptionHeadline = '';
  let subscriptionDescription = '';
  let subscriptionLink = '/tabs/home/stripeaccount'
  let subscriptionBadge = ''
  const trial_end = format(new Date(stripeAccount.trial_end * 1000), "dd MMM")

  if (stripeAccount.customerId === ''){
    subscriptionHeadline = 'Prosupervise Standard (free)'
    subscriptionDescription = "Subscribe to access free trial of premium features"
    subscriptionLink = '/tabs/home/striperegister'
  }
  else {
    if (stripeAccount.hasTrial  ){     
      subscriptionHeadline = `Prosupervise Premium Free Trial`
      subscriptionBadge = `ends ${trial_end}`
      subscriptionDescription = "Access your membership plan"
      subscriptionLink = '/tabs/home/stripeaccount'
    }
    else {
      switch (stripeAccount.status){
        case 'trialing':
          break;
        case 'canceled':
          subscriptionHeadline = `You have cancelled your Premium Membership and are now a Standard member`
          subscriptionDescription = "Upgrade to Prosupervise Premium"
          break
        case 'paused':
          subscriptionHeadline = `Your subscription has ended its trial period`
          subscriptionDescription = 'Enter you payment detail to continue with Premium membership'
          break;
        case 'incomplete_expired':
          subscriptionHeadline = `You have Prosupervise Standard (free) membership`
          subscriptionDescription = "Upgrade to Prosupervise Premium"
          break;
        case 'unpaid':
          subscriptionHeadline = `Your subscription payment details has failed`
          subscriptionDescription = 'Enter you payment detail to continue with Premium membership'
          break;
        case 'active':
        case 'past_due':
        case 'incomplete':
          subscriptionHeadline = `You have the Prosupervise Premium membership (paid by ${stripeAccount.period})`
          subscriptionDescription = "Access your membership plan"
      } 
    }
  }

  return (
    <IonItem detail={true} routerLink={subscriptionLink} key='subscription'>
      <IonIcon aria-hidden="true" icon={cardOutline} slot="start"></IonIcon>
      <IonLabel className="ion-text-wrap">
        <strong>{ subscriptionHeadline } { subscriptionBadge !== '' && <span>({subscriptionBadge})</span> }</strong>
        { !stripeAccount.livemode && <IonBadge color="warning">Test mode</IonBadge> }
        <br />
        {subscriptionDescription}
      </IonLabel>
    </IonItem>
  )
}

const AccountPage: React.FC = () => {
  // database listener for grabbing account record changes
  const { authInfo} = useAuth()!;
  const [stripeAccount, setStripeAccount] = React.useState(stripeAccountDefault)
  
  const [error, setError] = React.useState('')

  React.useEffect(()=>{
    const fetchStripeAccount = async () => {
      try {
        if (typeof authInfo.user.customerId !== 'undefined' && authInfo.user.customerId !== ''){
          const docRef = doc(collection(db,'stripeAccount'), authInfo.user.customerId)
          const docSnap = await getDoc(docRef)
          if (docSnap.exists()){
            setStripeAccount(docSnap.data() as StripeAccount)
          }
        }
        
      } catch(error ) {
        if (error instanceof FirebaseError){
          setError(error.message)
        }
      }
    }
    if (authInfo.user.customerId !== '')
      fetchStripeAccount()
  },[authInfo.user.customerId])
  
  // data db loading error
  if (error !== ''){
    // db error report it
    console.log('***error', error);
    return (<IonContent>DB Error { error }</IonContent>)
  }
    
  console.log('stripe account', stripeAccount)

  return (
    
    <IonPage id="homepage" className="homePage">
      <IonHeader>
        <IonToolbar color="primary">
            <IonButtons slot="start">
                <IonBackButton defaultHref="/tabs/home"></IonBackButton>       
            </IonButtons>
          <IonTitle>Account</IonTitle>
        </IonToolbar>
      </IonHeader>     
      <IonContent color="secondary">
      <div className="ion-padding">
        <h3>Membership Details</h3>
      </div>
      <IonList inset={true}>
        
        { DisplayMembership(stripeAccount)}
      </IonList>
      <div className="ion-padding">
        <h3>Account Settings</h3>
      </div>
      <IonList inset={true}>
        <IonItem detail={true} routerLink='/tabs/home/cookie'>
          <IonIcon aria-hidden="true" icon={shieldCheckmarkOutline} slot="start"></IonIcon>
          <IonLabel>Manage Cookies</IonLabel>
        </IonItem>
        <IonItem routerLink='/tabs/home/forgotpassword' detail={true}>
          <IonIcon aria-hidden="true" icon={lockClosedOutline} slot="start"></IonIcon>
          <IonLabel>Change Password</IonLabel>
        </IonItem>
        <IonItem>
          <IonIcon aria-hidden="true" icon={calendarOutline} slot="start"></IonIcon>
          <IonLabel>Calendar Settings</IonLabel>
        </IonItem>
      </IonList>    
      </IonContent>
    </IonPage>
    
  );
  
}
export default AccountPage;





import React, { useState } from "react";

import { ErrorMessage } from "@hookform/error-message";
import { IonPage, IonContent, IonGrid, IonRow, IonCol, IonCard, IonCardHeader, IonCardTitle, IonInput, IonButton, IonSpinner, IonItem, IonLabel, IonCardContent } from "@ionic/react";
import { Link } from "react-router-dom";
// react hook form 
import { useForm, Controller } from "react-hook-form";
import { sendPasswordResetEmail } from "firebase/auth";
import { useAuth} from '../contexts/authContext';
import { auth } from "../firebase";

const ForgotPassword : React.FC = () =>  {
  type FieldValues = {
    email: string
  }
  const [passwordResetSent, setPasswordResetSent] = useState(false)
  const { handleSubmit, control, formState } = useForm<FieldValues>();
  const { isSubmitting } = formState;
  const { errors } = formState;
  const [isError, setIsError] = useState(false)
  const [myError, setMyError] = useState('')
  const { authInfo} = useAuth()!;

const handleForgotPassword = async (data :FieldValues) => {

  try {
    await sendPasswordResetEmail(auth, data.email);
    setPasswordResetSent(true);
  } catch (error: any ) {    // eslint-disable-line
    if (error.code === 'auth/user-not-found' || error.code === "auth/missing-email") {
      setMyError('User not found, try again!');
      setIsError(true);
    }
    console.log(error)
  }
};

return (

<IonPage>
    <IonContent>
        <IonGrid className="gridColorPurple">
            <IonRow class="ion-align-items-center center-height">
                <IonCol class="no-padding">
                    <IonCard className="login margin-5px">
                        <IonCardHeader>
                            <IonCardTitle>Reset Password</IonCardTitle>
                        </IonCardHeader>
                        { passwordResetSent &&
                          <IonCardContent>
                            <p>A password reset email has been sent. Check your Inbox!</p> 
                            <center> <Link to="/login">Log in?</Link> </center>
                          </IonCardContent>
                        }
                        { !passwordResetSent &&
                        
                        <form onSubmit={handleSubmit(handleForgotPassword)}>
                        <IonGrid >
                            <IonRow>
                                <IonCol>
                                    <p>Enter your email address you use to log in to Prosupervise</p>
                                    <Controller
                                        render={({ field: { onChange } }) => (
                                        <IonInput labelPlacement="floating" fill="outline" label="Email address" name="email" type="email" placeholder="Email address" onIonChange={onChange} />
                                        )}
                                        control={control}
                                        name="email"
                                        rules={{
                                        required: "This is a required field",
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            message: "Enter a valid email address"
                                        }
                                        }}
                                    />
                                
                                    <ErrorMessage
                                        errors={errors}
                                        name="email"
                                        as={<div style={{ color: "red" }} />}
                                    />
                                    
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonButton expand="block" disabled={isSubmitting} size="default" type="submit">{ isSubmitting && <IonSpinner></IonSpinner>}Reset Password</IonButton>  
                                </IonCol>
                            </IonRow> 
                            { isError && 
                            
                            <IonRow>
                                <IonCol>
                                    <IonItem  color="danger">
                                        <IonLabel class="ion-text-wrap" >{myError}</IonLabel>
                                    </IonItem>
                                </IonCol>
                            </IonRow> 
                            }
                            { !authInfo?.loggedIn &&
                            <IonRow class="ion-justify-content-right">
                                <IonCol >                        
                                   <center> <Link to="/login">Log in?</Link> </center>
                                </IonCol>
                            </IonRow>  
                            }  
                        </IonGrid>
                        </form>
                        }
                    </IonCard>
                </IonCol>
            </IonRow>
        </IonGrid>
    </IonContent>
</IonPage>

/*
      <div>
        {
          emailMessage ?
          <h3>The Email has been sent; Check your Inbox!</h3> : 
          <form onSubmit={handleSubmit}>
            <input 
              type="email" 
              name="email"
              placeholder="name@email.com"
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <div>
              <button type='submit'>Reset Your Password</button>
            </div>
          </form>
        }
      </div>
    )
  */
    )
  }

  export default ForgotPassword
import { IonContent, IonMenuButton, IonButtons, IonHeader, IonPage, IonTitle, 
  IonToolbar, IonSpinner, 
  useIonActionSheet, IonItem, IonLabel, IonAvatar, IonButton, IonInput, IonTextarea,
  IonModal, IonIcon, IonList, IonSegment, IonSegmentButton,
  IonCard,
  IonCardContent,
  IonSearchbar, 
  } from '@ionic/react';
import './HomePage.css';
import { db, auth } from '../firebase'
import {useCollection} from 'react-firebase-hooks/firestore';
// firebase db
import { collection, deleteDoc, doc, addDoc, setDoc,  getDoc, query, orderBy } from "firebase/firestore";

import React from 'react';
import {chatbubbleOutline, addOutline, trashOutline, createOutline, starOutline, star, help, helpBuoy, document} from 'ionicons/icons';
import { useAuth } from '../contexts/authContext';

import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import CookieBanner from '../components/CookieBanner';
import { usePostHog } from "posthog-js/react";
import InfoBanner from '../components/InfoBanner';
import VerifyBanner from '../components/VerifyBanner';
//import styles from "./HomePage.module.scss";
interface Comment {
  comment: string
  date: number
  fullName: string
  uid : string
  avatar : string
}
interface Post {
  title: string 
  content: string 
  authorName: string 
  authorTitle: string
  avatar : string
  uid: string
  docId : string
  date: string
  stars: Array<string>
  comments: Array<Comment>
}

/// <ion-col style="display: flex; justify-content: center">
const HomePage: React.FC = () => {
  const posthog = usePostHog()
  const [currentPost, setCurrentPost] = React.useState("");
  const [isOpen, setIsOpen] = React.useState(false);
  const [postTitle, setPostTitle]=React.useState("");
  const [postContent, setPostContent]=React.useState("");
  const [isTouched, setIsTouched] = React.useState(false);
  const [myError, setMyError] = React.useState("");
  const [present] = useIonActionSheet();

  const { authInfo } = useAuth()!;

  React.useEffect(() => {
    if (posthog.has_opted_out_capturing()){
      posthog.opt_in_capturing();
    }
    
  },[posthog])
  

const handleSetPostContent =  (e: React.ChangeEvent<HTMLIonTextareaElement>) => {
  if (typeof e.target.value === 'string'){
    setPostContent(e.target.value);
  }
}

const handleSetPostTitle =  (e: React.ChangeEvent<HTMLIonInputElement>) => {
  if (typeof e.target.value === 'string'){
    setPostTitle(e.target.value);
  }
}
// return number of stars
const  displayStarsCount = (post: Post) =>{
  if (typeof post?.stars !== 'undefined'){
    return post.stars.length;
  }
  else {
    return 0;
  }
}

// return number of comments
const  displayCommentsCount = (post: Post) =>{
  if (typeof post?.comments !== 'undefined'){
    return post.comments.length;
  }
  else {
    return 0;
  }
}

// display stars icon
const displayStarsIcon = (post: Post) => {
  const uid = authInfo.user.uid;
  if (typeof post?.stars !== 'undefined'  && post.stars.includes(uid)){
    return star; 
  }
  else {
    return starOutline;
  }
}

// click is either star or unstar
const doStarPost = async (post: Post , docId: string) => {
console.log('do star post', post, docId)
  const uid = authInfo.user.uid;
  if (typeof post?.stars === 'undefined'){
    post.stars = [];
  }

  if (post.stars.includes(uid)){
    // unstar
    post.stars.splice(post.stars.indexOf(uid), 1)

  }
  else {
    // star
    post.stars.push(uid)
  }

  // update db
  try { 
    await setDoc(doc(collection(db,'posts'), docId), post, { merge: true});
  }
  catch(e){
    console.log('star post error:',e);
  }
}

// delete an existing post
const doDelete = async (docId : string) => {
  try {
    console.log('delete:', docId)
    await deleteDoc(doc(collection(db,'posts'), docId))
  }
  catch(e) {
    console.log('error delete:', e);
  }
}
  // edit an existing post 
  const doEdit = async (docId : string) =>{
    //console.log('doEdit', docId, isOpen)
    if (docId !== ''){
      // existing document load it
      try {
        const myDoc = await getDoc(doc(collection(db,'posts'), docId))
        if (myDoc.exists() ){
          const docData = myDoc.data();
          setPostTitle(docData.title);
          setPostContent(docData.content);
          setCurrentPost(docId)
          //console.log(myDoc.data())
        }
      }
      catch(e){
        console.log('do edit:',e)
      }
    } else {
      // new document
      setPostTitle("");
      setPostContent("");
      setCurrentPost("")
    }
     
    setIsTouched(false);
    setMyError("");
    setIsOpen(true);
  }
  
  // publish back to filestore
  const handlePublish = async () => {
    
    if (postTitle === '' || postTitle ===''){
      // empty fields return to dialog box
      setMyError('Post Title and Content are required fields');
    }
    else {
      // save data 
      if (currentPost !== ''){
        // update current doc
        try {
          const docData = { 
            title: postTitle,
            content: postContent, 
            avatar : authInfo.user.avatar,
            authorTitle: authInfo.user.occupation,
            authorName: authInfo.user.fullName,
            date: new Date().getTime()}
          await setDoc(doc(collection(db,'posts'), currentPost), docData, { merge: true});

        }
        catch(e){
          console.log('save post error:',e);
        }
      }
      else {
        // create new document
        try {
          const docData = { 
            title: postTitle, 
            content: postContent, 
            authorName: authInfo.user.fullName, 
            authorTitle: authInfo.user.occupation,
            avatar : authInfo.user.avatar,
            uid: authInfo.user.uid,
            docId : authInfo.docId,
            date: new Date().getTime()}
          await addDoc(collection(db,'posts'),docData)
        }
        catch(e){
          console.log('create doc:',e)
        }
      }

      // close dialog
      setIsOpen(false)
    }
  }

  const handleCancel = async () => {
    if (isTouched){
      discardEdits();
    }
    else
      setIsOpen(false);
  }

  const markTouched = () => {
    setIsTouched(true);
  };

  // dialog box when cancel is pressed and form edited
  const discardEdits = () => {
    present({
      header: "Post has been edited, keep?",
      buttons: [
        { 
          text: 'Continue Editing',
          role: 'cancel',
          data: {
            action: 'keep'
          }
        },
        { 
          text: 'Discard',
          role: 'destructive',
          data: {
            action: 'discard'
          }
        },

      ],
      onDidDismiss: ({ detail }) => {
        if (detail.data?.action === 'discard'){
          setIsOpen(false);
        }
      },
    })
  }

  
  const handleDeletePost = ( docId : string) => {
    present({
      header: "Delete Post?",
      buttons: [
        { 
          text: 'Delete',
          role: 'destructive',
          data: {
            action: 'delete'
          }
        },
        { 
          text: 'Keep',
          role: 'cancel',
          data: {
            action: 'cancel'
          }
        },

      ],
      onDidDismiss: ({ detail }) => {
        if (detail.data?.action === 'delete'){
          doDelete( docId );
        }
      },
    })
  }

  const gotoURL = (url : string ) => {
    window.location.href = url
  }

  return ( 
    <IonPage>     
      <IonHeader>
        <InfoBanner customerId={authInfo.user.customerId} uid={authInfo.user.uid}></InfoBanner>
        <VerifyBanner uid={authInfo.user.uid} email={authInfo.user.email} verified={auth.currentUser?.emailVerified} />
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          
          <IonTitle>Notices</IonTitle>
          <IonButtons slot="end">
            <IonButton  onClick={() => doEdit('')}>
              <IonIcon slot="icon-only" icon={addOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        
        <IonToolbar color="secondary" >
          <IonSegment color="primary" scrollable={true} onIonChange={(e) => gotoURL(e.detail.value as string)}>
            { authInfo.user.isSupervisor && 
            <IonSegmentButton value="/docs/articles/index.html" layout="icon-start">
              <IonIcon icon={document}></IonIcon>
              <IonLabel className="nocaps">Open Mind</IonLabel>
            </IonSegmentButton>
            }
            <IonSegmentButton value='/docs/faq/index.html' layout="icon-start">
              <IonIcon icon={help}></IonIcon>
              <IonLabel className="nocaps">FAQ</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="/docs/help/index.html" layout="icon-start">
              <IonIcon icon={helpBuoy}></IonIcon>
              <IonLabel className="nocaps">Help</IonLabel>
            </IonSegmentButton>
          </IonSegment>
          
        </IonToolbar>
        
      </IonHeader>
      <IonContent fullscreen={true} color={'secondary'}  >
        
      
        
        <IonModal isOpen={isOpen} backdropDismiss={false} showBackdrop={true} >
    
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton onClick={handleCancel}>Cancel</IonButton>
              </IonButtons>
              { postTitle === '' && <IonTitle>New Post</IonTitle> }
              { postTitle !== '' && <IonTitle>Edit Post</IonTitle>}
              <IonButtons slot="end">
                <IonButton disabled={!isTouched} onClick={() => handlePublish()}>
                  Publish
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonItem key='postTitle'>
              <IonInput
                labelPlacement="stacked"
                label="Title"
                className={`${isTouched && 'ion-touched'}`}
                type="text"
                value={postTitle}
                placeholder="Post Title"
                onInput={handleSetPostTitle}
                onIonBlur={() => markTouched()}
              ></IonInput>
            </IonItem>
            <IonItem key='postContent'>
              <IonTextarea
                labelPlacement="stacked"
                label="Content"
                placeholder="Post Content"
                autoGrow={true}
                onInput={handleSetPostContent}
                onIonBlur={() => markTouched()}
                value={postContent}></IonTextarea>
            </IonItem>
            { myError !== '' &&                             
              <IonItem  key='error' color="danger">
                <IonLabel className="ion-text-wrap" >{myError}</IonLabel>
              </IonItem>
            }
          </IonContent>
          
        </IonModal>
        
        
 
    



        <PostList doEdit={doEdit} doDelete={handleDeletePost} doStarPost={doStarPost} displayStarsCount={displayStarsCount} displayCommentsCount={displayCommentsCount} displayStarsIcon={displayStarsIcon} uid={authInfo.user.uid} /> 
      
       
      </IonContent>
      {posthog.has_opted_out_capturing()
      ||posthog.has_opted_in_capturing() 
        ? null 
        : <CookieBanner /> 
      }
    </IonPage>

  );
};

export default HomePage;

interface Props {
  doEdit : (id: string) => void
  doDelete : (id: string) => void
  doStarPost : (d: Post, docId: string) => void
  displayStarsIcon : (d : Post ) => string
  displayStarsCount : (d : Post) => number
  displayCommentsCount : (d : Post) => number 
  uid : string
  
}

const PostList: React.FC<Props> = ({ doEdit, doDelete, doStarPost, displayStarsCount, displayStarsIcon, displayCommentsCount, uid }) => {
  const { authInfo } = useAuth()!;
  const [value, loading, error] = useCollection(
    query(
      collection(db, "posts"), orderBy("date","desc")
    ),{ snapshotListenOptions: { includeMetadataChanges: true }});
    //console.log('post list')
  const [searchQuery, setSearchQuery] = React.useState("")
  
  const handleSearchInput = (ev:Event) => {
    let query = '';
    const target = ev.target as HTMLIonSearchbarElement;
    if (target) query = target.value!.toLowerCase();
    setSearchQuery(query) 
  };

  // return true if searchQuery matches user property
  const doesInclude = (content: string) => {
    if (content.toLowerCase().includes(searchQuery)){
      return true;
    }
    else {
      return false;
    }
  }

  const filterPost = (post: Post) => {
    if (searchQuery === ""){
      return true;
    }
    if (doesInclude(post.title))
      return true;
    if (doesInclude(post.content))
      return true;
    if (doesInclude(post.authorName))
      return true;
    if (doesInclude(post.authorTitle))
      return true;
    if (authInfo.user.isSupervisor && doesInclude('supervisor'))
      return true;
    if (!authInfo.user.isSupervisor && doesInclude('member'))
      return true;
    return false
  }
  
  if (error) console.log('error in post list', error);
  if (value){
    return(
      <>
      <IonList key='postlist' className="backGroundPrimary">
      <IonSearchbar showClearButton="focus" color={'almostwhite'} onIonInput={(ev) => handleSearchInput(ev)} ></IonSearchbar>
      
      { value.docs.map((doc, index)  => { 
        const post = doc.data() as Post
        const id = `post_${ index }`
        if (filterPost(post))
          return (
            <Post key={`postItem_${ index }`} id={ id } doEdit={doEdit} doDelete={doDelete} doStarPost={doStarPost} displayStarsCount={displayStarsCount} displayCommentsCount={displayCommentsCount} displayStarsIcon={displayStarsIcon} uid={uid} post={post} docId={doc.id}/> 
          )
        else {
          return <></>
        }
        
      })}
      
      </IonList>
      
      </>
    )
  }
  if (loading) {
    if (!authInfo.loggedIn){
      console.log('****************** logged out')
    }
    return (

    <IonSpinner name="bubbles" />)
  }

  return (<></>)

};

interface Props2 {
  id : string
  doEdit : (id: string) => void
  doDelete : (id: string) => void
  doStarPost : (d: Post, docId: string) => void
  displayStarsIcon : (d : Post ) => string
  displayStarsCount : (d : Post) => number
  displayCommentsCount : (d : Post) => number
  uid : string
  post: Post
  docId : string
}


const Post : React.FC<Props2> = ({ id, doEdit, doDelete, doStarPost, displayStarsCount, displayStarsIcon, displayCommentsCount, uid, post, docId}) => {
  //console.log('post id', id )
  return (
    
    <IonCard key={id}>
      <IonItem color='almostwhite' lines="full">
        <Link to={"/tabs/connect/"+post.uid}>
          <IonAvatar className="centeravatar" slot="start">
            <img alt="Avatar" className="shrink" src={post.avatar} />
          </IonAvatar>
        </Link>
        <IonLabel>
          <h3><Link to={"/tabs/connect/"+post.uid}>@{ post.authorName }</Link>, { (new Date(post.date)).toDateString() }<br/><b>{post.title}</b></h3>
        </IonLabel>
      </IonItem>
      <IonCardContent color='white'>
       
        <ReactMarkdown children={post.content} remarkPlugins={[remarkGfm]} />
        <div className="postReactions ">

					<div className="postReaction ">
            <IonButton size="small" fill='clear' routerLink={'/tabs/home/'+docId}>
						<IonIcon icon={ chatbubbleOutline } />
            </IonButton>
						<p> {displayCommentsCount(post) }</p>
					</div>
					<div className="postReaction ">
            <IonButton size="small" fill='clear' onClick={() => doStarPost(post,docId)}>
						<IonIcon icon={displayStarsIcon(post) } />
            </IonButton>
						<p>{ displayStarsCount(post) }</p>
					</div>

					<div className="postReaction ">
            <IonButton size="small" fill='clear' disabled={(uid !== post.uid)} onClick={() => doEdit(docId)}>
              <IonIcon icon={ createOutline } />
            </IonButton>
					</div>
          <div className="postReaction ">
            <IonButton size="small" fill='clear' disabled={(uid !== post.uid)} onClick={() => doDelete(docId)}>
              <IonIcon icon={ trashOutline } />
            </IonButton>
					</div>
				</div>
      </IonCardContent>
    </IonCard>

		
);}






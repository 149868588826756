import { format, parseISO,set, eachHourOfInterval, addHours, isSameDay } from 'date-fns';
import { Appointment, AppointmentMessage, AppointmentList} from './AppointmentClasses';
import { setDoc, doc, collection, deleteDoc, DocumentData, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { IonBadge, IonChip, IonIcon, IonLabel } from '@ionic/react';
import { formatISO } from 'date-fns';
import { alertCircleOutline } from 'ionicons/icons';
/*
const displayDate = (date : Timestamp, dateFormat: string) => {
    return format(date.toDate(),dateFormat)
}
*/

export const displayISODate = ( isoDate: string | string[] | null | undefined, dateFormat: string) =>  {
  if ( typeof isoDate === 'string'){
    return format(parseISO(isoDate), dateFormat)
  }
  else {
    return "";
  }
}

export const displayDate = (date : number, dateFormat: string) => {
  return format(new Date(date),dateFormat)
}

export const dateStamp = () => {
    return format(new Date(), "dd/MM/yy hh:mm")
}

export const zeroDate = ( d : Date ) => {
  return set(d, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
}

export function capitalizeFirstLetter(string : string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// return array of hours slots
const generateHourSlots = (d: Date, startHour: number, endHour: number) => {
  const startDate =  new Date( d.getFullYear(),   d.getMonth(), d.getDate(), startHour)
  const endDate = new Date( d.getFullYear(),   d.getMonth(), d.getDate(), endHour)
  const result = eachHourOfInterval({
      start: startDate,
      end: endDate
  })
  return result;
}

// generate a set of empty appointment slots for a day
export const generateDayDiary = ( isoDate : string ) => {

  // generate days hours
  const currentDate = parseISO(isoDate)
  const diaryDayHours = generateHourSlots(currentDate, 8, 17);
  
  // generate empty appointment slots for the day
  const diaryDayAppointments = new Array<Appointment>();

  for (let i=0; i < diaryDayHours.length; i++){   
    diaryDayAppointments.push({  
      start : diaryDayHours[i].getTime(),
      end : addHours(diaryDayHours[i],1).getTime(),  // ends 1 hour later
      consultantUid : '',
      consultantName: '',
      consultantEmail: '',
      paymentId: '',
      location: '',
      treatment: '',
      name : '',
      status : 'blocked',
      notes: '',
      patientPhone : '',
      patientUid : '',
      patientEmail: '',
      audit: [],
      message : new Array<AppointmentMessage>
    } as Appointment)
  }
  return(diaryDayAppointments)
}

// function to return array of appointments that match current date
export const filterAppointments = (docs: Array<DocumentData>, selectedISODate : string) :  [AppointmentList, string[]] => {
  let appointmentsList : AppointmentList = {};
  const appointmentMonthDays : Array<string> = []; 
  docs.filter((doc) => {
    const appointment = doc.data() as Appointment;
    const selectedDate = parseISO(selectedISODate)
    const appointmentStartDate = new Date(appointment.start)
    //console.log('compare:', selectedDate, appointmentStartDate, isSameDay(selectedDate, appointmentStartDate) )
    const matchDate = isSameDay(selectedDate, appointmentStartDate)
    
    if (matchDate){
      const appointmentHour = new Date(appointment.start).getHours().toString();
      appointmentsList  = { ...appointmentsList, [appointmentHour]: appointment }
    }

    // return iso dates booked in calendar
    
      const appointmentDay = formatISO(appointmentStartDate,{ representation: 'date' })
      if (!appointmentMonthDays.includes(appointmentDay)) appointmentMonthDays.push(appointmentDay)
    
    return matchDate;
  })
  return [ appointmentsList, appointmentMonthDays ] 
}



// function to return array of appointments that match current date

export const filterScheduleAppointments = (docs: Array<DocumentData>, selectedISODate : string, consultantUid: string, uid: string) : [AppointmentList, AppointmentList, string[]] => {
    let myAppointmentsList : AppointmentList = {};
    let consultantSchedule : AppointmentList = {};
    const appointmentMonthDays : Array<string> = []; 
    docs.filter((doc) => {
      const appointment = doc.data() as Appointment;
      const selectedDate = parseISO(selectedISODate)
      const appointmentStartDate = new Date(appointment.start)
      //console.log(appointment)
      //console.log('compare:', selectedDate, appointmentStartDate, isSameDay(selectedDate, appointmentStartDate) )
      const matchDate = isSameDay(selectedDate, appointmentStartDate)
      
      if (matchDate){
        const appointmentHour = new Date(appointment.start).getHours().toString();
        if (appointment.consultantUid === consultantUid  || appointment.patientUid == consultantUid){
            consultantSchedule  = { ...consultantSchedule, [appointmentHour]: appointment }
        }
        if (appointment.patientUid === uid  || appointment.consultantUid === uid){
            myAppointmentsList  = { ...myAppointmentsList, [appointmentHour]: appointment }
        }
   
      }
  
      // return iso dates booked in calendar consultant has slot and its not booked
      if ( appointment.consultantUid === consultantUid && ( appointment.patientUid === '' || appointment.patientUid === uid )){
        //console.log('slot', appointment)
          const appointmentDay = formatISO(appointmentStartDate,{ representation: 'date' })
          if (!appointmentMonthDays.includes(appointmentDay)) appointmentMonthDays.push(appointmentDay)
      }
      return matchDate;
    })
    
    return [ myAppointmentsList, consultantSchedule, appointmentMonthDays ] 
  }



//-----------------------------------------------------------------------------------------------------------------
//
// DB functions
//

//  db function to update appointment on db
export const dbChangeAppointment =  async ( appointment : Appointment) => {
  try {
    const key = appointment.consultantUid+appointment.start.toString();
    console.log('key:', key, appointment)
    await setDoc(doc(collection(db,'appointmentsList'), key), appointment, { merge: true});
  }
  catch(e){
    console.log('error update appointment:', appointment, e);
  }
}

export const dbDeleteAppointment =  async ( appointment : Appointment) => {
  try {
    const key = appointment.consultantUid+appointment.start.toString();
    console.log('key:', key)
    await deleteDoc(doc(collection(db,'appointmentsList'), key));
    return true
  }
  catch(e){
    console.log('error deleting appointment:', appointment, e);
    return false
  }
}

//  db function to update appointment on db
export const dbGetAppointment =  async ( key : string) => {
    try {
      
      return await getDoc(doc(collection(db,'appointmentsList'), key));
    }
    catch(e){
      console.log('error getting appointment:', key, e);
    }
  }
//---------------------------------------------------------------------------------
// GUI display functions

interface StatusBadgeProps { 
  status : string
}

export const StatusBadge: React.FC<StatusBadgeProps> = ({status}) => {
  if (status !== 'blocked')
  return (
    <IonBadge class={'status-'+status.toLowerCase() }>{ capitalizeFirstLetter(status) }</IonBadge>
  )
  else return (
    <></>
  )
};

interface BannerBadgeProps {
    color : string
    message: string
}

export const BannerBadge : React.FC<BannerBadgeProps> = ({color, message}) => {
    return (  
            <IonChip color={color}>
                <IonIcon icon={alertCircleOutline} > </IonIcon>
                <IonLabel>{message}</IonLabel>
            </IonChip>
    )
}

interface AppointmentAuditProps {
  audit : string[]
}

export const AppointmentAudit : React.FC<AppointmentAuditProps> = ({audit}) => {
  return (<>
    {
      audit.map(entry => {
        return (
          <p>{entry}</p>
        )
      })
    }
  </>)
}
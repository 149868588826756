import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonFooter, IonGrid, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';

//import './Home.css';
import { Action } from '../forms/components/Action';



const NotFound: React.FC = () => {
  
  
  return (
    <IonPage >
      <IonHeader>
        <IonToolbar color="primary">
        <IonButtons slot="start">
            <IonMenuButton ></IonMenuButton>
          </IonButtons>
          <IonTitle>Prosupervise Page Not Found</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent color="secondary">
  
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Page not Found</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            Prosupervise could not find a page. Click on home to continue.<br></br> 
            <IonButton  routerLink='/'>Home &rarr;</IonButton>
          </IonCardContent>
          
        </IonCard>
          
        
      </IonContent>
      <IonFooter >
        <IonGrid class="colorPrimary">
          <Action message="Already got an account?" text="Login" link="/login" />
        </IonGrid>
      </IonFooter>
    </IonPage>
  );
};

export default NotFound;
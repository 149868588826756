
import { IonPage, IonContent, IonGrid, IonRow, IonCol, IonCard, IonCardHeader, IonCardTitle, IonButton, IonCardContent, IonBadge, IonFooter, IonToolbar, IonHeader, IonTitle } from "@ionic/react";
import {useState, useEffect,useRef} from 'react'

import { sendEmailVerification } from "firebase/auth";

import { auth } from "../firebase";
import { useAuth } from '../contexts/authContext';
import './VerifyEmail.css'
import { Redirect } from "react-router-dom";
import { Action } from "../forms/components/Action";



// page displayed after a user registers
const VerifyEmail : React.FC = () =>  {

    const {authInfo} = useAuth()!;
    const [time, setTime] = useState(60)
    const [timeActive, setTimeActive] = useState(false)
    const [verified, setVerified] = useState(false)
    const [timeOut, setTimeOut] = useState(false)
    const [doRegisterAsSupervisor, setDoRegisterAsSupervisor] = useState(false)
    const intervalRef = useRef<NodeJS.Timer | null>(null);
    const delayRef = useRef<NodeJS.Timer | null>(null);
    const email = authInfo.loggedIn ? authInfo.user.email : "undefined";
    

    // button timeout
    useEffect(() => { 
        if (timeActive && time !== 0 ){
            intervalRef.current= setInterval(() => {
                setTime((time) => time - 1)
            }, 1000)
        } else if (time === 0 ){
            setTimeActive(false)
            setTime(60)
            if (intervalRef.current !== null)
                clearInterval(intervalRef.current)
        }
        return () => {if (intervalRef.current !== null) clearInterval(intervalRef.current)};
    }, [timeActive, time])

    useEffect(() => {
        if (!timeOut){
            delayRef.current = setInterval(() => {
                setTimeOut(true)
            },1000);

        } else {
            // try verification
            auth.currentUser?.reload()
            .then(() => {
                if (auth.currentUser?.emailVerified){
                    setVerified(auth.currentUser?.emailVerified)
                    const registerAsSupervisor = authInfo.user.registerAsSupervisor ? true : false
                    setDoRegisterAsSupervisor(registerAsSupervisor)
                }
                else {
                    setVerified(false)
                    setTimeOut(false)
                }
            })
        }

        return () => {if (delayRef.current !== null) clearInterval(delayRef?.current)}
    }, [verified,timeOut,authInfo.user.registerAsSupervisor])


    const resendVerificationEmail = () => {
        if (auth.currentUser && !auth.currentUser.emailVerified){   
            sendEmailVerification(auth.currentUser)
            .then(() => {       
                setTimeActive(true)
            }).catch((err) => {
                alert(err.message)           
            })
        }
    }
 
    return(
    <IonPage>
        <IonHeader>
            <IonToolbar color="primary">
                <IonTitle>Prosupervise Verify Email</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent>
            <IonGrid class="colorLightBlue">
                <IonRow class="ion-align-items-center centerHeight">
                    <IonCol >
                        { !verified &&
                        <IonCard color="secondary" className="margin5px">
                            <IonCardHeader>
                                <IonCardTitle><center>Verify Your Email Address</center></IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent>
                                <center>
                                <p>We need to verify your email in order to use the Prosupervise app.</p>
                                <p>A verification email has been sent to:</p>
                                <p><b>{ email }</b></p>
                                <p>Follow the instruction in the email to verify your account.</p>
                                <p>To change your email address please email us at info@prosupervise.com.</p>
                                <IonButton  disabled={timeActive || auth.currentUser === null} onClick={resendVerificationEmail} size="default">Resend Email {timeActive && <span>&nbsp;<IonBadge color="hotcoral">{time}</IonBadge></span>} {!timeActive && <span>&rarr;</span> }</IonButton>
                                </center>
                            </IonCardContent>  
                        </IonCard>
                        }
                        { verified && 
                        <IonCard color="secondary" className="margin5px">
                        <IonCardHeader>
                            <IonCardTitle><center>Your Email has been verified</center></IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <center>
                            <p>Prosupervise has validated the following email address:</p>
                            <p><b>{ email }</b></p>
                            <p>You can now use the Prosupervise app.</p>
                            <p>To change your email address please email us at info@prosupervise.com.</p>
                            <IonButton routerLink="/tabs/home">Home &rarr;</IonButton>
                            </center>
                        </IonCardContent>  
                    </IonCard>
                        }
                        { verified && doRegisterAsSupervisor && 
                            <Redirect to='/home/account/striperegister' />
                        }
                        
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonContent>
        <IonFooter >
              
          <IonGrid class="colorPrimary">
            
            <Action message={`You are logged in as ${email}.`} text="Logout" link="/logout" />
          </IonGrid>
        </IonFooter>
    </IonPage>
    )
}

export default VerifyEmail
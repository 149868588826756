// Page generated from markdown docs/pages/


import React from 'react';
import { IonHeader, IonBackButton, IonToolbar, IonTitle, IonContent, IonPage, IonButtons } from '@ionic/react';

const PaymentTerms: React.FC = () => {
    
    return (
        <IonPage >
          <IonHeader>
            <IonToolbar color="primary">
                <IonButtons slot="start">
                    <IonBackButton></IonBackButton>
            
                </IonButtons>
              <IonTitle>Prosupervise Payment Terms and Conditions</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding"> 
    
            
    
            <p><strong>Introduction</strong></p>
<p>Content to go here ....</p>

          </IonContent>
    
        </IonPage>
      );
};   

export default PaymentTerms;
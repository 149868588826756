import React, { useEffect, useState } from 'react';
import { useHistory, Link } from "react-router-dom";
import { 
    IonContent, 
    IonPage, 
    IonItem,
    IonButton,
    IonInput,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonGrid,
    IonRow,
    IonCol,
    IonSpinner,
    IonLabel,
    IonButtons,
    IonHeader,
    IonMenuButton,
    IonTitle,
    IonToolbar,
    IonInputPasswordToggle} from '@ionic/react';

//import { logoGoogle, logoFacebook } from 'ionicons/icons';

import "./LoginPage.css";

// react hook form 
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

// firebase auth
import { auth } from "../firebase";
import {  signInWithEmailAndPassword  } from 'firebase/auth'; 



import { useAuth } from '../contexts/authContext';
import { FirebaseError } from 'firebase/app';
import { usePostHog } from 'posthog-js/react';

/*
function getErrorMessage(error: unknown) {
    if (error instanceof Error) return error.message
    return String(error)
}
*/

const noAccountWithPassword = "Sorry, we can't find an account with this email address and password. Please try again or reset your password.";
const pleaseTryAgain = "Network error, please try again";
const appCheckHasBlocked = "Access from this URL is blocked.  Try app.prosupervise.com."

const LoginPage: React.FC = () => {

    interface FieldValues {
        email : string
        password : string
    }

    const posthog = usePostHog()

    const { handleSubmit, control, formState } = useForm<FieldValues>();
    const { isSubmitting } = formState;
    const { errors } = formState;
    const history = useHistory();
    const [isError, setIsError] = useState(false)
    const [myError, setMyError] = useState('')

    const { authInfo } = useAuth()!;

    useEffect(() => { 
        if (authInfo.error !== ''){
            setMyError(authInfo.error)
            setIsError(true)
        }
    },[authInfo])
    
   // const { login } = useAuth();

    const handleLogin = async (data: FieldValues) => {
        console.log('login:',data);

        try {
            // sign in
            const user = await signInWithEmailAndPassword(auth, data.email, data.password)

            // post hog identify user
            posthog?.identify(user.user.uid, {
                email: user.user.email,
            })
            console.log(user.user)
        }
        catch(error ){
            if (error instanceof FirebaseError){
                setIsError(true);
                if (error.code === 'auth/wrong-password' ||
                    error.code === 'auth/user-not-found' ||
                    error.code == 'auth/invalid-credential' )
                    setMyError(noAccountWithPassword);
                else if (error.code === 'auth/internal-error' ||
                         error.code === 'auth/network-request-failed')
                    setMyError(pleaseTryAgain)
                else if (error.code === 'appCheck/fetch-status-error')
                    setMyError(appCheckHasBlocked)
                else
                    setMyError(error.code);
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage)
            }
        }       
    }

/*
    const [presentAlert] = useIonAlert();

const alertNotImplemented = () => {
    presentAlert({
        header: 'Alert',
        subHeader: 'Information',
        message: 'Not yet implemented!',
        buttons: ['OK'],
    });
}
    */
console.log('login:', authInfo)

  if (authInfo.loggedIn){
    console.log("login signed in redirect");
    history.replace("/tabs/home");
    return null;
  }
  else 
    return (
<IonPage>
<IonHeader>
    <IonToolbar color="primary">
        <IonButtons slot="start">
            <IonMenuButton ></IonMenuButton>
          </IonButtons>
          <IonTitle>Login</IonTitle>
        </IonToolbar>
    </IonHeader>
    <IonContent>
        <IonGrid className="gridColorPurple">
            <IonRow className="ion-align-items-center center-height">
                <IonCol className="no-padding">
                    <IonCard className="login margin-5px">
                        <IonCardHeader>
                            <IonCardTitle>Login into ProSupervise</IonCardTitle>
                        </IonCardHeader>
                        <form onSubmit={handleSubmit(handleLogin)}>
                        <IonGrid class="background-white">
                            <IonRow>
                                <IonCol>
                                    
                                    <Controller
                                        render={({ field: { onChange } }) => (
                                        <IonInput labelPlacement="stacked" fill="outline" label="Email address" name="email" type="email" placeholder="you@youraddress.com" onIonChange={onChange} />
                                        )}
                                        control={control}
                                        name="email"
                                        rules={{
                                        required: "This is a required field",
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            message: "invalid email address"
                                        }
                                        }}
                                    />
                                
                                    <ErrorMessage
                                        errors={errors}
                                        name="email"
                                        as={<div style={{ color: "red" }} />}
                                    />
                                    
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <Controller
                                        render={({ field: { onChange } }) => (
                                        <IonInput labelPlacement="stacked" fill="outline" label="Password" name="password" type="password" placeholder="*********" onIonChange={onChange}><IonInputPasswordToggle slot="end"></IonInputPasswordToggle></IonInput> 
                                        )}
                                        control={control}
                                        name="password"
                                        rules={{
                                        required: "This is a required field",
                                        }}
                                    />
                                
                                    <ErrorMessage
                                        errors={errors}
                                        name="password"
                                        as={<div style={{ color: "red" }} />}
                                    />

                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                <center><Link to="/forgotpassword">Forgot your password?</Link> </center>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonButton expand="block" disabled={isSubmitting} size="default" type="submit">{ isSubmitting && <IonSpinner></IonSpinner>}LOGIN with Email</IonButton>  
                                </IonCol>
                            </IonRow> 
                            { isError && 
                            
                            <IonRow>
                                <IonCol>
                                    <IonItem  color="danger">
                                        <IonLabel class="ion-text-wrap" >{myError}</IonLabel>
                                    </IonItem>
                                </IonCol>
                            </IonRow> 
                            }
                             
                            
                            <IonRow class="ion-justify-content-center">
                                <IonCol >                        
                                   <center>Don't have an account? <Link className="custom-link" to="/register/false">Join &rarr;</Link> </center>
                                </IonCol>
                            </IonRow>    
                        </IonGrid>
                        </form>
                    </IonCard>
                </IonCol>
            </IonRow>
        </IonGrid>
    </IonContent>
</IonPage>
  );
};

export default LoginPage;


import { IonPage, IonContent, IonGrid, IonRow, IonCol, IonCard, IonCardHeader, IonCardTitle, IonButton, IonCardContent } from "@ionic/react";


import { applyActionCode} from "firebase/auth";

import { auth, db } from "../firebase";
import './VerifyEmail.css'
import { FirebaseError } from "firebase/app";
import { useEffect, useState } from "react";
import { sendWelcomeMail } from "../mail/SendMail";

import { useAuth } from '../contexts/authContext';
import { Redirect } from "react-router-dom";
import { collection, doc, getDoc } from "firebase/firestore";
import { userDefault } from "../data/user";



// page displayed after link to verify email = from UserAuthActions
const VerifiedEmail : React.FC = () =>  {
  const {authInfo, registerUser} = useAuth()!;
  const [state, setState] = useState({status: 'undefined', errorMessage: ''})
  let email = ""
  if (auth && auth.currentUser && auth?.currentUser.email !== null){
    email = auth?.currentUser.email
  }
  else {
    email = "undefined"
  }
  const fullName = authInfo.loggedIn ? authInfo.user.fullName : "undefined"
  const searchParams = new URLSearchParams(window.location.search)
  const oobCode:string | null = searchParams.get('oobCode')

  
  console.log(authInfo,auth)
  useEffect(() => { 
    console.log('useeffect')

    // check that a user record exists, if not create one
    const checkUserRecord = async (uid: string) => {
      console.log("check user", uid);
      getDoc(doc(collection(db,'users'), uid))
      .then(doc => {
        if (!doc.exists()){
          // document does not exist create it
           
          const registerUserDetails = {
            uid : uid,
            fullName : fullName,
            avatar: '',
            email : email,
            authProvider: "local"
          };
          registerUser( {...userDefault, ...registerUserDetails} );
          console.log("user created", registerUserDetails)
        }
      })
    }

    const decodeActionCode = () => {
      console.log("decode action", email, fullName)
      
      if (auth?.currentUser?.emailVerified){
        checkUserRecord(auth?.currentUser.uid)
        setState({status:"ok",errorMessage: ''})
        return
      }
      
      if (!auth?.currentUser  ){
        console.log("not logged in")
        setState({status:'not-logged-in',errorMessage:''})
        return;
      }
      if (oobCode !== null){
        // validate email has been verified
        applyActionCode(auth,oobCode)
        .then(()=> {  
            console.log("validated",auth.currentUser)
            sendWelcomeMail(fullName, email)
            setState({status:"ok", errorMessage: ''})
        })
        .catch( async (error) => {
          // error in apply action code
          //await auth.currentUser?.reload()
          console.log(error, error instanceof FirebaseError)
          //console.log(auth.currentUser)
          if (error instanceof FirebaseError){
            // firebase apply action code error
            console.log('error code', error.code)
            switch(error.code) {
              case "auth/invalid-action-code": 
              case "auth/expired-action-code": 
                setState({status: "email-validation-error", 
                    errorMessage: "This code has expired. Please verify email again."});
                break;
              case "auth/user-disabled": 
                setState({status: "email-validation-error",     
                    errorMessage:"The user account has been disabled by an administrator."});
                break;
              case "auth/user-not-found":
                setState({status: "email-validation-error", 
                errorMessage:"That email address doesn't match an existing account."});
                break;
              default:
                setState({status: "email-validation-error", 
                    errorMessage:"Unspecified error, please try again."});
            }
              console.log(error.code, error.message, state)
          } else {
              // some other error
              setState({status:"some-other-error", errorMessage:error.message})
              console.log('VerifiedEmail.tsx:',error)
          }
          /*
          if (auth.currentUser?.emailVerified && state.status !== "ok" ){
              // magic email validation
              //setState({status:"ok", errorMessage: ''})
              //sendWelcomeMail(fullName, email)
          }
          */
        })
      }
      else {
          setState({status:"oob-code-not-supplied", errorMessage:'verification code not supplied'});
      }
    }
    if (state.status === "undefined")
      decodeActionCode()
    // validate against oob code    
      
  }, [state,authInfo,email,fullName, oobCode, registerUser])

    //email, fullName, errorMessage, validatedEmail,errorState

    // Possible 'errorState' states and actions
    // undefined - show spinner, still loading
    // ok - jump to verify email page
    // oob-code-not-supplied - show error page and try again
    // email-validation-error - show error page and try again
    // some-other-error - show error page and try again
    // emails-do-not-match - message logout and login again
    // not-logged-in - login and try again
    
  console.log("errorState:", state)
    
  return(
    <IonPage>
      <IonContent>
        <IonGrid class="colorPrimary">
          <IonRow class="ion-align-items-center centerHeight">
              
            <IonCol >   
            { state.status === "oob-code-not-supplied" ||
              state.status === "some-other-error" || 
              state.status === "emails-do-not-match"  ||
              state.status === "email-validation-error" &&                 
                <IonCard color="secondary" className="margin5px">   
                  <IonCardHeader>
                    <IonCardTitle><center>Verify Your Email Address</center></IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <center>
                    <p>A verification for email:</p>
                    <p><b>{ email }</b></p>
                    <p>has failed with the following error:</p>
                    <p>{state.errorMessage}</p>
                    
                    <IonButton routerLink="/verify-email">Send Verify Email &rarr;</IonButton>
                    <p>Contact us via admin@prosupervise.com if you need assistance.</p>
                    </center>
                  </IonCardContent>  
                </IonCard>
            }
            { state.status === "not-logged-in" &&
                <IonCard color="secondary" className="margin5px">   
                <IonCardHeader>
                    <IonCardTitle><center>Verify Your Email Address</center></IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <center>
                  <p>You need to be logged in to verify your email.</p>
                  <p>Please login and try the link again.</p>
                  
                  <IonButton routerLink="/login">Login to Prosupervise &rarr;</IonButton>
                  <p>Contact us via admin@prosupervise.com if you need assistance.</p>
                  </center>
                </IonCardContent>  
            </IonCard>     
            }
            { state.status === "ok" && !authInfo.user.registerAsSupervisor &&
            <IonCard color="secondary" className="margin5px">
                <IonCardHeader>
                  <IonCardTitle><center>Your Email has been verified</center></IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <center>
                  <p>Prosupervise has validated the following email address:</p>
                  <p><b>{ email }</b></p>
                  <p>You can now use the Prosupervise app.</p>
                  <IonButton routerLink="/tabs/home">Home &rarr;</IonButton>
                  <p>To change your email address please email us at info@prosupervise.com.</p>
                  </center>
                </IonCardContent>  
            </IonCard>
            }
            { state.status === "ok" && authInfo.user.registerAsSupervisor &&
              <Redirect to={{pathname: '/striperegister'}} />
            }
            { state.status === "undefined"  && 
              <p>Loading...</p>
            }
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
    
}

export default VerifiedEmail

import { IonButton, IonFooter } from "@ionic/react";
import './CookieBanner.css'
import { usePostHog } from "posthog-js/react";
import React from "react";

const CookieBanner: React.FC = () => {
    const posthog = usePostHog()
    const [showBanner, setShowBanner] = React.useState(true)

    const acceptCookies = () => { 
        posthog.opt_in_capturing();
        setShowBanner(false); // new
    };
    
    const declineCookies = () => {
        //posthog.opt_out_capturing();
        posthog.opt_in_capturing();
        setShowBanner(false); // new
    };

    return (
        <div>
        { showBanner && <IonFooter className="cookieFooter"> 
            <p>By clicking "Accept analytics cookies", you agree to storing of cookies on your device to enhance site navigation, analyse site usage and help with our marketing of prosupervise.com.</p>
            <IonButton size="small" fill="solid" color="almostwhite" onClick={() => acceptCookies()}>Accept analytics Cookies</IonButton>
            <IonButton size="small" fill="solid" color="almostwhite" onClick={() => declineCookies()}>Decline analytics Cookies</IonButton>
            <IonButton size="small" fill="clear" routerLink='/tabs/home/cookie'>View Cookies</IonButton>
        </IonFooter>
        }
      </div>
    );
  };
  
  export default CookieBanner;
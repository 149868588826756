import React, { FC } from "react";
import { IonInput, IonTextarea, IonSelect, IonSelectOption} from "@ionic/react";
import { UseFormRegister, UseFormSetValue, Controller, Control, FieldErrors } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

export interface FormValues {
  
  fullName : string
  occupation: string
  bio : string
  myLocation : string
  servicesOffered: Array<string>
  email : string
  phone: string
  url: string
  orientation: string
  qualYears: string
  cpd: string
  accreditingBody : string
  compliance : string
}

export const defaultFormValues : FormValues = {
  fullName : '',
  occupation: '',
  bio : '',
  myLocation : '',
  servicesOffered: [],
  email : '',
  phone: '',
  url: '',
  orientation: '',
  qualYears: '',
  cpd: '',
  accreditingBody : '',
  compliance : ''
}

export type FormValueTypes = "fullName" | "occupation" | "bio" | "myLocation" | "servicesOffered" | "email" | "phone" | "url" | "orientation"|"qualYears"|"cpd"|"accreditingBody"|"compliance"
export const myFormValues : string[] = ["fullName" , "occupation" , "bio" , "myLocation" , "servicesOffered" , "email" , "phone" , "url" , "orientation","qualYears","cpd","accreditingBody","compliance"]

type TextFieldTypes =
  | 'date'
  | 'email'
  | 'number'
  | 'password'
  | 'search'
  | 'tel'
  | 'text'
  | 'url'
  | 'time'
  | 'week'
  | 'month'
  | 'datetime-local'

export interface InputProps {
  formName: FormValueTypes;
  label: string;
  inputType: TextFieldTypes
  placeholder: string
  register: UseFormRegister<FormValues>
  errors: FieldErrors<FormValues>
}


export const Input: FC<InputProps> = ({
  formName,
  inputType,
  label,
  placeholder,
  register,
  errors
}) => {
  return (
    <>
      
      <IonInput type={inputType} labelPlacement="stacked" label={label} placeholder={placeholder}       
        { ...register(formName) }/>
      <ErrorMessage
        errors={errors}
        name={formName}
        as={<div style={{ color: "red" }} />}
      />
    </>
  );
};

export const Textarea :  FC<InputProps> = ({
    formName,
    label,
    placeholder,
    register,
    errors
}) => {
    return (
       
      <>
      <IonTextarea labelPlacement="stacked" label={label} placeholder={placeholder}
            autoGrow={true}        
            { ...register(formName) }/>
      <ErrorMessage
        errors={errors}
        name={formName}
        as={<div style={{ color: "red" }} />}
      />
      </>
    );
};



export interface SelectProps {
    formName: FormValueTypes;  
    label: string;
    placeholder: string
    choose: Array<string>
    setValue: UseFormSetValue<FormValues>
    control: Control<FormValues, string>
    errors: FieldErrors<FormValues>
}
  

export const Select :  FC<SelectProps> = ({
    formName,
    label,
    choose,
    control,
    setValue,
    placeholder,
    errors
}) => {
    return (    
     
        <>
            <Controller
                render={({ field }) => (
                  <IonSelect
                    placeholder={placeholder}
                    label={label}
                    value={field.value}
                    multiple={true}
                    onIonChange={e => setValue(formName, e.detail.value)}
                    labelPlacement="stacked"
                  >
                    {choose.map((option,index) => {
                        return (<IonSelectOption key={`${formName}-${index}`} value={option}>{option}</IonSelectOption>)
                    })}
                  </IonSelect>
                )}
                control={control}
                name={formName}
                
            />
            <ErrorMessage
                errors={errors}
                name={formName}
                as={<div style={{ color: "red" }} />}
            />
            </>
      
    );
};

/*




  <IonLabel>Gender</IonLabel>
              <Controller
                render={({ field }) => (
                  <IonSelect
                    placeholder="Select One"
                    value={field.value}
                    onIonChange={e => setValue('gender', e.detail.value)}
                  >
                    <IonSelectOption value="FEMALE">Female</IonSelectOption>
                    <IonSelectOption value="MALE">Male</IonSelectOption>
                  </IonSelect>
                )}
                control={control}
                name="gender"
                rules={{ required: 'This is a required field' }}
              />

              */
import { IonButtons,IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonBackButton, IonButton, IonIcon, IonItem, IonLabel, IonList, IonCol, IonGrid, IonRow, IonText, IonThumbnail } from '@ionic/react';
import React , { useState, useEffect} from 'react';
import { collection, doc, getDoc } from "firebase/firestore";
import { db } from '../../firebase';
import {  useParams } from 'react-router-dom';

import { User, userDefault} from '../../data/user';
import { exitOutline, locationOutline, mailOutline } from 'ionicons/icons';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import './ShowUser.css'


const ShowDirectoryUser: React.FC = () => {
  
  const [consultant, setConsultant] = useState<User>(userDefault);
  const [reloadUser,setReloadUser]=useState(true)
  const [error, setError] = useState('')

  const { uid } = useParams() as { uid : string }

  // load user
  useEffect(() => {

    if (typeof uid === 'undefined'){
      setConsultant(userDefault);
      setError('user is not defined')
    }
    else {
      getDoc(doc(collection(db,'users'), uid))
      .then(doc => {
        if (doc.exists() ){
          const user = doc.data() as User;
          setConsultant(user)
          console.log('got user:', user)
        }
        else {
          console.log('user not found ', doc)
          setError('User id: "'+uid+'" not found')
        }
      })
      .catch(err => {
          console.log('Error getting user documents', err);
          setError("Error getting user")
      });
    }
    setReloadUser(false)
  }, [uid, reloadUser])

  

  const sendMsgLink = '/directorycontactme/'+uid

  return (
    <IonPage>     
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref='/directory/'></IonBackButton>
          </IonButtons>    
          <IonTitle>{consultant?.fullName}</IonTitle>
        </IonToolbar>
        
       </IonHeader>
      <IonContent color='duckegg'>
      {  error !== '' && <p>{error}</p> }
      
      <IonList class="ion-no-padding" key="profile">
      <IonItem key="top" color="highblue">
        <IonGrid>
          <IonRow class="ion-align-items-center">
            <IonCol size="3">
              <IonThumbnail>
              <center>           
                <img alt="avatar" className="avatar" src={consultant?.avatar} />         
              </center>
              </IonThumbnail>
            </IonCol>
            <IonCol size="8">
              <IonText color="primary"> <p>
              <b>{ consultant?.fullName }</b>
              <br />{consultant?.occupation}<br />
              { consultant.myLocation !== '' && <span><IonIcon color="hotcoral" icon={locationOutline}></IonIcon>{consultant.myLocation}</span>   }
              </p> </IonText>       
            </IonCol>
            
          </IonRow> 
            <IonRow class="ion-align-items-center">
              <IonCol>
                <IonButton routerLink={sendMsgLink} ><IonIcon icon={mailOutline} color="hotcoral" slot="start"></IonIcon>Contact Me</IonButton>
                <IonButton disabled={ typeof consultant.url === "undefined" || consultant.url === ''} href={consultant.url} ><IonIcon icon={exitOutline} color="hotcoral" slot="start"></IonIcon>Visit Website</IonButton>
              </IonCol>
            </IonRow>
        </IonGrid>
      </IonItem>
        
      <IonItem key='about' color='duckegg'>
        <IonLabel class="ion-text-wrap">
          <h2>About me</h2>
          { consultant.bio !== '' ? <ReactMarkdown children={consultant?.bio} remarkPlugins={[remarkGfm]} />: "Not available." }
        </IonLabel>
      </IonItem>
      <IonItem key='servicesOffered' color='duckegg'>
        <IonLabel class="ion-text-wrap">
          <h2>Services Offered</h2>
          { typeof consultant.servicesOffered !== "undefined" && consultant.servicesOffered.length >  0 ? <ul>
                {consultant.servicesOffered.map((service) => {
                  return (<li key={service}>{service}</li>)
                }
                )}</ul>
                : <p>{"Not available."}</p>
          }
        </IonLabel>
      </IonItem>
      <IonItem key='theory' color='duckegg'>
        <IonLabel class="ion-text-wrap">
          <h2>Theoretical Orientation </h2>
          <p>{ typeof consultant.orientation !== "undefined" && consultant.orientation !== '' ? consultant.orientation : "Not available." }</p>
        </IonLabel>
      </IonItem>
      </IonList>
    
    <div className="duckEggBackground" style={{padding: '100px'}}></div>
    
    </IonContent>
    </IonPage>
  )
}


export default ShowDirectoryUser
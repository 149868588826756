//
// Send mail out functions using brevo
//

import { auth, api } from '../firebase';


// send contact as email via brevo
export const sendAppointmentMail = async (name:string,email: string, subject : string, booking: string, appointmentDate: string, message: string, template : number) => {
  
  const mailData = {
    sender: {
      name: 'Prosupervise',
      email: 'info@prosupervise.com'
    },
    to: [
      {
        name: name,
        email: email
      }
    ],
    subject: subject,
    templateId: template,
    params: {
      booking: booking,
      message : message,
      appointmentDate: appointmentDate
    }
  };
  
  auth.currentUser?.getIdToken().then((token) => {
    const args = new URLSearchParams();
    args.append('mailData',JSON.stringify(mailData))
    //fetch(`https://europe-west1-prosupervise-dev-4241e.cloudfunctions.net/app/get-subscriptions?id=${authInfo.user.customerId}`, {
    fetch(`${api}app/get-user?`+ args.toString(), {
      headers: {
        authorization: `Bearer ${token}`
      }
    })
    .then(response => { 
      return response.json()
    })
    .then(result => {     
      if (result.ok){
        
        console.log(result.payload)
      }
      else {
        console.log(result.error)
      }
    })
  }) 
}




// send contact as email via brevo
export const sendWelcomeMail = async (name:string,email: string) => {
  console.log("send welcome email!", email)

  const data = {
    sender: {
      name: 'Prosupervise',
      email: 'info@prosupervise.com'
    },
    to: [
      {
        name: name,
        email: email
      }
    ],
    subject: "Welcome to Prosupervise",
    templateId: 4
  };
  
  auth.currentUser?.getIdToken().then((token) => {
    const args = new URLSearchParams();
    args.append('mailData',JSON.stringify(data))
    //fetch(`https://europe-west1-prosupervise-dev-4241e.cloudfunctions.net/app/get-subscriptions?id=${authInfo.user.customerId}`, {
    fetch(`${api}app/send-email?`+ args.toString(), {
      headers: {
        authorization: `Bearer ${token}`
      }
    })
    .then(response => { 
      return response.json()
    })
    .then(result => {     
      if (result.ok){
        console.log( result.payload)
      }
      else {
        console.log(result.error)
      }
    })
  }) 
}

// send contact as email via brevo
const sendMail = async (name:string,email: string, subject : string, message: string, template : number) => {
  
  const data = {
    sender: {
      name: 'Prosupervise',
      email: 'info@prosupervise.com'
    },
    to: [
      {
        name: name,
        email: email
      }
    ],
    subject: subject,
    htmlContent: message,
    templateId: template
  };
  auth.currentUser?.getIdToken().then((token) => {
    const args = new URLSearchParams();
    args.append('mailData',JSON.stringify(data))
    //fetch(`https://europe-west1-prosupervise-dev-4241e.cloudfunctions.net/app/get-subscriptions?id=${authInfo.user.customerId}`, {
    fetch(`${api}app/send-email?`+ args.toString(), {
      headers: {
        authorization: `Bearer ${token}`
      }
    })
    .then(response => { 
      return response.json()
    })
    .then(result => {     
      if (result.ok){
        console.log( result.payload)
      }
      else {
        console.log(result.error)
      }
    })
  }) 
  
  
}

export default  sendMail;

import { IonContent, IonButtons, IonHeader, IonPage, IonTitle, IonToolbar, IonItem, IonSpinner, IonAvatar, IonLabel, IonList, IonSearchbar, IonBackButton } from '@ionic/react';
import './ConnectPage.css';
import { db } from '../firebase';
import { collection, } from "firebase/firestore";
import {useCollection} from 'react-firebase-hooks/firestore';
import React from 'react';
import { User } from '../data/user';
import { useAuth } from '../contexts/authContext';
//import * as admin from 'firebase-admin'

 

const UserAdmin: React.FC = () => {

 const { authInfo} = useAuth()!;
 

  return (
    
    <IonPage className="homePage">
      <IonHeader>
        <IonToolbar color="primary">
        <IonButtons slot="start">
            <IonBackButton defaultHref='/'></IonBackButton>
          </IonButtons>  
          <IonTitle>User Admin</IonTitle>
        </IonToolbar>
      </IonHeader>
     
      <IonContent fullscreen={true} color={'secondary'} >
        
        { authInfo.user.isAdmin && <UserList loggedInUserId={authInfo.user.uid} /> }
        {! authInfo.user.isAdmin && <IonItem color="danger">You do not have access to this page.</IonItem>}
      </IonContent>
    </IonPage>
  );
};

export default UserAdmin;


interface Props {
  loggedInUserId : string
}


const UserList: React.FC<Props>= ( {loggedInUserId}) => {

  
  const [value, loading, error] = useCollection(collection(db, "users"),{ snapshotListenOptions: { includeMetadataChanges: true }});
  const [searchQuery, setSearchQuery] = React.useState("")
  
  const handleSearchInput = (ev:Event) => {
    let query = '';
    const target = ev.target as HTMLIonSearchbarElement;
    if (target) query = target.value!.toLowerCase();
    setSearchQuery(query) 
  };

  // return true if searchQuery matches user property
  const filterUser = (user: User) => {
    
    if (typeof user.uid === "undefined"){
      return true;
    }
    if (searchQuery === ""){
      return true;
    }

    if (user.fullName.toLowerCase().includes(searchQuery))
      return true;
    if (user.email.toLowerCase().includes(searchQuery))
      return true;
    if (user.myLocation.toLowerCase().includes(searchQuery))
      return true;
    if ('supervisor'.includes(searchQuery) && user.isSupervisor)
      return true;
    if ('member'.includes(searchQuery) && !user.isSupervisor)
      return true;
    return false
  }
  
  if (error) { console.log('userlist',error);
    return <p>Error: { error.message } </p> }
  if (value){ 
    
    return(
      <>
      <IonSearchbar showClearButton="focus"  onIonInput={(ev) => handleSearchInput(ev)} />
      <IonList className="backGroundPrimary">
      { value.docs.map((doc)  => { 
        const d = doc.data() as User
        
          let avatar = d?.avatar ;
          if (d?.avatar === ''){
            avatar = "https://ui-avatars.com/api/?size=128&name="+d?.fullName
          }
          let itsMe = '';
          if (loggedInUserId === d.uid)  
            itsMe = ' (You)'
          if (filterUser(d)){
            // user matches search term
           if (typeof d.uid === "undefined" )
            console.log("ffffffffff",d.uid, d, doc, doc.id,doc.ref.id)
            return (         
              <IonItem color="highblue" key={d.uid} routerLink={"/manageuser/"+doc.id} button detail={true} lines="full"  >
                <IonAvatar slot="start" >
                    <img alt= "Avatar" src={avatar} />
                </IonAvatar>
                <IonLabel class="ion-text-wrap">
                    <p><b>{ d?.fullName }</b>{itsMe}<br/>{d?.email}<br />{ d?.isSupervisor ? "Supervisor" : "Member"}</p>
                </IonLabel>
              </IonItem>  
            )
          }
          else {
              return <></>
          }
        
      })}
      </IonList>
      </>
      
    )
  }
  if (loading) {
    
    return (<IonSpinner />)
  }

  return (<></>)

};


